import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem } from '@material-ui/core';
import { useStyles } from '@material-ui/pickers/views/Calendar/SlideTransition';
import { useFormikContext } from 'formik';

import Grid from '~/components/core/Atomic/Grid/Grid';
import { NumericTextFieldFormik, TextFieldFormik } from '~/components/TextFieldFormik';
import { NOTIFICATIONS_RULES_TIMEFRAMES, NOTIFICATIONS_SCHEDULES } from '~/Types';

export const EventScheduleSelect = ({ isSubmitting, selectedEventConfig }) => {
  const classes = useStyles();
  const { values } = useFormikContext();

  const isSelectedEventTimeBased = selectedEventConfig?.is_time_based;

  const timeframeOptions = Object.entries(NOTIFICATIONS_RULES_TIMEFRAMES)
    .filter(([, option]) => (isSelectedEventTimeBased ? option.is_available_for_time_based : true))
    .map(([key, option]) => ({
      key,
      label: option.desc,
    }));

  const shouldShowScheduleFields =
    NOTIFICATIONS_SCHEDULES[values.schedule]?.should_show_schedule_fields || isSelectedEventTimeBased;

  return (
    <>
      {!isSelectedEventTimeBased ? (
        <Grid item xs={2}>
          <TextFieldFormik
            id="schedule"
            label="Schedule"
            disabled={isSubmitting}
            className={classes.formTextField}
            fullWidth
            select
          >
            {Object.keys(NOTIFICATIONS_SCHEDULES).map((option) => (
              <MenuItem key={option} value={option}>
                {NOTIFICATIONS_SCHEDULES[option].desc}
              </MenuItem>
            ))}
          </TextFieldFormik>
        </Grid>
      ) : null}
      {shouldShowScheduleFields && (
        <Grid item xs={1}>
          <NumericTextFieldFormik
            id="schedule_number"
            label="Number"
            disabled={isSubmitting}
            className={classes.formTextField}
            fullWidth
          />
        </Grid>
      )}
      {shouldShowScheduleFields && (
        <Grid item xs={2}>
          <TextFieldFormik
            id="schedule_timeframe"
            label="Time Frame"
            disabled={isSubmitting}
            className={classes.formTextField}
            fullWidth
            select
          >
            {timeframeOptions.map(({ key, label }) => (
              <MenuItem key={key} value={key}>
                {label}
              </MenuItem>
            ))}
          </TextFieldFormik>
        </Grid>
      )}
      {!shouldShowScheduleFields && <Grid item xs={6} />}
    </>
  );
};

EventScheduleSelect.propTypes = {
  isSubmitting: PropTypes.bool,
  selectedEventConfig: PropTypes.shape({
    is_time_based: PropTypes.bool,
  }),
};
