import type { ChangeEvent, FC } from 'react';
import React from 'react';
import { Checkbox, Typography } from '@material-ui/core';

import cn from '~/Utils/cn';

import { colorPalette, useStyles } from '../../assets/styles';

export const POSITIONS = {
  START: 'start',
  END: 'end',
} as const;

type Position = (typeof POSITIONS)[keyof typeof POSITIONS];

interface CheckboxWithIconProps {
  icon?: React.ReactNode;
  text: React.ReactNode;
  className?: string;
  backgroundColor?: string;
  checked?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  onBlur?: () => void;
  iconLocation?: Position;
  disabled?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any; // For other props - should be removed eventually after validating no unknown props are passed
}

const CheckboxWithIcon: FC<CheckboxWithIconProps> = ({
  className,
  icon,
  text,
  backgroundColor,
  checked = false,
  onChange,
  iconLocation = POSITIONS.START,
  disabled,
  ...rest
}) => {
  const classes = useStyles();

  const textColor = disabled ? colorPalette.text.disabled : colorPalette.text.primary;

  const marginFromSideProp = iconLocation === POSITIONS.START ? 'marginRight' : 'marginLeft';
  const iconWrapper = <span style={{ [marginFromSideProp]: '10px' }}>{icon}</span>;

  const handleClick = (event: ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(event, !checked);
    }
  };

  return (
    <label className={cn(classes.radioOrCheckboxWithIcon, className)} style={{ backgroundColor }}>
      <Checkbox
        onChange={handleClick}
        style={{ color: checked ? '#1A9C9E' : '#707070' }}
        checked={checked}
        size="small"
        {...rest}
        disabled={disabled}
      />
      {iconLocation === POSITIONS.START && iconWrapper}
      <Typography display="block" variant="body1" style={{ color: textColor }}>
        {text}
      </Typography>
      {iconLocation === POSITIONS.END && iconWrapper}
    </label>
  );
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
CheckboxWithIcon.POSITIONS = POSITIONS;

export default CheckboxWithIcon;
