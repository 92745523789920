import React from 'react';
import { Switch as MuiSwitch } from '@material-ui/core';

import { useStyles } from '../../../assets/styles';

export interface SwitchProps {
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  disabled: boolean;
}

const Switch: React.FC<SwitchProps> = ({ checked, onChange, disabled }) => {
  const classes = useStyles();

  return (
    <MuiSwitch checked={checked} className={classes.formsSwitch} size="small" onChange={onChange} disabled={disabled} />
  );
};

export default Switch;
