import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { MenuItem, TextField } from '@material-ui/core';
import axios from 'axios';

import DialogFooterActions from '~/components/core/DialogFooterActions';

import { ASSIGNMENTS_MIXPANEL_EVENTS } from '../../../../pocs/mixpanel';
import { reportAxiosError } from '../../../../Utils';
import CardDialog from '../../../CardDialog';
import mixpanel from '../../../CmsMain/mixpanel';
import { Text } from '../../../core';
import useAssignments from '../../AssignmentsContext';
import { useSysconfig } from '../../SystemConfigurationScreen';

import { useStyles } from '../../../../assets/styles';
import styles from './AssignmentsTab.module.scss';

export const FallbackAdjusterDialog = ({ onClose }) => {
  const classes = useStyles();
  const { organization } = useSysconfig();
  const { assignmentsConfigurations, reloadDefaults, adjustersDict } = useAssignments();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedAdjusterId, setSelectedAdjusterId] = useState(assignmentsConfigurations.fallback_adjuster_id);

  const handleFallbackUserSubmit = async () => {
    try {
      setIsSubmitting(true);
      await axios.patch(`/api/v1/assignments/${organization.id}/defaults`, { fallback_user_id: selectedAdjusterId });

      mixpanel.track(ASSIGNMENTS_MIXPANEL_EVENTS.ASSIGNMENT_DEFAULT_USER_SUBMITTED, {
        default_user_id: selectedAdjusterId,
      });

      await reloadDefaults();
      onClose();
    } catch (error) {
      await reportAxiosError(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleClickOnDefaultUser = () => {
    mixpanel.track(ASSIGNMENTS_MIXPANEL_EVENTS.ASSIGNMENT_DEFAULT_USER_CLICKED, {
      default_user_id: assignmentsConfigurations.fallback_user_id,
    });
  };

  return (
    <CardDialog
      isDialog
      open={true}
      onClose={onClose}
      title="Default Assignee"
      submitText="Save"
      maxWidth="md"
      footerActions={<DialogFooterActions onClickPrimary={handleFallbackUserSubmit} onClickSecondary={onClose} />}
    >
      <div className={styles.dialogContainer}>
        <Text>In the event that no rules are applicable, claims will be assigned to</Text>
        <TextField
          placeholder="Fallback User"
          className={classes.textField}
          select
          value={assignmentsConfigurations.fallback_user_id}
          onChange={(e) => setSelectedAdjusterId(e.target.value)}
          disabled={isSubmitting}
          onClick={handleClickOnDefaultUser}
        >
          {Object.keys(adjustersDict).map((user_id) => (
            <MenuItem key={user_id} value={user_id}>
              {adjustersDict[user_id].username}
            </MenuItem>
          ))}
        </TextField>
      </div>
    </CardDialog>
  );
};

FallbackAdjusterDialog.propTypes = {
  onClose: PropTypes.func,
};
