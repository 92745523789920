import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { MenuItem, TextField } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import { useTheme } from '@material-ui/core/styles';
import _, { noop } from 'lodash';

import { useLobConfiguration } from '~/components/hooks/useLobConfiguration';

import { getLobDescription } from '../../../Utils/lobUtils';
import useOrganization from '../../OrganizationContext';

import useLobOptions from './hooks/useLobOptions';

import { useStyles } from '../../../assets/styles';
import colors from '../../../assets/colors.module.scss';

export const LobMultiSelect = ({
  subOrganizationIds = [],
  onChange = noop,
  disabled = false,
  label = 'Line of Business',
  textFieldProps,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const { subOrganizationEnabled } = useOrganization();
  const { lobOptions, isLoading } = useLobOptions({ subOrganizationIds });
  const { lobConfigurationsDict } = useLobConfiguration();
  const [selectedLobs, setSelectedLobs] = useState([]);

  const handleLobsChanged = useCallback(
    (lob) => {
      setSelectedLobs(lob);
      if (onChange) {
        onChange(lob);
      }
    },
    [onChange]
  );

  const handleSelectionChanged = useCallback(
    ({ target: { value: lobs } }) => {
      handleLobsChanged(lobs);
    },
    [handleLobsChanged]
  );

  useEffect(() => {
    const intersection = _.intersection(selectedLobs, lobOptions);
    if (selectedLobs.length !== intersection.length) {
      handleLobsChanged(intersection);
    }
  }, [lobOptions, isLoading, selectedLobs, handleLobsChanged]);

  const isDisabled = disabled || isLoading || (subOrganizationEnabled && _.isEmpty(subOrganizationIds));

  const isLobChecked = (lob) => {
    return selectedLobs.includes(lob);
  };

  const renderFieldValue = (lobs) => {
    return lobs.map((lob) => getLobDescription(lob, lobConfigurationsDict)).join(', ');
  };

  return (
    <TextField
      value={selectedLobs}
      label={label}
      className={classes.textField}
      disabled={isDisabled}
      onChange={handleSelectionChanged}
      select
      fullWidth
      SelectProps={{
        multiple: true,
        renderValue: renderFieldValue,
      }}
      {...textFieldProps}
    >
      {lobOptions.map((lob) => (
        <MenuItem key={lob} value={lob}>
          <Checkbox
            checked={isLobChecked(lob)}
            style={{ color: isLobChecked(lob) ? theme.palette.primary.darker : colors.checkboxUnchecked }}
          />
          <ListItemText primary={getLobDescription(lob, lobConfigurationsDict)} />
        </MenuItem>
      ))}
    </TextField>
  );
};

LobMultiSelect.propTypes = {
  subOrganizationIds: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
  textFieldProps: PropTypes.object,
};
