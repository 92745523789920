import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import axios from 'axios';
import { Formik, useFormikContext } from 'formik';
import { snakeCase } from 'lodash';
import * as Yup from 'yup';

import AlertBanner from '~/components/core/AlertBanner';
import LobMultiSelectWithChipsFormik from '~/components/core/Formik/LobMultiSelectWithChipsFormik';
import RadioButtonGroupFormik from '~/components/core/Formik/RadioButtonGroupFormik';

import { CLOSING_REASON_CATEGORIES } from '../../../../Types';
import { reportAxiosError } from '../../../../Utils';
import AutocompleteFormik from '../../../AutocompleteFormik';
import CardDialog from '../../../CardDialog';
import { LoadingSwitch } from '../../../core';
import DialogFooterActions from '../../../core/DialogFooterActions';
import useOrganization from '../../../OrganizationContext';
import TextFieldFormik, { useSetDefaultFieldsOnChange } from '../../../TextFieldFormik';
import SubOrganizationMultiselectFormik from '../../../TPA/SubOrganizations/SubOrganizationMultiselectFormik';
import { useSysconfig } from '../../SystemConfigurationScreen';

import { useStyles } from '../../../../assets/styles';

const NOTE_OPTIONS = {
  ENABLED: 'Enabled',
  DISABLED: 'Disabled',
  REQUIRED: 'Required',
};

const TYPE_OPTIONS = {
  claim: 'Claim',
  exposure: 'Exposure',
};

const getIsInUse = async (orgID, reasonToEdit) => {
  const {
    data: { is_in_use },
  } = await axios.get(
    `/api/v1/organizations/${orgID}/closing_reason_configs/${reasonToEdit.db_id}/is_in_use/${reasonToEdit.type}`
  );
  return is_in_use;
};

const AddEditReasonDialog = ({ onClose, onSubmit, closingReasonConfigs, reasonToEdit }) => {
  const { organization } = useSysconfig();
  const [isInUse, setIsInUse] = useState(false);
  const [isInUseLoaded, setIsInUseLoaded] = useState(false);
  const [isInUseError, setIsInUseError] = useState(false);

  useEffect(() => {
    const func = async () => {
      if (reasonToEdit) {
        try {
          const answer = await getIsInUse(organization.id, reasonToEdit);
          setIsInUse(answer);
          setIsInUseLoaded(true);
        } catch (err) {
          setIsInUseError(true);
        }
      }
    };
    return func();
  }, [organization.id, reasonToEdit]);

  const isLessItemsThanInUse = (currentArray, newArray) => {
    const currentSet = new Set(currentArray);
    const newSet = new Set(newArray);
    return [...currentSet].every((element) => newSet.has(element));
  };

  return (
    <Formik
      initialValues={{
        sub_org_ids: reasonToEdit?.sub_org_ids ?? [],
        is_org_level: reasonToEdit?.is_org_level ?? false,
        lobs: reasonToEdit?.lobs ?? [],
        is_all_lobs: reasonToEdit?.is_all_lobs ?? false,
        category: reasonToEdit?.category ?? '',
        sub_category: reasonToEdit?.sub_category ?? '',
        display_name: reasonToEdit?.display_name ?? '',
        closing_reason_key: reasonToEdit?.closing_reason_key ?? '',
        note_options: '',
        type: reasonToEdit?.type ?? '',
      }}
      validationSchema={Yup.object().shape({
        sub_org_ids: organization.sub_organizations_enabled
          ? Yup.array().when('is_org_level', {
              is: true,
              then: Yup.array(),
              otherwise: isInUse
                ? Yup.array().test('in_use_no_less', "Can't subtract Sub-Organizations when in use", (value) => {
                    if (reasonToEdit?.is_org_level) {
                      return false;
                    }
                    return isLessItemsThanInUse(reasonToEdit?.sub_org_ids, value);
                  })
                : Yup.array().min(1, 'Select at least one Sub-Organization'),
            })
          : undefined,
        lobs: Yup.array().when('is_all_lobs', {
          is: true,
          then: Yup.array(),
          otherwise: isInUse
            ? Yup.array().test('in_use_no_less', "Can't subtract Lines of Business when in use", (value) => {
                if (reasonToEdit?.is_all_lobs) {
                  return false;
                }
                return isLessItemsThanInUse(reasonToEdit?.lobs, value);
              })
            : Yup.array().min(1, 'Select at least one Line of Business'),
        }),
        category: Yup.string().required('Required'),
        sub_category: Yup.string().required('Required'),
        display_name: Yup.string().max(50).required('Required'),
        closing_reason_key: Yup.string()
          .max(50)
          .required('Required')
          .test('closing_reason_key_unique', 'Must be unique', function (value) {
            const closingReasonKeys = closingReasonConfigs
              .filter((x) => x.type === this.parent.type)
              .map((x) => x.closing_reason_key)
              .filter((x) => x !== reasonToEdit?.closing_reason_key);
            return !closingReasonKeys.includes(value);
          }),
        note_options: Yup.string().required('Required'),
        type: Yup.string().required('Required'),
      })}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          await onSubmit(
            {
              ...values,
              is_note_enabled: [NOTE_OPTIONS.ENABLED, NOTE_OPTIONS.REQUIRED].includes(values.note_options),
              is_note_required: [NOTE_OPTIONS.REQUIRED].includes(values.note_options),
            },
            reasonToEdit?.db_id
          );
          onClose();
        } catch (error) {
          await reportAxiosError(error);
          setSubmitting(false);
        }
      }}
    >
      <AddEditReasonDialogInner
        onClose={onClose}
        reasonToEdit={reasonToEdit}
        isInUseError={isInUseError}
        isInUseLoaded={isInUseLoaded}
        isInUse={isInUse}
      />
    </Formik>
  );
};

AddEditReasonDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  closingReasonConfigs: PropTypes.array.isRequired,
  reasonToEdit: PropTypes.object,
};

const AddEditReasonDialogInner = ({ onClose, reasonToEdit, isInUseError, isInUseLoaded, isInUse }) => {
  const { isSubmitting, handleSubmit, values, setFieldValue } = useFormikContext();
  const { subOrganizations, subOrganizationEnabled } = useOrganization();
  const classes = useStyles();

  useEffect(() => {
    const mapped_note_option = reasonToEdit?.is_note_required
      ? NOTE_OPTIONS.REQUIRED
      : reasonToEdit?.is_note_enabled
      ? NOTE_OPTIONS.ENABLED
      : NOTE_OPTIONS.DISABLED;
    setFieldValue('note_options', reasonToEdit ? mapped_note_option : NOTE_OPTIONS.ENABLED);
  }, [reasonToEdit, setFieldValue]);

  useSetDefaultFieldsOnChange(
    values.display_name,
    {
      closing_reason_key: snakeCase(values.display_name),
    },
    null,
    !!reasonToEdit || isInUse,
    true
  );

  return (
    <CardDialog
      isDialog
      title={`${reasonToEdit ? 'Edit' : 'Add'} Reason`}
      onClose={onClose}
      width="xs"
      fullWidth
      preventClose={isSubmitting}
      footerActions={
        <DialogFooterActions onClickPrimary={handleSubmit} onClickSecondary={onClose} disabled={isSubmitting} />
      }
    >
      <LoadingSwitch isLoading={!isInUseLoaded && !!reasonToEdit} isError={isInUseError && !!reasonToEdit}>
        <Grid container spacing={2}>
          {isInUse && (
            <Grid item xs={12}>
              <AlertBanner
                title="Please Note"
                note={'This closing reason is already in use, which limits available editing \n' + 'options.'}
                alertType={AlertBanner.ALERT_TYPES.INFO}
                className="mt-15"
                withIcon
              />
            </Grid>
          )}
          <Grid item xs={12} className="mt-20 flex">
            <RadioButtonGroupFormik
              className="block w-full"
              allbtnsContainerClassName="w-full flex flex-nowrap"
              btnClassName="w-full block"
              btnContainerClassName="w-full block"
              label="Apply to"
              id="type"
              direction="row"
              spacing={3}
              showOnly={!!reasonToEdit}
              options={Object.keys(TYPE_OPTIONS)?.map((option) => ({
                text: TYPE_OPTIONS[option],
                optionValue: option,
                iconLocation: 'right',
              }))}
            />
          </Grid>

          {subOrganizationEnabled && (
            <Grid item xs={12}>
              <SubOrganizationMultiselectFormik
                subOrganizationsFieldId="sub_org_ids"
                allSelectedFieldId="is_org_level"
                selectAllLabel="All (inc. future sub-orgs)"
                showAllInRenderedSelectedOptions
                shouldDisplayAllOption
              />
            </Grid>
          )}
          <Grid item xs={12} className="mt-20">
            <LobMultiSelectWithChipsFormik
              lobsFieldId="lobs"
              subOrganizationIds={values.is_org_level ? subOrganizations.map((s) => s.id) : values.sub_org_ids}
              allLobsFieldId="is_all_lobs"
              subOrgFieldId="sub_org_ids"
              shouldResetOnSubOrgChange={!isInUse}
            />
          </Grid>
          <Grid item xs={6}>
            <AutocompleteFormik
              id="category"
              label="Category"
              options={Object.keys(CLOSING_REASON_CATEGORIES)}
              getOptionLabel={(category) => CLOSING_REASON_CATEGORIES[category]?.display_name ?? ''}
              sortAlphabetic
              className={classes.formTextField}
              onChange={(_, newVal) => {
                setFieldValue('category', newVal ? newVal : '');
                setFieldValue('sub_category', '');
                setFieldValue('display_name', '');
                if (!isInUse) {
                  setFieldValue('closing_reason_key', '');
                }
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <AutocompleteFormik
              id="sub_category"
              label="Sub Category"
              options={Object.keys(CLOSING_REASON_CATEGORIES[values.category]?.sub_categories ?? [])}
              getOptionLabel={(subCategory) =>
                CLOSING_REASON_CATEGORIES[values.category]?.sub_categories[subCategory]?.display_name ?? ''
              }
              sortAlphabetic
              className={classes.formTextField}
              onChange={(_, newVal) => {
                setFieldValue('sub_category', newVal ? newVal : '');
                if (newVal) {
                  setFieldValue(
                    'display_name',
                    CLOSING_REASON_CATEGORIES[values.category]?.sub_categories[newVal]?.display_name
                  );
                  if (!isInUse) {
                    setFieldValue('closing_reason_key', newVal);
                  }
                } else {
                  setFieldValue('display_name', '');
                  if (!isInUse) {
                    setFieldValue('closing_reason_key', '');
                  }
                }
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextFieldFormik
              id="display_name"
              label="Display Name"
              className={classes.textField}
              fullWidth
              disabled={!values.category || !values.sub_category}
            />
          </Grid>
          <Grid item xs={6}>
            <TextFieldFormik
              id="closing_reason_key"
              label="Closing Reason Key"
              className={classes.textField}
              fullWidth
              disabled={!values.category || !values.sub_category || isInUse}
            />
          </Grid>
          <Grid item xs={12} className="mt-20 flex">
            <RadioButtonGroupFormik
              label="Note should be"
              id="note_options"
              direction="row"
              className="block w-full"
              allbtnsContainerClassName="flex flex-nowrap w-full"
              btnClassName="w-full block"
              btnContainerClassName="w-full block"
              spacing={3}
              options={[NOTE_OPTIONS.ENABLED, NOTE_OPTIONS.DISABLED, NOTE_OPTIONS.REQUIRED]?.map((option) => ({
                text: option,
                optionValue: option,
                iconLocation: 'right',
              }))}
            />
          </Grid>
        </Grid>
      </LoadingSwitch>
    </CardDialog>
  );
};

AddEditReasonDialogInner.propTypes = {
  onClose: PropTypes.func.isRequired,
  reasonToEdit: PropTypes.object,
  isInUseError: PropTypes.bool,
  isInUseLoaded: PropTypes.bool,
  isInUse: PropTypes.bool,
};
export default AddEditReasonDialog;
