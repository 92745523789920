import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import axios from 'axios';
import { Form, Formik, getIn } from 'formik';
import JSZip from 'jszip';
import { isEmpty } from 'lodash';
import * as Yup from 'yup';

import AlertBanner from '~/components/core/AlertBanner';
import Button from '~/components/core/Atomic/Buttons/Button';
import CollapsibleWrapper from '~/components/core/Collapsible/CollapsibleWrapper';

import { reportAxiosError } from '../../../Utils';
import CardDialog from '../../CardDialog';
import CancelButton from '../../core/Buttons/CancelButton';
import FileDropZone from '../../FileDropZone';
import useOrganization from '../../OrganizationContext';

import styles from './ImportDocumentsTemplatesZip.module.scss';

const collapsibleListsTitles = {
  overRideFiles: 'Override Files',
  subOrgDoesNotExist: 'Sub-org does not exist',
  lobDoesNotExist: 'Line of business does not exist',
  coverageDoesNotExist: 'Coverage does not exist',
  subOrgsNotEnabled: 'Sub orgs not enabled',
};

function FileContents({
  zipFile,
  existingDocumentTemplates = [],
  onCancel,
  reloadDataDocumentTemplates,
  reloadDataTemplatesBundles,
}) {
  const organization = useOrganization();
  const [files, setFiles] = useState([]);
  const [config, setConfig] = useState(undefined);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(undefined);

  const configValidator = ({ config }) => {
    const fileStatusLists = {
      newFiles: [],
      overRideFiles: [],
      errorLists: {
        subOrgDoesNotExist: [],
        lobDoesNotExist: [],
        coverageDoesNotExist: [],
        subOrgsNotEnabled: [],
      },
    };

    if (!config) {
      return fileStatusLists;
    }

    const existingFilenames = existingDocumentTemplates.map((template) => template.filename);

    const organizationSubOrgExternalIds = organization.sub_organizations_enabled
      ? (organization.subOrganizations || []).map((subOrg) => subOrg.external_id)
      : null;

    const organizationCoverages = Object.values(organization.supportedCoverages).flat();
    organizationCoverages.push('general');

    const organizationLobs = organization.orgLevelSupportedLobs;

    config.document_templates.forEach((template) => {
      let hasError = false;

      if (
        organization.sub_organizations_enabled &&
        !template.sub_organizations_external_ids.every((subOrgExternalId) =>
          organizationSubOrgExternalIds.includes(subOrgExternalId)
        )
      ) {
        fileStatusLists.errorLists.subOrgDoesNotExist.push(template);
        hasError = true;
      }

      if (!isEmpty(template.sub_organizations_external_ids) && !organization.subOrganizationEnabled) {
        fileStatusLists.errorLists.subOrgsNotEnabled.push(template);
        hasError = true;
      }

      if (!template.lobs.every((lob) => organizationLobs.includes(lob))) {
        fileStatusLists.errorLists.lobDoesNotExist.push(template);
        hasError = true;
      }

      if (!template.coverages.split(',').every((coverage) => organizationCoverages.includes(coverage))) {
        fileStatusLists.errorLists.coverageDoesNotExist.push(template);
        hasError = true;
      }

      if (!hasError) {
        if (existingFilenames.includes(template.filename)) {
          fileStatusLists.overRideFiles.push(template);
        } else {
          fileStatusLists.newFiles.push(template);
        }
      }
    });

    return fileStatusLists;
  };

  useEffect(() => {
    const loadZip = async () => {
      try {
        const zip = new JSZip();
        const zipContent = await zip.loadAsync(zipFile);
        if (!zipContent.file('document_templates_configuration.json')) {
          setError({ message: 'No document_templates_configuration.json in zip' });
          return;
        }
        const configFile = await zipContent.file('document_templates_configuration.json').async('string');
        const config = JSON.parse(configFile);
        setConfig(config);
        setFiles(Object.values(zipContent.files));
      } catch (error) {
        setError(error);
      }
    };

    loadZip();
  }, [zipFile]);

  const { newFiles, overRideFiles, errorLists } = configValidator({
    config,
    files,
  });

  const handleSave = async () => {
    try {
      setIsSubmitting(true);

      const finalDocumentTemplates = [...newFiles, ...overRideFiles];

      const finalConfigFile = {
        ...config,
        document_templates: finalDocumentTemplates,
      };

      const jsonConfig = JSON.stringify(finalConfigFile);

      const finalDocumentTemplatesFileNames = finalDocumentTemplates.map((template) => template.filename);

      const filteredFiles = files.filter((file) => finalDocumentTemplatesFileNames.includes(file.name));

      const newZip = new JSZip();

      filteredFiles.forEach((file) => {
        newZip.file(file.name, file.async('blob'));
      });

      newZip.file('document_templates_configuration.json', jsonConfig);
      const blob = await newZip.generateAsync({ type: 'blob' });
      const formData = new FormData();

      formData.append('file', blob, 'document_templates.zip');

      try {
        await axios.post(
          `/api/v1/document_templates/organizations/${organization.organizationId}/import_document_templates`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        );
        await reloadDataTemplatesBundles();
        await reloadDataDocumentTemplates();
        onCancel();
      } catch (error) {
        reportAxiosError(error, false);
        setError(error);
      }
    } catch (error) {
      setError(error);
    }
    setIsSubmitting(false);
  };

  const filteredErrorLists = Object.keys(errorLists).filter((listKey) => !isEmpty(errorLists[listKey]));

  if (error) {
    return (
      <div className={styles.zipFileListsContainer}>
        <Typography color="error" align="center">
          {typeof error?.message === 'string' ? error?.message : 'Error importing document templates'}
        </Typography>
        <div className={styles.zipUploadButtonsContainer}>
          <CancelButton onClick={onCancel} />
        </div>
      </div>
    );
  }

  return (
    <div className={styles.zipFileListsContainer}>
      <div className={styles.collapsibleListsSection}>
        {isEmpty(newFiles) ? null : (
          <div className={styles.doneAlertContainer}>
            <AlertBanner
              alertType={AlertBanner.ALERT_TYPES.DONE}
              note={`${newFiles.length} new files will be imported`}
              withIcon
            />
          </div>
        )}
        {isEmpty(overRideFiles) ? null : (
          <div>
            <AlertBanner
              alertType={AlertBanner.ALERT_TYPES.WARNING}
              note="Below files exist in the organization and will be overwritten"
              withIcon
            />

            <div className={styles.collapsibleListsContainer}>
              <CollapsibleWrapper
                title="Override Files"
                subtitle={`${overRideFiles.length} Files`}
                variant={CollapsibleWrapper.VARIANT.slim}
                expansionMode={CollapsibleWrapper.EXPANSION_MODES.grow}
              >
                <ul className={styles.zipFileList}>
                  {overRideFiles.map((file) => (
                    <li key={file.filename} className={styles.listItem}>
                      {file.display_name} ({file.filename})
                    </li>
                  ))}
                </ul>
              </CollapsibleWrapper>
            </div>
          </div>
        )}
        {!isEmpty(filteredErrorLists) ? (
          <div className={styles.zipFilesErrorContainer}>
            <AlertBanner
              alertType={AlertBanner.ALERT_TYPES.ERROR}
              note="Files with errors will not be imported:"
              withIcon
            />
            <div className={styles.collapsibleListsContainer}>
              {filteredErrorLists.map((listKey) => (
                <CollapsibleWrapper
                  title={collapsibleListsTitles[listKey]}
                  subtitle={`${errorLists[listKey].length} Files`}
                  key={listKey}
                  variant={CollapsibleWrapper.VARIANT.slim}
                  titleColor={CollapsibleWrapper.TITLE_COLOR.error}
                  expansionMode={CollapsibleWrapper.EXPANSION_MODES.grow}
                >
                  <ul className={styles.zipFileList}>
                    {errorLists[listKey].map((file) => (
                      <li key={file.filename} className={styles.listItem}>
                        {file.display_name} ({file.filename})
                      </li>
                    ))}
                  </ul>
                </CollapsibleWrapper>
              ))}
            </div>
          </div>
        ) : null}
      </div>
      <div className={styles.zipUploadButtonsContainer}>
        <CancelButton onClick={onCancel} withMarginRight disabled={isSubmitting} />

        <Button color="primary" variant="contained" onClick={handleSave} disabled={isSubmitting}>
          Save
        </Button>
      </div>
    </div>
  );
}

FileContents.propTypes = {
  zipFile: PropTypes.object.isRequired,
  existingDocumentTemplates: PropTypes.arrayOf(PropTypes.object),
  onCancel: PropTypes.func.isRequired,
  reloadDataDocumentTemplates: PropTypes.func.isRequired,
  reloadDataTemplatesBundles: PropTypes.func.isRequired,
};

function ImportDocumentTemplatesZip({
  onCancel,
  documentTemplates,
  reloadDataDocumentTemplates,
  reloadDataTemplatesBundles,
}) {
  const [zipFile, setZipFile] = useState(null);

  const handleDocumentSubmit = (values, { setSubmitting }) => {
    setZipFile(values['file']);
    setSubmitting(false);
  };

  return (
    <CardDialog
      isDialog={true}
      maxWidth="sm"
      title="Import Documents Templates"
      onClose={onCancel}
      preventClose={false}
      fullWidth
    >
      <Formik
        initialValues={{ file: undefined, file_size: '' }}
        onSubmit={handleDocumentSubmit}
        validationSchema={Yup.object().shape({
          file: Yup.mixed()
            .required('Please select a file')
            .test('fileType', 'Please select a zip file', (value) => value && value.type === 'application/zip'),
          file_size: Yup.number().required('File Required').min(1, 'File size has to be larger then 0.'), // we prefer validating file_size to file since we can vouch for its value
        })}
      >
        {({ isSubmitting, setValues, setTouched, values, errors, touched, resetForm }) => {
          const handleFileSelect = (file) => {
            setValues({
              file,
              file_size: file.size,
            });

            setTouched({
              file: true,
              file_size: true,
            });
          };

          const handleCancelFileSelect = () => {
            setValues({
              file: undefined,
              file_size: undefined,
            });

            setZipFile(null);
          };

          const handleCancel = () => {
            setZipFile(null);
            resetForm();
            onCancel();
          };

          return (
            <Form>
              <FileDropZone
                onFileSelect={handleFileSelect}
                onCancelFileSelect={handleCancelFileSelect}
                file={values['file']}
                uploadPercent={0}
                error={
                  (getIn(errors, 'file') && getIn(touched, 'file')) ||
                  (getIn(errors, 'file_size') && getIn(touched, 'file_size'))
                }
                errorMessage={getIn(errors, 'file') || getIn(errors, 'file_size')}
                dropZoneOptions={{ accept: '.zip' }}
              />
              {zipFile ? (
                <FileContents
                  zipFile={zipFile}
                  existingDocumentTemplates={documentTemplates}
                  onCancel={handleCancel}
                  reloadDataTemplatesBundles={reloadDataTemplatesBundles}
                  reloadDataDocumentTemplates={reloadDataDocumentTemplates}
                />
              ) : (
                <div className={styles.zipUploadButtonsContainer}>
                  <CancelButton
                    onClick={handleCancel}
                    withMarginRight
                    disabled={isSubmitting}
                    className={styles.cancelButton}
                  />

                  <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    disabled={isSubmitting || !values['file'] || !isEmpty(errors)}
                  >
                    Next
                  </Button>
                </div>
              )}
            </Form>
          );
        }}
      </Formik>
    </CardDialog>
  );
}

ImportDocumentTemplatesZip.propTypes = {
  onCancel: PropTypes.func.isRequired,
  documentTemplates: PropTypes.array.isRequired,
  reloadDataDocumentTemplates: PropTypes.func.isRequired,
  reloadDataTemplatesBundles: PropTypes.func.isRequired,
};

export default ImportDocumentTemplatesZip;
