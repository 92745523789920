import React from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { flatten, get, isEmpty, snakeCase } from 'lodash';

import { useStyles } from '~/assets/styles';
import Grid from '~/components/core/Atomic/Grid/Grid';
import InnerCard from '~/components/core/Cards/InnerCard';
import TextFieldFormik, { useSetDefaultFieldsOnChange } from '~/components/TextFieldFormik';

import cn from '../../../../../../Utils/cn';
import { getObjectPaths } from '../../../../../../Utils/index';
import TrashIcon from '../../../../../icons/TrashIcon';
import InlineIconButton from '../../../../../InlineIconButton';
import copies from '../../copies.json';

const ID_PREFIX = 'configuration.categories';

const CategoryCard = ({ index, arrayHelpers }) => {
  const { initialValues, values, setFieldTouched } = useFormikContext();
  const classes = useStyles();

  const isSavedField = !isEmpty(get(initialValues, `${ID_PREFIX}[${index}]`));
  const field = get(values, `${ID_PREFIX}[${index}]`, {});
  const paths = getObjectPaths(values)?.filter((path) => path.split('.').includes('incident_sub_type_categories'));
  const isCategoryUsed = flatten(paths?.map((path) => get(values, path, [])))?.includes(field.id);
  const onDelete = () => arrayHelpers.remove(index);

  useSetDefaultFieldsOnChange(
    field.desc,
    {
      [`${ID_PREFIX}[${index}].id`]: snakeCase(field.desc),
    },
    () => [`${ID_PREFIX}[${index}].id`].forEach((field) => setFieldTouched(field, false)),
    isSavedField
  );

  return (
    <InnerCard>
      <Grid container spacing={3}>
        <Grid item xs={5}>
          <TextFieldFormik id={`${ID_PREFIX}[${index}].desc`} label="Display Name" fullWidth />
        </Grid>
        <Grid item xs={5}>
          <TextFieldFormik id={`${ID_PREFIX}[${index}].id`} label="Key" fullWidth disabled={isSavedField} />
        </Grid>
        <Grid item xs={2}>
          <div className="flex items-center justify-center p-15">
            <InlineIconButton
              icon={TrashIcon}
              tooltipTitle={isCategoryUsed ? copies.categories.category_in_use : copies.categories.remove}
              iconStyle={{ display: 'flex' }}
              className={cn(classes.textIcon, classes.hoverableNonFilledIcon)}
              onClick={onDelete}
              hideErrorGap
              disabled={isCategoryUsed}
            />
          </div>
        </Grid>
      </Grid>
    </InnerCard>
  );
};

CategoryCard.propTypes = {
  index: PropTypes.number.isRequired,
  arrayHelpers: PropTypes.object.isRequired,
};

export default CategoryCard;
