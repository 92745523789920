import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox,
  Divider,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useFormikContext } from 'formik';
import { groupBy, isEmpty, noop } from 'lodash';

import Button from '~/components/core/Atomic/Buttons/Button';

import OverflowTextWithToolTip from '../../../OverflowTextWithToolTip';
import SimpleSearchField from '../../SimpleSearchField';
import { ErrorHelperTextFormik } from '../ErrorHelperTextFormik';

import colors from '../../../../assets/colors.module.scss';
import styles from './SearchWithOptionsFormik.module.scss';

const checkBoxStyle = makeStyles((theme) => ({
  root: {
    color: colors.headlineColor,
    '&$checked': {
      color: theme.palette.primary.darker,
    },
  },
  checked: {},
}));

const SelectAllContainer = React.memo(({ entries, selectAllButtonText, mainFieldId, onChange }) => {
  const { setFieldValue, values, isSubmitting } = useFormikContext();
  const selectedList = values[mainFieldId];
  const classesCheckBox = checkBoxStyle();

  const { disabledCheckboxesIds, enabledCheckboxesIds } = React.useMemo(() => {
    const groupedEntries = groupBy(entries, 'isDisabled');
    const disabledCheckboxesIds = (groupedEntries[true] || []).map((entry) => entry.id);
    const enabledCheckboxesIds = (groupedEntries[false] || []).map((entry) => entry.id);
    return { disabledCheckboxesIds, enabledCheckboxesIds };
  }, [entries]);

  const updateAll = React.useCallback(
    (newValue) => {
      const disabledCheckedCheckboxes = disabledCheckboxesIds.filter((id) => selectedList.includes(id));

      if (newValue) {
        const newValues = entries.reduce((acc, entry) => {
          if (!entry.isDisabled) {
            acc.push(entry.id);
          }
          return acc;
        }, []);
        setFieldValue(mainFieldId, [...disabledCheckedCheckboxes, ...newValues]);
      } else {
        setFieldValue(mainFieldId, [...disabledCheckedCheckboxes]);
      }
    },
    [mainFieldId, selectedList, setFieldValue, disabledCheckboxesIds, entries]
  );

  return (
    <div className={styles.selectAllContainer}>
      <div>
        <Checkbox
          classes={classesCheckBox}
          color="primary"
          checked={enabledCheckboxesIds.every((id) => selectedList.includes(id))}
          disabled={isSubmitting}
          onClick={(evt) => {
            updateAll(evt.target.checked);
            onChange({ checked: evt.target.checked });
          }}
        />
        <Button
          className={styles.selectButton}
          variant="text"
          color="primary"
          disabled={isSubmitting}
          onClick={() => {
            updateAll(true);
            onChange({ checked: true });
          }}
        >
          {selectAllButtonText}
        </Button>
      </div>
      <Button
        className={styles.selectButton}
        variant="text"
        color="primary"
        onClick={() => {
          updateAll(false);
          onChange({ checked: false });
        }}
      >
        Clear
      </Button>
    </div>
  );
});

SelectAllContainer.displayName = 'SelectAllContainer';

const entryPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  entryTitle: PropTypes.string.isRequired,
  entrySubtitle: PropTypes.string.isRequired,
  secondaryAction: PropTypes.node,
  isDisabled: PropTypes.bool,
});

SelectAllContainer.propTypes = {
  mainFieldId: PropTypes.string.isRequired,
  selectAllButtonText: PropTypes.string.isRequired,
  entries: PropTypes.arrayOf(entryPropType),
  onChange: PropTypes.func,
};

SelectAllContainer.defaultProps = {
  onChange: noop,
};

const listItemTextStyle = makeStyles({
  primary: {
    color: colors.textPrimary,
  },
  secondary: {
    color: colors.textSecondary,
  },
});

const dividerStyle = makeStyles({
  root: {},
  inset: {
    marginLeft: '0',
  },
});

const OptionsList = React.memo(
  ({ showOnly, classes, mainFieldValue, entry, onChange, submitting, classes1, maxWidth, classes2 }) => {
    const onChangeHandler = React.useCallback(() => {
      onChange(entry);
    }, [onChange, entry]);

    return (
      <React.Fragment>
        <ListItem component="label" className={styles.listItem}>
          {!showOnly ? (
            <Checkbox
              classes={classes}
              checked={mainFieldValue.includes(entry.id)}
              onChange={onChangeHandler}
              id={entry.id}
              disabled={entry.isDisabled || submitting}
            />
          ) : null}
          <ListItemText
            classes={classes1}
            primary={<OverflowTextWithToolTip maxWidth={maxWidth}>{entry.entryTitle}</OverflowTextWithToolTip>}
            secondary={<OverflowTextWithToolTip maxWidth={maxWidth}>{entry.entrySubtitle}</OverflowTextWithToolTip>}
          />
          <ListItemSecondaryAction>
            <OverflowTextWithToolTip maxWidth={maxWidth}>{entry.secondaryAction}</OverflowTextWithToolTip>
          </ListItemSecondaryAction>
        </ListItem>
        <Divider variant="inset" component="li" classes={classes2} />
      </React.Fragment>
    );
  }
);

OptionsList.displayName = 'OptionsList';

OptionsList.propTypes = {
  showOnly: PropTypes.bool,
  classes: PropTypes.object,
  mainFieldValue: PropTypes.array,
  entry: PropTypes.any,
  onChange: PropTypes.func,
  submitting: PropTypes.bool,
  classes1: PropTypes.any,
  maxWidth: PropTypes.any,
  classes2: PropTypes.any,
};

const OptionsContainer = React.memo(({ options = [], mainFieldId, textMaxWidth, showOnly, onChange }) => {
  const { isSubmitting, values, setFieldValue } = useFormikContext();
  const selectedList = values[mainFieldId];
  const listItemTextClasses = listItemTextStyle();
  const classesCheckBox = checkBoxStyle();
  const dividerClasses = dividerStyle();

  const onChangeHandler = React.useCallback(
    (entry) => {
      const currValues = [...selectedList];
      const entryIndex = currValues.indexOf(entry.id);
      if (entryIndex === -1) {
        currValues.push(entry.id);
        setFieldValue(mainFieldId, currValues);
        onChange({ value: entry.id, checked: true });
      } else {
        currValues.splice(entryIndex, 1);
        setFieldValue(mainFieldId, currValues);
        onChange({ value: entry.id, checked: false });
      }
    },
    [mainFieldId, selectedList, setFieldValue, onChange]
  );

  if (isEmpty(options)) {
    return (
      <div className={styles.optionsContainer}>
        <Typography variant="body1" className={styles.emptyResults}>
          No options found, please update the search term
        </Typography>
      </div>
    );
  }

  return (
    <div className={styles.optionsContainer}>
      {options.map((option, idx) => (
        <React.Fragment key={option.title}>
          {idx !== 0 && <Divider />}
          <div>
            {/* Not displaying the titles if there is only one "category, this is a temp solution and might change in the future" */}
            {options.length > 1 ? (
              <div className={styles.titleContainer}>
                <Typography variant="subtitle1">{option.title}</Typography>
                <Typography variant="subtitle2" className={styles.subTitle}>
                  {option.subtitle}
                </Typography>
              </div>
            ) : null}
            <List>
              {option.entries.map((entry) => {
                return (
                  <OptionsList
                    key={entry.id}
                    showOnly={showOnly}
                    classes={classesCheckBox}
                    mainFieldValue={selectedList}
                    entry={entry}
                    onChange={onChangeHandler}
                    submitting={isSubmitting}
                    classes1={listItemTextClasses}
                    maxWidth={textMaxWidth}
                    classes2={dividerClasses}
                  />
                );
              })}
            </List>
          </div>
        </React.Fragment>
      ))}
      <ErrorHelperTextFormik id={mainFieldId} />
    </div>
  );
});
OptionsContainer.displayName = 'OptionsContainer';

const optionsPropType = PropTypes.arrayOf(
  PropTypes.shape({
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    entries: PropTypes.arrayOf(entryPropType).isRequired,
  })
);

OptionsContainer.propTypes = {
  mainFieldId: PropTypes.string.isRequired,
  options: optionsPropType.isRequired,
  textMaxWidth: PropTypes.string,
  showOnly: PropTypes.bool,
  onChange: PropTypes.func,
};

OptionsContainer.defaultProps = {
  onChange: noop,
  showOnly: false,
};

const SearchWithOptionsFormik = React.memo(
  ({
    mainFieldId,
    isSelectAllEnabled,
    options,
    selectAllButtonText,
    label,
    className,
    textMaxWidth,
    showOnly,
    onSelectAll,
    onChange,
  }) => {
    const [search, setSearch] = useState(null);
    const entriesForSelectAll = React.useMemo(() => options.map((option) => option.entries).flat(), [options]);
    const filteredOptions = React.useMemo(() => {
      if (!search) {
        return options;
      }
      return options
        .map((option) => {
          const entries = option.entries.filter((entry) => {
            return `${entry.entryTitle} ${entry.entrySubtitle}`.toLowerCase().includes(search.toLowerCase());
          });
          const modOption = {
            ...option,
            entries,
          };
          return modOption;
        })
        .filter((option) => {
          return option.entries.length !== 0;
        });
    }, [options, search]);

    const handleSearchChange = (search) => {
      setSearch(search);
    };

    return (
      <div className={className}>
        <SimpleSearchField renderClearButton label={label} onChange={handleSearchChange} />
        {!showOnly && isSelectAllEnabled && !search ? (
          <SelectAllContainer
            mainFieldId={mainFieldId}
            selectAllButtonText={selectAllButtonText}
            entries={entriesForSelectAll}
            onChange={onSelectAll}
          />
        ) : null}
        <OptionsContainer
          showOnly={showOnly}
          mainFieldId={mainFieldId}
          options={filteredOptions}
          textMaxWidth={textMaxWidth}
          onChange={onChange}
        />
      </div>
    );
  }
);
SearchWithOptionsFormik.displayName = 'SearchWithOptionsFormik';

SearchWithOptionsFormik.propTypes = {
  selectAllButtonText: PropTypes.string,
  label: PropTypes.string.isRequired,
  isSelectAllEnabled: PropTypes.bool.isRequired,
  options: optionsPropType.isRequired,
  mainFieldId: PropTypes.string.isRequired,
  className: PropTypes.string,
  textMaxWidth: PropTypes.string,
  showOnly: PropTypes.bool,
  onSelectAll: PropTypes.func,
  onChange: PropTypes.func,
};

SearchWithOptionsFormik.defaultProps = {
  className: '',
  showOnly: false,
  selectAllButtonText: 'Select All',
  onSelectAll: noop,
  onChange: noop,
};

export default SearchWithOptionsFormik;
