import React from 'react';
import { func, string } from 'prop-types';
import { IconButton, MenuItem } from '@material-ui/core';
import { noop } from 'lodash';

import Grid from '~/components/core/Atomic/Grid/Grid';
import cn from '~/Utils/cn';

import { PERFORMANCE_QA } from '../../../../../../../../Types';
import { TrashIcon_Deprecated } from '../../../../../../../icons';
import TextFieldFormik from '../../../../../../../TextFieldFormik';

import { useStyles } from '../../../../../../../../assets/styles';
import sectionStyles from '../../sections.module.scss';
import styles from '../customQuestions.module.scss';

const TEXT_MAX_LENGTH = 256;
const EXPLANATION_MAX_LENGTH = 128;

const Question = ({ id, onDelete = noop }) => {
  const classes = useStyles();

  return (
    <Grid container spacing={4} className={sectionStyles.questionContainer}>
      <Grid item xs={7}>
        <TextFieldFormik
          id={`${id}.text`}
          className={cn(classes.textField, sectionStyles.questionBorder, styles.questionText)}
          label="Fill free text question"
          defaultValue=""
          fullWidth
          inputProps={{ maxLength: TEXT_MAX_LENGTH }}
        />
      </Grid>
      <Grid item xs={2}>
        <TextFieldFormik
          id={`${id}.category`}
          label="Category"
          select
          fullWidth
          defaultValue=""
          className={cn(classes.textField, sectionStyles.questionBorder, sectionStyles.questionSelect)}
        >
          {Object.entries(PERFORMANCE_QA.CATEGORIES).map(([key, { desc }]) => (
            <MenuItem key={key} value={key}>
              {desc}
            </MenuItem>
          ))}
        </TextFieldFormik>
      </Grid>
      <Grid item xs={2}>
        <TextFieldFormik
          id={`${id}.explanation`}
          className={cn(classes.textField, sectionStyles.questionBorder, styles.questionText)}
          label="Explanation"
          defaultValue=""
          fullWidth
          inputProps={{ maxLength: EXPLANATION_MAX_LENGTH }}
        />
      </Grid>
      <Grid item xs={1} className={styles.freeTextRemoveContainer}>
        <IconButton onClick={onDelete} className={styles.freeTextRemove}>
          <TrashIcon_Deprecated size={20} />
        </IconButton>
      </Grid>
    </Grid>
  );
};

Question.propTypes = {
  id: string.isRequired,
  onDelete: func,
};

export default Question;
