import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { Chip } from '@mui/material';
import axios from 'axios';
import _ from 'lodash';
import moment from 'moment/moment';

import { useStyles } from '~/assets/styles';
import Grid from '~/components/core/Atomic/Grid/Grid';
import RadioWithButtonWrapper from '~/components/core/RadioWithButtonWrapper';
import ClosedExposureExpensesLimitDialog from '~/components/SystemConfiguration/Tabs/PaymentsConfiguration/PaymentConfigTab/AdditionalDialogs/ClosedExposureLimitDialog/ClosedExposureExpensesLimitDialog';
import ClosedExposureIndemnityLimitDialog from '~/components/SystemConfiguration/Tabs/PaymentsConfiguration/PaymentConfigTab/AdditionalDialogs/ClosedExposureLimitDialog/ClosedExposureIndemnityLimitDialog';
import OfacScreenConfigurationDialog from '~/components/SystemConfiguration/Tabs/PaymentsConfiguration/PaymentConfigTab/AdditionalDialogs/OfacScreenConfigurationDialog';
import SelectManagersConfigurationDialog from '~/components/SystemConfiguration/Tabs/PaymentsConfiguration/PaymentConfigTab/AdditionalDialogs/SelectManagersConfigurationDialog';
import {
  CONFIGURATION_FEATURES_NAMES,
  DUPLICATE_PAYMENTS_VERIFICATION_ACTION,
  PAYMENT_METHODS_DETAILS_DICT,
} from '~/Types';
import { isFeatureEnabled, reportAxiosError } from '~/Utils';

import Text from '../../../..//core/TextComponents/Text/index';
import CardDialog from '../../../../CardDialog';
import WithConfirm from '../../../../ConfirmModal';
import { FsTooltip, LoadingSwitch, SortableTable } from '../../../../core';
import useOrganization from '../../../../OrganizationContext';
import useLobOptions from '../../../../TPA/LOB/hooks/useLobOptions';
import useDataFetcher from '../../../../useDataFetcher';
import { useSysconfig } from '../../../SystemConfigurationScreen';
import PaymentsConfigurationForm from '../PaymentsConfigurationForm';

import EditDuplicatePaymentsConfigurationDialog from './AdditionalDialogs/EditDuplicatePaymentsConfigurationDialog';
import PresentApprovalScreenConfigurationDialog from './AdditionalDialogs/PresentApprovalScreenConfigurationDialog';
import ExpensesSubReservesContainer from './SubReservesTypesDialog/ExpensesSubReservesContainer';
import ConfigSwitch from './ConfigSwitch';
import { CONFIG_PER_VALUES, PAYMENTS_CONFIG_FIELDS } from './constants';
import PaymentMethodSpecialCell from './PaymentMethodSpecialCell';
import { getUserIdsWithImplicitApprovalPermission } from './utils';

import styles from '../PaymentsConfigurationForm.module.scss';

const ADJUSTERS_NUM_APPROVAL_SCREEN_CONFIG = 7;

const PaymentsConfigTab = ({ processIncomingConfigValues, processOutgoingConfigValues }) => {
  const { organization, users } = useSysconfig();
  const { subOrganizations } = useOrganization();
  const classes = useStyles();

  const usersDict = _.keyBy(users, 'id');

  const { isLoading, isError, data, reloadData } = useDataFetcher(
    `/sysconfig/api/v1/organizations/${organization.id}/payments_configuration`
  );

  const paymentsConfiguration = data?.payments_configuration;
  const isConfigPerOrg = paymentsConfiguration?.is_org_level_configuration;
  const paymentsMethodConfigurations = paymentsConfiguration?.payment_method_configurations || [];

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isGlobalPaymentsSubmitting, setIsGlobalPaymentsSubmitting] = useState(false);
  const [activePaymentMethodConfig, setActivePaymentMethodConfig] = useState(null);
  const [isNewPaymentMethodConfig, setIsNewPaymentMethodConfig] = useState(false);

  const commerceBankEnabled = !!_.find(
    paymentsMethodConfigurations,
    (config) => config.payment_method === 'commerce_bank' && !config.is_disabled
  );

  const { lobOptions, isLoading: isLobLoading } = useLobOptions({
    subOrganizationIds: [],
  });
  const isHomeLobEnabledForOrg = lobOptions?.includes('home_claim');

  const handleOpenDialog = (paymentMethodConfig) => {
    setActivePaymentMethodConfig(paymentMethodConfig);
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsNewPaymentMethodConfig(false);
    setIsDialogOpen(false);
  };

  const getLastUpdatedDate = (columnRow) => {
    let datesArray;

    if (isConfigPerOrg) {
      datesArray = paymentsMethodConfigurations?.map((paymentMethodConfig) => paymentMethodConfig.last_updated);
    } else {
      datesArray = paymentsMethodConfigurations
        ?.filter((paymentMethodConfig) => paymentMethodConfig.sub_organization_id === columnRow.id)
        .map((paymentMethodConfig) => paymentMethodConfig.last_updated);
    }

    return datesArray.length > 0 ? moment(_.max(datesArray)).format('DD/MM/YYYY HH:mm') : null;
  };

  const getColumns = () => {
    const columnData = [
      {
        id: 'name',
        label: isConfigPerOrg ? 'Org Name' : 'Sub-org Name',
      },
      {
        id: 'last_updated',
        label: 'Last Updated',
        specialCell: getLastUpdatedDate,
      },
    ];

    Object.keys(PAYMENT_METHODS_DETAILS_DICT)
      .filter((key) => {
        let res = true;
        if (
          PAYMENT_METHODS_DETAILS_DICT[key].feature_flag &&
          !isFeatureEnabled(organization, PAYMENT_METHODS_DETAILS_DICT[key].feature_flag)
        ) {
          res = false;
        }
        if (key === 'one_inc' && !data?.additional_details?.is_one_inc_configured) {
          res = false;
        }
        return res;
      })
      .map((key) => {
        columnData.push({
          id: key,
          label: `${PAYMENT_METHODS_DETAILS_DICT[key].display_name} Method`,
          disableSort: true,
          width: '12.5%',
          specialCell: (columnRow) => (
            <PaymentMethodSpecialCell
              orgId={organization.id}
              subOrgId={isConfigPerOrg ? undefined : columnRow.id}
              methodName={key}
              paymentsMethods={paymentsMethodConfigurations}
              handleOpenDialog={handleOpenDialog}
              handleIsNewPaymentMethodConfig={setIsNewPaymentMethodConfig}
              isDisabled={isGlobalPaymentsSubmitting}
            />
          ),
        });
      });

    return columnData;
  };

  const getRows = () => {
    if (!organization.sub_organizations_enabled || isConfigPerOrg) {
      return [organization];
    }
    return subOrganizations;
  };

  const handlePaymentsConfigUpdate = async (payload, throwOnError = false) => {
    try {
      setIsGlobalPaymentsSubmitting(true);
      await axios.patch(`/sysconfig/api/v1/organizations/${organization.id}/payments_configuration`, payload);
      await reloadData();
    } catch (error) {
      await reportAxiosError(error);

      if (throwOnError) {
        throw error;
      }
    } finally {
      setIsGlobalPaymentsSubmitting(false);
    }
  };

  const handleFormSubmit = async (values, methodName) => {
    try {
      const payload = { ...activePaymentMethodConfig, ...processOutgoingConfigValues(values) };
      if (isNewPaymentMethodConfig) {
        await axios.post(
          `/sysconfig/api/v1/organizations/${organization.id}/payments_configuration/${methodName}`,
          payload
        );
        setIsNewPaymentMethodConfig(false);
      } else {
        await axios.put(
          `/sysconfig/api/v1/organizations/${organization.id}/payments_configuration/${methodName}`,
          payload
        );
      }
      await reloadData();
      setIsDialogOpen(false);
    } catch (e) {
      await reportAxiosError(e);
    }
  };

  const validateIsLastEnabledPaymentMethod = () => {
    let basePaymentMethodConfigsArray = paymentsMethodConfigurations;
    if (!isConfigPerOrg) {
      basePaymentMethodConfigsArray = basePaymentMethodConfigsArray.filter(
        (paymentMethodConfig) =>
          paymentMethodConfig.sub_organization_id === activePaymentMethodConfig?.sub_organization_id
      );
    }
    return !basePaymentMethodConfigsArray
      .filter((paymentMethodConfig) => paymentMethodConfig.payment_method !== activePaymentMethodConfig?.payment_method)
      .some((paymentMethodConfig) => !paymentMethodConfig.is_disabled);
  };

  const approvingUserIds = Array.from(
    new Set(
      (paymentsConfiguration?.[PAYMENTS_CONFIG_FIELDS.ADDITIONAL_APPROVING_USERS] || []).concat(
        getUserIdsWithImplicitApprovalPermission(users)
      )
    )
  );
  const submittingUserIds = paymentsConfiguration?.[PAYMENTS_CONFIG_FIELDS.ADDITIONAL_SUBMITTING_USER_IDS] || [];

  return (
    <CardDialog title="Payments Configuration" containerClassName={styles.paymentsV3Container}>
      <LoadingSwitch isLoading={isLoading || isLobLoading} isError={isError}>
        <Typography variant="body1">Manage payment methods, configurations and settings</Typography>
        <Grid container spacing={2} className={styles.switchesContainer}>
          <ConfigSwitch
            title="Allow expense payments in a closed exposure"
            paymentsConfiguration={paymentsConfiguration}
            configKey={PAYMENTS_CONFIG_FIELDS.ENABLE_PAYING_EXPENSES_IF_EXPOSURE_IS_CLOSED}
            onChange={handlePaymentsConfigUpdate}
            disabled={isGlobalPaymentsSubmitting}
            hasAdditionalFields
            AdditionalFieldsDialog={ClosedExposureExpensesLimitDialog}
          >
            {paymentsConfiguration && paymentsConfiguration.is_expenses_limit_for_closed_exposure_enabled ? (
              <div>
                <Text variant={Text.VARIANTS.SM} weight={Text.WEIGHTS.REGULAR}>
                  Payment limit: {paymentsConfiguration.expenses_limit_for_closed_exposure}
                </Text>
              </div>
            ) : null}
          </ConfigSwitch>

          <ConfigSwitch
            title="Allow indemnity payments in a closed exposure"
            paymentsConfiguration={paymentsConfiguration}
            configKey={PAYMENTS_CONFIG_FIELDS.ENABLE_PAYING_INDEMNITY_IF_EXPOSURE_IS_CLOSED}
            onChange={handlePaymentsConfigUpdate}
            disabled={isGlobalPaymentsSubmitting}
            hasAdditionalFields
            AdditionalFieldsDialog={ClosedExposureIndemnityLimitDialog}
          >
            {paymentsConfiguration && paymentsConfiguration.is_indemnity_limit_for_closed_exposure_enabled ? (
              <div>
                <Text variant={Text.VARIANTS.SM} weight={Text.WEIGHTS.REGULAR}>
                  Payment limit: {paymentsConfiguration.indemnity_limit_for_closed_exposure}
                </Text>
              </div>
            ) : null}
          </ConfigSwitch>

          <ConfigSwitch
            title="Pre-fill indemnity payee with claimant details"
            paymentsConfiguration={paymentsConfiguration}
            configKey={PAYMENTS_CONFIG_FIELDS.PREFILL_INDEMNITY_PAYEE_WITH_CLAIMANT}
            onChange={handlePaymentsConfigUpdate}
            disabled={isGlobalPaymentsSubmitting}
          />
          <ConfigSwitch
            title="Require approval for every payment"
            paymentsConfiguration={paymentsConfiguration}
            configKey={PAYMENTS_CONFIG_FIELDS.REQUIRE_APPROVAL}
            onChange={handlePaymentsConfigUpdate}
            disabled={isGlobalPaymentsSubmitting}
          />

          <ConfigSwitch
            title="Allow indemnity payment in damaged property exposures only when the property owner is defined"
            paymentsConfiguration={paymentsConfiguration}
            configKey={PAYMENTS_CONFIG_FIELDS.ALLOW_INDEMNITY_PAYMENTS_ONLY_FOR_EXISTING_PROPERTY_OWNER}
            onChange={handlePaymentsConfigUpdate}
            disabled={isGlobalPaymentsSubmitting}
          />

          <ConfigSwitch
            title="Present approval screen"
            hasAdditionalFields
            AdditionalFieldsDialog={PresentApprovalScreenConfigurationDialog}
            paymentsConfiguration={paymentsConfiguration}
            configKey={PAYMENTS_CONFIG_FIELDS.PRESENT_APPROVAL_SCREEN}
            onChange={handlePaymentsConfigUpdate}
            disabled={isGlobalPaymentsSubmitting}
          >
            <div>
              Users with approval permissions:
              <div>
                {approvingUserIds.slice(0, ADJUSTERS_NUM_APPROVAL_SCREEN_CONFIG).map((user_id) => (
                  <Chip key={user_id} size="small" label={usersDict[user_id].username} className={classes.chip} />
                ))}
                {approvingUserIds.length > ADJUSTERS_NUM_APPROVAL_SCREEN_CONFIG ? (
                  <FsTooltip
                    title={approvingUserIds
                      .slice(ADJUSTERS_NUM_APPROVAL_SCREEN_CONFIG)
                      .map((user_id) => usersDict[user_id].username)
                      .join(', ')}
                    placement="top"
                  >
                    <span className="pl-10 font-bold text-teal-700">
                      {' '}
                      +{approvingUserIds.length - ADJUSTERS_NUM_APPROVAL_SCREEN_CONFIG}
                    </span>
                  </FsTooltip>
                ) : null}
              </div>
            </div>
          </ConfigSwitch>

          <ConfigSwitch
            title="Allow multiple pending payment requests"
            paymentsConfiguration={paymentsConfiguration}
            configKey={PAYMENTS_CONFIG_FIELDS.ALLOW_MULTIPLE_PENDING_PAYMENT_REQUESTS}
            onChange={handlePaymentsConfigUpdate}
            disabled={isGlobalPaymentsSubmitting}
          />

          <ConfigSwitch
            title="Enable duplicate verification"
            isInitialized={paymentsConfiguration?.[PAYMENTS_CONFIG_FIELDS.DUPLICATE_PAYMENTS_VERIFICATION_INITIALIZED]}
            hasAdditionalFields
            AdditionalFieldsDialog={EditDuplicatePaymentsConfigurationDialog}
            paymentsConfiguration={paymentsConfiguration}
            configKey={PAYMENTS_CONFIG_FIELDS.ENABLE_DUPLICATE_PAYMENTS_VERIFICATION}
            onChange={handlePaymentsConfigUpdate}
            disabled={isGlobalPaymentsSubmitting}
          >
            {paymentsConfiguration && (
              <div>
                <div>
                  Identify duplicate payments per:{' '}
                  <span style={{ fontWeight: 'bold' }}>
                    {paymentsConfiguration.duplicate_payments_level_of_verification === 'claim' ? 'Claim' : 'Exposure'}
                  </span>
                </div>
                <div>
                  When a duplicate payment is identified:{' '}
                  <span style={{ fontWeight: 'bold' }}>
                    {DUPLICATE_PAYMENTS_VERIFICATION_ACTION[paymentsConfiguration.duplicate_payments_action]?.desc}
                  </span>
                </div>
              </div>
            )}
          </ConfigSwitch>
          <ConfigSwitch
            title="Include VAT in payment screen"
            paymentsConfiguration={paymentsConfiguration}
            configKey={PAYMENTS_CONFIG_FIELDS.INCLUDE_VAT_IN_PAYMENT_SCREEN}
            onChange={handlePaymentsConfigUpdate}
            disabled={isGlobalPaymentsSubmitting}
          />
          <ConfigSwitch
            title="Enable 1099 fields"
            paymentsConfiguration={paymentsConfiguration}
            configKey={PAYMENTS_CONFIG_FIELDS.ENABLE_1099_FIELDS}
            onChange={handlePaymentsConfigUpdate}
            disabled={isGlobalPaymentsSubmitting}
          />
          <ConfigSwitch
            title="Enable Expenses Sub Reserves"
            paymentsConfiguration={paymentsConfiguration}
            configKey={PAYMENTS_CONFIG_FIELDS.IS_EXPENSES_SUB_RESERVES_CONFIG_ENABLED}
            onChange={handlePaymentsConfigUpdate}
            disabled={isGlobalPaymentsSubmitting}
            hasAdditionalFields
            AdditionalFieldsDialog={ExpensesSubReservesContainer}
            isInitialized={!_.isEmpty(paymentsConfiguration?.expenses_sub_reserves_config)}
          />
          {isFeatureEnabled(organization, CONFIGURATION_FEATURES_NAMES.MULTIPLE_SUB_RESERVE_PAYMENTS) ? (
            <ConfigSwitch
              title="Multiple Sub Reserves Per Payment"
              paymentsConfiguration={paymentsConfiguration}
              configKey={PAYMENTS_CONFIG_FIELDS.IS_MULTIPLE_SUB_RESERVE_PAYMENTS_ENABLED}
              onChange={handlePaymentsConfigUpdate}
              disabled={isGlobalPaymentsSubmitting}
              isInitialized={!_.isEmpty(paymentsConfiguration?.is_multiple_sub_reserve_payments_enabled)}
            />
          ) : null}

          {commerceBankEnabled && (
            <ConfigSwitch
              title="Present payment submission screen"
              hasAdditionalFields
              AdditionalFieldsDialog={PresentSubmissionScreenConfigurationDialog}
              paymentsConfiguration={paymentsConfiguration}
              configKey={PAYMENTS_CONFIG_FIELDS.PRESENT_SUBMISSION_SCREEN}
              onChange={handlePaymentsConfigUpdate}
              disabled={isGlobalPaymentsSubmitting}
            >
              <div>
                Users with submission permissions:
                <div>
                  {submittingUserIds.map((user_id) => (
                    <Chip key={user_id} size="small" label={usersDict[user_id].username} className={classes.chip} />
                  ))}
                </div>
              </div>
            </ConfigSwitch>
          )}
          <ConfigSwitch
            title="Disable payment above reserve"
            paymentsConfiguration={paymentsConfiguration}
            configKey={PAYMENTS_CONFIG_FIELDS.SHOULD_DISABLE_PAYMENT_EXCEEDING_RESERVE}
            onChange={handlePaymentsConfigUpdate}
            disabled={isGlobalPaymentsSubmitting}
          />
          <ConfigSwitch
            title="Enable Uk Bank Account Validation"
            isInitialized={paymentsConfiguration?.[PAYMENTS_CONFIG_FIELDS.ENABLE_UK_BANK_ACCOUNT_VALIDATION]}
            paymentsConfiguration={paymentsConfiguration}
            configKey={PAYMENTS_CONFIG_FIELDS.ENABLE_UK_BANK_ACCOUNT_VALIDATION}
            onChange={handlePaymentsConfigUpdate}
            disabled={isGlobalPaymentsSubmitting}
          />
          <ConfigSwitch
            title="Applied deductible will be deducted from outstanding reserves "
            isInitialized={paymentsConfiguration?.[PAYMENTS_CONFIG_FIELDS.ADJUST_RESERVE_INCLUDES_DEDUCTIBLE]}
            paymentsConfiguration={paymentsConfiguration}
            configKey={PAYMENTS_CONFIG_FIELDS.ADJUST_RESERVE_INCLUDES_DEDUCTIBLE}
            onChange={handlePaymentsConfigUpdate}
            disabled={isGlobalPaymentsSubmitting}
          />
          <ConfigSwitch
            title="Adjust reserve after payment cancellation (not operational)"
            isInitialized={paymentsConfiguration?.[PAYMENTS_CONFIG_FIELDS.ADJUST_RESERVE_AFTER_PAYMENT_CANCELLATION]}
            paymentsConfiguration={paymentsConfiguration}
            configKey={PAYMENTS_CONFIG_FIELDS.ADJUST_RESERVE_AFTER_PAYMENT_CANCELLATION}
            onChange={handlePaymentsConfigUpdate}
            disabled={isGlobalPaymentsSubmitting}
          />
          <ConfigSwitch
            title="OFAC Configuration"
            isSelected
            hasAdditionalFields
            AdditionalFieldsDialog={OfacScreenConfigurationDialog}
            paymentsConfiguration={paymentsConfiguration}
            configKey={PAYMENTS_CONFIG_FIELDS.OFAC_CONFIGURATION_ENABLED}
            onChange={handlePaymentsConfigUpdate}
            disabled={isGlobalPaymentsSubmitting}
          />
          <ConfigSwitch
            title="Include anticipated amount to the total recoveries calculation"
            isInitialized={
              paymentsConfiguration?.[PAYMENTS_CONFIG_FIELDS.IS_ANTICIPATED_AMOUNT_INCLUDED_IN_RECOVERIES_CALC]
            }
            paymentsConfiguration={paymentsConfiguration}
            configKey={PAYMENTS_CONFIG_FIELDS.IS_ANTICIPATED_AMOUNT_INCLUDED_IN_RECOVERIES_CALC}
            onChange={handlePaymentsConfigUpdate}
            disabled={isGlobalPaymentsSubmitting}
          />
          <ConfigSwitch
            title="Disable the Adjuster from modifying self payment limits"
            isInitialized={
              paymentsConfiguration?.[PAYMENTS_CONFIG_FIELDS.DISABLE_ADJUSTER_MODIFYING_SELF_PAYMENT_LIMITS]
            }
            paymentsConfiguration={paymentsConfiguration}
            configKey={PAYMENTS_CONFIG_FIELDS.DISABLE_ADJUSTER_MODIFYING_SELF_PAYMENT_LIMITS}
            onChange={handlePaymentsConfigUpdate}
            disabled={isGlobalPaymentsSubmitting}
          />
        </Grid>
        {organization.sub_organizations_enabled && (
          <div className={styles.configPerContainer}>
            <Typography variant="subtitle2">Set configuration per:</Typography>
            <div className={styles.radioButtonsContainer}>
              {CONFIG_PER_VALUES.map((configPerValue) => (
                <WithConfirm
                  key={configPerValue.value}
                  primaryButtonName="Set"
                  triggerMethod="onChange"
                  title="Are you sure you want to change the context?"
                  shouldCloseOnPrimary
                  contentText="Changing the context will immediately affect all payment configurations"
                >
                  <RadioWithButtonWrapper
                    text={configPerValue.text}
                    value={configPerValue.value}
                    checked={
                      (isConfigPerOrg && configPerValue.value === 'org') ||
                      (!isConfigPerOrg && configPerValue.value === 'suborgs')
                    }
                    onChange={() =>
                      handlePaymentsConfigUpdate({
                        [PAYMENTS_CONFIG_FIELDS.IS_ORG_LEVEL_CONFIGURATION]:
                          !paymentsConfiguration.is_org_level_configuration,
                      })
                    }
                    className={styles.radioButton}
                    disabled={isGlobalPaymentsSubmitting}
                  />
                </WithConfirm>
              ))}
            </div>
          </div>
        )}
        <SortableTable columns={getColumns()} rows={getRows()} />
        {isDialogOpen && (
          <PaymentsConfigurationForm
            isDialogOpen={isDialogOpen}
            onClose={handleCloseDialog}
            handleFormSubmit={handleFormSubmit}
            configValues={activePaymentMethodConfig ? processIncomingConfigValues(activePaymentMethodConfig) : null}
            isHomeLobEnabledForOrg={isHomeLobEnabledForOrg}
            paymentMethodName={activePaymentMethodConfig?.payment_method}
            isLastEnabledPaymentMethod={validateIsLastEnabledPaymentMethod()}
          />
        )}
      </LoadingSwitch>
    </CardDialog>
  );
};

PaymentsConfigTab.propTypes = {
  processIncomingConfigValues: PropTypes.func.isRequired,
  processOutgoingConfigValues: PropTypes.func.isRequired,
};

const PresentSubmissionScreenConfigurationDialog = ({
  paymentsConfiguration,
  disabled = false,
  onCancel,
  onSubmit,
}) => {
  return (
    <SelectManagersConfigurationDialog
      paymentsConfiguration={paymentsConfiguration}
      disabled={disabled}
      onCancel={onCancel}
      onSubmit={onSubmit}
      configurationKey={PAYMENTS_CONFIG_FIELDS.ADDITIONAL_SUBMITTING_USER_IDS}
      cardTitle="Present payment submission screen"
      inputLabel="Users with submission permissions"
    />
  );
};

PresentSubmissionScreenConfigurationDialog.propTypes = {
  paymentsConfiguration: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
};

export default PaymentsConfigTab;
