import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Divider, IconButton } from '@material-ui/core';
import axios from 'axios';
import { FieldArray, Formik } from 'formik';
import * as Yup from 'yup';

import AlertBanner from '~/components/core/AlertBanner';
import Button from '~/components/core/Atomic/Buttons/Button';
import CancelButton from '~/components/core/Buttons/CancelButton';

import { reportAxiosError } from '../../../Utils';
import CardDialog from '../../CardDialog';
import { LoadingSwitch } from '../../core';
import { PlusIcon, TrashIcon } from '../../icons';
import TextFieldFormik, { ShowOnlyTextField } from '../../TextFieldFormik';
import useDataFetcher from '../../useDataFetcher';

import { useStyles } from '../../../assets/styles';
import colors from '../../../assets/colors.module.scss';
import styles from './EmailDomainsContainer.module.scss';

const EmailDomainsContainer = ({ organization }) => {
  const emailDomainsUrl = `/api/v1/organizations/${organization.id}/email_domains`;

  const { data: emailDomains, reloadData, isLoading, isError } = useDataFetcher(emailDomainsUrl);
  const classes = useStyles();
  const [editDomainsDialogOpen, setEditDomainsDialogOpen] = React.useState(false);

  const handleNewDomainsSubmit = async (values, { setSubmitting }) => {
    try {
      await axios.post(emailDomainsUrl, values);
      await reloadData();
      setEditDomainsDialogOpen(false);
    } catch (error) {
      setSubmitting(false);
      reportAxiosError(error);
    }
  };

  return (
    <>
      <ShowOnlyTextField
        onEdit={
          organization.is_suborgs_domains_enabled
            ? () => {
                setEditDomainsDialogOpen(true);
              }
            : null
        }
        classes={classes}
        showOnlyValueComponent={
          <LoadingSwitch isLoading={isLoading} isError={isError}>
            <div className={styles.valueContainer}>
              {emailDomains?.map((emailDomain, idx) => (
                <Fragment key={emailDomain.domain}>
                  <span key={emailDomain.domain} className={styles.domain}>
                    {emailDomain.domain}
                  </span>
                  {idx !== emailDomains.length - 1 ? (
                    <Divider orientation="vertical" flexItem className={styles.divider} />
                  ) : null}
                </Fragment>
              ))}
            </div>
          </LoadingSwitch>
        }
        label="Claims Email Domain"
      />
      {editDomainsDialogOpen ? (
        <Formik
          initialValues={{ domains: [''] }}
          onSubmit={handleNewDomainsSubmit}
          validationSchema={Yup.object().shape({
            domains: Yup.array().of(
              Yup.string()
                .required('Domain is required')
                .matches(/^(?!:\/\/)([a-zA-Z0-9-_]+\.)+[a-zA-Z]{2,6}?$/, 'Invalid domain')
                .notOneOf(
                  emailDomains.map((emailDomain) => emailDomain.domain),
                  'Domain already exists'
                )
                .test('is-unique', 'Domain must be unique', (value, context) => {
                  return context.parent.filter((item) => item === value).length < 2;
                })
            ),
          })}
        >
          {(formikProps) => {
            const { isSubmitting, handleSubmit, values } = formikProps;
            const onCancel = () => {
              setEditDomainsDialogOpen(false);
            };
            return (
              <CardDialog
                isDialog={true}
                title="Edit Claims Email Domain"
                maxWidth="sm"
                onClose={onCancel}
                fullWidth
                preventClose={isSubmitting}
              >
                <AlertBanner
                  note="Contact 5S support team to finalize setting up a new email domain"
                  alertType={AlertBanner.ALERT_TYPES.INFO}
                />
                {emailDomains?.map((emailDomain) => (
                  <div key={emailDomain.id} className={styles.formExistingDomain}>
                    {emailDomain.domain}
                  </div>
                ))}
                <FieldArray name="domains">
                  {(arrayHelpers) => (
                    <div className={styles.additionalDomainsContainer}>
                      {values['domains'].map((domain, index) => (
                        <div key={index} className={styles.additionalDomainRow}>
                          <TextFieldFormik
                            className={classes.textFieldRow}
                            id={`domains.${index}`}
                            label="Email Domain"
                          />
                          <IconButton className={styles.trashIconButton} onClick={() => arrayHelpers.remove(index)}>
                            <TrashIcon size={20} iconColor={colors.textPrimary} />
                          </IconButton>
                        </div>
                      ))}
                      <Button
                        className={styles.additionalDomainButton}
                        variant="text"
                        color="primary"
                        startIcon={<PlusIcon iconColor={colors.buttonLink} size={14} />}
                        onClick={() => arrayHelpers.push('')}
                        disabled={isSubmitting}
                      >
                        Add Email Domain
                      </Button>
                    </div>
                  )}
                </FieldArray>
                <div className={classes.buttonsContainer}>
                  <CancelButton disabled={isSubmitting} onClick={onCancel} />
                  <Button variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
                    Save
                  </Button>
                </div>
              </CardDialog>
            );
          }}
        </Formik>
      ) : null}
    </>
  );
};

EmailDomainsContainer.propTypes = {
  organization: PropTypes.object.isRequired,
};

export default EmailDomainsContainer;
