import React from 'react';
import { TextField } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';

import { useStyles } from '~/assets/styles';
import { Text } from '~/components/core';

interface RolesSearchProps {
  onSearchTermChanged: (term: string) => void;
  onShowOnlyCustomRolesChanged: (show: boolean) => void;
}

const RolesFilter: React.FC<RolesSearchProps> = ({ onSearchTermChanged, onShowOnlyCustomRolesChanged }) => {
  const classes = useStyles();

  return (
    <div className="my-20 grid grid-cols-3 gap-30">
      <TextField label="Search Roles" onChange={(e) => onSearchTermChanged(e.target.value)} />
      <span className="flex items-center">
        <Switch onChange={(e) => onShowOnlyCustomRolesChanged(e.target.checked)} className={classes.formsSwitch} />{' '}
        <Text variant={Text.VARIANTS.SM} weight={Text.WEIGHTS.REGULAR}>
          Show only custom roles
        </Text>
      </span>
    </div>
  );
};

export default RolesFilter;
