import React from 'react';

import Editor from '~/components/core/Editor';
import type { TemplateBuilderEditorInnerComponentProps } from '~/components/GenericTemplates/types';
import { TEMPLATE_BUILDER_FORM_KEYS } from '~/components/GenericTemplates/utils/genericTemplatesUtils';

import Text from '../../../core/TextComponents/Text';

const SmsGenericTemplatesEditor: React.FC<TemplateBuilderEditorInnerComponentProps> = ({
  handleChange,
  disabled,
  bodyTemplate,
  suggestions,
}) => {
  return (
    <div className="my-30">
      <Text variant={Text.VARIANTS.SM} className="my-10">
        SMS Body
      </Text>
      <Editor
        className="overflow-auto"
        disabled={disabled}
        onChange={(value) => handleChange(value, TEMPLATE_BUILDER_FORM_KEYS.BODY_TEMPLATE)}
        autoFocus
        displayConfig={{ suggestions: !!suggestions }}
        content={bodyTemplate}
        suggestionOptions={suggestions}
      />
    </div>
  );
};

export default SmsGenericTemplatesEditor;
