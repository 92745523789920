import React from 'react';
import { useFormikContext } from 'formik';

import SkeletonTable from '~/components/core/Skeletons/SkeletonTable';
import EmailGenericTemplatesEditor from '~/components/GenericTemplates/GenericTemplatesConfiguration/GenericTemplateBuilder/EmailGenericTemplatesEditor';
import NoteGenericTemplatesEditor from '~/components/GenericTemplates/GenericTemplatesConfiguration/GenericTemplateBuilder/NoteGenericTemplateEditor';
import SmsGenericTemplatesEditor from '~/components/GenericTemplates/GenericTemplatesConfiguration/GenericTemplateBuilder/SmsGenericTemplatesEditor';
import type { TemplateTypeKey } from '~/components/GenericTemplates/types';
import { useSysconfig } from '~/components/SystemConfiguration/SystemConfigurationScreen';
import useDataFetcher from '~/components/useDataFetcher';
import { CONFIGURATION_FEATURES_NAMES } from '~/Types';
import { isFeatureEnabled } from '~/Utils';

const EDITOR_COMPONENT_BY_TEMPLATE_TYPE = {
  email: {
    component: EmailGenericTemplatesEditor,
  },
  note: {
    component: NoteGenericTemplatesEditor,
  },
  sms: {
    component: SmsGenericTemplatesEditor,
  },
} as const;

interface TemplateBuilderEditorProps {
  templateType: TemplateTypeKey;
  titleTemplate?: string;
  bodyTemplate: string;
}

// these are only partial values
interface FormValues {
  lobs: string[];
  is_claim_level: boolean;
}
const buildQueryParams = (params: FormValues) => {
  const searchParams = new URLSearchParams();
  searchParams.append('is_claim_level', params.is_claim_level.toString());
  if (params.lobs) {
    params.lobs.forEach((lob) => searchParams.append('lobs', lob));
  } else {
    params.lobs;
  }
  return searchParams.toString();
};

export const TemplateBuilderEditor: React.FC<TemplateBuilderEditorProps> = ({
  templateType,
  titleTemplate,
  bodyTemplate,
}) => {
  const { setFieldValue, isSubmitting, values } = useFormikContext<FormValues>();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { organization } = useSysconfig();
  const isGenericTemplateTokenFFEnabled = isFeatureEnabled(
    organization,
    CONFIGURATION_FEATURES_NAMES.GENERIC_TEMPLATES_TOKENS
  );
  const { data, isLoading } = useDataFetcher(
    `/api/v1/generic_templates/organization/${organization.id}/template/tokens?${buildQueryParams(values)}`,
    {},
    isGenericTemplateTokenFFEnabled
  );

  const handleChange = (value: string, id: string) => {
    setFieldValue(id, value);
  };

  const Component = EDITOR_COMPONENT_BY_TEMPLATE_TYPE[templateType].component;

  // Need to unmount and remount editor component so suggestions will get updated
  if (isGenericTemplateTokenFFEnabled && (!data || isLoading)) {
    return <SkeletonTable rowsCount={5} columnsCount={1} maxHeight="100px" />;
  }
  return (
    <Component
      handleChange={handleChange}
      disabled={isSubmitting}
      titleTemplate={titleTemplate}
      bodyTemplate={bodyTemplate}
      suggestions={
        isGenericTemplateTokenFFEnabled
          ? data?.tokens && !isLoading
            ? data.tokens.map((token: { display_name: string; token_key: string }) => ({
                label: token.display_name,
                id: token.token_key,
              }))
            : []
          : null
      }
    />
  );
};
