import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem } from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from 'yup';

import AlertBanner from '~/components/core/AlertBanner';
import Grid from '~/components/core/Atomic/Grid/Grid';
import CancelButton from '~/components/core/Buttons/CancelButton';

import { LOAD_BALANCING } from '../../../../Types';
import CardDialog from '../../../CardDialog';
import CheckboxFormik from '../../../CheckboxFormik';
import { FsButton, Text } from '../../../core';
import TextFieldFormik from '../../../TextFieldFormik';
import useAssignments from '../../AssignmentsContext';

import { useStyles } from '../../../../assets/styles';
import styles from './AssignmentsTab.module.scss';

const LoadBalancingConfigurationDialog = ({ handleClose, handleSubmit }) => {
  const { assignmentsConfigurations } = useAssignments();
  const classes = useStyles();

  const lookBackPeriodTypes = LOAD_BALANCING['look_back_period_types'];

  return (
    <Formik
      initialValues={{
        is_load_balancing_enabled: true,
        look_back_period_type: assignmentsConfigurations?.look_back_period_type || '',
        look_back_period_amount: assignmentsConfigurations?.look_back_period_amount || '',
        is_out_of_office_days_excluded: assignmentsConfigurations?.is_out_of_office_days_excluded || false,
      }}
      validationSchema={Yup.object().shape({
        look_back_period_type: Yup.string().oneOf(Object.keys(lookBackPeriodTypes)).required('Required'),
        look_back_period_amount: Yup.number().required('Required'),
        is_out_of_office_days_excluded: Yup.boolean(),
      })}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          await handleSubmit(values);
          await handleClose();
        } catch (error) {
          setSubmitting(false);
        }
      }}
    >
      {(formikProps) => {
        const { isSubmitting, handleSubmit } = formikProps;

        return (
          <CardDialog isDialog onClose={handleClose} title="Workload Balance">
            <div>
              <AlertBanner
                title="Please Note"
                note="The load balancing algorithm aims to distribute work among adjusters in an assignment group based on the number of newly assigned claims and exposures within a specified look-back period. It provides a grace period for new users and enforces uniform ownership of identical exposures within a claim."
                alertType={AlertBanner.ALERT_TYPES.INFO}
                withIcon
              />

              <div className={styles.loadBalancingFieldsContainer}>
                <Text weight={Text.WEIGHTS.SEMI_BOLD} variant={Text.VARIANTS.SM}>
                  Define look back period
                </Text>

                <Grid container spacing={4}>
                  <Grid item xs={6}>
                    <TextFieldFormik
                      id="look_back_period_type"
                      label="Time Frame"
                      className={classes.textField}
                      select
                      fullWidth
                      disabled={isSubmitting}
                    >
                      {Object.keys(lookBackPeriodTypes).map((lookBackPeriodKey) => (
                        <MenuItem key={lookBackPeriodKey} value={lookBackPeriodKey}>
                          {lookBackPeriodTypes[lookBackPeriodKey]}
                        </MenuItem>
                      ))}
                    </TextFieldFormik>
                  </Grid>
                  <Grid item xs={6}>
                    <TextFieldFormik
                      id="look_back_period_amount"
                      label="Amount"
                      className={classes.textField}
                      fullWidth
                      disabled={isSubmitting}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <CheckboxFormik id="is_out_of_office_days_excluded" label="Exclude OOO days from the algorithm" />
                  </Grid>
                </Grid>
              </div>

              <div className={classes.buttonsContainer}>
                <CancelButton disabled={isSubmitting} onClick={handleClose} />
                <FsButton variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
                  Save
                </FsButton>
              </div>
            </div>
          </CardDialog>
        );
      }}
    </Formik>
  );
};

LoadBalancingConfigurationDialog.propTypes = {
  handleClose: PropTypes.func,
  handleSubmit: PropTypes.func,
};

export default LoadBalancingConfigurationDialog;
