import React from 'react';
import { object, string } from 'prop-types';
import { Typography } from '@material-ui/core';
import { getIn, useFormikContext } from 'formik';

import cn from '~/Utils/cn';

import { TOTAL_ALLOWED_QUESTIONS } from '../../constants';
import { getTotalSelected } from '../../utils';

import Question from './Question';

import { useStyles } from '../../../../../../../assets/styles';
import styles from '../sections.module.scss';

const Questions = ({ questions = {}, categoryName }) => {
  const { values } = useFormikContext();
  const classes = useStyles();

  const questionKeys = Object.keys(questions);
  const totalSelectedLength = getTotalSelected(values);
  const selected = questionKeys.filter((key) => getIn(values, `questions.${key}.selected`));

  return (
    <section className={styles.sectionContainer}>
      <Typography display="block" variant="subtitle1" className={cn(styles.title, classes.h4)}>
        {categoryName} {selected.length}/{questionKeys.length}
      </Typography>
      {Object.entries(questions).map(([key, { template }]) => (
        <Question
          key={key}
          id={`questions.${key}`}
          template={template}
          disabled={totalSelectedLength >= TOTAL_ALLOWED_QUESTIONS && !getIn(values, `questions.${key}.selected`)}
        />
      ))}
    </section>
  );
};

Questions.propTypes = {
  questions: object.isRequired,
  categoryName: string.isRequired,
};

export default Questions;
