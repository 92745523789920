import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, List, ListItem, ListItemText, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import axios from 'axios';
import { Formik } from 'formik';
import { JsonEditor } from 'jsoneditor-react';
import * as Yup from 'yup';

import Button from '~/components/core/Atomic/Buttons/Button';
import DialogFooterActions from '~/components/core/DialogFooterActions';

import { isUserFiveSigma, isUserSuperUser } from '../../../UserUtils';
import { reportAxiosError } from '../../../Utils';
import CardDialog from '../../CardDialog';
import WithConfirm from '../../ConfirmModal';
import { OrganizationLogoUploadContainer } from '../../core';
import { useCms } from '../../hooks/useCms';
import { TextFieldFormik } from '../../TextFieldFormik';

import { useSysconfig } from './../SystemConfigurationScreen';
import EmailDomainsContainer from './EmailDomainsContainer';
import OrganizationAdjusterSelect from './OrganizationAdjusterSelect';

import { useStyles } from '../../../assets/styles';

function TscSpecificSettings(props) {
  const { organization, onUpdateSettings } = props;
  const classes = useStyles();
  const [showAddIp, setShowAddIp] = React.useState(false);

  const staticIpList = organization.extra_settings?.static_ip_list || [];

  return (
    <div className="flex flex-col gap-10">
      <div className={classes.container} style={{ alignItems: 'center' }}>
        <Typography>Static IPs:</Typography>
        <IconButton style={{ padding: '0px 0px 0px 16px' }} onClick={() => setShowAddIp(true)}>
          <AddIcon />
        </IconButton>
      </div>

      <List dense>
        {staticIpList.map((ip) => (
          <ListItem key={ip} dense>
            <ListItemText primary={`${ip}`} />
            <WithConfirm title={`Delete ${ip}?`} primaryButtonName="Delete" shouldCloseOnPrimary={true}>
              <IconButton
                style={{ padding: '0px 0px 0px 16px' }}
                onClick={() => onUpdateSettings({ static_ip_list: staticIpList.filter((currIp) => currIp !== ip) })}
              >
                <DeleteIcon />
              </IconButton>
            </WithConfirm>
          </ListItem>
        ))}
      </List>

      {showAddIp && (
        <Formik
          initialValues={{
            ip: '',
          }}
          validationSchema={Yup.object().shape({
            ip: Yup.string().test('is-valid', 'Not a valid IP address', (value) => {
              if (!value) {
                return false;
              }
              const splitIp = value.split('.');
              if (splitIp.length !== 4) {
                return false;
              }
              return splitIp.every((item) => item >= 0 && item <= 255);
            }),
          })}
          onSubmit={async (values) => {
            await onUpdateSettings({ static_ip_list: [...staticIpList, values['ip'].trim()] });
            setShowAddIp(false);
          }}
        >
          {(formikProps) => (
            <CardDialog isDialog title="Add static IP" onClose={() => setShowAddIp(false)}>
              <TextFieldFormik id="ip" label="IP" fullWidth />
              <div className={classes.buttonsContainer}>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={formikProps.isSubmitting}
                  onClick={formikProps.handleSubmit}
                >
                  Save
                </Button>
              </div>
            </CardDialog>
          )}
        </Formik>
      )}
    </div>
  );
}

TscSpecificSettings.propTypes = {
  organization: PropTypes.object.isRequired,
  onUpdateSettings: PropTypes.func.isRequired,
};

function OrganizationSettings({ withoutTitle, withoutSupervisor }) {
  const { organization, reloadOrganization } = useSysconfig();
  const { user } = useCms();

  const handleUpdateSettings = async (values) => {
    try {
      await axios.put(`/sysconfig/api/v1/organizations/${organization.id}`, values);
      reloadOrganization();
    } catch (error) {
      reportAxiosError(error);
    }
  };

  let SettingComponent = undefined;
  switch (organization.name.toLowerCase()) {
    case 'tsc':
      SettingComponent = TscSpecificSettings;
      break;
    default:
      SettingComponent = undefined;
  }

  const updateOrganizationLogo = async (base64Image, type) => {
    try {
      await axios.put(`/sysconfig/api/v1/organizations/${organization.id}/logo`, { base64Image, type });
      await reloadOrganization();
    } catch (error) {
      await reportAxiosError(error);
    }
  };

  const removeOrganizationLogo = async () => {
    try {
      await axios.delete(`/sysconfig/api/v1/organizations/${organization.id}/logo`);
      await reloadOrganization();
    } catch (error) {
      await reportAxiosError(error);
      throw error;
    }
  };

  return (
    <CardDialog title={withoutTitle ? undefined : 'Settings'} noCardTitle={withoutTitle}>
      <div className="flex w-full flex-col gap-20">
        {isUserFiveSigma(user) && (
          <OrganizationLogoUploadContainer onSave={updateOrganizationLogo} onRemove={removeOrganizationLogo} />
        )}

        {!withoutSupervisor && (
          <OrganizationAdjusterSelect
            id="supervisor"
            label="Supervisor"
            adjuster={organization.supervisor}
            organization={organization}
            onSelectAdjuster={(adjusterId) => handleUpdateSettings({ supervisor_id: adjusterId })}
          />
        )}

        <EmailDomainsContainer organization={organization} />

        {SettingComponent && <SettingComponent organization={organization} onUpdateSettings={handleUpdateSettings} />}

        {isUserSuperUser(user) && (
          <div className="flex">
            <OrganizationConfigurationEditor organization={organization} reloadOrganization={reloadOrganization} />
          </div>
        )}
      </div>
    </CardDialog>
  );
}

OrganizationSettings.propTypes = {
  withoutTitle: PropTypes.bool,
  withoutSupervisor: PropTypes.bool,
};

function OrganizationConfigurationEditor(props) {
  const [isConfigurationDialogOpen, setIsConfigurationDialogOpen] = React.useState(false);
  const { organization, reloadOrganization } = props;
  const { reloadOperationalDetails } = useSysconfig();

  const handleUpdateConfiguration = async (values) => {
    try {
      await axios.put(`/sysconfig/api/v1/organizations/${organization.id}`, values);
      setIsConfigurationDialogOpen(false);
      reloadOrganization();
    } catch (error) {
      reportAxiosError(error);
      throw error;
    }
  };

  const FIELD_NAME = 'configuration';

  return (
    <>
      <Button variant="contained" color="primary" onClick={() => setIsConfigurationDialogOpen(true)}>
        Edit Configuration
      </Button>
      {isConfigurationDialogOpen && (
        <Formik
          initialValues={{ [FIELD_NAME]: organization.configuration }}
          enableReinitialize
          onSubmit={(values, formikProps) => {
            handleUpdateConfiguration(values).catch(() => {
              formikProps.setSubmitting(false);
            });
          }}
        >
          {(formikProps) => {
            const { isSubmitting, handleSubmit, values, setFieldValue, setFieldError, isValid } = formikProps;

            return (
              <CardDialog
                isDialog
                maxWidth="xl"
                open={Boolean(isConfigurationDialogOpen)}
                title="Edit Configuration"
                onClose={() => setIsConfigurationDialogOpen(false)}
                footerActions={
                  <DialogFooterActions
                    disabled={isSubmitting || !isValid}
                    onClickPrimary={handleSubmit}
                    onClickSecondary={() => setIsConfigurationDialogOpen()}
                  />
                }
              >
                <JsonEditor
                  value={values?.[FIELD_NAME] || {}}
                  onChange={(value) => {
                    setFieldValue(FIELD_NAME, value);
                    reloadOperationalDetails();
                  }}
                  mode="text"
                  allowedModes={['text', 'tree']}
                  onValidationError={(errors) => {
                    if (errors?.length) {
                      setFieldError(FIELD_NAME, true);
                    }
                  }}
                  htmlElementProps={{
                    style: { height: '70vh', width: '70vw' },
                  }}
                />
              </CardDialog>
            );
          }}
        </Formik>
      )}
    </>
  );
}

OrganizationConfigurationEditor.propTypes = {
  organization: PropTypes.object.isRequired,
  reloadOrganization: PropTypes.func.isRequired,
};

export default OrganizationSettings;
