import React from 'react';

import { Heading } from '~/components/core';

const Header = () => {
  return (
    <>
      <Heading variant={Heading.TYPES.H2} className="py-15">
        Closing Reasons
      </Heading>
      <span>
        Manage claim and exposure closing reasons for your organization.
        <br /> You can add new closing reasons, edit reasons that are not currently in use and configure whether a
        closing reason note is enabled, disabled or required for users in the system.
      </span>
    </>
  );
};

export default Header;
