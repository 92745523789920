import React, { useState } from 'react';
import AddIcon from '@material-ui/icons/Add';
import axios from 'axios';

import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';

import { reportAxiosError } from '../../../../../../Utils';
import CardDialog from '../../../../../CardDialog';
import { LoadingSwitch } from '../../../../../core';
import useDataFetcher from '../../../../../useDataFetcher';
import { useSysconfig } from '../../../../SystemConfigurationScreen';

import { vendorApiProfile as vendorApiProfileAPI, vendorApiProfiles as vendorApiProfilesAPI } from './routes';
import VendorApiProfileDialog from './VendorApiProfileDialog';
import VendorAPIProfilesTable from './VendorAPIProfilesTable';

import { useStyles } from '../../../../../../assets/styles';
import styles from './vendorApiProfile.module.scss';

const VendorAPIProfiles = () => {
  const [isAddVendorApiProfileDialogOpen, setAddVendorApiProfileDialogOpen] = useState(false);
  const [vendorApiProfileEdit, setVendorApiProfileEdit] = useState();
  const { organization, isLoading: isOrganizationLoading, isError: isOrganizationError } = useSysconfig();
  const classes = useStyles();

  const {
    isLoading: isVendorApiProfileLoading,
    isError: isVendorApiProfileError,
    data: vendorApiProfiles = [],
    reloadData: reloadVendorApiProfiles,
  } = useDataFetcher(vendorApiProfilesAPI(organization));

  const onAddCloseDialog = () => {
    setAddVendorApiProfileDialogOpen(false);
  };

  const onAddOpenDialog = () => {
    setAddVendorApiProfileDialogOpen(true);
  };

  const onEditCloseDialog = () => {
    setVendorApiProfileEdit(undefined);
  };

  const onEditOpenDialog = (vendorApiProfile) => {
    setVendorApiProfileEdit(vendorApiProfile);
  };

  const addNewVendorApiProfile = async (values) => {
    try {
      await axios.post(vendorApiProfilesAPI(organization), values);
      await reloadVendorApiProfiles();
    } catch (error) {
      await reportAxiosError(error);
      throw error;
    }
    onAddCloseDialog();
  };

  const editVendorApiProfile = async (values) => {
    try {
      await axios.patch(vendorApiProfileAPI(organization, vendorApiProfileEdit.id), values);
      await reloadVendorApiProfiles();
    } catch (error) {
      await reportAxiosError(error);
      throw error;
    }
    onEditCloseDialog();
  };

  const isLoading = isOrganizationLoading || isVendorApiProfileLoading;
  const isError = isOrganizationError || isVendorApiProfileError;

  return (
    <CardDialog noCardTitle>
      <LoadingSwitch isLoading={isLoading} isError={isError}>
        <Grid container className={styles.vendorApiProfile} direction="column">
          <Grid item>
            <div className={classes.inLineButtonsContainer}>
              <Button color="primary" onClick={onAddOpenDialog}>
                <AddIcon className={classes.leftButtonIcon} />
                ADD SERVICE PROVIDER PROFILE
              </Button>
            </div>
          </Grid>
          <Grid item className={styles.tableContainer}>
            <VendorAPIProfilesTable
              vendorApiProfiles={vendorApiProfiles}
              onUpdate={reloadVendorApiProfiles}
              onActionClick={onEditOpenDialog}
            />
            {isAddVendorApiProfileDialogOpen && (
              <VendorApiProfileDialog onClose={onAddCloseDialog} handleFormSubmit={addNewVendorApiProfile} />
            )}
            {vendorApiProfileEdit && (
              <VendorApiProfileDialog
                vendorApiProfile={vendorApiProfileEdit}
                onClose={onEditCloseDialog}
                handleFormSubmit={editVendorApiProfile}
              />
            )}
          </Grid>
        </Grid>
      </LoadingSwitch>
    </CardDialog>
  );
};

export default VendorAPIProfiles;
