import React, { useCallback, useMemo, useState } from 'react';
import { Switch } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import axios from 'axios';
import _ from 'lodash';

import Button from '~/components/core/Atomic/Buttons/Button';
import Chip from '~/components/core/Atomic/Chip/Chip';
import Grid from '~/components/core/Atomic/Grid/Grid';
import ThreeDotsMenu from '~/components/core/ThreeDotsMenu';
import { useLobConfiguration } from '~/components/hooks/useLobConfiguration';

import { reportAxiosError, subOrgIdToNameDict } from '../../../../Utils';
import { getLobDescription, getLobIcon } from '../../../../Utils/lobUtils';
import CardDialog from '../../../CardDialog';
import { Heading, SortableTable } from '../../../core';
import useCurrencyFormatter from '../../../CurrencyFormatterContext';
import { SubReservesContextProvider } from '../../../hooks/useSubReserves';
import { ArrowDownwardIcon, ArrowUpwardIcon } from '../../../icons';
import InlineIconButton from '../../../InlineIconButton';
import LoadingIndicator from '../../../LoadingIndicator';
import useDataFetcher from '../../../useDataFetcher';
import { useSysconfig } from '../../SystemConfigurationScreen';

import AddOrEditRuleDialog from './AddOrEditRuleDialog';
import FilterRules from './FilterRules';
import SearchRules from './SearchRules';

import { useStyles } from '../../../../assets/styles';
import styles from './automaticExposureRules.module.scss';

const AutomaticExposures = () => {
  const { organization } = useSysconfig();
  const { lobConfigurationsDict } = useLobConfiguration();
  const { currencyFormatter } = useCurrencyFormatter();
  const classes = useStyles();
  const [isProcessing, setIsProcessing] = useState(false);
  const [filteredParams, setFilteredParams] = useState({ subOrgIds: [], lobs: [], searchTerm: '' });
  const [isRuleDialogOpen, setIsRuleDialogOpen] = useState(false);
  const [ruleToEdit, setRuleToEdit] = useState(null);

  const {
    isLoading,
    isError,
    data: rules,
    reloadData: reloadRules,
  } = useDataFetcher(`/api/v1/automatic_exposures/${organization.id}/rules`);

  const filteredRules = useMemo(() => {
    let filteredRules = rules || [];

    if (rules) {
      if (!_.isEmpty(filteredParams.subOrgIds)) {
        filteredRules = filteredRules.filter(
          (rule) =>
            rule.is_all_sub_orgs || !_.isEmpty(_.intersection(rule.sub_organization_ids, filteredParams.subOrgIds))
        );
      }

      if (!_.isEmpty(filteredParams.lobs)) {
        filteredRules = filteredRules.filter((rule) => filteredParams.lobs.includes(rule.lob));
      }

      if (filteredParams.searchTerm) {
        // TODO NGTPA-6852: add conditions summary
        filteredRules = filteredRules.filter((rule) =>
          rule.display_name.toLowerCase().includes(filteredParams.searchTerm.toLowerCase())
        );
      }
    }

    return filteredRules;
  }, [filteredParams.lobs, filteredParams.searchTerm, filteredParams.subOrgIds, rules]);

  const callAPI = async (method, url, values) => {
    try {
      setIsProcessing(true);
      await axios({ method, url, data: values });
      await reloadRules();
    } catch (error) {
      await reportAxiosError(error);
      throw error;
    } finally {
      setIsProcessing(false);
    }
  };

  const handleMoveRuleUp = async (rule) => {
    await callAPI('post', `/api/v1/automatic_exposures/${organization.id}/rules/${rule.id}/up`);
  };

  const handleMoveRuleDown = async (rule) => {
    await callAPI('post', `/api/v1/automatic_exposures/${organization.id}/rules/${rule.id}/down`);
  };

  const handleDeleteRule = async (rule) => {
    await callAPI('delete', `/api/v1/automatic_exposures/${organization.id}/rules/${rule.id}`);
  };

  const handEditRuleClicked = (rule) => {
    setRuleToEdit(rule);
    setIsRuleDialogOpen(true);
  };

  const handleSubmitRule = async (values) => {
    const baseUrl = `/api/v1/automatic_exposures/${organization.id}/rules`;
    if (values.id) {
      await callAPI('put', `${baseUrl}/${values.id}`, values);
    } else {
      await callAPI('post', baseUrl, values);
    }

    handleCloseRuleDialog();
  };

  const handAddRuleClicked = () => {
    setIsRuleDialogOpen(true);
  };

  const handleCloseRuleDialog = () => {
    setIsRuleDialogOpen(false);
    setRuleToEdit(null);
  };

  const handleEnabledChanged = async (rule) => {
    await callAPI('post', `/api/v1/automatic_exposures/${organization.id}/rules/${rule.id}/enable`, {
      is_enabled: !rule.is_enabled,
    });
  };

  const handleFiltersChanged = useCallback(({ subOrgIds, lobs }) => {
    setFilteredParams((params) => ({ ...params, subOrgIds, lobs }));
  }, []);

  const handleSearchTermChanged = useCallback((searchTerm) => {
    setFilteredParams((params) => ({ ...params, searchTerm }));
  }, []);

  const actionOptions = (rule) => [
    {
      onClick: () => handEditRuleClicked(rule),
      node: 'Edit',
      key: 'editRule',
    },
    {
      onClick: () => handleDeleteRule(rule),
      withConfirmProps: {
        title: 'Delete Rule?',
        contentText: `Are you sure you want to delete "${rule.display_name}"?`,
        primaryButtonName: 'Delete',
      },
      node: 'Delete',
      key: 'deleteRule',
    },
  ];

  const lobCell = (rule) => (
    <div className={styles.lobCell}>
      <span className={styles.lobIcon}>{getLobIcon({ lob: rule.lob, lobConfigurationsDict })}</span>
      <span>{getLobDescription(rule.lob, lobConfigurationsDict)}</span>
    </div>
  );

  const enabledCell = (rule) =>
    rule.notification_type === 'mandatory' ? null : (
      <Switch
        onChange={() => handleEnabledChanged(rule)}
        className={classes.formsSwitch}
        size="small"
        disabled={isProcessing}
        checked={rule.is_enabled}
      />
    );

  const actionsCell = (rule) => (
    <div>
      <span style={{ marginRight: 10 }}>
        <InlineIconButton
          icon={ArrowUpwardIcon}
          onClick={() => handleMoveRuleUp(rule)}
          disabled={rule.id === rules[0].id || isProcessing}
        />
      </span>
      <InlineIconButton
        icon={ArrowDownwardIcon}
        onClick={() => handleMoveRuleDown(rule)}
        disabled={rule.id === rules[rules.length - 1].id || isProcessing}
      />
      <ThreeDotsMenu options={actionOptions(rule)} />
    </div>
  );

  const chipsCell = (values) => {
    if (_.isEmpty(values)) {
      return '';
    }

    return (
      <div>
        {values.split(',').map((value) => (
          <Chip key={value} size="small" label={value} className={classes.chip} />
        ))}
      </div>
    );
  };

  const columns = [
    { id: 'display_name', label: 'Rule Name' },
    { id: 'lob', label: 'Line of Business', specialCell: lobCell },
    {
      id: 'involved_parties',
      label: 'Involved Entities',
      specialCell: (rule) => chipsCell(rule.involved_parties_summary),
    },
    {
      id: 'indemnity_reserve',
      label: 'Indemnity Reserve',
      specialCell: (rule) => currencyFormatter.format(rule.indemnity_reserve),
    },
    {
      id: 'expenses_reserve',
      label: 'Expenses Reserve',
      specialCell: (rule) => currencyFormatter.format(rule.expenses_reserve),
    },
    {
      id: 'conditions_summary',
      label: 'Conditions Summary',
      specialCell: (rule) => chipsCell(rule.conditions_summary),
    },
    { id: 'coverage_name', label: 'Coverage Name' },
    {
      id: 'enable',
      numeric: false,
      label: 'Enable',
      specialCell: enabledCell,
      disableSort: true,
    },
    {
      id: 'actions',
      label: 'Actions',
      width: '90px',
      specialCell: actionsCell,
    },
  ];

  if (organization.sub_organizations_enabled) {
    columns.splice(1, 0, {
      id: 'sub_organization_id',
      label: 'Sub Organization',
      specialCell: (rule) => {
        const subOrgIdToName = subOrgIdToNameDict(organization);
        return subOrgIdToName[rule.sub_organization_id];
      },
    });
  }

  if (isLoading || isError) {
    return <LoadingIndicator isError={isError} />;
  }

  return (
    <SubReservesContextProvider>
      <CardDialog noCardTitle>
        <Grid container className={styles.automaticExposureRules}>
          <Grid item xs={12} className={styles.sectionWrapper}>
            <Heading variant={Heading.TYPES.H3}>Exposure Opening Rules</Heading>
          </Grid>
          <Grid item xs={8} className={styles.sectionWrapper}>
            <SearchRules onSearchChanged={handleSearchTermChanged} />
          </Grid>
          <Grid item xs={4} />
          <Grid item xs={8} className={styles.sectionWrapper}>
            <FilterRules onFilterChanged={handleFiltersChanged} />
          </Grid>
          <Grid item xs={4}>
            <div className={styles.addButtonContainer}>
              <Button color="primary" onClick={handAddRuleClicked}>
                <AddIcon />
                Add Rule
              </Button>
            </div>
          </Grid>
          <Grid item xs={12} className={styles.rulesTable}>
            <SortableTable columns={columns} rows={filteredRules} disableSortByUser keepRowsOrder />
          </Grid>
        </Grid>
        {isRuleDialogOpen && (
          <AddOrEditRuleDialog onCancel={handleCloseRuleDialog} onSubmit={handleSubmitRule} rule={ruleToEdit} />
        )}
      </CardDialog>
    </SubReservesContextProvider>
  );
};

export default AutomaticExposures;
