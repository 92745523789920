import React from 'react';
import PropTypes from 'prop-types';
import { Collapse, Typography } from '@material-ui/core';
import { getIn, useFormikContext } from 'formik';
import { isEmpty } from 'lodash';

import RadioButtonSectionFormik from '~/components/core/Formik/RadioButtonSectionFormik';

import { CLAIM_SEARCH } from '../../../../../../../../Types';
import { FIELD_IDS } from '../..';
import ConfigurationTrigger from '../../TriggerSelection';

import styles from '../../claimSearchConfigurationDialog.module.scss';

const TRIGGERS = {
  IMMEDIATE: 'immediate',
  FOLLOWING_INFORMATION: 'following_information',
};

const additionalTriggerEntries = Object.keys(CLAIM_SEARCH.SUPPORTED_ADDITIONAL_INITIAL_TRIGGER).map((key) => ({
  id: key,
  text: CLAIM_SEARCH.SUPPORTED_ADDITIONAL_INITIAL_TRIGGER[key].desc,
}));

const InnerComponent = ({ id, title, subtitle, isSelected, selections = [] }) => (
  <div className={styles.initialTriggersSection}>
    <Typography display="block" variant="subtitle2" className={styles.title}>
      {title}
    </Typography>
    <p>{subtitle}</p>
    {!isEmpty(selections) && (
      <Collapse in={isSelected} timeout="auto" unmountOnExit>
        {selections.map((option) => (
          <ConfigurationTrigger key={option.id} id={`${id}.${option.id}`} text={option.text} />
        ))}
      </Collapse>
    )}
  </div>
);

InnerComponent.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  isSelected: PropTypes.bool,
  selections: PropTypes.arrayOf(PropTypes.object),
  subtitle: PropTypes.string.isRequired,
};

const InitialTriggers = () => {
  const { values } = useFormikContext();

  return (
    <div>
      <RadioButtonSectionFormik
        id={FIELD_IDS.INITIAL_REPORTS.METHOD}
        className={styles.initialTriggerRadio}
        Component={
          <InnerComponent
            id={FIELD_IDS.INITIAL_REPORTS.METHOD}
            title="Required information only"
            subtitle="Report after all required information is submitted."
          />
        }
        isSelected={getIn(values, FIELD_IDS.INITIAL_REPORTS.METHOD) === TRIGGERS.IMMEDIATE}
        value={TRIGGERS.IMMEDIATE}
      />
      <RadioButtonSectionFormik
        id={`${FIELD_IDS.INITIAL_REPORTS.METHOD}`}
        className={styles.initialTriggerRadio}
        Component={
          <InnerComponent
            id={FIELD_IDS.INITIAL_REPORTS.FOLLOWING_INFORMATION}
            title="Optional information"
            subtitle="Report after this optional information is submitted in addition to the required information."
            isSelected={getIn(values, FIELD_IDS.INITIAL_REPORTS.METHOD) === TRIGGERS.FOLLOWING_INFORMATION}
            selections={additionalTriggerEntries}
          />
        }
        value={TRIGGERS.FOLLOWING_INFORMATION}
      />
    </div>
  );
};

export default InitialTriggers;
