import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link, TextField, Tooltip, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import axios from 'axios';
import copy from 'copy-to-clipboard';
import { FieldArray, Formik, getIn } from 'formik';
import { isIP } from 'is-ip';
import * as Yup from 'yup';

import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';
import CancelButton from '~/components/core/Buttons/CancelButton';
import SwitchFormik from '~/components/core/Formik/SwitchFormik';
import RadioWithButtonWrapper from '~/components/core/RadioWithButtonWrapper';

import { reportAxiosError } from '../../Utils';
import CardDialog from '../CardDialog';
import { CheckIcon, CopyToClipboardIcon, InfoIcon, PencilIcon, TrashIcon_Deprecated, WarningIcon } from '../icons';
import LoadingIndicator from '../LoadingIndicator';
import TextFieldFormik from '../TextFieldFormik';
import useDataFetcher from '../useDataFetcher';

import OperationsBreadcrumbs from './OperationsBreadcrumbs';
import { useSysconfig } from './SystemConfigurationScreen';

import { useStyles } from '../../assets/styles';

const SSOOptions = [
  { text: 'No SSO', value: 'none', isConfigurable: false },
  { text: 'Azure', value: 'azure_ad', isConfigurable: true },
  { text: 'Okta', value: 'okta', isConfigurable: true },
];

const initialSSOValues = {
  scope: '',
  domain: '',
  tenant: '',
  provider: '',
  client_id: '',
  client_secret: '',
};

const LoginConfiguration = () => {
  return (
    <CardDialog noCardTitle>
      <OperationsBreadcrumbs currentTab="Login Configuration" />
      <Typography variant="subtitle1" style={{ padding: '14px 0px', fontSize: '18px' }}>
        AUTHENTICATION CONFIGURATION
      </Typography>
      <span>Manage login configuration and authentication</span>
      <UsernameAndPasswordConfiguration />
      <GSuiteConfiguration />
      <SSOMethods />
    </CardDialog>
  );
};

const UsernameAndPasswordConfiguration = () => {
  const { organization, users } = useSysconfig();
  const [isMFADialogOpen, setIsMFADialogOpen] = useState(false);
  const classes = useStyles();
  const theme = useTheme();
  const { isLoading, isError, data, reloadData } = useDataFetcher(
    `/sysconfig/api/v1/organizations/${organization.id}/login_configurations`
  );

  const Header = () => (
    <Typography
      display="block"
      variant="subtitle2"
      style={{ paddingTop: '15px', fontWeight: 'bold', fontSize: '16px' }}
    >
      Username and Password
    </Typography>
  );

  if (isLoading || isError) {
    return (
      <>
        <Header />
        <LoadingIndicator isError={isError} />
      </>
    );
  }

  const activeUsersWithoutPhone = users.reduce(
    (count, user) => (!user.is_removed && !user.mobile_phone_number ? count + 1 : count),
    0
  );

  const UsernameAndPasswordPhonesWarning = () => (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Typography variant="body2" style={{ marginBottom: '14px', fontSize: '14px', color: '#606060' }}>
        Update mobile phone number in <span style={{ fontWeight: 'bold' }}>User Management</span>
      </Typography>
      {activeUsersWithoutPhone > 0 && (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <WarningIcon iconColor={theme.palette.warning.main} />
          <Typography variant="body2" style={{ fontSize: '14px', marginLeft: '5px', color: '#606060' }}>
            There are {activeUsersWithoutPhone} active users without phone number configuration
          </Typography>
        </div>
      )}
    </div>
  );

  return (
    <>
      <Header />
      <Typography variant="body2" style={{ fontSize: '14px', color: '#606060', margin: '10px 0px 5px 0px' }}>
        Enabled by default
      </Typography>
      <div style={{ display: 'flex', marginBottom: '15px' }}>
        {!data.allow_login_username_password_no_mfa && <CheckIcon iconColor="#0CBC8B" />}
        <span
          style={{ marginLeft: !data.allow_login_username_password_no_mfa ? '7px' : undefined, marginRight: '12px' }}
        >
          Authentication with MFA {!data.allow_login_username_password_no_mfa ? '' : 'is disabled'}
        </span>
        <PencilIcon
          style={{ cursor: 'pointer' }}
          className={classes.hoverableNonFilledIcon}
          onClick={() => setIsMFADialogOpen(true)}
        />
      </div>
      {!data.allow_login_username_password_no_mfa && (
        <>
          <UsernameAndPasswordPhonesWarning />
          {data.static_ip_list.length > 0 && (
            <>
              <Typography
                variant="body2"
                style={{ fontSize: '14px', marginTop: '15px', fontWeight: 600, color: '#606060' }}
              >
                MFA with IP White List
              </Typography>
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {data.static_ip_list.map((item) => (
                  <span key={item} style={{ marginRight: '10px', marginBottom: '10px' }}>
                    <TextField className={classes.textField} label="IP Address" disabled value={item} />
                  </span>
                ))}
              </div>
            </>
          )}
        </>
      )}
      {isMFADialogOpen && (
        <EditUserNameAndPasswordDialog
          onClose={() => setIsMFADialogOpen(false)}
          data={data}
          reloadData={reloadData}
          UsernameAndPasswordPhonesWarning={UsernameAndPasswordPhonesWarning}
        />
      )}
    </>
  );
};

const GSuiteConfiguration = () => {
  return (
    <div style={{ marginTop: '40px' }}>
      <Typography
        display="block"
        variant="subtitle2"
        style={{ paddingTop: '15px', fontWeight: 'bold', fontSize: '16px' }}
      >
        G-Suite
      </Typography>
      <Typography variant="body2" style={{ fontSize: '14px', color: '#606060', margin: '10px 0px 5px 0px' }}>
        Enabled by default, no need to configure.
      </Typography>
    </div>
  );
};

const putSSO = async (organization, values) =>
  await axios.put(`/sysconfig/api/v1/organizations/${organization.id}/login_configurations/sso`, values);

const SSOMethods = () => {
  const [methodChanged, setMethodChanged] = useState(null);
  const { organization } = useSysconfig();
  const theme = useTheme();
  const { isLoading, isError, data, reloadData } = useDataFetcher(
    `/sysconfig/api/v1/organizations/${organization.id}/login_configurations`
  );

  const Header = () => (
    <Typography
      display="block"
      variant="subtitle2"
      style={{ paddingTop: '15px', fontWeight: 'bold', fontSize: '16px' }}
    >
      Single Sign On (SSO) Methods
    </Typography>
  );

  if (isLoading || isError) {
    return (
      <>
        <Header />
        <LoadingIndicator isError={isError} />
      </>
    );
  }

  const method = data?.sso?.provider || 'none';
  const methodObject = SSOOptions.find((option) => option.value === method);

  return (
    <div style={{ marginTop: '40px' }}>
      <Header />
      <Typography variant="body2" style={{ fontSize: '14px', color: '#606060', margin: '10px 0px 5px 0px' }}>
        Choose SSO method:
      </Typography>
      {SSOOptions.map(({ value, text }) => (
        <RadioWithButtonWrapper
          key={value}
          text={text}
          value={value}
          checked={method === value}
          onChange={async () => {
            setMethodChanged(value);
            if (value === 'none') {
              await putSSO(organization, { sso: {}, logout_redirect_page: '' });
              await reloadData();
            }
          }}
          wrapperOverrideStyle={{ '&:not(:hover)': { backgroundColor: '#FAFAFA' } }}
          disabled={isLoading}
        />
      ))}
      {methodObject?.isConfigurable && (
        <div style={{ marginTop: '24px' }}>
          <Link
            href=""
            style={{ margin: 0, textDecoration: 'underline', color: '#305A7F', fontSize: '14px', fontWeight: 500 }}
            onClick={(e) => {
              e.preventDefault();
              copy(data?.direct_login_url);
            }}
            disabled={!data?.direct_login_url}
          >
            {`Sign in to ${methodObject.text} shortcut`}
            <Tooltip arrow placement="top" title="Copy to clipboard">
              <span>
                <CopyToClipboardIcon iconColor={theme.palette.primary.darker} style={{ marginLeft: '10px' }} />
              </span>
            </Tooltip>
          </Link>
        </div>
      )}
      {methodChanged && SSOOptions.find((option) => option.value === methodChanged)?.isConfigurable && (
        <EditSSOMethodDialog
          data={data}
          reloadData={reloadData}
          method={methodChanged}
          onClose={() => {
            setMethodChanged(null);
          }}
        />
      )}
    </div>
  );
};

const EditUserNameAndPasswordDialog = ({ reloadData, data = {}, onClose, UsernameAndPasswordPhonesWarning }) => {
  const { organization } = useSysconfig();
  const classes = useStyles();

  return (
    <Formik
      initialValues={{
        mfa_enabled: !data.allow_login_username_password_no_mfa,
        static_ip_list: data.static_ip_list,
      }}
      validationSchema={Yup.object().shape({
        static_ip_list: Yup.array().of(Yup.string().test('is-possible-ip', 'Invalid IP', (ip) => isIP(ip))),
      })}
      enableReinitialize
      onSubmit={async (values, formikProps) => {
        try {
          await axios.put(`/sysconfig/api/v1/organizations/${organization.id}/login_configurations/mfa`, {
            allow_login_username_password_no_mfa: !values.mfa_enabled,
          });
          await axios.put(`/sysconfig/api/v1/organizations/${organization.id}/login_configurations/static_ip`, values);
          await reloadData();
          onClose();
        } catch (error) {
          await reportAxiosError(error);
          formikProps.setSubmitting(false);
        }
      }}
    >
      {({ handleSubmit, isSubmitting, values }) => {
        return (
          <CardDialog isDialog title="Edit Username and Password" onClose={onClose}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <SwitchFormik
                  className={classes.formsSwitch}
                  id="mfa_enabled"
                  label={
                    <div>
                      <div style={{ display: 'flex' }}>
                        Authentication with MFA
                        <Tooltip
                          arrow
                          placement="top"
                          title={
                            <div>
                              <div>MFA- multi-factor authentication</div>
                              <div>Disabling MFA will reduce security and is not recommended</div>
                            </div>
                          }
                        >
                          <span style={{ display: 'flex' }}>
                            <InfoIcon iconColor="#909090" size={16} style={{ marginLeft: '6px' }} />
                          </span>
                        </Tooltip>
                      </div>
                      <Typography variant="body2" style={{ marginBottom: '14px', fontSize: '14px', color: '#606060' }}>
                        {!values.mfa_enabled && (
                          <span>
                            Disabling Multi Factor Authentication will reduce security and is not recommended.
                          </span>
                        )}
                        {values.mfa_enabled && (
                          <span>For Multi Factor Authentication mobile phone number or IP is required.</span>
                        )}
                      </Typography>
                    </div>
                  }
                />
              </Grid>
              <Grid item xs={12} container style={{ marginLeft: '60px' }}>
                {values.mfa_enabled && (
                  <>
                    <Grid item xs={12} style={{ marginTop: '-30px' }}>
                      <Typography variant="body2" style={{ fontSize: '14px', marginTop: '14px', fontWeight: 600 }}>
                        MFA with mobile phone number
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <UsernameAndPasswordPhonesWarning />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body2" style={{ fontSize: '14px', marginTop: '14px', fontWeight: 600 }}>
                        MFA with IP white list
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <FieldArray
                        name="static_ip_list"
                        render={({ remove, push }) => (
                          <>
                            {getIn(values, 'static_ip_list').map((_, idx) => (
                              <Grid container key={idx} style={{ marginRight: '5px', marginBottom: '5px' }}>
                                <Grid item xs={6}>
                                  <TextFieldFormik
                                    className={classes.textField}
                                    id={`static_ip_list.${idx}`}
                                    label="IP Address"
                                    fullWidth
                                  />
                                </Grid>
                                <Grid item xs={1}>
                                  <span style={{ marginLeft: '10px' }} onClick={() => remove(idx)}>
                                    <TrashIcon_Deprecated
                                      iconColor="202020"
                                      className={classes.hoverableIcon}
                                      style={{ marginTop: '28px' }}
                                    />
                                  </span>
                                </Grid>
                                <Grid item xs={5} />
                              </Grid>
                            ))}
                            <Button
                              color="primary"
                              startIcon={<AddIcon />}
                              onClick={() => push('')}
                              style={{ display: 'flex' }}
                            >
                              <span style={{ marginRight: '5px' }}>ADD IP ADDRESS</span>
                            </Button>
                          </>
                        )}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
              <Grid container style={{ paddingTop: '8px' }}>
                <div className={classes.buttonsContainer}>
                  <CancelButton disabled={isSubmitting} onClick={onClose} contained />
                  <Button onClick={handleSubmit} variant="contained" color="primary" disabled={isSubmitting}>
                    Save
                  </Button>
                </div>
              </Grid>
            </Grid>
          </CardDialog>
        );
      }}
    </Formik>
  );
};

EditUserNameAndPasswordDialog.propTypes = {
  reloadData: PropTypes.func.isRequired,
  data: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  UsernameAndPasswordPhonesWarning: PropTypes.func.isRequired,
};

const EditSSOMethodDialog = ({ reloadData, method, data = {}, onClose }) => {
  const { organization } = useSysconfig();
  const classes = useStyles();

  const getValidationByMethod = (requiredMethod) => {
    if (requiredMethod === method) {
      return Yup.string().required('Required');
    }
    return Yup.string();
  };

  return (
    <Formik
      initialValues={{
        sso_method: method,
        sso: { ...initialSSOValues, ...data.sso },
        logout_redirect_page: data.logout_redirect_page || '',
      }}
      validationSchema={Yup.object().shape({
        sso_method: Yup.string().oneOf(['okta', 'azure_ad']),
        sso: Yup.object().shape({
          scope: Yup.string(),
          domain: getValidationByMethod('okta'),
          tenant: getValidationByMethod('azure_ad'),
          client_id: Yup.string().required('Required'),
          client_secret: Yup.string().required('Required'),
        }),
        logout_redirect_page: Yup.string(),
      })}
      enableReinitialize
      onSubmit={async (values, formikProps) => {
        try {
          await putSSO(organization, {
            sso: { ...values.sso, provider: values.sso_method },
            logout_redirect_page: values.logout_redirect_page,
          });
          await reloadData();
          onClose();
        } catch (error) {
          await reportAxiosError(error);
          formikProps.setSubmitting(false);
        }
      }}
    >
      {({ handleSubmit, isSubmitting }) => {
        return (
          <CardDialog
            isDialog
            title={`Edit ${SSOOptions.find((option) => option.value === method)?.text || ''} SSO Method`}
            onClose={onClose}
          >
            <Grid container spacing={1}>
              <span>{`In order to login with ${
                SSOOptions.find((option) => option.value === method)?.text || ''
              } add an OpenID Connect (OIDC) web app integration.`}</span>
              <Grid item xs={6}>
                <TextFieldFormik className={classes.textField} id="sso.scope" label="Scope" fullWidth />
              </Grid>
              <Grid item xs={6} />
              {method === 'okta' && (
                <Grid item xs={6}>
                  <TextFieldFormik className={classes.textField} id="sso.domain" label="Domain" fullWidth />
                </Grid>
              )}
              {method === 'azure_ad' && (
                <Grid item xs={6}>
                  <TextFieldFormik className={classes.textField} id="sso.tenant" label="Tenant" fullWidth />
                </Grid>
              )}
              <Grid item xs={6} />
              <Grid item xs={6}>
                <TextFieldFormik
                  className={classes.textField}
                  id="sso.client_id"
                  label="Client ID"
                  type="password"
                  fullWidth
                />
              </Grid>
              <Grid item xs={6} />
              <Grid item xs={6}>
                <TextFieldFormik
                  className={classes.textField}
                  id="sso.client_secret"
                  label="Client Secret"
                  type="password"
                  fullWidth
                />
              </Grid>
              <Grid item xs={6} />
              <Grid item xs={6}>
                <TextFieldFormik
                  className={classes.textField}
                  id="logout_redirect_page"
                  label="Logout redirect page"
                  fullWidth
                />
              </Grid>
              <Grid item xs={6} />
              <Grid container style={{ paddingTop: '8px' }}>
                <div className={classes.buttonsContainer}>
                  <CancelButton disabled={isSubmitting} onClick={onClose} autoFocus contained />
                  <Button onClick={handleSubmit} variant="contained" color="primary" disabled={isSubmitting}>
                    Save
                  </Button>
                </div>
              </Grid>
            </Grid>
          </CardDialog>
        );
      }}
    </Formik>
  );
};

EditSSOMethodDialog.propTypes = {
  reloadData: PropTypes.func.isRequired,
  method: PropTypes.string.isRequired,
  data: PropTypes.object,
  onClose: PropTypes.func.isRequired,
};

export default LoginConfiguration;
