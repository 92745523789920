import React from 'react';
import PropTypes from 'prop-types';
import AddIcon from '@material-ui/icons/Add';
import { FieldArray, useFormikContext } from 'formik';
import { get } from 'lodash';

import AlertBanner from '~/components/core/AlertBanner';
import Button from '~/components/core/Atomic/Buttons/Button';

import copies from '../../copies.json';
import BaseConfigurationSection from '../BaseConfigurationSection';

import CategoryCard from './CategoryCard';

const ID_PREFIX = 'configuration.categories';

const CategoriesSection = () => {
  const { values } = useFormikContext();

  const categories = get(values, ID_PREFIX, []);

  const getNewField = ({ index }) => ({
    id: `category_${index}`,
    desc: `Category ${index}`,
  });

  return (
    <BaseConfigurationSection title="Edit Categories List">
      <div className="mt-15">
        <AlertBanner alertType={AlertBanner.ALERT_TYPES.INFO} note={copies.categories.categories_note} withIcon />
        <div className="pb-20" />
        <FieldArray
          name={ID_PREFIX}
          render={(arrayHelpers) => (
            <div className="mr-6 flex flex-col gap-10">
              {Array.isArray(categories) &&
                categories.map((_, idx) => <CategoryCard index={idx} key={idx} arrayHelpers={arrayHelpers} />)}
              <div className="flex">
                <Button
                  variant="text"
                  color="primary"
                  onClick={() => arrayHelpers.push(getNewField({ index: categories?.length + 1 }))}
                >
                  <AddIcon />
                  ADD A CATEGORY
                </Button>
              </div>
            </div>
          )}
        />
      </div>
    </BaseConfigurationSection>
  );
};

CategoriesSection.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default CategoriesSection;
