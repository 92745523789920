import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem } from '@material-ui/core';
import { useFormikContext } from 'formik';

import { Card } from '~/components/core/Cards/Card';
import RadioButtonGroupFormik from '~/components/core/Formik/RadioButtonGroupFormik';

import { TooltipIcon } from '../../../../../core';
import WarningCircleIcon from '../../../../../icons/WarningCircle';
import TextFieldFormik from '../../../../../TextFieldFormik';
import { DIALOG_MODE, ORG_UNIT_TYPE, PARENT_UNIT_CHANGE_WARNING_MESSAGE, UNIT_FIELDS } from '../../consts';

export const UnitDetailsCard = ({ unitsDict, dialogMode }) => {
  const { values, setFieldValue, initialValues } = useFormikContext();
  const { id, parent_unit_id, unit_type } = values;
  const isEditMode = dialogMode === DIALOG_MODE.EDIT;
  const isDeactivateMode = dialogMode === DIALOG_MODE.DEACTIVATE;
  const isParentUnitChanged = isEditMode && initialValues[UNIT_FIELDS.parentUnitId] !== parent_unit_id;

  React.useEffect(() => {
    if (dialogMode === DIALOG_MODE.ADD) {
      setFieldValue(UNIT_FIELDS.unitType, ORG_UNIT_TYPE.SUB_UNIT);
      return;
    }
    const unitType = parent_unit_id ? ORG_UNIT_TYPE.SUB_UNIT : ORG_UNIT_TYPE.TOP_UNIT;
    setFieldValue(UNIT_FIELDS.unitType, unitType);
  }, [dialogMode, parent_unit_id, setFieldValue]);

  const options = [
    {
      id: 1,
      optionValue: ORG_UNIT_TYPE.TOP_UNIT,
      text: 'Top Level Unit',
      disabled: isEditMode || isDeactivateMode,
    },
    {
      id: 2,
      optionValue: ORG_UNIT_TYPE.SUB_UNIT,
      text: 'Sub Level Unit',
      disabled: isEditMode || isDeactivateMode,
    },
  ];
  const onChangeUnitType = (value) => {
    setFieldValue(UNIT_FIELDS.unitType, value);
  };

  return (
    <Card cardType={Card.CARD_TYPES.PRIMARY} title="Details">
      <div className="flex min-h-[200px] flex-col gap-20">
        <TextFieldFormik id="name" label="Unit Name" fullWidth disabled={isDeactivateMode} />
        <TextFieldFormik id="description" label="Description" fullWidth disabled={isDeactivateMode} />

        {dialogMode !== DIALOG_MODE.ADD_TOP_ONLY && (
          <>
            <RadioButtonGroupFormik
              label="Type"
              id={UNIT_FIELDS.unitType}
              direction="row"
              options={options}
              sizeButtonsEvenly
              allbtnsContainerClassName="gap-10 flex-nowrap"
              onClick={onChangeUnitType}
              btnClassName="bg-slate-100"
            />

            {unit_type === ORG_UNIT_TYPE.SUB_UNIT && (
              <div className="flex items-end gap-10">
                <TextFieldFormik id="parent_unit_id" label="Parent Unit" fullWidth select disabled={isDeactivateMode}>
                  {Object.values(unitsDict)
                    .filter((unit) => unit.id !== id)
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((unit) => (
                      <MenuItem key={unit.id} value={unit.id}>
                        {unit.name}
                      </MenuItem>
                    ))}
                </TextFieldFormik>
                {isParentUnitChanged && (
                  <TooltipIcon title={PARENT_UNIT_CHANGE_WARNING_MESSAGE}>
                    <WarningCircleIcon className="h-24 w-24" />
                  </TooltipIcon>
                )}
              </div>
            )}
          </>
        )}
      </div>
    </Card>
  );
};

UnitDetailsCard.propTypes = {
  unitsDict: PropTypes.shape({}),
  dialogMode: PropTypes.oneOf(Object.values(DIALOG_MODE)).isRequired,
};

UnitDetailsCard.deafultProps = {
  units: [],
};
