import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem } from '@material-ui/core';
import { useStyles } from '@material-ui/pickers/views/Calendar/SlideTransition';
import { getIn, useFormikContext } from 'formik';

import TextFieldFormik, { MultiSelectTextFieldFormik } from '../../../../TextFieldFormik';

// if more information about operator will be needed, move it to BE
export const MULTI_SELECT_OPERATORS = ['in'];

const ConditionValueField = ({ conditionOperator, baseFieldProps, operands }) => {
  const classes = useStyles();
  const { values } = useFormikContext();
  const conditionValue = getIn(values, baseFieldProps.id);

  return MULTI_SELECT_OPERATORS.includes(conditionOperator) ? (
    Array.isArray(conditionValue) ? (
      <MultiSelectTextFieldFormik
        {...baseFieldProps}
        className={classes.formTextField}
        fullWidth
        renderOption={(option) => operands.find((subOperand) => subOperand.value === option)?.desc}
        options={operands.map((operand) => operand.value)}
        renderValue={() => ''}
        withOptionChips
      />
    ) : null
  ) : (
    <TextFieldFormik {...baseFieldProps} className={classes.formTextField} fullWidth select>
      {operands.map((operand) => (
        <MenuItem key={operand.value} value={operand.value}>
          {operand.desc}
        </MenuItem>
      ))}
    </TextFieldFormik>
  );
};

ConditionValueField.propTypes = {
  conditionOperator: PropTypes.string,
  baseFieldProps: PropTypes.object,
  operands: PropTypes.array,
};

export default ConditionValueField;
