import React, { useState } from 'react';
import { Typography } from '@material-ui/core';

import CardDialog from '../../../CardDialog';
import LoadingSwitch from '../../../core/Loading/LoadingSwitch';
import { PencilIcon } from '../../../icons';
import InlineIconButton from '../../../InlineIconButton';
import useDataFetcher from '../../../useDataFetcher';
import OperationsBreadcrumbs from '../../OperationsBreadcrumbs';
import { useSysconfig } from '../../SystemConfigurationScreen';

import EditNavigatorDialog from './EditNavigatorDialog';
import NavigatorOrganizationTableCard from './NavigatorOrganizationTableCard';
import useCreateSubOrgConfigIfNeeded from './useCreateSubOrgConfigIfNeeded';

import { useStyles } from '../../../../assets/styles';

const ExternalAdminNavigatorCard = () => {
  const { organization } = useSysconfig();
  const {
    isLoading,
    isError,
    data: navigatorConfigurations,
    reloadData: reloadNavigatorConfigurations,
  } = useDataFetcher(`/api/v1/navigator/organizations/${organization.id}/configurations`);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [activeNavigatorConfiguration, setActiveNavigatorConfiguration] = useState(null);
  const classes = useStyles();

  const { isLoading: isLoadingNewSubOrgsConfigs, isError: isErrorNewSubOrgsConfigs } = useCreateSubOrgConfigIfNeeded({
    organization,
    existingNavigatorConfiguration: navigatorConfigurations,
    reloadConfigurations: reloadNavigatorConfigurations,
    isNavigatorEnabled: true,
  });

  const handleOpenEditDialog = (navigatorConfiguration) => {
    setActiveNavigatorConfiguration(navigatorConfiguration);
    setEditDialogOpen(true);
  };

  const handleCloseEditDialog = () => {
    setEditDialogOpen(false);
  };

  const handleSubmit = async () => {
    await reloadNavigatorConfigurations();
  };

  const additionalColumnsArray = [
    {
      id: 'note',
      numeric: false,
      label: 'Note',
      disableSort: true,
      specialCell: (navigatorConfiguration) =>
        navigatorConfiguration.navigator_status === 'pending' ? 'Contact your solution manager' : '',
    },
    {
      id: 'actions',
      numeric: false,
      label: 'Actions',
      disableSort: true,
      specialCell: (navigatorConfiguration) => (
        <InlineIconButton
          tooltipTitle="Edit"
          icon={PencilIcon}
          className={`${classes.textIcon} ${classes.marginedIcon} ${classes.hoverableNonFilledIcon}`}
          onClick={() => handleOpenEditDialog(navigatorConfiguration)}
          wrapWithSpan
        />
      ),
    },
  ];

  return (
    <>
      <LoadingSwitch isError={isError} isLoading={isLoading}>
        <OperationsBreadcrumbs currentTab="Navigator" />
        <CardDialog title="NAVIGATOR">
          <Typography variant="body1">Enable integration with Navigator.</Typography>
          <LoadingSwitch isError={isErrorNewSubOrgsConfigs} isLoading={isLoadingNewSubOrgsConfigs}>
            <NavigatorOrganizationTableCard
              navigatorConfigurations={navigatorConfigurations}
              additionalColumnsArray={additionalColumnsArray}
            />
          </LoadingSwitch>
          {editDialogOpen && (
            <EditNavigatorDialog
              handleClose={handleCloseEditDialog}
              onSubmit={handleSubmit}
              activeNavigatorConfiguration={activeNavigatorConfiguration}
            />
          )}
        </CardDialog>
      </LoadingSwitch>
    </>
  );
};

export default ExternalAdminNavigatorCard;
