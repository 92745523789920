import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { getIn, useFormikContext } from 'formik';
import { v4 as uuidv4 } from 'uuid';

import { useStyles } from '~/assets/styles';
import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';
import SectionWithColoredIndent from '~/components/core/IndentedSections/SectionWithColoredIndent';
import { NOTIFICATIONS_RULES_TIMEFRAMES } from '~/Types';

import cn from '../../../../../Utils/cn';
import AutocompleteFormik from '../../../../AutocompleteFormik';
import { Text } from '../../../../core';
import {
  DatePickerTextFieldFormik,
  MultiSelectTextFieldFormik,
  NumericTextFieldFormik,
  TextFieldFormik,
  TimePickerTextFieldFormik,
  useAllOptionOnMultiSelect,
  useSetDefaultFieldsOnChange,
} from '../../../../TextFieldFormik';
import { ALL_OPTION, BASE_CONDITION_FIELD, CONDITION_FIELD_NAME } from '../utils';

import { ConditionCardWrapper } from './ConditionCard';
import { ConditionsSeparator } from './ConditionsSeparator';

export const ConditionInnerFormik = ({
  item,
  idx,
  self,
  remove,
  push,
  selectedConditionKey,
  conditionsWithGroupForSelectedCriteria,
  relevantCriteriaKeys,
  conditions,
  selectedCriteria,
}) => {
  const classes = useStyles();
  const { isSubmitting, values } = useFormikContext();

  useSetDefaultFieldsOnChange(selectedCriteria, {
    [`${CONDITION_FIELD_NAME}.${idx}.criteria_types`]: [ALL_OPTION],
    [`${CONDITION_FIELD_NAME}.${idx}.condition_key`]: null,
    [`${CONDITION_FIELD_NAME}.${idx}.condition_text_value`]: null,
    [`${CONDITION_FIELD_NAME}.${idx}.condition_numeric_value`]: null,
    [`${CONDITION_FIELD_NAME}.${idx}.condition_schedule_value`]: null,
    [`${CONDITION_FIELD_NAME}.${idx}.condition_schedule_timeframe_value`]: null,
  });

  useAllOptionOnMultiSelect(
    getIn(values, `${CONDITION_FIELD_NAME}.${idx}.criteria_types`),
    `${CONDITION_FIELD_NAME}.${idx}.criteria_types`,
    ALL_OPTION
  );

  const DATE_RANGE_FIELDS_CONFIG = [
    { id: 'condition_date_range_start_date', type: 'date', label: 'Start Date' },
    { id: 'condition_date_range_start_time', type: 'time', label: 'Start Time' },
    { id: 'start_end_separator', type: 'separator', label: 'To' },
    { id: 'condition_date_range_end_date', type: 'date', label: 'End Date' },
    { id: 'condition_date_range_end_time', type: 'time', label: 'End Time' },
  ];

  const topRowFieldsWidth = !selectedCriteria || !conditions[selectedCriteria].has_type ? 6 : 4;

  const filteredConditionsForSelectedCriteria = Object.fromEntries(
    Object.entries(conditionsWithGroupForSelectedCriteria).filter(
      ([, condition]) => !condition.lob || !values.lob || values.lob === 'all' || condition.lob.includes(values.lob)
    )
  );

  return (
    <React.Fragment key={item.id}>
      <Grid item xs={12} style={{ padding: '0px 6px' }}>
        <SectionWithColoredIndent colorPalette="primary" colorType="darker">
          <ConditionCardWrapper idx={idx} onDelete={remove}>
            <Grid container spacing={3}>
              <Grid item xs={topRowFieldsWidth}>
                <AutocompleteFormik
                  id={`${CONDITION_FIELD_NAME}.${idx}.criteria_key`}
                  label="Object"
                  options={relevantCriteriaKeys}
                  getOptionLabel={(criteria) => conditions[criteria].description}
                  sortAlphabetic
                  disabled={isSubmitting}
                  fullWidth
                />
              </Grid>
              {selectedCriteria && (
                <>
                  {conditions[selectedCriteria].has_type && (
                    <Grid item xs={topRowFieldsWidth}>
                      <MultiSelectTextFieldFormik
                        id={`${CONDITION_FIELD_NAME}.${idx}.criteria_types`}
                        label="Type"
                        disabled={isSubmitting}
                        options={conditions[selectedCriteria].types.concat(ALL_OPTION)}
                        renderValue={(value) => value.key}
                        renderOption={(value) => value.desc}
                        className={classes.formTextFieldNoErrorSpacing}
                        fullWidth
                        withOptionChips
                        sortAlphabetic
                      />
                    </Grid>
                  )}
                  {Object.keys(filteredConditionsForSelectedCriteria).length > 0 && (
                    <Grid item xs={topRowFieldsWidth}>
                      <TextFieldFormik
                        id={`${CONDITION_FIELD_NAME}.${idx}.condition_key`}
                        label="Condition"
                        disabled={isSubmitting}
                        className={classes.formTextField}
                        fullWidth
                        select
                      >
                        {Object.keys(filteredConditionsForSelectedCriteria).map((optionKey) => (
                          <MenuItem key={optionKey} value={optionKey}>
                            {conditionsWithGroupForSelectedCriteria[optionKey].desc}
                          </MenuItem>
                        ))}
                      </TextFieldFormik>
                    </Grid>
                  )}
                  {conditionsWithGroupForSelectedCriteria[selectedConditionKey]?.additionalValueType === 'text' && (
                    <Grid item xs={6}>
                      <TextFieldFormik
                        id={`${CONDITION_FIELD_NAME}.${idx}.condition_text_value`}
                        label="Value"
                        disabled={isSubmitting}
                        className={classes.formTextField}
                        fullWidth
                        select
                      >
                        {conditionsWithGroupForSelectedCriteria[selectedConditionKey].textValues
                          .filter(
                            (option) => !option.lob || !values.lob || values.lob === 'all' || option.lob === values.lob
                          )
                          .map((option) => (
                            <MenuItem key={option.key} value={option.key}>
                              {option.desc}
                            </MenuItem>
                          ))}
                      </TextFieldFormik>
                    </Grid>
                  )}
                  {conditionsWithGroupForSelectedCriteria[selectedConditionKey]?.additionalValueType === 'numeric' && (
                    <Grid item xs={6}>
                      <NumericTextFieldFormik
                        id={`${CONDITION_FIELD_NAME}.${idx}.condition_numeric_value`}
                        label="Number"
                        disabled={isSubmitting}
                        className={classes.formTextField}
                        fullWidth
                      />
                    </Grid>
                  )}
                  {conditionsWithGroupForSelectedCriteria[selectedConditionKey]?.additionalValueType === 'schedule' && (
                    <>
                      <Grid item xs={6}>
                        <NumericTextFieldFormik
                          id={`${CONDITION_FIELD_NAME}.${idx}.condition_schedule_value`}
                          label="Number"
                          disabled={isSubmitting}
                          className={classes.formTextField}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextFieldFormik
                          id={`${CONDITION_FIELD_NAME}.${idx}.condition_schedule_timeframe_value`}
                          label="Time Frame"
                          disabled={isSubmitting}
                          className={classes.formTextField}
                          fullWidth
                          select
                        >
                          {Object.keys(NOTIFICATIONS_RULES_TIMEFRAMES).map((option) => (
                            <MenuItem key={option} value={option}>
                              {NOTIFICATIONS_RULES_TIMEFRAMES[option].desc}
                            </MenuItem>
                          ))}
                        </TextFieldFormik>
                      </Grid>
                    </>
                  )}
                  {conditionsWithGroupForSelectedCriteria[selectedConditionKey]?.additionalValueType === 'date_range' &&
                    DATE_RANGE_FIELDS_CONFIG.map((fieldConfig) => (
                      <>
                        {fieldConfig.type === 'date' ? (
                          <div className={cn('w-[23%] px-10 pb-15')} key={fieldConfig.id}>
                            <DatePickerTextFieldFormik
                              id={`${CONDITION_FIELD_NAME}.${idx}.${fieldConfig.id}`}
                              label={fieldConfig.label}
                              disableFuture
                              className={classes.textField}
                              fullWidth
                            />
                          </div>
                        ) : null}
                        {fieldConfig.type === 'time' ? (
                          <div className={cn('w-[23%] px-10 pb-15')} key={fieldConfig.id}>
                            <TimePickerTextFieldFormik
                              id={`${CONDITION_FIELD_NAME}.${idx}.${fieldConfig.id}`}
                              label={fieldConfig.label}
                              fullWidth
                              className={classes.textField}
                            />
                          </div>
                        ) : null}
                        {fieldConfig.type === 'separator' ? (
                          <div className={cn('flex w-[8%] flex-col items-center justify-end px-10 pb-15')}>
                            <Text variant={Text.VARIANTS.SM} weight={Text.WEIGHTS.REGULAR}>
                              {fieldConfig.label}
                            </Text>
                          </div>
                        ) : null}
                      </>
                    ))}
                </>
              )}
            </Grid>
          </ConditionCardWrapper>
          {idx + 1 !== self.length && (
            <Grid item xs={12}>
              <ConditionsSeparator key={idx} />
            </Grid>
          )}
        </SectionWithColoredIndent>
      </Grid>
      {idx + 1 === self.length && (
        <Grid item xs={12} container style={{ padding: '12px 9px 2px 0px' }}>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
            <Button
              color="primary"
              startIcon={<AddIcon />}
              onClick={() => push({ ...BASE_CONDITION_FIELD, id: uuidv4() })}
            >
              <span>ADD CONDITION</span>
            </Button>
          </div>
        </Grid>
      )}
    </React.Fragment>
  );
};

ConditionInnerFormik.propTypes = {
  item: PropTypes.object.isRequired,
  idx: PropTypes.number.isRequired,
  self: PropTypes.array.isRequired,
  remove: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  selectedConditionKey: PropTypes.string,
  conditionsWithGroupForSelectedCriteria: PropTypes.object,
  relevantCriteriaKeys: PropTypes.array.isRequired,
  conditions: PropTypes.object.isRequired,
  selectedCriteria: PropTypes.string,
};
