import React from 'react';
import { MenuItem, TextField } from '@material-ui/core';

import copies from '~/components/SystemConfiguration/FnolConfiguration/FnolConfigurationScreen/copies.json';

import type { SystemField } from './types';

interface SystemFieldTypeProps {
  field: SystemField;
}

const SystemFieldType: React.FC<SystemFieldTypeProps> = ({ field }: SystemFieldTypeProps) => {
  const options = copies.field_types;
  return (
    <TextField label="Type" select value={field.type || ''} fullWidth disabled>
      {options.map((option) => (
        <MenuItem key={option.id} value={option.id}>
          {option.desc}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default SystemFieldType;
