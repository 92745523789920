import React from 'react';

import Editor from '~/components/core/Editor';
import Text from '~/components/core/TextComponents/Text';
import type { TemplateBuilderEditorInnerComponentProps } from '~/components/GenericTemplates/types';
import { TEMPLATE_BUILDER_FORM_KEYS } from '~/components/GenericTemplates/utils/genericTemplatesUtils';

const EmailGenericTemplatesEditor: React.FC<TemplateBuilderEditorInnerComponentProps> = ({
  handleChange,
  disabled,
  titleTemplate,
  bodyTemplate,
  suggestions,
}) => {
  return (
    <div className="my-15">
      <div className="my-15">
        <Text variant={Text.VARIANTS.SM} className="my-10">
          Email Title
        </Text>
        <Editor
          className="overflow-auto"
          disabled={disabled}
          onChange={(value) => handleChange(value, TEMPLATE_BUILDER_FORM_KEYS.TITLE_TEMPLATE)}
          autoFocus
          displayConfig={{ suggestions: !!suggestions }}
          content={titleTemplate}
          suggestionOptions={suggestions}
        />
      </div>
      <div className="my-15">
        <Text variant={Text.VARIANTS.SM} className="my-10">
          Email Body
        </Text>
        <Editor
          className="min-h-[150px] overflow-auto"
          disabled={disabled}
          onChange={(value) => handleChange(value, TEMPLATE_BUILDER_FORM_KEYS.BODY_TEMPLATE)}
          autoFocus
          displayConfig={{
            font: true,
            textColor: true,
            textStyle: true,
            align: true,
            link: true,
            suggestions: !!suggestions,
          }}
          suggestionOptions={suggestions}
          content={bodyTemplate}
        />
      </div>
    </div>
  );
};

export default EmailGenericTemplatesEditor;
