import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { MenuItem, Typography } from '@material-ui/core';
import { Formik, getIn, useFormikContext } from 'formik';
import { get, isEmpty, snakeCase } from 'lodash';
import * as Yup from 'yup';

import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';
import CancelButton from '~/components/core/Buttons/CancelButton';
import InnerCard from '~/components/core/Cards/InnerCard';
import { useLobConfiguration } from '~/components/hooks/useLobConfiguration';
import cn from '~/Utils/cn';

import { CONFIGURATION_FEATURES_NAMES, LOB_ISO_CODES } from '../../../../Types';
import { isFeatureEnabled, isInshurUkOrganization, isOrganizationUs } from '../../../../Utils';
import { lobIdentifierValidation, subOrgIdentifierValidation } from '../../../Admin/WizardUtils/Utils';
import AutocompleteFormik from '../../../AutocompleteFormik';
import CardDialog from '../../../CardDialog';
import CheckboxFormik from '../../../CheckboxFormik';
import useFormikChangeListener from '../../../core/Formik/FormikChangeListener';
import { useShouldDisableFormikField } from '../../../hooks/useShouldDisableFormikField';
import TextFieldFormik from '../../../TextFieldFormik';
import { LobCheckboxMultiselectFormik } from '../../../TPA/LOB/LobCheckboxMultiselectFormik';

import { useStyles } from '../../../../assets/styles';
import styles from '../subOrganizations.module.scss';

const FIELD_IDS = {
  EXTERNAL_ID: 'external_id',
  NAME: 'name',
  DESCRIPTION: 'description',
  LOBS: 'lobs',
  LOBS_METADATA: 'lobs_metadata',
  POLICY_CLASSIFICATION_REGEX: 'policy_classification_regex',
  CLAIM_IDENTIFIER: 'claim_identifier', // per suborg
  EMAIL_DOMAIN_ID: 'email_domain_id',
};

const SubOrganizationDialogInner = ({
  handleClose,
  subOrganization,
  hiddenLobsToHideWhenNotSelected = [],
  organization,
  enableRemovingExistingLobs = false,
  claimNumberingScheme,
  emailDomains,
  overrideDisabled,
}) => {
  const classes = useStyles();
  const { isSubmitting, setFieldValue, handleSubmit, errors, values } = useFormikContext();
  const { lobConfigurationsDict } = useLobConfiguration();

  useFormikChangeListener({
    listenForKeys: [FIELD_IDS.LOBS],
    onChange: () => {
      const selectedLobs = getIn(values, FIELD_IDS.LOBS);
      const defaultIdentifiersValues = {};

      selectedLobs.forEach((selectedLob) => {
        const isClaimsMadeSupported = get(lobConfigurationsDict, [selectedLob, 'is_claims_made_supported'], false);

        defaultIdentifiersValues[selectedLob] = {
          lob_claim_identifier: '',
          lob_iso_code: '',
          is_claims_made: isClaimsMadeSupported,
        };
      });

      setFieldValue(FIELD_IDS.LOBS_METADATA, {
        ...defaultIdentifiersValues,
        ...getIn(values, FIELD_IDS.LOBS_METADATA),
      });
    },
  });

  const inEditMode = !!subOrganization;
  const isSetLineOfBusiness = Boolean(claimNumberingScheme?.lob_prefix_toggle);

  const onNameChange = useCallback(
    (event) => {
      const inEditMode = !!subOrganization;
      const value = event.target.value;
      setFieldValue(FIELD_IDS.NAME, value);

      if (!inEditMode) {
        setFieldValue(FIELD_IDS.EXTERNAL_ID, snakeCase(value));
      }
    },
    [setFieldValue, subOrganization]
  );

  const externalIdError = getIn(errors, FIELD_IDS.EXTERNAL_ID);

  const shouldDisableFieldFunction = useShouldDisableFormikField(overrideDisabled);

  return (
    <CardDialog
      title={inEditMode ? 'Edit Sub Organization' : 'Add Sub Organization'}
      containerClassName={styles.newSubOrganizationDialog}
      isDialog
      preventClose={isSubmitting}
      maxWidth="md"
      fullWidth
      onClose={handleClose}
    >
      <Grid container spacing={2} className={styles.inputContainer}>
        <Grid item xs={6}>
          <TextFieldFormik
            id={FIELD_IDS.NAME}
            label="Sub Organization Name"
            className={classes.textField}
            onChange={onNameChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextFieldFormik
            id={FIELD_IDS.EXTERNAL_ID}
            label="Sub Organization ID"
            helperText={externalIdError || 'Can’t change the ID after creating the sub organization'}
            className={classes.textField}
            fullWidth
            disabled
          />
        </Grid>
        {(claimNumberingScheme.sub_organization_prefix_toggle || isInshurUkOrganization(organization)) && (
          <Grid item xs={6}>
            <TextFieldFormik
              id={FIELD_IDS.CLAIM_IDENTIFIER}
              helperText={getPolicyClaimsPlaceholder(organization)}
              label="Claim identifier"
              className={classes.textField}
              fullWidth
              disabled={shouldDisableFieldFunction(inEditMode)}
            />
          </Grid>
        )}

        {organization.is_suborgs_domains_enabled && (
          <Grid item xs={6}>
            <TextFieldFormik
              id={FIELD_IDS.EMAIL_DOMAIN_ID}
              select
              label="Email Domain"
              className={classes.textField}
              fullWidth
              disabled={shouldDisableFieldFunction(inEditMode)}
            >
              {emailDomains.map(({ id, domain }) => (
                <MenuItem key={id} value={id}>
                  {domain}
                </MenuItem>
              ))}
            </TextFieldFormik>
          </Grid>
        )}

        <InnerCard className={styles.lobContainer}>
          <Grid container>
            <Grid item xs={12}>
              <Typography className={styles.lobTitle} display="block" variant="subtitle1">
                Set Line of Business
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography className={styles.lobTitle} display="block" variant="subtitle2">
                Select supported lines of business
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <LobCheckboxMultiselectFormik
                lobsFieldId={FIELD_IDS.LOBS}
                label=""
                shouldIncludeLabel
                disableAllOption
                onlyNewValues={inEditMode && !enableRemovingExistingLobs}
                disableOnEmptySubOrganizations={false}
                subOrganizationIds={[]}
                hiddenLobsToHideWhenNotSelected={hiddenLobsToHideWhenNotSelected}
                showOrgLevelLobs
                direction={LobCheckboxMultiselectFormik.DIRECTION.ROW}
                afterComponent={({ lob }) => {
                  const isClaimsMadeSupported = get(lobConfigurationsDict, [lob, 'is_claims_made_supported'], false);

                  return (
                    <Grid container spacing={2}>
                      {isSetLineOfBusiness && (
                        <Grid item xs={6}>
                          <TextFieldFormik
                            id={`${FIELD_IDS.LOBS_METADATA}.${lob}.lob_claim_identifier`}
                            disabled={shouldDisableFieldFunction(
                              subOrganization?.supported_lobs.find((supportedLob) => supportedLob.lob === lob)
                                ? inEditMode
                                : null
                            )}
                            label="Line of Business Identifier"
                            className={cn(classes.textField, styles.lobBusinessIdentifier)}
                            fullWidth
                          />
                        </Grid>
                      )}

                      {isOrganizationUs(organization, organization?.locale) ? (
                        <Grid item xs={6}>
                          <AutocompleteFormik
                            id={`${FIELD_IDS.LOBS_METADATA}.${lob}.lob_iso_code`}
                            label="ISO code"
                            options={Object.keys(LOB_ISO_CODES)}
                            getOptionLabel={(option) => LOB_ISO_CODES[option]}
                            sortAlphabetic
                            disabled={shouldDisableFieldFunction(
                              subOrganization?.supported_lobs.find((supportedLob) => supportedLob.lob === lob)
                                ? inEditMode
                                : null
                            )}
                            className={cn(classes.textFieldRow, styles.lobInsuranceType)}
                            fullWidth
                          />
                        </Grid>
                      ) : null}
                      {isClaimsMadeSupported && (
                        <Grid item xs={6}>
                          <CheckboxFormik
                            id={`${FIELD_IDS.LOBS_METADATA}.${lob}.is_claims_made`}
                            label="Claim Made Policy"
                            disabled={shouldDisableFieldFunction(
                              subOrganization?.supported_lobs.find((supportedLob) => supportedLob.lob === lob)
                                ? inEditMode
                                : null
                            )}
                            fullWidth
                          />
                        </Grid>
                      )}
                    </Grid>
                  );
                }}
              />
            </Grid>
          </Grid>
        </InnerCard>

        {isPolicyRegexEnabled(organization) && (
          <Grid item xs={12} className={styles.inputContainer}>
            <TextFieldFormik
              id={FIELD_IDS.POLICY_CLASSIFICATION_REGEX}
              label="Policy regex"
              helperText="Sub organization classification pattern"
              className={classes.textField}
              fullWidth
            />
          </Grid>
        )}

        <Grid item xs={12} className={styles.inputContainer}>
          <TextFieldFormik
            id={FIELD_IDS.DESCRIPTION}
            label="Description"
            helperText="Sub organization description"
            className={classes.textField}
            fullWidth
          />
        </Grid>
      </Grid>
      <div className={classes.buttonsContainer}>
        <CancelButton disabled={shouldDisableFieldFunction()} onClick={handleClose} />
        <Button variant="contained" color="primary" disabled={shouldDisableFieldFunction()} onClick={handleSubmit}>
          Save
        </Button>
      </div>
    </CardDialog>
  );
};

const isRegularExpression = (pattern) => {
  try {
    new RegExp(pattern);
    return true;
  } catch (e) {
    return false;
  }
};

const getPolicyClaimsPlaceholder = (organization) =>
  isInshurUkOrganization(organization)
    ? '2 Letters identifying the partner / MGA'
    : 'Letters identifying the partner / MGA';

const isPolicyRegexEnabled = (organization) =>
  !isFeatureEnabled(organization, CONFIGURATION_FEATURES_NAMES.DISABLE_REGEX_FIELD_FOR_SUB_ORGS);

SubOrganizationDialogInner.propTypes = {
  handleClose: PropTypes.func.isRequired,
  subOrganization: PropTypes.object,
  hiddenLobsToHideWhenNotSelected: PropTypes.array,
  organization: PropTypes.object.isRequired,
  emailDomains: PropTypes.array,
  enableRemovingExistingLobs: PropTypes.bool,
  claimNumberingScheme: PropTypes.object,
  overrideDisabled: PropTypes.bool,
};

const SubOrganizationDialog = ({
  handleClose,
  handleCreate,
  handleUpdate,
  subOrganization,
  allSubOrganizations = [],
  hiddenLobsToHideWhenNotSelected = [],
  organization,
  enableRemovingExistingLobs,
  claimNumberingScheme,
  emailDomains,
  overrideDisabled,
  subOrgsClaimIdentifiers,
}) => {
  const existingExternalIds = allSubOrganizations
    .filter(({ id }) => subOrganization?.id !== id)
    .map(({ external_id }) => external_id);

  const isSetLineOfBusiness = Boolean(claimNumberingScheme?.lob_prefix_toggle);

  const getClaimIdentifierValidator = (subOrgsClaimIdentifiers) => {
    if (subOrganization || !claimNumberingScheme.sub_organization_prefix_toggle) {
      return Yup.string();
    }
    return isInshurUkOrganization(organization)
      ? Yup.string()
          .required('Required')
          .matches(/^[A-Z]{2}$/, 'Identifier must consist of two uppercase letters')
      : subOrgIdentifierValidation(subOrgsClaimIdentifiers);
  };

  const validationSchema = Yup.lazy((values) =>
    Yup.object().shape({
      [FIELD_IDS.NAME]: Yup.string().required('Required'),
      [FIELD_IDS.EXTERNAL_ID]: Yup.string()
        .required('Required')
        .test(
          'uniqueExternalId',
          'External id exists, Please change Sub organization name',
          (val) => !existingExternalIds.includes(val)
        ),
      [FIELD_IDS.DESCRIPTION]: Yup.string().required('Required'),
      [FIELD_IDS.LOBS]: Yup.array().of(Yup.string()).min(1, 'You must select at least 1 Line of Business'),
      [FIELD_IDS.LOBS_METADATA]: isSetLineOfBusiness
        ? Yup.object().shape(
            Object.fromEntries(
              values[FIELD_IDS.LOBS].map((lob) => [
                lob,
                Yup.object().shape({
                  lob_claim_identifier: lobIdentifierValidation().test(
                    'is-unique-per-suborg',
                    'Claim Identifier has to be unique within the sub organization’s lob’s Claim Identifiers if the running number sequence is per sub org and lob.',
                    () => {
                      const subOrgLobIdentifiers = Object.values(values?.lobs_metadata).map(
                        (lobMetadata) => lobMetadata.lob_claim_identifier
                      );

                      if (claimNumberingScheme.running_sequence_selection === 'sub_org_and_lob') {
                        return new Set(subOrgLobIdentifiers).size === subOrgLobIdentifiers.length;
                      }

                      return true;
                    }
                  ),
                  lob_iso_code: Yup.string(),
                  is_claims_made: Yup.bool(),
                }),
              ])
            )
          )
        : Yup.object().notRequired(),
      [FIELD_IDS.POLICY_CLASSIFICATION_REGEX]: Yup.string()
        .test('validRegex', 'Must be a valid regex expression', (val) => isRegularExpression(val))
        .test('existsIfActivated', 'Required', (val) => !isPolicyRegexEnabled(organization) || !isEmpty(val)),
      [FIELD_IDS.CLAIM_IDENTIFIER]: getClaimIdentifierValidator(subOrgsClaimIdentifiers),
      [FIELD_IDS.EMAIL_DOMAIN_ID]: organization.is_suborgs_domains_enabled ? Yup.number().required('required') : null,
    })
  );

  return (
    <Formik
      initialValues={{
        [FIELD_IDS.EXTERNAL_ID]: subOrganization?.external_id || '',
        [FIELD_IDS.NAME]: subOrganization?.name || '',
        [FIELD_IDS.POLICY_CLASSIFICATION_REGEX]: subOrganization?.policy_classification_regex || '',
        [FIELD_IDS.CLAIM_IDENTIFIER]: subOrganization?.claim_identifier || '',
        [FIELD_IDS.DESCRIPTION]: subOrganization?.description || '',
        [FIELD_IDS.LOBS]: subOrganization?.supported_lobs.map(({ lob }) => lob) || [],
        [FIELD_IDS.LOBS_METADATA]:
          subOrganization?.supported_lobs.reduce(
            (acc, { lob, claim_identifier, lob_iso_code, is_claims_made }) => ({
              ...acc,
              [lob]: {
                lob_claim_identifier: claim_identifier,
                lob_iso_code,
                is_claims_made,
              },
            }),
            {}
          ) || {},
        [FIELD_IDS.EMAIL_DOMAIN_ID]: subOrganization?.email_domain_id,
      }}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={async (values, formikProps) => {
        await (subOrganization ? handleUpdate(values, subOrganization.id) : handleCreate(values));
        formikProps.setSubmitting(false);
      }}
    >
      <SubOrganizationDialogInner
        handleClose={handleClose}
        subOrganization={subOrganization}
        hiddenLobsToHideWhenNotSelected={hiddenLobsToHideWhenNotSelected}
        organization={organization}
        enableRemovingExistingLobs={enableRemovingExistingLobs}
        claimNumberingScheme={claimNumberingScheme}
        emailDomains={emailDomains}
        overrideDisabled={overrideDisabled}
      />
    </Formik>
  );
};

SubOrganizationDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleCreate: PropTypes.func.isRequired,
  handleUpdate: PropTypes.func.isRequired,
  subOrganization: PropTypes.object,
  hiddenLobsToHideWhenNotSelected: PropTypes.array,
  allSubOrganizations: PropTypes.arrayOf(
    PropTypes.shape({
      external_id: PropTypes.string,
    })
  ),
  organization: PropTypes.object.isRequired,
  enableRemovingExistingLobs: PropTypes.bool,
  claimNumberingScheme: PropTypes.object,
  emailDomains: PropTypes.array,
  overrideDisabled: PropTypes.bool,
  subOrgsClaimIdentifiers: PropTypes.array,
};

export default SubOrganizationDialog;
