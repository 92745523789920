import React, { useMemo, useState } from 'react';
import { Switch, TextField, Typography } from '@material-ui/core';
import axios from 'axios';

import Grid from '~/components/core/Atomic/Grid/Grid';
import { useOrganizationUnits } from '~/components/SystemConfiguration/Users/hooks/useOrganizationUnits';
import { buildUser, prepareValuesForSave } from '~/components/SystemConfiguration/Users/Utils';

import { ROLES_TYPES_DICT } from '../../../Types';
import { reportAxiosError } from '../../../Utils';
import CardDialog from '../../CardDialog';
import { LoadingSwitch, MultiSelectFilter } from '../../core';
import { useCms } from '../../hooks/useCms';
import useDataFetcher from '../../useDataFetcher';
import OrganizationSettings from '../OrganizationSettings';
import { useSysconfig } from '../SystemConfigurationScreen';

import OrganizationUsers from './OrganizationUsers';

import { useStyles } from '../../../assets/styles';

const UsersTab = () => {
  const { organization, roles, reloadOrganization } = useSysconfig();
  const classes = useStyles();
  const { user } = useCms();

  const [selectedRoles, setSelectedRoles] = useState([]);
  const [rolesOptions, setRolesOptions] = useState([]);
  const [searchUserText, setSearchUserText] = useState('');

  const [showActiveUsersOnly, setShowActiveUsersOnly] = useState(true);

  const usersRoute = `/sysconfig/api/v1/organizations/${organization.id}/users`;

  const {
    isLoading,
    isError,
    data: users,
    reloadData: reloadUsers,
  } = useDataFetcher(usersRoute, { params: { include_deactivated: true } });

  const { isOrgUnitsFeatureEnabled, units, isUnitsLoading, userToUnitDict, leaderToUnitsDict, reloadUnits } =
    useOrganizationUnits({ organization });

  const filterUsers = React.useCallback(
    (user) => {
      const doesRoleMatch = selectedRoles.length
        ? selectedRoles.includes(ROLES_TYPES_DICT[user.role.role_type]['desc'])
        : true;
      const doesUserNameMatch =
        `${user.first_name} ${user.last_name}`.toLowerCase().indexOf(searchUserText.toLowerCase().trim()) > -1;
      const activeMatch = showActiveUsersOnly ? !user.is_removed : true;
      return doesRoleMatch && doesUserNameMatch && activeMatch;
    },
    [selectedRoles, searchUserText, showActiveUsersOnly]
  );

  const usersToShow = useMemo(() => {
    if (isLoading || isError || isUnitsLoading) {
      return [];
    }

    return users.filter(filterUsers).map((user) => buildUser({ user, units, userToUnitDict, leaderToUnitsDict }));
  }, [units, filterUsers, users, isLoading, isError, isUnitsLoading, userToUnitDict, leaderToUnitsDict]);

  React.useEffect(() => {
    setRolesOptions(roles.map((role) => ROLES_TYPES_DICT[role.role_type]['desc']) || []);
  }, [roles, setRolesOptions, organization, isOrgUnitsFeatureEnabled]);

  const handleITOperation = async (opType, userId, values) => {
    const command = opTypeConfiguration[opType];
    const data = prepareValuesForSave({ values });
    try {
      await axios({ method: command.method, url: command.url(userId), data });
      await reloadUsers();
      await reloadUnits();
    } catch (error) {
      reportAxiosError(error);
    }
  };

  const opTypeConfiguration = {
    updateUser: { method: 'put', url: (userId) => `${usersRoute}/${userId}` },
    deleteUser: { method: 'delete', url: (userId) => `${usersRoute}/${userId}` },
    resetPassword: { method: 'post', url: (userId) => `${usersRoute}/${userId}/password` },
    unlockUser: { method: 'post', url: (userId) => `${usersRoute}/${userId}/unlock_user` },
    lockUser: { method: 'post', url: (userId) => `${usersRoute}/${userId}/lock_user` },
    addUser: { method: 'post', url: () => usersRoute },
  };

  return (
    <CardDialog noCardTitle>
      <Grid container style={{ marginTop: '24px' }}>
        <Grid item md={12}>
          <div className={classes.cardDivRow}>
            <OrganizationSettings
              organization={organization}
              onUpdateSettings={reloadOrganization}
              withoutTitle
              withoutSupervisor
            />
          </div>
        </Grid>
        <Grid item md={12}>
          <div className={classes.cardDivRow} style={{ marginTop: '0px', marginBottom: '0px' }}>
            <CardDialog title="">
              <Typography
                display="block"
                variant="subtitle1"
                style={{ fontWeight: 'bold', fontSize: '18px', marginBottom: '10px' }}
              >
                USERS
              </Typography>
              <Grid container>
                <Grid item xs={3}>
                  <TextField
                    className={classes.textField}
                    InputProps={{
                      placeholder: 'Search User',
                    }}
                    style={{ width: '220px' }}
                    value={searchUserText}
                    onChange={(e) => setSearchUserText(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} style={{ marginTop: '15px', marginBottom: '20px' }}>
                  <Typography
                    display="block"
                    variant="subtitle2"
                    style={{ paddingTop: '8px', fontWeight: 'bold', fontSize: '16px' }}
                  >
                    Filters
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <MultiSelectFilter
                    label="By Role Type"
                    value={selectedRoles}
                    onChange={setSelectedRoles}
                    options={rolesOptions}
                    withOptionChips
                  />
                </Grid>
                <Grid item xs={3}>
                  <Switch
                    checked={showActiveUsersOnly}
                    onChange={() => setShowActiveUsersOnly(!showActiveUsersOnly)}
                    className={classes.formsSwitch}
                    size="small"
                  />
                  <span>Show only active users</span>
                </Grid>
              </Grid>
            </CardDialog>
          </div>
        </Grid>
        <Grid item md={12}>
          <div className={classes.cardDivRow}>
            <LoadingSwitch isLoading={isLoading || isUnitsLoading} isError={isError}>
              <OrganizationUsers
                classes={classes}
                organization={organization}
                user={user}
                users={usersToShow}
                roles={roles}
                units={units}
                onUpdateUser={(userId, values) => handleITOperation('updateUser', userId, values)}
                onDeleteUser={(userId, values) => handleITOperation('deleteUser', userId, values)}
                onResetPassword={(userId, values) => handleITOperation('resetPassword', userId, values)}
                onUnlockUser={(userId, values) => handleITOperation('unlockUser', userId, values)}
                onLockUser={(userId) => handleITOperation('lockUser', userId)}
                onAddUser={(values) => handleITOperation('addUser', undefined, values)}
                withoutTitle
              />
            </LoadingSwitch>
          </div>
        </Grid>
      </Grid>
    </CardDialog>
  );
};

export default UsersTab;
