import React, { useState } from 'react';
import { Typography } from '@material-ui/core';
import axios from 'axios';

import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';

import { CONFIGURATION_FEATURES_NAMES } from '../../../Types';
import { isFeatureEnabled, reportAxiosError } from '../../../Utils';
import LoadingIndicator from '../../LoadingIndicator';
import { useSysconfig } from '../SystemConfigurationScreen';

import { MoiConfigurationToolBackendWrapper } from './MoiConfigurationToolBackendWrapper';

import { useStyles } from '../../../assets/styles';

const MoiConfig = () => {
  const classes = useStyles();
  const { organization, reloadOrganization } = useSysconfig();
  const [isLoading, setIsLoading] = useState(false);

  const urlMoiConfig = `/sysconfig/api/v1/organizations/${organization.id}/enable_new_moi`;

  if (isLoading) {
    return <LoadingIndicator />;
  }

  const enableMoiConfiguration = async () => {
    try {
      setIsLoading(true);
      await axios.post(urlMoiConfig);
      await reloadOrganization();
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      reportAxiosError(error);
    }
  };

  return isFeatureEnabled(organization, CONFIGURATION_FEATURES_NAMES.NEW_MOI_EXPERIENCE) ? (
    <MoiConfigurationToolBackendWrapper />
  ) : (
    <Grid container spacing={0} direction="column" style={{ paddingTop: '6px' }}>
      <Grid
        item
        x={6}
        style={{ display: 'inline-flex', alignItems: 'center', paddingTop: '37px', paddingLeft: '32px' }}
      >
        <Grid>
          <Typography
            display="block"
            variant="subtitle2"
            style={{ fontSize: '20px', fontWeight: 800, marginBottom: '20px' }}
          >
            MOI Configuration
          </Typography>
          <Button color="primary" variant="contained" onClick={enableMoiConfiguration}>
            {isLoading && <LoadingIndicator size={24} className={classes.buttonProgress} />}
            Enable MOI
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MoiConfig;
