import React from 'react';

import Editor from '~/components/core/Editor';
import Caption from '~/components/core/TextComponents/Caption';

interface GenericTemplateContentContainerProps {
  titleTemplate?: string;
  bodyTemplate: string;
}

const GenericTemplateContentContainer: React.FC<GenericTemplateContentContainerProps> = ({
  titleTemplate,
  bodyTemplate,
}) => {
  return (
    <div>
      {titleTemplate ? (
        <div className="m-10">
          <Caption variant={Caption.VARIANTS.LABEL}>Subject:</Caption>
          <Editor disabled content={titleTemplate} containerClassName="border-0" />
        </div>
      ) : null}
      <div className="m-10">
        <Caption variant={Caption.VARIANTS.LABEL}>Body:</Caption>
        <Editor disabled containerClassName="border-0" content={bodyTemplate} />
      </div>
    </div>
  );
};

export default GenericTemplateContentContainer;
