import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

import TextWithChips from '~/components/core/TextWithChips';

import styles from './VoiceMessageDetailsPreview.module.scss';

const VoiceMessageDetailsPreview = ({
  configurationType,
  messageTextTitle,
  messageText,
  uploadedTextMessageTitle,
  audioUrl,
}) => {
  return (
    <div className={styles.detailsContainer}>
      {configurationType === 'text_message' ? (
        <>
          <Typography variant="subtitle2">{messageTextTitle}</Typography>
          <TextWithChips
            text={messageText}
            tokensValues={{
              adjuster_name: 'Adjuster Name',
              date_of_return: 'Date of Return',
            }}
          />
        </>
      ) : null}
      {configurationType === 'uploaded_text_message' ? (
        <>
          <Typography variant="subtitle2">{uploadedTextMessageTitle}</Typography>
          <audio className={styles.audioPlayer} controls src={audioUrl} />
        </>
      ) : null}
    </div>
  );
};

VoiceMessageDetailsPreview.propTypes = {
  messageTextTitle: PropTypes.string.isRequired,
  messageText: PropTypes.string,
  configurationType: PropTypes.string.isRequired,
  uploadedTextMessageTitle: PropTypes.string.isRequired,
  audioUrl: PropTypes.string,
};
export default VoiceMessageDetailsPreview;
