import React, { useState } from 'react';
import { Container, Typography } from '@material-ui/core';
import axios from 'axios';

import { TWILIO_CONFIGURATION } from '../../../../Types';
import { reportAxiosError } from '../../../../Utils';
import { useCms } from '../../../hooks/useCms';
import LoadingIndicator from '../../../LoadingIndicator';
import useOrganization from '../../../OrganizationContext';
import useDataFetcher from '../../../useDataFetcher';
import { useSysconfig } from '../../SystemConfigurationScreen';

import EditQueueRoutingConfiguration from './QueueRoutingConfiguration/EditQueueRoutingConfiguration';
import AdjusterViewTable from './Tables/AdjusterViewTable';
import QueueViewTable from './Tables/QueueViewTable';
import EditWrapUpTimeConfiguration from './WrapUpTimeConfiguration/EditWrapUpTimeConfiguration';
import QueueManagementConfigurableOption from './QueueManagementConfigurableOption';
import CONSTS from './QueueManagementConstants';

import styles from './QueueManagementTab.module.scss';

const QueueManagementTab = () => {
  const { organization, organizationOperationalDetails, reloadOperationalDetails } = useSysconfig();

  const { twilioConfiguration } = organizationOperationalDetails;

  const { organizationId } = useOrganization();
  const { userReloadTwilioDetails } = useCms();

  const { data, isLoading, isError, reloadData } = useDataFetcher(
    `/api/v1/twilio_queues/organizations/${organizationId}/queues`
  );
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleUpdateConfiguration = async (values, throwOnError = true) => {
    try {
      setIsSubmitting(true);
      await axios.patch(`/api/v1/twilio_configuration/organizations/${organization.id}`, values);
      await reloadOperationalDetails();
    } catch (error) {
      reportAxiosError(error);
      if (throwOnError) {
        throw error;
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleAssignAdjustersToQueues = async (values) => {
    try {
      setIsSubmitting(true);
      await axios.patch(
        `/api/v1/twilio_queues/organizations/${organization.id}/queues/${values.queue_sid}/assigned_users`,
        values
      );
      await reloadData();
      await userReloadTwilioDetails();
    } catch (error) {
      reportAxiosError(error);

      throw error;
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleAssignQueuesToAdjuster = async (values) => {
    try {
      setIsSubmitting(true);
      await axios.put(
        `/api/v1/twilio_queues/organizations/${organization.id}/queues/workers/${values.worker_sid}`,
        values
      );
      await reloadData();
      await userReloadTwilioDetails();
    } catch (error) {
      reportAxiosError(error);

      throw error;
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className={styles.container}>
      {data ? (
        <>
          <Container maxWidth="lg" className={styles.configurationsContainer}>
            <QueueManagementConfigurableOption
              isInitiated={twilioConfiguration[CONSTS.DDI_ROUTING_INITIATED]}
              isEnabled={twilioConfiguration[CONSTS.DDI_ROUTING_ENABLED]}
              onChangeToggle={async (enabled) => {
                await handleUpdateConfiguration({
                  [CONSTS.DDI_ROUTING_ENABLED]: enabled,
                });
                await reloadData();
              }}
              configurationView={
                <div>
                  <Typography variant="subtitle2">Enable queue routing </Typography>
                  <Typography variant="caption">
                    Set the no answer timeout and define the default route queue
                  </Typography>
                  {twilioConfiguration[CONSTS.DDI_ROUTING_ENABLED] ? (
                    <div>
                      <div className={styles.configurationPreviewContainer}>
                        <Typography variant="subtitle2">Organization Level Default Queue: </Typography>
                        <span>
                          {
                            data.queues.find((q) => q.queue_sid === twilioConfiguration[CONSTS.DEFAULT_QUEUE_SID])
                              ?.queue_name
                          }
                        </span>
                      </div>
                      <div className={styles.configurationPreviewContainer}>
                        <Typography variant="subtitle2">Queue routing time: </Typography>
                        <span>
                          {
                            TWILIO_CONFIGURATION.QUEUE_ROUTING.POSSIBLE_TIME_VALUES[
                              twilioConfiguration[CONSTS.DIRECT_CALL_TIMEOUT_SECONDS]
                            ].desc
                          }
                        </span>
                      </div>
                    </div>
                  ) : null}
                </div>
              }
              configuration={{
                [CONSTS.DIRECT_CALL_TIMEOUT_SECONDS]: twilioConfiguration[CONSTS.DIRECT_CALL_TIMEOUT_SECONDS],
                [CONSTS.DDI_ROUTING_INITIATED]: twilioConfiguration[CONSTS.DDI_ROUTING_INITIATED],
                [CONSTS.DEFAULT_QUEUE_SID]: twilioConfiguration[CONSTS.DEFAULT_QUEUE_SID],
                possibleQueues: data.queues,
              }}
              EditConfigurationDialogComponent={EditQueueRoutingConfiguration}
              showOnly={isSubmitting}
              onSubmitDialog={async (values) => {
                await handleUpdateConfiguration(values);
                await reloadData();
              }}
            />
            <QueueManagementConfigurableOption
              isInitiated={twilioConfiguration[CONSTS.WRAP_UP_TIME_INITIATED]}
              isEnabled={twilioConfiguration[CONSTS.WRAP_UP_TIME_ENABLED]}
              onChangeToggle={(enabled) => {
                handleUpdateConfiguration({
                  [CONSTS.WRAP_UP_TIME_ENABLED]: enabled,
                });
              }}
              configurationView={
                <div>
                  <Typography variant="subtitle2">Configure wrap-up time</Typography>
                  <Typography variant="caption">
                    Define wrap-up time before another call can be routed to a user after finishing a call.
                  </Typography>
                  {twilioConfiguration[CONSTS.WRAP_UP_TIME_ENABLED] ? (
                    <div className={styles.configurationPreviewContainer}>
                      <Typography variant="subtitle2">Wrap-up time: </Typography>
                      <span>
                        {
                          TWILIO_CONFIGURATION.WRAP_UP_TIME.POSSIBLE_TIME_VALUES[
                            twilioConfiguration[CONSTS.WRAP_UP_TIME_SECONDS]
                          ].desc
                        }
                      </span>
                    </div>
                  ) : null}
                </div>
              }
              configuration={{
                [CONSTS.WRAP_UP_TIME_SECONDS]: twilioConfiguration[CONSTS.WRAP_UP_TIME_SECONDS],
                [CONSTS.WRAP_UP_TIME_INITIATED]: twilioConfiguration[CONSTS.WRAP_UP_TIME_INITIATED],
              }}
              EditConfigurationDialogComponent={EditWrapUpTimeConfiguration}
              showOnly={isSubmitting}
              onSubmitDialog={async (values) => {
                await handleUpdateConfiguration(values);
                await reloadData();
              }}
            />
          </Container>

          <div>
            <Container maxWidth="lg" className={styles.queueViewContainer}>
              <QueueViewTable data={data} onSubmit={handleAssignAdjustersToQueues} />
            </Container>
            <Container maxWidth="lg" className={styles.adjusterViewContainer}>
              <AdjusterViewTable
                data={data}
                onSubmit={handleAssignQueuesToAdjuster}
                routingEnabled={twilioConfiguration[CONSTS.DDI_ROUTING_ENABLED]}
              />
            </Container>
          </div>
        </>
      ) : isLoading || isError ? (
        <LoadingIndicator isError={isError} />
      ) : null}
    </div>
  );
};

export default QueueManagementTab;
