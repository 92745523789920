import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { filter, isEmpty } from 'lodash';

import LocationTable from '../LocationTable';
import propTypes from '../propTypes';

import styles from '../supportedLocations.module.scss';

const AllLineOfBusiness = ({
  countriesConfiguration = {
    countries_configuration_for_locations: [],
  },
  onUpdate,
}) => {
  const supportedLocations = filter(countriesConfiguration.countries_configuration_for_locations, ({ lob }) =>
    isEmpty(lob)
  );

  return (
    <div className={styles.section}>
      <Typography variant="subtitle1" className={styles.countriesSectionTitle}>
        All Line Of Business
      </Typography>
      <LocationTable supportedLocations={supportedLocations} onUpdate={onUpdate} />
    </div>
  );
};

AllLineOfBusiness.propTypes = {
  countriesConfiguration: PropTypes.shape(propTypes.countriesConfiguration),
  onUpdate: PropTypes.func,
};

export default AllLineOfBusiness;
