import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AddIcon from '@material-ui/icons/Add';

import Button from '~/components/core/Atomic/Buttons/Button';
import Chip from '~/components/core/Atomic/Chip/Chip';
import Grid from '~/components/core/Atomic/Grid/Grid';
import EmptyState from '~/components/core/EmptyState';
import RoundEmptyStateWrapper from '~/components/core/EmptyState/RoundEmptyStateWrapper';
import { useLobConfiguration } from '~/components/hooks/useLobConfiguration';

import { CONFIGURATION_FEATURES_NAMES } from '../../../../Types';
import { isUserSuperUser } from '../../../../UserUtils';
import { isFeatureEnabled } from '../../../../Utils';
import { getLobDescription } from '../../../../Utils/lobUtils';
import CardDialog from '../../../CardDialog';
import { ConfirmModal } from '../../../ConfirmModal';
import { SortableTable } from '../../../core';
import { useCms } from '../../../hooks/useCms';
import { OrganizationIcon, PencilIcon, TrashIcon_Deprecated } from '../../../icons';
import OperationsBreadcrumbs from '../../OperationsBreadcrumbs';
import EnableSubOrganizationsButton from '../EnableSubOrganizationsButton';
import SubOrganizationDialog from '../SubOrganizationDialog';

import { useStyles } from '../../../../assets/styles';
import styles from '../subOrganizations.module.scss';

const SubOrganizationsInner = ({
  organization,
  subOrganizations,
  lobsWithHiddenIndication,
  handleCreateSubOrganization,
  handleUpdateSubOrganization,
  handleEnableSubOrgs,
  enableRemovingSubOrgs = false,
  handleRemoveSubOrganization,
  enableRemovingExistingLobs,
  claimNumberingScheme,
  emailDomains,
  overrideDisabled = false,
  subOrgsClaimIdentifiers,
}) => {
  const { user } = useCms();
  const [addSubOrgDialogOpen, setAddSubOrgDialogOpen] = useState(false);
  const [subOrganizationToEdit, setSubOrganizationToEdit] = useState(null);
  const [warningSubOrgsDialog, setWarningSubOrgsDialog] = useState(false);
  const classes = useStyles();
  const { lobConfigurationsDict } = useLobConfiguration();

  const isSubOrgPolicyRegexEnabled = !isFeatureEnabled(
    organization,
    CONFIGURATION_FEATURES_NAMES.DISABLE_REGEX_FIELD_FOR_SUB_ORGS
  );

  const AddSubOrgButtonContent = () => (
    <div className={styles.addSubOrgButton}>
      <AddIcon className={classes.leftButtonIcon} />
      Add Sub Organization
    </div>
  );

  const AddSubOrgButton = () => {
    return (
      <>
        {organization.sub_organizations_enabled && (
          <Button color="primary" onClick={() => handleOpenDialog(null)} style={{ marginTop: 10, marginRight: 10 }}>
            <AddSubOrgButtonContent />
          </Button>
        )}
      </>
    );
  };

  const handleCloseDialog = () => {
    setAddSubOrgDialogOpen(false);
    setSubOrganizationToEdit(null);
  };

  const handleOpenDialog = (sub_organization) => {
    setSubOrganizationToEdit(sub_organization);
    setAddSubOrgDialogOpen(true);
  };

  const handleCreateSubOrganizationSubmitted = async (values) => {
    await handleCreateSubOrganization(values);
    handleCloseDialog();
  };

  const handleUpdateSubOrganizationSubmitted = async (values, subOrganizationId) => {
    await handleUpdateSubOrganization(values, subOrganizationId);
    handleCloseDialog();
  };

  const handleCloseWarningSubORgsDialog = () => {
    setWarningSubOrgsDialog(false);
    handleCloseDialog();
  };

  const Actions = () => (
    <>
      {isUserSuperUser(user) && !organization.sub_organizations_enabled && (
        <Grid container alignItems="center">
          <EnableSubOrganizationsButton
            onClose={() => setWarningSubOrgsDialog(true)}
            organization={organization}
            handleEnableSubOrgs={handleEnableSubOrgs}
          />
          <span className={styles.warningLabel}>
            If the organization already handles claims - do not click the button without consulting R&D first
          </span>
        </Grid>
      )}
      {organization.sub_organizations_enabled && !!subOrganizations.length && <AddSubOrgButton />}
    </>
  );

  const columnData = [
    {
      id: 'name',
      label: 'Name',
    },
    {
      id: 'external_id',
      label: 'Sub Organization ID',
    },
    {
      id: 'lobs',
      label: 'Line of business',
      disableSort: true,
      specialCell: ({ supported_lobs = [] }) => (
        <span>
          {supported_lobs.map(({ lob }) => (
            <Chip
              key={lob}
              className={classes.chip}
              size="small"
              label={getLobDescription(lob, lobConfigurationsDict)}
            />
          ))}
        </span>
      ),
    },
    {
      id: 'description',
      label: 'Description',
    },
    {
      id: 'policy_classification_regex',
      label: 'Policy Classification Regex',
      isHidden: !isSubOrgPolicyRegexEnabled,
    },
    {
      id: 'claim_identifier',
      label: 'Claim Identifier',
    },
    {
      id: 'actions',
      label: 'Actions',
      disableSort: true,
      specialCell: (sub_organization) => (
        <>
          <PencilIcon
            className={`${classes.marginedIcon} ${classes.hoverableNonFilledIcon}`}
            onClick={() => handleOpenDialog(sub_organization)}
          />
          {enableRemovingSubOrgs && (
            <TrashIcon_Deprecated
              className={`${classes.marginedIcon} ${classes.hoverableIcon}`}
              onClick={() => handleRemoveSubOrganization(sub_organization.id)}
            />
          )}
        </>
      ),
    },
  ];

  return (
    <>
      <CardDialog noCardTitle>
        <OperationsBreadcrumbs currentTab="Sub Organizations" />
        <CardDialog
          title="SUB ORGANIZATIONS"
          action={<Actions />}
          headerStyle={{ padding: '12px 12px 6px 0px' }}
          contentStyle={{ padding: '6px 0px 16px' }}
        >
          <SortableTable
            columns={columnData}
            rows={subOrganizations}
            emptyStateComponent={
              <EmptyState
                illustration={
                  <RoundEmptyStateWrapper>
                    <OrganizationIcon />
                  </RoundEmptyStateWrapper>
                }
                title="No Sub Organizations"
                subtitle="No added Sub-Organizations yet"
                buttonComponent={<AddSubOrgButton />}
              />
            }
          />
        </CardDialog>
        {addSubOrgDialogOpen && (
          <SubOrganizationDialog
            handleCreate={handleCreateSubOrganizationSubmitted}
            handleUpdate={handleUpdateSubOrganizationSubmitted}
            handleClose={handleCloseDialog}
            allSubOrganizations={subOrganizations}
            subOrganization={subOrganizationToEdit}
            hiddenLobsToHideWhenNotSelected={lobsWithHiddenIndication
              .filter((lob) => lob.is_hidden)
              .map(({ key }) => key)}
            organization={organization}
            enableRemovingExistingLobs={enableRemovingExistingLobs}
            claimNumberingScheme={claimNumberingScheme}
            emailDomains={emailDomains}
            overrideDisabled={overrideDisabled}
            subOrgsClaimIdentifiers={subOrgsClaimIdentifiers}
          />
        )}
        {warningSubOrgsDialog && (
          <ConfirmModal
            isOpen
            title="Make sure to add sub-orgs"
            contentText="Add at least one sub-org to prevent inconsistencies in the system"
            primaryButtonName="Ok"
            onPrimaryBtnClick={handleCloseWarningSubORgsDialog}
            onClose={handleCloseWarningSubORgsDialog}
            removeSecondaryButton
          />
        )}
      </CardDialog>
    </>
  );
};

SubOrganizationsInner.propTypes = {
  organization: PropTypes.object.isRequired,
  subOrganizations: PropTypes.array,
  lobsWithHiddenIndication: PropTypes.array,
  handleCreateSubOrganization: PropTypes.func.isRequired,
  handleUpdateSubOrganization: PropTypes.func.isRequired,
  handleEnableSubOrgs: PropTypes.func.isRequired,
  enableRemovingSubOrgs: PropTypes.bool,
  handleRemoveSubOrganization: PropTypes.func,
  enableRemovingExistingLobs: PropTypes.bool,
  claimNumberingScheme: PropTypes.object,
  overrideDisabled: PropTypes.bool,
  subOrgsClaimIdentifiers: PropTypes.array,
  emailDomains: PropTypes.array,
};

export default SubOrganizationsInner;
