import React, { useEffect, useState } from 'react';
import axios from 'axios';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { v4 as uuidv4 } from 'uuid';

import CardDialog from '~/components/CardDialog';
import LoadingSwitch from '~/components/core/Loading/LoadingSwitch';
import GenericTemplateBuilderContainer from '~/components/GenericTemplates/GenericTemplatesConfiguration/GenericTemplateBuilder/GenericTemplateBuilderContainer';
import GenericTemplateCardButtonsContainer from '~/components/GenericTemplates/GenericTemplatesConfiguration/GenericTemplateCard/GenericTemplateCardButtonsContainer';
import GenericTemplateContentContainer from '~/components/GenericTemplates/GenericTemplatesConfiguration/GenericTemplateCard/GenericTemplateContentContainer';
import GenericTemplatePreviewContainer from '~/components/GenericTemplates/GenericTemplatesConfiguration/GenericTemplateCard/GenericTemplatePreview/GenericTemplatePreviewContainer';
import type { TemplateFormSubmitValuesProps } from '~/components/GenericTemplates/types';
import { GENERIC_TEMPLATES_ROUTES } from '~/components/GenericTemplates/utils/routes';
import { useSysconfig } from '~/components/SystemConfiguration/SystemConfigurationScreen';
import useDataFetcher from '~/components/useDataFetcher';
import { reportAxiosError } from '~/Utils';

interface GenericTemplateCardProps {
  templateId: number;
  reloadTable: () => void;
}

const GenericTemplateCardContainer: React.FC<GenericTemplateCardProps> = ({ templateId, reloadTable }) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { organization } = useSysconfig();

  const [isEditTemplateDialogOpen, setIsEditTemplateDialogOpen] = useState(false);
  const [isPreviewTemplateDialogOpen, setIsPreviewTemplateDialogOpen] = useState(false);
  const [isCopyTemplateDialogOpen, setIsCopyTemplateDialogOpen] = useState(false);

  const [containerContentKey, setContainerContentKey] = useState(uuidv4());
  const [isEdit, setIsEdit] = useState(false);

  const { data, isLoading, isError, reloadData } = useDataFetcher(
    GENERIC_TEMPLATES_ROUTES.GET_TEMPLATE(organization.id, templateId)
  );

  // The GenericTemplateContentContainer wraps Editor components for content updates. Due to Editors' tight coupling
  // with their initial content, direct updates don't trigger rerenders. By updating the component's key via useEffect,
  // we force a rerender to reflect content changes, ensuring the UI stays updated
  useEffect(() => {
    const key = uuidv4();
    setContainerContentKey(key);
  }, [data]);

  const handleEditClick = () => {
    setIsEdit(true);
    setIsEditTemplateDialogOpen(true);
  };

  const handleCloseEditTemplateDialog = () => {
    setIsEdit(false);
    setIsEditTemplateDialogOpen(false);
  };

  const handleUpdateTemplate = async (values: TemplateFormSubmitValuesProps) => {
    try {
      await axios.put(GENERIC_TEMPLATES_ROUTES.UPDATE_TEMPLATE(organization.id, data?.id), values);
      await reloadData();
      await reloadTable();
    } catch (error) {
      await reportAxiosError(error);
      throw error;
    }
  };

  const handlePreviewClick = () => {
    setIsPreviewTemplateDialogOpen(true);
  };

  const handleClosePreview = () => {
    setIsPreviewTemplateDialogOpen(false);
  };

  const handleCopyClick = () => {
    setIsCopyTemplateDialogOpen(true);
  };

  const handleCloseCopyTemplateDialog = () => {
    setIsCopyTemplateDialogOpen(false);
  };

  const handleSubmitTemplate = async (values: TemplateFormSubmitValuesProps) => {
    try {
      await axios.post(GENERIC_TEMPLATES_ROUTES.CREATE_TEMPLATE(organization.id), values);
      await reloadTable();
    } catch (error) {
      await reportAxiosError(error);
      throw error;
    }
  };

  return (
    <LoadingSwitch isLoading={isLoading} isError={isError}>
      <CardDialog
        containerClassName="overflow-y-auto border-1 border-slate-600 border-solid rounded-md mx-10"
        noCardTitle
      >
        <GenericTemplateCardButtonsContainer
          handleEditClick={handleEditClick}
          handlePreviewClick={handlePreviewClick}
          handleCopyClick={handleCopyClick}
        />
        <GenericTemplateContentContainer
          titleTemplate={data?.title_template}
          bodyTemplate={data?.body_template}
          key={containerContentKey}
        />
      </CardDialog>
      {isEditTemplateDialogOpen ? (
        <GenericTemplateBuilderContainer
          templateType={data?.template_type}
          handleClose={handleCloseEditTemplateDialog}
          handleSubmit={handleUpdateTemplate}
          initialValues={data}
          isEdit={isEdit}
        />
      ) : null}
      {isCopyTemplateDialogOpen ? (
        <GenericTemplateBuilderContainer
          templateType={data?.template_type}
          handleClose={handleCloseCopyTemplateDialog}
          handleSubmit={handleSubmitTemplate}
          initialValues={data}
        />
      ) : null}
      {isPreviewTemplateDialogOpen ? (
        <GenericTemplatePreviewContainer onClose={handleClosePreview} template={data} />
      ) : null}
    </LoadingSwitch>
  );
};

export default GenericTemplateCardContainer;
