import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import axios from 'axios';
import { Formik, getIn, useFormikContext } from 'formik';
import * as Yup from 'yup';

import { useStyles } from '~/assets/styles';
import AddressAutocompleteFormik from '~/components/core/AddressAutocomplete/AddressAutocompleteFormik';
import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';
import CancelButton from '~/components/core/Buttons/CancelButton';
import { COUNTRY_TO_STATE_MAP } from '~/Types';
import { getPostalCodeYupTestParams, reportAxiosError, yupPhoneValidation } from '~/Utils';

import CardDialog from '../../../CardDialog';
import { TrashIcon_Deprecated } from '../../../icons';
import InlineIconButton from '../../../InlineIconButton';
import TextFieldFormik from '../../../TextFieldFormik';
import { CoveragesSingleSelectFormik } from '../../../TPA/Coverages/CoveragesSingleSelectFormik';
import { LobSelectFormik } from '../../../TPA/LOB/LobSelectFormik';
import { useSysconfig } from '../../SystemConfigurationScreen';
import { getIsActiveOrPending, getRequiredWhenActiveOrPendingSchema } from '../utils';

const EditNavigatorDialog = ({ handleClose, onSubmit, activeNavigatorConfiguration }) => {
  const classes = useStyles();
  const { organization } = useSysconfig();

  const handleSubmit = async (values) => {
    try {
      await axios.put(
        `/api/v1/navigator/organizations/${organization.id}/configurations/${activeNavigatorConfiguration.id}`,
        values
      );
      await onSubmit();
      await handleClose();
    } catch (error) {
      await reportAxiosError(error);
      throw error;
    }
  };

  const generateCoverages = () => {
    if (activeNavigatorConfiguration.coverage_associations.length === 0) {
      return [{ lob: '', coverage_type: '', insurance_type: '' }];
    }

    return activeNavigatorConfiguration.coverage_associations;
  };

  const initialValues = {
    team_name: activeNavigatorConfiguration.navigator.team_name || '',
    mailing_name: activeNavigatorConfiguration.navigator.mailing_name || '',
    op_company_key: activeNavigatorConfiguration.navigator_contact.op_company_key || '',
    rre_number: activeNavigatorConfiguration.navigator_contact.rre_number || '',
    tax_id: activeNavigatorConfiguration.navigator_contact.tax_id || '',
    op_company_mailing_name: activeNavigatorConfiguration.navigator_contact.op_company_mailing_name || '',
    mir_reporting_group: activeNavigatorConfiguration.navigator_contact.mir_reporting_group || '',
    address1: activeNavigatorConfiguration.navigator_contact.location.address1 || '',
    address2: activeNavigatorConfiguration.navigator_contact.location.address2 || '',
    city: activeNavigatorConfiguration.navigator_contact.location.city || '',
    state: activeNavigatorConfiguration.navigator_contact.location.state || '',
    zipcode: activeNavigatorConfiguration.navigator_contact.location.zipcode || '',
    primary_contact_first_name: activeNavigatorConfiguration.navigator_contact.primary_contact_first_name || '',
    primary_contact_last_name: activeNavigatorConfiguration.navigator_contact.primary_contact_last_name || '',
    primary_contact_phone: activeNavigatorConfiguration.navigator_contact.primary_contact_phone || '',
    primary_contact_email: activeNavigatorConfiguration.navigator_contact.primary_contact_email || '',
    coverages: generateCoverages(),
  };

  const getRequiredWhenPendingActivationOrStartedReportingSchema = (baseSchema) =>
    getRequiredWhenActiveOrPendingSchema(activeNavigatorConfiguration?.navigator_status, baseSchema);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        mailing_name: getRequiredWhenPendingActivationOrStartedReportingSchema(Yup.string().nullable()),
        team_name: getRequiredWhenPendingActivationOrStartedReportingSchema(Yup.string().nullable()),
        op_company_key: getRequiredWhenPendingActivationOrStartedReportingSchema(Yup.string().nullable()),
        rre_number: getRequiredWhenPendingActivationOrStartedReportingSchema(Yup.string().nullable()),
        tax_id: getRequiredWhenPendingActivationOrStartedReportingSchema(
          Yup.number()
            .nullable()
            .test('correct-amount-of-digits', 'Has to contain 9 digits', (taxId) =>
              taxId ? taxId.toString().length === 9 : true
            )
        ),
        op_company_mailing_name: getRequiredWhenPendingActivationOrStartedReportingSchema(Yup.string()),
        mir_reporting_group: getRequiredWhenPendingActivationOrStartedReportingSchema(Yup.number().nullable()),
        address1: getRequiredWhenPendingActivationOrStartedReportingSchema(Yup.string()),
        address2: Yup.string().nullable(),
        city: getRequiredWhenPendingActivationOrStartedReportingSchema(Yup.string().nullable()),
        state: getRequiredWhenPendingActivationOrStartedReportingSchema(Yup.string().nullable()),
        zipcode: Yup.string()
          .nullable()
          .test(...getPostalCodeYupTestParams(getIsActiveOrPending(activeNavigatorConfiguration?.navigator_status))),
        primary_contact_first_name: Yup.string().nullable(),
        primary_contact_last_name: Yup.string().nullable(),
        primary_contact_phone: yupPhoneValidation.nullable(),
        primary_contact_email: Yup.string().nullable().email(),
        coverages: Yup.array().of(
          Yup.object().shape({
            lob: Yup.string().required('Required'),
            coverage_type: Yup.string().required('Required'),
            insurance_type: Yup.string().required('Required'),
          })
        ),
      })}
      enableReinitialize
      onSubmit={async (values, { setSubmitting }) => {
        try {
          await handleSubmit(values);
        } catch (error) {
          setSubmitting(false);
        }
      }}
    >
      {(formikProps) => {
        const { isSubmitting, handleSubmit } = formikProps;

        return (
          <CardDialog
            title={`Navigator Integration Setup - ${activeNavigatorConfiguration.name}`}
            isDialog
            preventClose={isSubmitting}
            maxWidth="sm"
            fullWidth
            onClose={handleClose}
          >
            <Grid container spacing={1}>
              <Coverages activeNavigatorConfiguration={activeNavigatorConfiguration} />
            </Grid>
            <Grid container spacing={1}>
              <Typography
                display="block"
                variant="subtitle1"
                style={{ paddingTop: '8px', fontWeight: 'bold', marginBottom: '24px', marginTop: '54px' }}
              >
                Claims Organization Information
              </Typography>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <TextFieldFormik
                    id="mailing_name"
                    label="Organization name"
                    fullWidth
                    className={classes.textField}
                    disabled={isSubmitting}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextFieldFormik
                    id="team_name"
                    label="Team name"
                    fullWidth
                    className={classes.textField}
                    disabled={isSubmitting}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Typography
                display="block"
                variant="subtitle1"
                style={{ paddingTop: '8px', fontWeight: 'bold', marginBottom: '24px', marginTop: '54px' }}
              >
                Operating Company Information
              </Typography>
              <Grid container>
                <Typography
                  display="block"
                  variant="subtitle2"
                  style={{ paddingTop: '8px', fontWeight: 'bold', marginBottom: '10px' }}
                >
                  General Details
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextFieldFormik
                      id="op_company_key"
                      label="Operating Company Key"
                      fullWidth
                      className={classes.textField}
                      disabled={isSubmitting}
                      helperText={
                        <Typography variant="caption" style={{ fontSize: '9px' }}>
                          The unique Medicare identifier of the underwriter carrier
                        </Typography>
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextFieldFormik
                      id="rre_number"
                      label="RRE Number"
                      disabled={isSubmitting || !!activeNavigatorConfiguration.navigator_contact.rre_number}
                      fullWidth
                      className={classes.textField}
                      helperText={
                        <Typography variant="caption" style={{ fontSize: '9px' }}>
                          CMS assigned RRE number - including the leading zeros.
                        </Typography>
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextFieldFormik
                      id="tax_id"
                      label="Company Tax ID"
                      disabled={isSubmitting}
                      fullWidth
                      className={classes.textField}
                      helperText={
                        <Typography variant="caption" style={{ fontSize: '9px' }}>
                          9-digit number only.
                        </Typography>
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextFieldFormik
                      id="op_company_mailing_name"
                      label="Company Mailing Name"
                      disabled={isSubmitting}
                      fullWidth
                      className={classes.textField}
                      helperText={
                        <Typography variant="caption" style={{ fontSize: '9px' }}>
                          The company name for mailing correspondence.
                        </Typography>
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextFieldFormik
                      id="mir_reporting_group"
                      label="MIR Reporting Group"
                      disabled={isSubmitting}
                      fullWidth
                      className={classes.textField}
                      helperText={
                        <Typography variant="caption" style={{ fontSize: '9px' }}>
                          Must be a value between 01 and 12
                        </Typography>
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Typography
                  display="block"
                  variant="subtitle2"
                  style={{ paddingTop: '8px', fontWeight: 'bold', marginBottom: '10px', marginTop: '28px' }}
                >
                  Address
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <AddressAutocompleteFormik
                      id="address1"
                      label="Company Street 1"
                      disabled={isSubmitting}
                      fullWidth
                      className={classes.textField}
                      streetAddress1FieldId="address1"
                      zipcodeFieldId="zipcode"
                      cityFieldId="city"
                      stateFieldId="state"
                      country="US"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextFieldFormik
                      id="address2"
                      label="Company Street 2"
                      disabled={isSubmitting}
                      fullWidth
                      className={classes.textField}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextFieldFormik
                      id="city"
                      label="Company City"
                      disabled={isSubmitting}
                      fullWidth
                      className={classes.textField}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextFieldFormik
                      id="state"
                      label="Company State"
                      className={classes.textField}
                      disabled={isSubmitting}
                      select
                      fullWidth
                    >
                      {Object.keys(COUNTRY_TO_STATE_MAP['US'])
                        .filter((value) => value !== '')
                        .sort((s1, s2) => COUNTRY_TO_STATE_MAP['US'][s1].localeCompare(COUNTRY_TO_STATE_MAP['US'][s2]))
                        .map((state) => (
                          <MenuItem key={state} value={state}>
                            {COUNTRY_TO_STATE_MAP['US'][state]}
                          </MenuItem>
                        ))}
                    </TextFieldFormik>
                  </Grid>
                  <Grid item xs={4}>
                    <TextFieldFormik
                      id="zipcode"
                      label="Company Zip"
                      disabled={isSubmitting}
                      fullWidth
                      className={classes.textField}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Typography
                  display="block"
                  variant="subtitle2"
                  style={{ paddingTop: '8px', fontWeight: 'bold', marginBottom: '10px', marginTop: '28px' }}
                >
                  Primary Contact
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextFieldFormik
                      id="primary_contact_first_name"
                      label="First Name"
                      disabled={isSubmitting}
                      fullWidth
                      className={classes.textField}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextFieldFormik
                      id="primary_contact_last_name"
                      label="Last Name"
                      disabled={isSubmitting}
                      fullWidth
                      className={classes.textField}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextFieldFormik
                      id="primary_contact_phone"
                      label="Telephone"
                      disabled={isSubmitting}
                      fullWidth
                      className={classes.textField}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextFieldFormik
                      id="primary_contact_email"
                      label="Email Address"
                      disabled={isSubmitting}
                      fullWidth
                      className={classes.textField}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <div className={classes.buttonsContainer}>
                <CancelButton disabled={isSubmitting} onClick={handleClose} />
                <Button variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
                  Save
                </Button>
              </div>
            </Grid>
          </CardDialog>
        );
      }}
    </Formik>
  );
};

EditNavigatorDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  activeNavigatorConfiguration: PropTypes.object.isRequired,
};

const Coverages = ({ activeNavigatorConfiguration }) => {
  const { values, setFieldValue } = useFormikContext();

  const addCoverage = () => {
    setFieldValue('coverages', [...values.coverages, { lob: '', coverage_type: '', insurance_type: '' }]);
  };

  const removeCoverage = (index) => {
    const coverages = values.coverages;
    setFieldValue('coverages', [...coverages.slice(0, index), ...coverages.slice(index + 1, coverages.length)]);
  };

  return (
    <Grid container spacing={1}>
      <Typography
        display="block"
        variant="subtitle1"
        style={{ paddingTop: '8px', fontWeight: 'bold', marginBottom: '24px' }}
      >
        Select Coverages to Report
      </Typography>
      <Grid container spacing={2}>
        {values.coverages.map((coverage, index) => (
          <Grid key={`coverage_${coverage.lob}_${coverage.coverage_type}`} item xs={12}>
            <Coverage
              coverageRowFieldId={`coverages[${index}]`}
              onRemoveCoverage={() => removeCoverage(index)}
              activeNavigatorConfiguration={activeNavigatorConfiguration}
              index={index}
            />
          </Grid>
        ))}
      </Grid>
      <Button
        color="primary"
        onClick={addCoverage}
        disabled={values.coverages[0].insurance_type === ''}
        style={{ marginLeft: -12, marginTop: 34 }}
      >
        <AddIcon />
        Add Coverage
      </Button>
    </Grid>
  );
};

Coverages.propTypes = {
  activeNavigatorConfiguration: PropTypes.object.isRequired,
};

const Coverage = ({ coverageRowFieldId, onRemoveCoverage, activeNavigatorConfiguration, index }) => {
  const classes = useStyles();
  const { values, isSubmitting } = useFormikContext();
  const insuranceTypes = {
    liability: 'Liability',
    no_fault: 'No Fault',
  };
  const coverage = getIn(values, coverageRowFieldId);

  return (
    <Grid container spacing={1}>
      <Grid item xs={3}>
        <LobSelectFormik
          subOrganizationIds={
            activeNavigatorConfiguration.sub_organization_id ? [activeNavigatorConfiguration.sub_organization_id] : []
          }
          fieldId={`${coverageRowFieldId}.lob`}
          disabled={isSubmitting}
        />
      </Grid>
      <Grid item xs={4}>
        <CoveragesSingleSelectFormik
          coverageFieldId={`${coverageRowFieldId}.coverage_type`}
          subOrganizationIds={
            activeNavigatorConfiguration.sub_organization_id ? [activeNavigatorConfiguration.sub_organization_id] : []
          }
          lobs={[coverage.lob]}
          label="Coverage Name"
          disabled={isSubmitting || coverage.lob === ''}
          includeOnlyInvolvedPersonsCoverages={true}
          coveragesToFilterOut={values.coverages
            .sort((a, b) => a.id - b.id)
            .map((c) => {
              const currentCoverageType = coverage.coverage_type;
              if (currentCoverageType !== null && currentCoverageType !== c.coverage_type) {
                return c.coverage_type;
              }
            })}
        />
      </Grid>
      <Grid item xs={4}>
        <TextFieldFormik
          id={`${coverageRowFieldId}.insurance_type`}
          label="Insurance Type"
          className={classes.textField}
          select
          fullWidth
          disabled={isSubmitting}
        >
          {Object.keys(insuranceTypes).map((insurance_type) => (
            <MenuItem key={insurance_type} value={insurance_type}>
              {insuranceTypes[insurance_type]}
            </MenuItem>
          ))}
        </TextFieldFormik>
      </Grid>
      {index !== 0 && values.coverages.length > 1 && (
        <Grid item xs={1}>
          <div style={{ marginTop: 25 }}>
            <InlineIconButton icon={TrashIcon_Deprecated} className={classes.textIcon} onClick={onRemoveCoverage} />
          </div>
        </Grid>
      )}
    </Grid>
  );
};

Coverage.propTypes = {
  coverageRowFieldId: PropTypes.string.isRequired,
  onRemoveCoverage: PropTypes.func.isRequired,
  activeNavigatorConfiguration: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
};

export default EditNavigatorDialog;
