import React from 'react';
import { Typography } from '@material-ui/core';

import cn from '~/Utils/cn';

import { ErrorIcon } from '../../../../../../icons';
import TextFieldFormik from '../../../../../../TextFieldFormik';
import { TOTAL_ALLOWED_QUESTIONS } from '../../constants';

import { useStyles } from '../../../../../../../assets/styles';
import styles from './welcome.module.scss';

const FORM_NAME_MAX_LENGTH = 64;

const Welcome = () => {
  const classes = useStyles();

  return (
    <div className={styles.container}>
      <section>
        <Typography display="block" variant="subtitle1" className={styles.welcomeTitle}>
          Welcome to the QA Form Wizard!
        </Typography>
        <Typography variant="body1" component="span">
          This Wizard will allow you to set up a QA questionnaire form in an easy and convenient way!
          <br />
          The form isn’t associated with a particular LOB and all questions are at the exposure level.
          <br />
          The form includes {TOTAL_ALLOWED_QUESTIONS} questions which you can choose or define yourself through the
          following steps:
          <ul className={styles.sectionDetails}>
            {Object.values(QUESTIONS).map(({ title, desc }, index) => (
              <li key={index}>
                <Typography display="block" variant="subtitle1" className={cn(classes.h4, styles.sectionType)}>
                  {title} - &nbsp;
                  <Typography variant="body1">{desc}</Typography>
                </Typography>
              </li>
            ))}
          </ul>
        </Typography>
      </section>
      <section>
        <Typography display="block" variant="subtitle1" className={cn(classes.h4, styles.noteTitle)}>
          <ErrorIcon className={styles.noteIcon} iconColor={styles.iconColor} />
          Note
        </Typography>
        <Typography variant="body1">
          <span className={styles.welcomeSubtitle}>
            The form cannot be edited after it has been set up in this wizard.
            <br />
            You can create a new one and select which form to present in the QA’s configuration management tab.
          </span>
        </Typography>
      </section>
      <section className={styles.welcomeFormName}>
        <Typography display="block" variant="subtitle1" className={cn(classes.h4, styles.startTitle)}>
          Let’s Start!
        </Typography>
        <Typography variant="body1">
          <span className={styles.welcomeSubtitle}>
            A good practice is giving each form its unique name so forms can be easily identified.
            <br />
            Each form’s creation date and creator name are displayed next to the form name.
          </span>
        </Typography>
        <Typography variant="body1" className={styles.welcomeInputTitle}>
          Fill name for the QA form
        </Typography>
        <TextFieldFormik
          id="form_name"
          label="QA Form Name"
          className={classes.textField}
          inputProps={{ maxLength: FORM_NAME_MAX_LENGTH }}
        />
      </section>
    </div>
  );
};

const QUESTIONS = {
  automatic: {
    title: 'Automatic questions',
    desc: 'Select questions with answers that are automatically populated based on data in the (5Sigma) system.',
  },
  predefined: {
    title: 'Predefined questions',
    desc: 'Select Yes/No questions based on 5Sigma’s repertoire of recommended questions.',
  },
  free_text: {
    title: 'Custom questions',
    desc: 'Create your own Yes/No questions.',
  },
};

export default Welcome;
