import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem } from '@material-ui/core';
import { Formik, useFormikContext } from 'formik';
import { orderBy } from 'lodash';
import * as Yup from 'yup';

import CancelButton from '~/components/core/Buttons/CancelButton';
import { CONFIGURATION_FEATURES_NAMES } from '~/Types';
import { isFeatureEnabled } from '~/Utils';
import cn from '~/Utils/cn';

import CardDialog from '../../../CardDialog';
import { FsButton } from '../../../core';
import TextFieldFormik from '../../../TextFieldFormik';
import { useSysconfig } from '../../SystemConfigurationScreen';

import styles from './index.module.scss';

const TABLEAU_SITE_ROLES = {
  viewer: 'Viewer',
  explorer: 'Explorer',
};

const TableauAccountsDialogInner = ({ tableauAccounts = [], onClose }) => {
  const { isSubmitting, handleSubmit } = useFormikContext();
  const { users, organization } = useSysconfig();
  const isSelfServiceFeatureEnabled = isFeatureEnabled(
    organization,
    CONFIGURATION_FEATURES_NAMES.FF_TABLEAU_SELF_SERVICE
  );
  const enabledUserIds = tableauAccounts?.map((row) => row.user_id);
  const filteredUsers = users?.filter((user) => !enabledUserIds?.includes(user.id));
  const sortedUsers = orderBy(filteredUsers, ['username']);

  const Actions = (
    <div className={styles.actionsContainer}>
      <CancelButton disabled={isSubmitting} onClick={onClose} />
      <FsButton variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
        Save
      </FsButton>
    </div>
  );

  return (
    <CardDialog
      title="Add Account Access"
      isDialog
      preventClose={isSubmitting}
      maxWidth="sm"
      fullWidth
      onClose={onClose}
      footerActions={Actions}
    >
      <div className="flex w-full flex-col gap-20">
        <TextFieldFormik id="user_id" label="User" fullWidth select>
          {sortedUsers.map((user) => (
            <MenuItem key={user.id} value={user.id}>
              {user.username}
            </MenuItem>
          ))}
        </TextFieldFormik>

        <TextFieldFormik
          id="site_role"
          label="Site Role"
          fullWidth
          select
          className={cn({ ['hidden']: !isSelfServiceFeatureEnabled })}
        >
          {Object.entries(TABLEAU_SITE_ROLES).map(([roleKey, roleName]) => (
            <MenuItem key={roleKey} value={roleKey}>
              {roleName}
            </MenuItem>
          ))}
        </TextFieldFormik>
      </div>
    </CardDialog>
  );
};

TableauAccountsDialogInner.propTypes = {
  tableauAccounts: PropTypes.string,
  isUpdate: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

const TableauAccountsDialog = ({ tableauAccounts = [], handleSubmit, onClose }) => {
  return (
    <Formik
      initialValues={{ user_id: '', site_role: 'viewer' }}
      validationSchema={Yup.object().shape({
        user_id: Yup.string().required('Required'),
        site_role: Yup.string().required('Required'),
      })}
      enableReinitialize
      onSubmit={async (values, { setSubmitting }) => {
        try {
          await handleSubmit(values);
        } catch (error) {
          setSubmitting(false);
        }
      }}
    >
      <TableauAccountsDialogInner onClose={onClose} tableauAccounts={tableauAccounts} />
    </Formik>
  );
};

TableauAccountsDialog.propTypes = {
  tableauAccounts: PropTypes.array,
  handleSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default TableauAccountsDialog;
