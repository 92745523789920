import React from 'react';
import PropTypes from 'prop-types';
import AddIcon from '@material-ui/icons/Add';
import { FieldArray, getIn, useFormikContext } from 'formik';
import { v4 as uuidv4 } from 'uuid';

import Button from '~/components/core/Atomic/Buttons/Button';
import Text from '~/components/core/TextComponents/Text';
import { WarningIcon } from '~/components/icons';
import colors from '~/theme/tailwind/colors';

import { ErrorHelperTextFormik } from '../../../../core/Formik/ErrorHelperTextFormik';
import { BASE_CONDITION_FIELD, CONDITION_FIELD_NAME, getConditionsWithGroupForSelectedCriteria } from '../utils';

import { ConditionInnerFormik } from './ConditionInnerFormik';

export const ConditionsFormik = ({ conditions, disabled = false }) => {
  const { values, setFieldValue } = useFormikContext();

  return (
    <>
      <FieldArray
        name={CONDITION_FIELD_NAME}
        render={({ remove, push }) => (
          <>
            {getIn(values, CONDITION_FIELD_NAME).map((item, idx, self) => {
              const selectedCriteria = getIn(values, `${CONDITION_FIELD_NAME}.${idx}.criteria_key`);
              const selectedConditionKey = getIn(values, `${CONDITION_FIELD_NAME}.${idx}.condition_key`);

              const relevantCriteriaKeys = Object.keys(conditions).filter((conditionKey) => {
                let doesMatchContext = true;
                let doesMatchScope = true;
                let doesMatchLob = true;

                if (conditions[conditionKey].context && values.event_key) {
                  const doesContextMatchScope = conditions[conditionKey].context === values.scope;
                  doesMatchContext =
                    doesContextMatchScope || conditions[conditionKey].context === values.event_key.context;
                }

                if (conditions[conditionKey].scope && values.scope) {
                  doesMatchScope = conditions[conditionKey].scope === values.scope;
                }

                if (conditions[conditionKey].lob.length && values.lob) {
                  doesMatchLob = !!conditions[conditionKey].lob.find((supported_lob) => supported_lob === values.lob);
                }

                return doesMatchContext && doesMatchScope && doesMatchLob;
              });

              relevantCriteriaKeys.sort();

              const conditionsWithGroupForSelectedCriteria = getConditionsWithGroupForSelectedCriteria(
                conditions[selectedCriteria]
              );

              return (
                <ConditionInnerFormik
                  key={item.id}
                  item={item}
                  idx={idx}
                  self={self}
                  remove={remove}
                  push={push}
                  selectedConditionKey={selectedConditionKey}
                  conditionsWithGroupForSelectedCriteria={conditionsWithGroupForSelectedCriteria}
                  relevantCriteriaKeys={relevantCriteriaKeys}
                  conditions={conditions}
                  selectedCriteria={selectedCriteria}
                />
              );
            })}
          </>
        )}
      />
      {!values.conditions.length ? (
        <div className="py-15">
          <div className="-mt-20 mb-10 flex items-center">
            <Button
              disabled={disabled}
              color="primary"
              startIcon={<AddIcon />}
              onClick={() => setFieldValue('conditions', [{ ...BASE_CONDITION_FIELD, id: uuidv4() }])}
            >
              <span>ADD CONDITION</span>
            </Button>
            <ErrorHelperTextFormik id={CONDITION_FIELD_NAME} />
          </div>
          {disabled ? (
            <div className="-mt-15 flex items-center">
              <WarningIcon iconColor={colors.yellow[700]} />
              <Text variant={Text.VARIANTS.SM} weight={Text.WEIGHTS.REGULAR}>
                Complete context block to enable conditions
              </Text>
            </div>
          ) : null}
        </div>
      ) : null}
    </>
  );
};

ConditionsFormik.propTypes = {
  conditions: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
};
