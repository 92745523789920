import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

import Grid from '~/components/core/Atomic/Grid/Grid';

import styles from './UserPermissionsDialogs.module.scss';

export const ConfirmDialogContent = ({ title, subtitle }) => (
  <div className={styles.confirmDialogContentContainer}>
    <Grid container direction="column" alignItems="center" justifyContent="center">
      <Grid item>
        <Typography className={styles.dialogTitle} display="block" variant="h6">
          {title}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body1" className={styles.dialogContent}>
          {subtitle}
        </Typography>
      </Grid>
    </Grid>
  </div>
);

ConfirmDialogContent.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
};

export default ConfirmDialogContent;
