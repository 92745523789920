import React, { useRef } from 'react';
import requiredIf from 'react-required-if';
import PropTypes from 'prop-types';
import { IconButton } from '@material-ui/core';
import _ from 'lodash';

import Chip from '~/components/core/Atomic/Chip/Chip';

import { RemoveIcon } from '../icons';

import { useStyles } from '../../assets/styles';

const TextWithChips = ({ text, tokensValues, editable, onEdit, onChange }) => {
  const classes = useStyles();
  const childrenRef = useRef([]);

  const tokensInText = text.match(/{(.*?)}/g);
  const withChips = text.split(/{(.*?)}/);
  const tokensMap = _.keyBy(tokensInText, (token) => token.slice(1, -1));
  return (
    <div
      onClick={() => childrenRef.current[childrenRef.current.length - 1]?.focus()}
      className={editable ? '' : classes.overflowTextWithWrap}
    >
      {(withChips || ['']).map((value, i) =>
        tokensMap[value] ? (
          <Chip
            key={i}
            size="small"
            label={
              <>
                {tokensValues[value]}
                {editable && (
                  <IconButton
                    style={{ padding: 4, fontSize: 5 }}
                    title="Clear"
                    onClick={() => {
                      const newVal = spliceNewPartToFullValue(
                        withChips,
                        tokensMap,
                        i - 1,
                        3,
                        `${withChips[i - 1].trim()} ${withChips[i + 1].trim()}`.trim()
                      );
                      onEdit(newVal);
                      onChange(newVal);
                    }}
                  >
                    <RemoveIcon fontSize="small" />
                  </IconButton>
                )}
              </>
            }
            className={classes.chip}
            ref={(ref) => (childrenRef.current[i] = ref)}
            onClick={(e) => e.stopPropagation()}
          />
        ) : (
          <span
            key={i}
            contentEditable={!!editable}
            suppressContentEditableWarning={!!editable}
            onKeyDown={(e) => {
              switch (e.code) {
                case 'Enter':
                case 'BracketRight':
                case 'BracketLeft':
                  e.preventDefault();
                  break;
                case 'Home':
                  e.preventDefault();
                  childrenRef.current[0].focus();
                  break;
                case 'End':
                  e.preventDefault();
                  childrenRef.current[childrenRef.current.length - 1]?.focus();
                  break;
                case 'ArrowRight':
                  if (e.metaKey) {
                    childrenRef.current[childrenRef.current.length - 1]?.focus();
                  }
                  break;
                case 'ArrowLeft':
                  if (e.metaKey) {
                    childrenRef.current[0].focus();
                  }
                  break;
              }
            }}
            onBlur={(e) => {
              const newVal = spliceNewPartToFullValue(withChips, tokensMap, i, 1, e.currentTarget.textContent);
              onEdit(newVal);
              onChange(newVal);
            }}
            onInput={(e) => {
              onChange(spliceNewPartToFullValue(withChips, tokensMap, i, 1, e.currentTarget.textContent));
            }}
            ref={(ref) => {
              childrenRef.current[i] = ref;
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
            style={{ outline: '0px solid transparent', caretColor: 'black', border: '1px solid transparent' }}
          >
            {value}
          </span>
        )
      )}
    </div>
  );
};

TextWithChips.propTypes = {
  text: PropTypes.string,
  tokensValues: PropTypes.object.isRequired,
  editable: PropTypes.bool,
  onEdit: requiredIf(PropTypes.func, (props) => props.editable),
  onChange: requiredIf(PropTypes.func, (props) => props.editable),
};

export default TextWithChips;

const createMergedText = (textWithChips, tokensMap) =>
  textWithChips.reduce((merged, currValue, idx) => {
    const isToken = idx % 2 !== 0 && tokensMap[currValue];
    let addToMerged = `${idx === 0 ? '' : ' '}${currValue.trim()}${idx === textWithChips.length - 1 ? '' : ' '}`;
    if (isToken) {
      addToMerged = `{${currValue}}`;
    }
    return `${merged}${addToMerged}`;
  }, '');

const spliceNewPartToFullValue = (withChips, tokensMap, spliceStart, spliceEnd, spliceItems) => {
  const withChipsCopy = [...withChips];
  withChipsCopy.splice(spliceStart, spliceEnd, spliceItems);
  return createMergedText(withChipsCopy, tokensMap);
};
