import React, { useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import axios from 'axios';

import { SortableTable } from '~/components/core';
import { useLobConfiguration } from '~/components/hooks/useLobConfiguration';
import { CheckIcon, PencilIcon } from '~/components/icons';
import useOrganization from '~/components/OrganizationContext';
import type { ContactRoleType } from '~/components/SystemConfiguration/ContactRoles/types';
import UpsertRoleDialog from '~/components/SystemConfiguration/ContactRoles/UpsertRoleDialog';
import { useSysconfig } from '~/components/SystemConfiguration/SystemConfigurationScreen';
import { reportAxiosError } from '~/Utils';
import { getLobDescription } from '~/Utils/lobUtils';

interface ContactRolesListProps {
  searchTerm: string;
  showOnlyCustomRoles: boolean;
}

const ContactRolesList: React.FC<ContactRolesListProps> = ({ searchTerm, showOnlyCustomRoles }) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { organizationContactRolesDict }: { organizationContactRolesDict: Record<string, ContactRoleType> } =
    useOrganization();
  const { lobConfigurationsDict } = useLobConfiguration();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { organization } = useSysconfig();

  const [roleToEdit, setRoleToEdit] = useState<ContactRoleType | null>(null);

  const yesOption = <CheckIcon width={18} height={18} className="fill-slate-700" />;

  const filteredRoles = Object.values(organizationContactRolesDict)
    .filter((row: ContactRoleType) => row.desc.includes(searchTerm) || row.role_key.includes(searchTerm))
    .filter((row: ContactRoleType) => !showOnlyCustomRoles || !row.is_mandatory);

  const handleEditRole = (role: ContactRoleType) => {
    setRoleToEdit(role);
  };

  const handleClose = () => {
    setRoleToEdit(null);
  };

  const getActionCell = (role: ContactRoleType) =>
    role.is_mandatory ? null : (
      <IconButton onClick={() => handleEditRole(role)}>
        <PencilIcon />
      </IconButton>
    );

  const handleSubmit = async (values: Record<string, unknown>) => {
    try {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      await axios.put(`/api/v1/contact_roles/organizations/${organization.id}/roles/${roleToEdit.id}`, values);
    } catch (error) {
      await reportAxiosError(error);
      throw error;
    }
  };

  const columns = [
    { id: 'desc', label: 'Role Name' },
    { id: 'role_key', label: 'Role Key' },
    {
      id: 'lobs',
      label: 'Lines of Business',
      specialCell: ({ is_all_lobs, lobs }: { is_all_lobs: boolean; lobs: string[] }) =>
        is_all_lobs ? (
          'All'
        ) : (
          <span>{lobs.map((lob) => getLobDescription(lob, lobConfigurationsDict)).join(', ')}</span>
        ),
    },
    {
      id: 'is_mandatory',
      label: 'Is Custom',
      specialCell: ({ is_mandatory }: ContactRoleType) => (!is_mandatory ? yesOption : ''),
    },
    {
      id: 'is_multi_claim',
      label: 'Is Multi-Claim',
      specialCell: ({ is_multi_claim }: ContactRoleType) => (is_multi_claim ? yesOption : ''),
    },
    {
      id: 'is_moi_enabled',
      label: 'MOI Expertise Enabled',
      specialCell: ({ is_moi_enabled }: ContactRoleType) => (is_moi_enabled ? yesOption : ''),
    },
    {
      id: '',
      label: 'Actions',
      specialCell: getActionCell,
    },
  ];

  return (
    <>
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <SortableTable columns={columns} rows={filteredRoles} />
      {roleToEdit ? <UpsertRoleDialog contactRole={roleToEdit} onSubmit={handleSubmit} onClose={handleClose} /> : null}
    </>
  );
};

export default ContactRolesList;
