import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { InputLabel, MenuItem, Popover } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { getIn, useFormikContext } from 'formik';
import _ from 'lodash';

import Button from '~/components/core/Atomic/Buttons/Button';

import TextWithChips from '../TextWithChips';

import { ErrorHelperTextFormik } from './ErrorHelperTextFormik';

import { useStyles } from '../../../assets/styles';

function TextAreaWithTokens({
  id,
  label,
  tokensList,
  disabled,
  charsLimit = 250,
  onAddToken = null,
  onChooseToken = null,
}) {
  const { setFieldValue, values } = useFormikContext();
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentLength, setCurrentLength] = useState(0);
  const classes = useStyles();

  const sortedTokens = Object.keys(tokensList);
  sortedTokens.sort((a, b) => ('' + tokensList[a].desc).localeCompare(tokensList[b].desc));

  return (
    <>
      <div style={{ display: 'flex' }}>
        <div
          style={{
            background: '#F5F5F5',
            border: '1px solid #D0D0D0',
            padding: '10px',
            marginBottom: '2px',
            width: '425px',
            minHeight: '80px',
          }}
        >
          <InputLabel
            className={classes.textFieldRow}
            style={{ opacity: '0.5', fontSize: '12px', marginBottom: '10px' }}
          >
            {label}
          </InputLabel>
          <div>
            <TextWithChips
              text={getIn(values, id)}
              tokensValues={_.mapValues(tokensList, (value) => value.desc)}
              editable={!disabled}
              onEdit={(newVal) => setFieldValue(id, newVal)}
              onChange={(newVal) => setCurrentLength(newVal.length)}
            />
          </div>
        </div>
        <Button
          style={{ marginLeft: '20px', whiteSpace: 'nowrap' }}
          className={classes.actionButton}
          variant="contained"
          color="primary"
          onClick={(e) => {
            setAnchorEl(e.currentTarget);
            if (onAddToken) {
              onAddToken();
            }
          }}
          endIcon={<ExpandMoreIcon />}
          disabled={disabled}
        >
          ADD TOKEN
        </Button>
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          placement="bottom-end"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <div>
            {sortedTokens.map((tokenKey) => (
              <MenuItem
                key={tokenKey}
                value={tokenKey}
                onClick={() => {
                  const newVal = `${getIn(values, id)} {${tokenKey}}`;
                  setFieldValue(id, newVal);
                  setCurrentLength(newVal.length);
                  if (onChooseToken) {
                    onChooseToken(tokenKey);
                  }
                }}
              >
                {tokensList[tokenKey].desc}
              </MenuItem>
            ))}
          </div>
        </Popover>
      </div>
      <ErrorHelperTextFormik id={id} />
      {typeof charsLimit === 'number' ? (
        <InputLabel className={classes.textFieldRow} style={{ opacity: '0.5', fontSize: '12px', marginTop: '2px' }}>
          {currentLength}/{charsLimit}
        </InputLabel>
      ) : null}
    </>
  );
}

TextAreaWithTokens.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  tokensList: PropTypes.object.isRequired,
  charsLimit: PropTypes.number,
  disabled: PropTypes.bool,
  onAddToken: PropTypes.func,
  onChooseToken: PropTypes.func,
};

export default TextAreaWithTokens;
