import React from 'react';
import { Typography } from '@material-ui/core';
import { getIn, useFormikContext } from 'formik';
import { get } from 'lodash';

import cn from '~/Utils/cn';

import { PERFORMANCE_QA } from '../../../../../../../Types';
import { CUSTOM_QUESTIONS_KEY, QUESTIONS_KEY, SLA_TIME_PLACEHOLDER, SLA_VALUE_PLACEHOLDER } from '../../constants';

import Question from './Question';

import { useStyles } from '../../../../../../../assets/styles';
import styles from './previewSection.module.scss';

const Preview = () => {
  const { values } = useFormikContext();
  const classes = useStyles();
  const questionsMap = {};

  // Add Predefined questions
  Object.entries(getIn(values, QUESTIONS_KEY, {}))
    .filter(([, { selected }]) => selected)
    .forEach(([id, { sla_type, sla_value } = {}]) => {
      const { template, category } = PERFORMANCE_QA.QUESTIONS[id];

      const text = template
        .replace(SLA_TIME_PLACEHOLDER, get(PERFORMANCE_QA.SLA_TYPES, `[${sla_type}].desc`), '')
        .replace(SLA_VALUE_PLACEHOLDER, sla_value);

      if (!questionsMap[category]) {
        questionsMap[category] = [];
      }
      questionsMap[category].push({ id, text });
    });

  // Add Custom questions
  Object.entries(getIn(values, CUSTOM_QUESTIONS_KEY, {})).forEach(([id, { text, category, explanation }]) => {
    if (!questionsMap[category]) {
      questionsMap[category] = [];
    }
    questionsMap[category].push({ id, text, explanation });
  });

  return (
    <div className={styles.sectionContainer}>
      {Object.entries(questionsMap).map(([category, questions]) => (
        <div key={category} className={styles.questionsContainer}>
          <Typography display="block" variant="subtitle1" className={cn(styles.sectionTitle, classes.h4)}>
            {PERFORMANCE_QA.CATEGORIES[category].desc}
          </Typography>
          {questions.map(({ id, text, explanation }) => (
            <Question key={id} id={id} text={text} tooltip={explanation} />
          ))}
        </div>
      ))}
    </div>
  );
};

export default Preview;
