import React from 'react';
import { IconButton, InputAdornment, Link, MenuItem, TextField } from '@material-ui/core';
import { isEmpty } from 'lodash';

import { useStyles } from '~/assets/styles';
import { Text } from '~/components/core';
import Grid from '~/components/core/Atomic/Grid/Grid';
import InnerCard from '~/components/core/Cards/InnerCard';
import { useLobConfiguration } from '~/components/hooks/useLobConfiguration';
import CancelIcon from '~/components/icons/CancelIcon';
import CloseIcon from '~/components/icons/CloseIcon';
import { useSysconfig } from '~/components/SystemConfiguration/SystemConfigurationScreen';
import { subOrgIdToNameDict } from '~/Utils';
import cn from '~/Utils/cn';
import { getLobDescription } from '~/Utils/lobUtils';

import { FILTERS_DEFAULT } from './constants';
import type { Filters, SetFilters } from './types';

type ConfigurationFiltersProps = {
  filters: Filters;
  setFilters: SetFilters;
  searchPlaceholder: string;
};

const ConfigurationFilters = ({ filters, setFilters, searchPlaceholder }: ConfigurationFiltersProps): JSX.Element => {
  const { searchText, selectedLob, subOrganizationIds } = filters;
  const classes = useStyles();
  const { lobConfigurationsDict } = useLobConfiguration();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { organization, organizationOperationalDetails } = useSysconfig();
  const subOrgIdToName = subOrgIdToNameDict(organization);

  const resetFilters = () => {
    setFilters({ ...FILTERS_DEFAULT });
  };

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item xs={2} className="my-5">
        <TextField
          fullWidth
          className={classes.textField}
          value={searchText}
          onChange={(e) => setFilters({ ...filters, searchText: e.target.value })}
          InputProps={{
            placeholder: searchPlaceholder,
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  className={cn(`p-2 ${isEmpty(searchText) ? 'hidden' : 'visible'} mr-5`)}
                  title="Clear"
                  onClick={() => setFilters({ ...filters, searchText: '' })}
                >
                  <CloseIcon size={10} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <InnerCard className="p-6">
          <Grid container direction="column">
            <Grid item xs={12} className="mb-5">
              <Text variant={Text.VARIANTS.LG} weight={Text.WEIGHTS.SEMI_BOLD}>
                Filters
              </Text>
            </Grid>
          </Grid>
          <Grid container spacing={2} className="flex items-center pb-10">
            {organization.sub_organizations_enabled && (
              <Grid item>
                <TextField
                  className={classes.textFieldRow}
                  label="By Sub-Organization"
                  onChange={(e) => setFilters({ ...filters, subOrganizationIds: [Number(e.target.value)] })}
                  select
                  value={subOrganizationIds}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          className={cn(`p-2 ${isEmpty(subOrganizationIds) ? 'hidden' : 'visible'} mr-20`)}
                          title="Clear"
                          onClick={() => setFilters({ ...filters, subOrganizationIds: [] })}
                        >
                          <CloseIcon size={10} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                >
                  {organizationOperationalDetails.subOrganizations.map(({ id }: { id: number }) => (
                    <MenuItem key={id} value={id}>
                      {subOrgIdToName[id]}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            )}
            <Grid item>
              <TextField
                className={classes.textFieldRow}
                label="By Line of Business"
                onChange={(e) => setFilters({ ...filters, selectedLob: e.target.value })}
                select
                value={selectedLob}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        className={cn(`p-2 ${isEmpty(selectedLob) ? 'hidden' : 'visible'} mr-20`)}
                        title="Clear"
                        onClick={() => setFilters({ ...filters, selectedLob: '' })}
                      >
                        <CloseIcon size={10} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              >
                {organizationOperationalDetails.orgLevelSupportedLobs.map((lob: string) => (
                  <MenuItem key={lob} value={lob}>
                    {getLobDescription(lob, lobConfigurationsDict)}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item>
              <Link
                href=""
                onClick={(e) => {
                  e.preventDefault();
                  resetFilters();
                }}
                className={cn(classes.alignedContainerForGrid, classes.secondaryLink, 'pt-1.5')}
              >
                <CancelIcon className={cn(classes.filledIcon, classes.leftButtonIcon)} />
                Clear all filters
              </Link>
            </Grid>
          </Grid>
        </InnerCard>
      </Grid>
    </Grid>
  );
};

export default ConfigurationFilters;
