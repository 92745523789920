import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Switch } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import axios from 'axios';
import { isEmpty } from 'lodash';

import { reportAxiosError } from '../../../../../Utils';
import CardDialog from '../../../../CardDialog';
import WithConfirm from '../../../../ConfirmModal';
import { FsButton, LoadingSwitch } from '../../../../core';
import FsIconButton from '../../../../core/FsWrappers/FsIconButton/FsIconButton';
import { PencilIcon, ThreeDotsHorizontalIcon, TrashIcon } from '../../../../icons';
import useOrganization from '../../../../OrganizationContext';
import { useSysconfig } from '../../../SystemConfigurationScreen';
import { useXactAnalysis } from '../hooks/useXactAnalysis';
import XactAnalysisConfigurationTable from '../XactAnalysisConfigurationTable';

import XactAnalysisConfigurationDialog from './XactAnalysisConfigurationDialog';
import XactAnalysisInfoDialog from './XactAnalysisInfoDialog';

import { useStyles } from '../../../../../assets/styles';
import styles from './index.module.scss';

const EnableXactAnalysisButton = ({ onSetupFormClick, isEnabled }) => {
  return (
    !isEnabled && (
      <FsButton color="primary" variant="contained" className={styles.button} onClick={onSetupFormClick}>
        Enable XactAnalysis Integration
      </FsButton>
    )
  );
};

EnableXactAnalysisButton.propTypes = {
  onSetupFormClick: PropTypes.func.isRequired,
  isEnabled: PropTypes.bool,
};

const InternalAdminXactAnalysisWrapper = () => {
  const { organization } = useSysconfig();
  const { subOrganizationEnabled, subOrganizations } = useOrganization();
  const classes = useStyles();
  const [isSetupFormOpen, setSetupFormOpen] = useState(false);
  const [infoCardState, setInfoCardState] = useState({ isOpen: false });
  const [currentConfig, setCurrentConfig] = useState({});
  const [isUpdating, setIsEnablingConfig] = useState(false);
  const {
    isLoading,
    isError,
    isEnabled,
    reloadData: reloadConfigurations,
    xactAnalysisOrgConfigs,
    enableXactAnalysis,
    upsertXactAnalysisConfig,
  } = useXactAnalysis();
  // configSubOrgIds holds the suborg IDs for which a configuration already
  // exists, except the current configuration, if this is an update
  const configuredSubOrgIds = new Set(
    xactAnalysisOrgConfigs
      // Filter out the current config (if this is an update)
      .filter((config) => config.id !== currentConfig?.id)
      // Get the suborg IDs for all relevant configs, in one flattened array
      .flatMap((config) => config.sub_organization_ids)
  );

  const handleSubmitConfig = async (values) => {
    const isNewConfig = !currentConfig?.id;
    if (subOrganizationEnabled && values?.is_all_sub_orgs) {
      values.sub_organization_ids = subOrganizations?.map(({ id }) => id) || [];
    }
    const config = await upsertXactAnalysisConfig({ ...values, id: currentConfig?.id });
    if (!config) {
      return;
    }
    setSetupFormOpen(false);
    if (isNewConfig) {
      handleInfoClick(config, { withBanner: true });
    }
  };

  const handleSetupFormClick = (config) => {
    setCurrentConfig(config);
    setSetupFormOpen(true);
  };

  const handleCloseConfigForm = () => {
    setCurrentConfig({});
    setSetupFormOpen(false);
  };

  const handleInfoClick = (config, options = {}) => {
    options = { withBanner: false, ...options };
    setCurrentConfig(config);
    setInfoCardState({ isOpen: true, ...options });
  };

  const handleCloseInfoCard = () => {
    setCurrentConfig({});
    setInfoCardState({ isOpen: false });
  };

  const handleDeleteConfig = async (config) => {
    try {
      await axios.delete(`/api/v1/xactanalysis/${organization.id}/configurations/${config.id}`);
      await reloadConfigurations();
    } catch (error) {
      await reportAxiosError(error);
    }
  };

  const handleEnableConfig = async (config, is_enabled) => {
    try {
      setIsEnablingConfig(true);
      await axios.patch(`/api/v1/xactanalysis/${organization.id}/configurations/${config.id}`, { is_enabled });
      await reloadConfigurations();
      setIsEnablingConfig(false);
    } catch (error) {
      await reportAxiosError(error);
    }
  };

  const additionalColumnsArray = [
    {
      id: 'is_enabled',
      numeric: false,
      label: 'Integrate with XactAnalysis',
      disableSort: true,
      align: 'center',
      specialCell: (config) => (
        <WithConfirm
          key={config.id}
          title="Deactivate this Carrier Profile?"
          contentText="Deactivating this carrier profile will disable the integration and you will no longer be able to collect or send data via XactAnalysis in the CMS."
          primaryButtonName="Yes"
          shouldCloseOnPrimary={true}
          triggerMethod="onChange"
          disableConfirm={!config?.is_enabled}
        >
          <Switch
            checked={!!config?.is_enabled}
            className={classes.formsSwitch}
            size="small"
            onChange={() => handleEnableConfig(config, !config.is_enabled)}
          />
        </WithConfirm>
      ),
    },
    {
      id: 'actions',
      label: 'Edit Profile',
      numeric: false,
      disableSort: true,
      align: 'center',
      width: '60px',
      specialCell: (config) => (
        <div className={styles.actionsContainer}>
          <FsIconButton
            icon={PencilIcon}
            tooltipText="Edit"
            className={styles.btn}
            onClick={() => handleSetupFormClick(config)}
          />
          <WithConfirm
            key={config.id}
            title="Delete This Carrier Profile?"
            contentText="Deleting this carrier profile will disable the integration and you will need to create a new profile if required."
            primaryButtonName="Yes"
            shouldCloseOnPrimary={true}
          >
            <FsIconButton
              icon={TrashIcon}
              tooltipText="Delete"
              className={styles.deleteBtn}
              onClick={() => handleDeleteConfig(config)}
            />
          </WithConfirm>
          <FsIconButton
            icon={ThreeDotsHorizontalIcon}
            tooltipText="More details"
            className={styles.btn}
            onClick={() => handleInfoClick(config)}
          />
        </div>
      ),
    },
  ];

  return (
    <LoadingSwitch isLoading={isLoading || isUpdating} isError={isError}>
      <CardDialog
        containerClassName={styles.xactAnalysisInternalAdmin}
        title="XactAnalysis Integration Setup"
        action={
          <>
            {isEnabled && !isEmpty(xactAnalysisOrgConfigs) && (
              <div className={styles.addCarrierBtnContainer}>
                <FsButton
                  color={FsButton.COLORS.primary}
                  className={styles.addCarrierBtn}
                  onClick={() => handleSetupFormClick({ is_enabled: true })}
                >
                  <AddIcon className={classes.rightButtonIcon} />
                  Add Carrier Profile
                </FsButton>
              </div>
            )}
          </>
        }
      >
        <EnableXactAnalysisButton onSetupFormClick={enableXactAnalysis} isEnabled={isEnabled} />
        {isEnabled && (
          <XactAnalysisConfigurationTable
            configurations={xactAnalysisOrgConfigs}
            additionalColumnsArray={additionalColumnsArray}
            setConfigDialogOpen={() => handleSetupFormClick({ is_enabled: true })}
          />
        )}
      </CardDialog>
      {isSetupFormOpen && (
        <XactAnalysisConfigurationDialog
          data={currentConfig}
          configuredSubOrgIds={configuredSubOrgIds}
          handleSubmit={handleSubmitConfig}
          onClose={handleCloseConfigForm}
        />
      )}
      {infoCardState?.isOpen && currentConfig && (
        <XactAnalysisInfoDialog
          config={currentConfig}
          onClose={handleCloseInfoCard}
          showConfigCreatedBanner={infoCardState.withBanner}
        />
      )}
    </LoadingSwitch>
  );
};

export default InternalAdminXactAnalysisWrapper;
