import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { Formik } from 'formik';
import { isEmpty } from 'lodash';
import * as Yup from 'yup';

import { useStyles } from '~/assets/styles';
import Button from '~/components/core/Atomic/Buttons/Button';
import Chip from '~/components/core/Atomic/Chip/Chip';
import Grid from '~/components/core/Atomic/Grid/Grid';
import CancelButton from '~/components/core/Buttons/CancelButton';

import CardDialog from '../../../../CardDialog';
import RadioButtonGroupFormik from '../../../../core/Formik/RadioButtonGroupFormik';
import { MultiSelectTextFieldFormik } from '../../../../TextFieldFormik';
import { useSysconfig } from '../../../SystemConfigurationScreen';
import { FIELD_IDS } from '../PhoneConfigurationTab';

const EditAutomaticCallRecordingConfigDialog = ({ configuration, onSubmit, onCancel }) => {
  const classes = useStyles();
  const { roles } = useSysconfig();
  return (
    <Formik
      initialValues={
        isEmpty(configuration)
          ? {
              [FIELD_IDS.IS_AUTO_RECORD_FOR_ALL_ROLES]: '',
              [FIELD_IDS.AUTO_RECORD_CALLS_ROLES]: [],
            }
          : configuration
      }
      validationSchema={Yup.object().shape({
        [FIELD_IDS.IS_AUTO_RECORD_FOR_ALL_ROLES]: Yup.boolean().nullable().required('Required'),
        [FIELD_IDS.AUTO_RECORD_CALLS_ROLES]: Yup.array().when('is_auto_record_for_all_roles', {
          is: false,
          then: Yup.array().nullable().min(1, 'At least on role is required'),
        }),
      })}
      onSubmit={async (values, formikProps) => {
        try {
          await onSubmit(values);
          onCancel();
        } catch {
          formikProps.setSubmitting(false);
        }
      }}
    >
      {({ values, isSubmitting, handleSubmit }) => (
        <CardDialog
          title="Automatic Call Recording"
          isDialog
          maxWidth="sm"
          fullWidth
          onClose={onCancel}
          preventClose={isSubmitting}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <RadioButtonGroupFormik
                label="Record For"
                id={FIELD_IDS.IS_AUTO_RECORD_FOR_ALL_ROLES}
                direction="row"
                options={[
                  { text: 'All Calls', optionValue: true },
                  { text: 'Per Role', optionValue: false },
                ]}
                variant="subtitle1"
              />
            </Grid>
          </Grid>

          {values[FIELD_IDS.IS_AUTO_RECORD_FOR_ALL_ROLES] === false && (
            <Grid item xs={12}>
              <MultiSelectTextFieldFormik
                id={FIELD_IDS.AUTO_RECORD_CALLS_ROLES}
                label="Select Roles"
                className={classes.textFieldRow}
                fullWidth
                renderOption={(option) => {
                  const roleIndex = roles.findIndex((role) => role.id === option);
                  return roleIndex > -1 ? roles[roleIndex]['name'] : '';
                }}
                options={roles.map((role) => role.id)}
                renderValue={() => ''}
                withOptionChips
              />
            </Grid>
          )}

          <div className={classes.buttonsContainer}>
            <CancelButton disabled={isSubmitting} onClick={onCancel} />
            <Button variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
              Save
            </Button>
          </div>
        </CardDialog>
      )}
    </Formik>
  );
};

EditAutomaticCallRecordingConfigDialog.propTypes = {
  configuration: PropTypes.object.isRequired,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
};

const AutomaticCallRecordingConfigView = ({ twilioConfiguration, roles }) => {
  const classes = useStyles();

  return (
    <>
      <Typography variant="subtitle1">Automatic call recording</Typography>
      <Typography variant="caption">Set up the automatic recording of incoming and outgoing calls</Typography>
      <div className="mt-20">
        {twilioConfiguration[FIELD_IDS.AUTO_RECORD_CALLS] ? (
          <>
            <Typography variant="subtitle2">
              {twilioConfiguration[FIELD_IDS.IS_AUTO_RECORD_FOR_ALL_ROLES]
                ? 'Enabled for all roles'
                : 'Enabled for roles:'}
            </Typography>
            {twilioConfiguration[FIELD_IDS.IS_AUTO_RECORD_FOR_ALL_ROLES] ? null : (
              <div className="mt-10">
                {Array.isArray(twilioConfiguration[FIELD_IDS.AUTO_RECORD_CALLS_ROLES])
                  ? twilioConfiguration[FIELD_IDS.AUTO_RECORD_CALLS_ROLES].map((roleId) => (
                      <Chip
                        className={classes.chip}
                        size="small"
                        key={roleId}
                        label={roles.find((role) => role.id === roleId)?.name}
                      />
                    ))
                  : null}
              </div>
            )}
          </>
        ) : null}
      </div>
    </>
  );
};

AutomaticCallRecordingConfigView.propTypes = {
  twilioConfiguration: PropTypes.object.isRequired,
  roles: PropTypes.array.isRequired,
};

export { AutomaticCallRecordingConfigView, EditAutomaticCallRecordingConfigDialog };
