import React from 'react';
import { Formik, getIn, useFormikContext } from 'formik';
import { isNil, snakeCase } from 'lodash';
import * as Yup from 'yup';

import CardDialog from '~/components/CardDialog';
import CheckboxFormik from '~/components/CheckboxFormik';
import Button from '~/components/core/Atomic/Buttons/Button';
import CancelButton from '~/components/core/Buttons/CancelButton';
import useOrganization from '~/components/OrganizationContext';
import type { ContactRoleType } from '~/components/SystemConfiguration/ContactRoles/types';
import TextFieldFormik, { useSetDefaultFieldsOnChange } from '~/components/TextFieldFormik';
import { LobCheckboxMultiselectFormik } from '~/components/TPA/LOB/LobCheckboxMultiselectFormik';
import { reportAxiosError } from '~/Utils';

interface UpsertRoleDialogProps {
  onClose: () => void;
  onSubmit: (values: Record<string, unknown>) => Promise<void>;
  contactRole?: ContactRoleType;
}

const FORMIK_IDS = {
  ROLE_KEY: 'role_key',
  DESC: 'desc',
  IS_ALL_LOBS: 'is_all_lobs',
  LOBS: 'lobs',
  IS_MULTI_CLAIM: 'is_multi_claim',
  IS_MOI_ENABLED: 'is_moi_enabled',
  IS_TIN_REQUIRED: 'is_tin_required',
};

interface UpsertRoleDialogInnerProps {
  contactRole?: ContactRoleType;
}

const UpsertRoleDialogInner: React.FC<UpsertRoleDialogInnerProps> = ({ contactRole }) => {
  const { isSubmitting, values } = useFormikContext();

  useSetDefaultFieldsOnChange(getIn(values, FORMIK_IDS.DESC), {
    [FORMIK_IDS.ROLE_KEY]: contactRole ? getIn(values, FORMIK_IDS.ROLE_KEY) : snakeCase(getIn(values, FORMIK_IDS.DESC)),
  });

  useSetDefaultFieldsOnChange(getIn(values, FORMIK_IDS.IS_MULTI_CLAIM), {
    [FORMIK_IDS.IS_MOI_ENABLED]: false,
  });

  return (
    <div className="grid gap-20">
      <div className="grid grid-cols-2 gap-20">
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <TextFieldFormik id={FORMIK_IDS.DESC} label="Contact Role Name" disabled={isSubmitting} />
        <TextFieldFormik
          id={FORMIK_IDS.ROLE_KEY}
          label="Contact Role Key"
          disabled={isSubmitting || !isNil(contactRole)}
        />
      </div>
      <LobCheckboxMultiselectFormik
        lobsFieldId={FORMIK_IDS.LOBS}
        allSelectedFieldId={FORMIK_IDS.IS_ALL_LOBS}
        disabled={isSubmitting}
        onlyNewValues={!isNil(contactRole)}
      />
      <div className="grid">
        <CheckboxFormik
          id={FORMIK_IDS.IS_MULTI_CLAIM}
          label="Is Multi-Claim"
          disabled={isSubmitting || contactRole?.is_multi_claim}
        />
        <CheckboxFormik
          id={FORMIK_IDS.IS_MOI_ENABLED}
          label="Is MOI Expertise Enabled"
          disabled={isSubmitting || !getIn(values, FORMIK_IDS.IS_MULTI_CLAIM) || contactRole?.is_moi_enabled}
        />
        <CheckboxFormik
          id={FORMIK_IDS.IS_TIN_REQUIRED}
          label="Is TIN Required"
          disabled={isSubmitting || contactRole?.is_tin_required}
        />
      </div>
    </div>
  );
};

const UpsertRoleDialog: React.FC<UpsertRoleDialogProps> = ({ onClose, onSubmit, contactRole }) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const {
    organizationContactRolesDict,
    reloadContactRoles,
  }: { organizationContactRolesDict: Record<string, ContactRoleType>; reloadContactRoles: () => void } =
    useOrganization();

  return (
    <Formik
      initialValues={{
        [FORMIK_IDS.DESC]: contactRole?.desc ?? '',
        [FORMIK_IDS.ROLE_KEY]: contactRole?.role_key ?? '',
        [FORMIK_IDS.IS_ALL_LOBS]: contactRole?.is_all_lobs ?? false,
        [FORMIK_IDS.LOBS]: contactRole?.lobs ?? [],
        [FORMIK_IDS.IS_MULTI_CLAIM]: contactRole?.is_multi_claim ?? false,
        [FORMIK_IDS.IS_MOI_ENABLED]: contactRole?.is_moi_enabled ?? false,
        [FORMIK_IDS.IS_TIN_REQUIRED]: contactRole?.is_tin_required ?? false,
      }}
      validationSchema={Yup.object().shape({
        [FORMIK_IDS.DESC]: Yup.string()
          .required('Required')
          .trim()
          .lowercase()
          .notOneOf(
            Object.values(organizationContactRolesDict)
              .filter((r) => r.id !== contactRole?.id)
              .map((r) => r.desc.toLowerCase().trim()),
            'Must be unique'
          ),
        [FORMIK_IDS.ROLE_KEY]: Yup.string()
          .required('Required')
          .trim()
          .matches(/^[a-z0-9_]*$/, 'Can only contain lowercase letters, numbers and underscores')
          .notOneOf(
            Object.keys(organizationContactRolesDict).filter((key) => key !== contactRole?.role_key),
            'Must be unique'
          ),
        [FORMIK_IDS.IS_ALL_LOBS]: Yup.bool(),
        [FORMIK_IDS.LOBS]: Yup.array().when('is_all_lobs', {
          is: false,
          then: Yup.array().of(Yup.string()).min(1, 'Required'),
        }),
        [FORMIK_IDS.IS_MULTI_CLAIM]: Yup.bool().required('Required'),
        [FORMIK_IDS.IS_MOI_ENABLED]: Yup.bool().when('is_multi_claim', {
          is: true,
          then: Yup.bool().required('Required'),
        }),
        [FORMIK_IDS.IS_TIN_REQUIRED]: Yup.bool().required('Required'),
      })}
      onSubmit={async (values: Record<string, unknown>, formikProps) => {
        try {
          await onSubmit(values);
          await reloadContactRoles();
          onClose();
        } catch (error) {
          formikProps.setSubmitting(false);
          await reportAxiosError(error);
        }
      }}
      enableReinitialize
    >
      {({ isSubmitting, handleSubmit }) => {
        return (
          <CardDialog
            title={contactRole ? 'Edit Contact Role' : 'Create Contact Role'}
            isDialog
            onClose={onClose}
            maxWidth="md"
          >
            <UpsertRoleDialogInner contactRole={contactRole} />
            <div className="mt-px mt-30 flex w-full justify-end">
              <CancelButton disabled={isSubmitting} onClick={onClose} />
              {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
              {/* @ts-ignore */}
              <Button variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
                Save
              </Button>
            </div>
          </CardDialog>
        );
      }}
    </Formik>
  );
};

export default UpsertRoleDialog;
