import React from 'react';
import { bool, func, string } from 'prop-types';
import { MenuItem } from '@material-ui/core';
import { getIn, useFormikContext } from 'formik';

import Grid from '~/components/core/Atomic/Grid/Grid';
import cn from '~/Utils/cn';

import { PERFORMANCE_QA } from '../../../../../../../../Types';
import CheckboxFormik from '../../../../../../../CheckboxFormik';
import TextFieldFormik, { NumericTextFieldFormik } from '../../../../../../../TextFieldFormik';
import { SLA_TIME_PLACEHOLDER, SLA_VALUE_PLACEHOLDER } from '../../../constants';

import { useStyles } from '../../../../../../../../assets/styles';
import styles from '../../sections.module.scss';

const SLA_TYPES = PERFORMANCE_QA.SLA_TYPES;
const SLA_VALUE_MAX_LENGTH = 4;

const slaValueDecorator = (value = '') => `<span class=${styles.slaValueContainer}>${value}</span>`;

const Question = ({ id, disabled, template }) => {
  const { values, setFieldValue } = useFormikContext();
  const classes = useStyles();

  const slaTimeId = `${id}.sla_type`;
  const slaValueId = `${id}.sla_value`;
  const slaTime = getIn(values, slaTimeId);
  const slaValue = getIn(values, slaValueId);

  const isSlaQuestion = template.includes(SLA_TIME_PLACEHOLDER);

  const displayText = template
    .replace(SLA_TIME_PLACEHOLDER, slaTime ? slaValueDecorator(SLA_TYPES[slaTime].desc) : slaValueDecorator())
    .replace(SLA_VALUE_PLACEHOLDER, slaValue ? slaValueDecorator(slaValue) : slaValueDecorator());

  return (
    <Grid container spacing={4} className={styles.questionContainer}>
      <Grid item xs={isSlaQuestion ? 8 : 12}>
        <CheckboxFormik
          id={`${id}.selected`}
          containerClassName={cn(styles.questionText, styles.questionBorder)}
          label={<p dangerouslySetInnerHTML={{ __html: displayText }} />}
          fullWidth
          disabled={disabled}
          onChange={({ target }) => {
            setFieldValue(`${id}.selected`, target.checked);
            setFieldValue(`${id}.is_sla`, isSlaQuestion);
          }}
        />
      </Grid>
      {isSlaQuestion && (
        <>
          <Grid item xs={2}>
            <TextFieldFormik
              id={slaTimeId}
              className={cn(classes.textField, styles.questionBorder, styles.questionSelect)}
              label="SLA value"
              select
              fullWidth
              disabled={disabled}
              defaultValue=""
            >
              {Object.entries(SLA_TYPES).map(([key, { desc }]) => (
                <MenuItem key={key} value={key} selected={key === slaValue}>
                  {desc}
                </MenuItem>
              ))}
            </TextFieldFormik>
          </Grid>
          <Grid item xs={2}>
            <NumericTextFieldFormik
              id={slaValueId}
              isAllowed={() => !disabled}
              label="Number"
              fullWidth
              inputProps={{ maxLength: SLA_VALUE_MAX_LENGTH }}
              className={cn(classes.textField, styles.questionBorder, styles.questionSelect, {
                [styles.disabledNumber]: disabled,
              })}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};

Question.propTypes = {
  id: string.isRequired,
  template: string.isRequired,
  disabled: bool.isRequired,
  onChange: func,
};

export default Question;
