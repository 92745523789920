import React from 'react';
import PropTypes from 'prop-types';

import mixpanel from '~/components/CmsMain/mixpanel';
import Switch from '~/components/core/Atomic/Switch';
import { ChipsPanel } from '~/components/core/ChipsPanel/ChipsPanel';
import cn from '~/Utils/cn';

import { PencilIcon, TrashIcon } from '../../../../components/icons';
import InlineIconButton from '../../../../components/InlineIconButton';
import { isoUtcDateTimeToLocal } from '../../../../DateTimeUtils';
import { FsTooltip, LoadingSwitch, SortableTable, Text } from '../../../core';

import { ORG_UNIT_TYPE, ORGANIZATION_UNITS_MIXPANEL_EVENTS } from './consts';

export const OrganizationUnitsTable = ({ units, isLoading, isError, onActionClick, unitsToSubUnitsDict }) => {
  const onEditUnitClick = (row) => {
    onActionClick({ action: 'edit', unit: row });
    mixpanel.track(ORGANIZATION_UNITS_MIXPANEL_EVENTS.EDIT_UNIT_CLICKED, {});
  };

  const onDeleteUnitClick = (row) => {
    onActionClick({ action: 'delete', unit: row });
    mixpanel.track(ORGANIZATION_UNITS_MIXPANEL_EVENTS.DELETE_UNIT_CLICKED, {});
  };

  const onToggleActivationClick = React.useCallback(
    (row) => {
      onActionClick({ action: 'toggle_activation', unit: row });
      mixpanel.track(
        row.is_active
          ? ORGANIZATION_UNITS_MIXPANEL_EVENTS.UNIT_ACTIVATED
          : ORGANIZATION_UNITS_MIXPANEL_EVENTS.UNIT_DEACTIVATED,
        {}
      );
    },
    [onActionClick]
  );

  const getActivationInfo = React.useCallback((unit) => {
    if (unit.is_active && unit.type === ORG_UNIT_TYPE.TOP_UNIT && unit.has_sub_units) {
      return { canToggleActivation: false, tooltip: 'Cannot deactivate top-level unit with sub-units' };
    }
    return { canToggleActivation: true, tooltip: null };
  }, []);

  const createChip = ({ unit }) => {
    if (!unit) return null;

    return {
      id: unit.id,
      text: unit.name,
      color: 'default',
      allowDelete: false,
    };
  };

  const buildSubUnitsCell = (unit) => {
    if (!unit) return null;

    const subUnits = unitsToSubUnitsDict[unit.id];
    if (!subUnits || !subUnits.length) return null;

    const numberOfUnitsToShow = 3;
    const chips = subUnits.slice(0, numberOfUnitsToShow).map((subUnit) => createChip({ unit: subUnit }));
    const numberOfSubUnits = subUnits.length;
    if (numberOfSubUnits > numberOfUnitsToShow) {
      chips.push({
        id: 'counter',
        text: `+${numberOfSubUnits - numberOfUnitsToShow}`,
        tooltip: subUnits
          .slice(numberOfUnitsToShow)
          .map((unit) => unit.name)
          .join(', '),
      });
    }
    return <ChipsPanel chips={chips} size="small" />;
  };

  const columns = [
    {
      id: OrganizationUnitsTable.COLUMNS.name,
      label: 'Name',
    },
    {
      id: OrganizationUnitsTable.COLUMNS.type,
      label: 'Type',
      specialCell: (row) => (row.type === ORG_UNIT_TYPE.TOP_UNIT ? 'Top Unit' : 'Sub Unit'),
    },
    {
      id: OrganizationUnitsTable.COLUMNS.description,
      label: 'Description',
    },
    {
      id: OrganizationUnitsTable.COLUMNS.parent_unit_name,
      label: 'Parent Unit',
    },
    {
      id: OrganizationUnitsTable.COLUMNS.child_units,
      label: 'Child Unit',
      specialCell: (row) => buildSubUnitsCell(row),
    },
    {
      id: OrganizationUnitsTable.COLUMNS.leader_user_name,
      label: 'Unit Leader',
    },
    {
      id: OrganizationUnitsTable.COLUMNS.members_count,
      label: 'Members',
      specialCell: (row) => {
        const numberOfMembers = row?.members?.length ?? 0;
        const hasMembers = numberOfMembers > 0;
        return (
          <span onClick={hasMembers ? () => onActionClick({ action: 'show_members', unit: row }) : null}>
            <Text
              variant={Text.VARIANTS.SM}
              colorVariant={hasMembers ? Text.COLOR_VARIANTS.BUTTON_LINK : Text.COLOR_VARIANTS.DISABLED}
              className={cn({ 'cursor-pointer': hasMembers })}
            >
              {numberOfMembers}
            </Text>
          </span>
        );
      },
    },
    {
      id: OrganizationUnitsTable.COLUMNS.last_updated,
      label: 'Last Updated',
      specialCell: (row) => (row.last_updated ? isoUtcDateTimeToLocal(row.last_updated) : ''),
    },
    {
      id: OrganizationUnitsTable.COLUMNS.is_active,
      label: 'Activate',
      specialCell: (row) => {
        return (
          <FsTooltip title={getActivationInfo(row)['tooltip']}>
            <Switch
              checked={row.is_active}
              onChange={() => onToggleActivationClick(row)}
              disabled={!getActivationInfo(row)['canToggleActivation']}
            />
          </FsTooltip>
        );
      },
    },
    {
      id: OrganizationUnitsTable.COLUMNS.actions,
      label: 'Actions',
      width: '100px',
      disableSort: true,
      specialCell: (row) => {
        return (
          <div className="flex gap-10">
            <InlineIconButton
              tooltipTitle="Edit"
              icon={PencilIcon}
              onClick={() => onEditUnitClick(row)}
              className="hover:stroke-teal-700"
            />
            <InlineIconButton
              tooltipTitle={row?.has_sub_units ? 'Please remove all sub-units before deletion.' : 'Delete'}
              icon={TrashIcon}
              onClick={() => onDeleteUnitClick(row)}
              className="ml-0 hover:stroke-teal-700"
              disabled={row?.has_sub_units}
            />
          </div>
        );
      },
    },
  ];

  const deactivatedUnitIds = React.useMemo(
    () => units.filter((unit) => !unit.is_active).map((unit) => unit.id),
    [units]
  );

  return (
    <LoadingSwitch isLoading={isLoading} isError={isError}>
      <SortableTable
        columns={columns}
        rows={units}
        defaultOrderColumn={columns.findIndex((column) => column.id === OrganizationUnitsTable.COLUMNS.type)}
        order="desc"
        stickyHeader
        greyedOutRowsIds={deactivatedUnitIds}
      />
    </LoadingSwitch>
  );
};

OrganizationUnitsTable.propTypes = {
  units: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      type: PropTypes.oneOf([ORG_UNIT_TYPE.TOP_UNIT, ORG_UNIT_TYPE.SUB_UNIT]),
      description: PropTypes.string,
      parent_unit: PropTypes.string,
      unit_leader: PropTypes.string,
      members: PropTypes.arrayOf(
        PropTypes.shape({
          member_user_id: PropTypes.string,
          member_user_name: PropTypes.string,
        })
      ),
      last_updated: PropTypes.string,
      is_active: PropTypes.bool,
    })
  ),
  unitsToSubUnitsDict: PropTypes.shape({}),
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  onActionClick: PropTypes.func,
};

const noop = () => {};
OrganizationUnitsTable.defaultProps = {
  units: [],
  unitsToSubUnitsDict: {},
  isLoading: false,
  isError: false,
  onActionClick: noop,
};

OrganizationUnitsTable.COLUMNS = {
  name: 'name',
  type: 'type',
  description: 'description',
  parent_unit_name: 'parent_unit_name',
  child_units: 'child_units',
  leader_user_name: 'leader_user_name',
  members_count: 'members_count',
  last_updated: 'last_updated',
  is_active: 'is_active',
  actions: 'actions',
};
