import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem } from '@material-ui/core';
import { isEmpty } from 'lodash';

import AlertBanner from '~/components/core/AlertBanner';
import Grid from '~/components/core/Atomic/Grid/Grid';
import InnerCard from '~/components/core/Cards/InnerCard';
import CollapsibleWrapper from '~/components/core/Collapsible/CollapsibleWrapper';
import { useLobConfiguration } from '~/components/hooks/useLobConfiguration';

import { LOSS_CAUSE_ISO_CODES } from '../../../../../../../../Types';
import { getLobDescription } from '../../../../../../../../Utils/lobUtils';
import { Caption, Heading, LoadingSwitch, Text } from '../../../../../../../core';
import TextFieldFormik from '../../../../../../../TextFieldFormik';
import { FIELD_IDS } from '../..';
import { useCauseLossCodes } from '../../hooks/useCauseLossCodes';

import { useStyles } from '../../../../../../../../assets/styles';
import styles from '../../claimSearchConfigurationDialog.module.scss';

const NA = {
  key: 'na',
  value: 'N/A',
};

const CauseLossCodes = () => {
  const { isLoading, isError, subTypesDict, supportedLobsAndLobIsoCodes, coverages } = useCauseLossCodes();
  const { lobConfigurationsDict } = useLobConfiguration();

  return (
    <LoadingSwitch isLoading={isLoading} isError={isError}>
      {supportedLobsAndLobIsoCodes.map(({ lob, lob_iso_code: lobCode }) => {
        const lobDescription = getLobDescription(lob, lobConfigurationsDict);

        return (
          <div key={lob}>
            {lobCode ? (
              <div className={styles.colContainer}>
                <Heading variant={Heading.TYPES.H3}>
                  {lobDescription} ({lobCode})
                </Heading>
                <span>
                  {coverages
                    .filter((coverage) => coverage.lob === lob)
                    .map((coverageData) => (
                      <LobCard
                        key={coverageData.coverage_key}
                        lob={lob}
                        lobCode={lobCode}
                        coverageData={coverageData}
                        subTypes={subTypesDict[lob][coverageData.coverage_key]}
                      />
                    ))}
                </span>
              </div>
            ) : (
              <div className={styles.alertBannerContainer}>
                <AlertBanner
                  alertType={AlertBanner.ALERT_TYPES.WARNING}
                  note={`Missing Line of Business ISO code for ${lobDescription}`}
                />
              </div>
            )}
          </div>
        );
      })}
    </LoadingSwitch>
  );
};

const LobCard = ({ coverageData, lob, lobCode, subTypes }) => {
  const coverageKey = coverageData.coverage_key;
  const coverageIsoCode = coverageData.iso_code;

  const lossCauseIsoCodes = LOSS_CAUSE_ISO_CODES[lobCode].coverages[coverageIsoCode];

  return (
    <InnerCard className={styles.subIncidentContainer}>
      <CollapsibleWrapper
        title={`${coverageData.display_name || coverageKey}${coverageIsoCode ? ` (${coverageIsoCode})` : ''}`}
        variant={CollapsibleWrapper.VARIANT.slim}
      >
        <div>
          {!coverageIsoCode ? (
            <Text variant={Text.VARIANTS.SM} weight={Text.WEIGHTS.REGULAR}>
              Missing Coverage ISO Code Configuration
            </Text>
          ) : isEmpty(lossCauseIsoCodes) ? (
            <Text variant={Text.VARIANTS.SM} weight={Text.WEIGHTS.REGULAR}>
              No Available Loss Codes for the Coverage ISO Code
            </Text>
          ) : isEmpty(subTypes) ? (
            <Text variant={Text.VARIANTS.SM} weight={Text.WEIGHTS.REGULAR}>
              Missing Sub Types Configuration
            </Text>
          ) : (
            <>
              <Caption variant={Caption.VARIANTS.LABEL}>Incident subtype</Caption>
              {subTypes.map(({ id, desc }) => (
                <ColEntry
                  key={id}
                  subType={id}
                  desc={desc}
                  lob={lob}
                  lossCauseIsoCodes={lossCauseIsoCodes}
                  coverage={coverageKey}
                />
              ))}
            </>
          )}
        </div>
      </CollapsibleWrapper>
    </InnerCard>
  );
};

LobCard.propTypes = {
  coverageData: PropTypes.shape({
    coverage_key: PropTypes.string,
    display_name: PropTypes.string,
    iso_code: PropTypes.string,
  }),
  lob: PropTypes.string,
  lobCode: PropTypes.string,
  subTypes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      desc: PropTypes.string,
    })
  ),
};

const ColEntry = ({ subType, desc, lob, coverage, lossCauseIsoCodes }) => {
  const classes = useStyles();

  return (
    <Grid container className={styles.colEntryContainer}>
      <Grid item xs={6} className={styles.codeTitle}>
        {desc}
      </Grid>
      <Grid item xs={6}>
        <TextFieldFormik
          id={`${FIELD_IDS.CAUSE_LOSS_CODES.BASE_ID}.${lob}.${coverage}.${subType}.iso`}
          label="CoL code options"
          className={classes.textField}
          select
          fullWidth
        >
          {Object.keys(lossCauseIsoCodes)
            .sort()
            .map((code) => (
              <MenuItem key={code} value={code}>
                {lossCauseIsoCodes[code]} ({code})
              </MenuItem>
            ))
            .concat(
              <MenuItem key={NA.key} value={NA.key}>
                {NA.value} (NOT APPLICABLE)
              </MenuItem>
            )}
        </TextFieldFormik>
      </Grid>
    </Grid>
  );
};

ColEntry.propTypes = {
  subType: PropTypes.string,
  coverage: PropTypes.string,
  desc: PropTypes.string,
  lob: PropTypes.string,
  lossCauseIsoCodes: PropTypes.object,
};

export default CauseLossCodes;
