import React from 'react';
import { Tooltip } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';

import Button from '~/components/core/Atomic/Buttons/Button';
import { CopyFileIcon, EyeIcon, PencilIcon } from '~/components/icons';
import colors from '~/theme/tailwind/colors';

interface GenericTemplateCardButtonsContainerProps {
  handleEditClick: () => void;
  handlePreviewClick: () => void;
  handleCopyClick: () => void;
}
const GenericTemplateCardButtonsContainer: React.FC<GenericTemplateCardButtonsContainerProps> = ({
  handleEditClick,
  handlePreviewClick,
  handleCopyClick,
}) => {
  return (
    <div className="m-10 flex items-center justify-between">
      <div>
        <Button color="primary" disabled={false} onClick={() => handlePreviewClick()}>
          <EyeIcon iconColor={colors.teal['700']} className="mr-5" />
          Generate example
        </Button>
      </div>
      <div>
        <Tooltip title="Edit">
          <IconButton onClick={() => handleEditClick()} className="p-10">
            <PencilIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Duplicate">
          <IconButton onClick={() => handleCopyClick()} className="p-10">
            <CopyFileIcon />
          </IconButton>
        </Tooltip>
      </div>
    </div>
  );
};

export default GenericTemplateCardButtonsContainer;
