import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';

import Grid from '~/components/core/Atomic/Grid/Grid';

import { ROLES_TYPES_DICT } from '../../../../../Types';
import TextFieldFormik from '../../../../TextFieldFormik';

import { useStyles } from '../../../../../assets/styles';

export const RoleSelect = ({ filteredRoles, fullWidth }) => {
  const classes = useStyles();

  return (
    <Grid item xs={fullWidth ? 12 : 6}>
      <TextFieldFormik id="role_id" select label="Role" className={classes.textField} fullWidth>
        {filteredRoles.map(({ id, name, role_type }) => (
          <MenuItem key={id} value={id}>
            {`${name ? `${name} - ` : ''}${ROLES_TYPES_DICT[role_type]['desc']}`}
          </MenuItem>
        ))}
      </TextFieldFormik>
    </Grid>
  );
};

RoleSelect.propTypes = {
  filteredRoles: PropTypes.array.isRequired,
  fullWidth: PropTypes.bool,
};
