import React from 'react';
import PropTypes from 'prop-types';
import { Link, Typography } from '@material-ui/core';

import { PAYMENT_METHOD_CONFIGURATION_DEFAULT_V3 } from '../../../../../Types';
import { PencilIcon } from '../../../../icons';
import InlineIconButton from '../../../../InlineIconButton';

import { useStyles } from '../../../../../assets/styles';
import styles from '../PaymentsConfigurationForm.module.scss';

const PaymentMethodSpecialCell = ({
  orgId,
  subOrgId,
  methodName,
  paymentsMethods,
  handleOpenDialog,
  handleIsNewPaymentMethodConfig,
  isDisabled,
}) => {
  const classes = useStyles();

  const paymentMethod = paymentsMethods.find((method) => {
    if (subOrgId) {
      return method.payment_method === methodName && method.sub_organization_id === subOrgId;
    }
    return method.payment_method === methodName;
  });

  if (!paymentMethod) {
    const newPaymentMethod = {
      ...PAYMENT_METHOD_CONFIGURATION_DEFAULT_V3,
      organization_id: orgId,
      sub_organization_id: subOrgId,
      payment_method: methodName,
      is_disabled: false,
    };

    return (
      <>
        {isDisabled ? (
          <Typography variant="caption">Set</Typography>
        ) : (
          <Link
            onClick={() => {
              handleIsNewPaymentMethodConfig(true);
              handleOpenDialog(newPaymentMethod);
            }}
            className={styles.setLink}
          >
            Set
          </Link>
        )}
      </>
    );
  }

  return (
    <div className={styles.methodEnablementStatusContainer}>
      {paymentMethod.is_disabled ? (
        <Typography className={styles.methodDisabledText}>Disabled</Typography>
      ) : (
        <Typography className={styles.methodEnabledText}>Enabled</Typography>
      )}
      <InlineIconButton
        className={classes.hoverableNonFilledIcon}
        onClick={() => handleOpenDialog(paymentMethod)}
        disabled={isDisabled}
        icon={PencilIcon}
        tooltipTitle="Edit"
      />
    </div>
  );
};

PaymentMethodSpecialCell.propTypes = {
  orgId: PropTypes.number.isRequired,
  subOrgId: PropTypes.number,
  methodName: PropTypes.string.isRequired,
  paymentsMethods: PropTypes.array.isRequired,
  handleOpenDialog: PropTypes.func.isRequired,
  handleIsNewPaymentMethodConfig: PropTypes.func,
  isDisabled: PropTypes.bool,
};

export default PaymentMethodSpecialCell;
