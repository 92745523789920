import React, { useState } from 'react';
import AddIcon from '@material-ui/icons/Add';
import { Chip } from '@mui/material';
import axios from 'axios';

import Grid from '~/components/core/Atomic/Grid/Grid';

import { reportAxiosError } from '../../../Utils';
import CardDialog from '../../CardDialog';
import { FsButton, Heading, LoadingSwitch, Text } from '../../core';
import { PencilIcon } from '../../icons';
import InlineIconButton from '../../InlineIconButton';
import useDataFetcher from '../../useDataFetcher';
import { useSysconfig } from '../SystemConfigurationScreen';

import AuthorizedUsersDialog from './AuthorizedUsersDialog';
import GeneralEmailDialog from './GeneralEmailDialog';

import { useStyles } from '../../../assets/styles';
import styles from './generalEmailConfiguration.module.scss';

const GeneralEmailConfig = () => {
  const { organization, reloadOperationalDetails, organizationOperationalDetails } = useSysconfig();
  const classes = useStyles();

  const generalEmailClassificationConfigurationsUrl = `/sysconfig/api/v1/organizations/${organization.id}/general_email_classification_configuration`;

  const emailDomains = organizationOperationalDetails.emailDomains;

  const {
    isLoading: isLoadingConfiguration,
    isError: isErrorConfiguration,
    data: generalEmailClassificationConfigurationsAndUsers,
    reloadData: reloadGeneralEmailClassificationConfigurationsAndUsers,
  } = useDataFetcher(generalEmailClassificationConfigurationsUrl);

  const generalEmailClassificationConfigurations =
    generalEmailClassificationConfigurationsAndUsers?.general_email_classification_configurations;
  const specialUsers = generalEmailClassificationConfigurationsAndUsers?.special_users;

  const [isAddGeneralQueueDialogueOpen, setAddGeneralQueueDialogOpen] = useState(false);
  const [emailDomainToAddGQ, setEmailDomainToAddGQ] = useState(null);
  const [isUserDialogOpen, setUserDialogOpen] = useState(false);

  const emailDomainIdsFromGQConfigs =
    generalEmailClassificationConfigurations?.map((config) => config.email_domain_id) || [];

  const unusedEmailDomains = emailDomains?.filter(
    (emailDomain) => !emailDomainIdsFromGQConfigs.includes(emailDomain.id)
  );

  const handleOpenAddGeneralQueueDialog = (emailDomain) => {
    setAddGeneralQueueDialogOpen(true);
    setEmailDomainToAddGQ(emailDomain);
  };

  const handleCloseAddGeneralQueueDialog = () => {
    setAddGeneralQueueDialogOpen(false);
    setEmailDomainToAddGQ(null);
  };

  const handleSubmitAddGeneralQueue = async (email_address, email_domain_id) => {
    try {
      const payload = { general_email: email_address, email_domain_id, is_enabled: true };
      await axios.post(generalEmailClassificationConfigurationsUrl, payload);
      await reloadGeneralEmailClassificationConfigurationsAndUsers();
      await reloadOperationalDetails();
      setAddGeneralQueueDialogOpen(false);
      setEmailDomainToAddGQ(null);
    } catch (error) {
      reportAxiosError(error);
      throw error;
    }
  };

  const handleOpenUserDialog = () => {
    setUserDialogOpen(true);
  };

  const handleCloseUserDialog = () => {
    setUserDialogOpen(false);
  };

  const handleUpdateSpecialUsers = async ({ user_ids }) => {
    try {
      const payload = { user_ids };
      await axios.post(`${generalEmailClassificationConfigurationsUrl}/users`, payload);
      await reloadGeneralEmailClassificationConfigurationsAndUsers();
      handleCloseUserDialog();
    } catch (error) {
      reportAxiosError(error);
      throw error;
    }
  };

  return (
    <LoadingSwitch isError={isErrorConfiguration} isLoading={isLoadingConfiguration}>
      <CardDialog noCardTitle>
        <CardDialog title="General Email Classification">
          {generalEmailClassificationConfigurations?.length > 0 && (
            <div className={styles.sectionContainer}>
              <Heading variant={Heading.TYPES.H3}>Email Address</Heading>
              {generalEmailClassificationConfigurations.map((config) => (
                <div key={config.id} className={styles.domainsContainer}>
                  <Text colorVariant={Text.COLOR_VARIANTS.SECONDARY} variant={Text.VARIANTS.SM}>
                    {config.organization_email}
                  </Text>
                </div>
              ))}
            </div>
          )}
          {unusedEmailDomains?.length > 0 && (
            <div className={styles.sectionContainer}>
              <Heading variant={Heading.TYPES.H3}>
                {unusedEmailDomains?.length === 1 ? 'Not implemented domain' : 'Other Domains'}
              </Heading>
              {unusedEmailDomains.map((unusedEmailDomain) => (
                <Grid container key={unusedEmailDomain.id} className={styles.domainsContainer}>
                  <Grid item xs={2}>
                    <Text colorVariant={Text.COLOR_VARIANTS.SECONDARY} variant={Text.VARIANTS.SM}>
                      @{unusedEmailDomain.domain}
                    </Text>
                  </Grid>
                  <Grid item xs={2}>
                    <FsButton color="primary" onClick={() => handleOpenAddGeneralQueueDialog(unusedEmailDomain)}>
                      <AddIcon className={classes.leftButtonIcon} />
                      Add general email queue
                    </FsButton>
                  </Grid>
                </Grid>
              ))}
            </div>
          )}
          <div className={styles.sectionContainer}>
            <div className={styles.usersContainer}>
              <Heading variant={Heading.TYPES.H3}>Set Authorized Editing Users</Heading>
              <InlineIconButton className={classes.inlineEditIcon} icon={PencilIcon} onClick={handleOpenUserDialog} />
            </div>
            <div>
              {specialUsers?.map((user) => (
                <Chip size="small" label={user.username} className={classes.chip} key={user.id} />
              ))}
            </div>
          </div>
          {isAddGeneralQueueDialogueOpen && (
            <GeneralEmailDialog
              emailDomain={emailDomainToAddGQ}
              onSubmit={handleSubmitAddGeneralQueue}
              onCancel={handleCloseAddGeneralQueueDialog}
            />
          )}
          {isUserDialogOpen && (
            <AuthorizedUsersDialog
              onCancel={handleCloseUserDialog}
              onSubmit={handleUpdateSpecialUsers}
              existingUsers={specialUsers}
            />
          )}
        </CardDialog>
      </CardDialog>
    </LoadingSwitch>
  );
};

export default GeneralEmailConfig;
