import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem } from '@material-ui/core';
import { useFormikContext } from 'formik';

import { Card } from '~/components/core/Cards/Card';
import {
  getSubordinatesRecursive,
  isUserLeaderOfTwoParentUnits,
} from '~/components/SystemConfiguration/Users/OrganizationUnits/Utils';

import { Text, TooltipIcon } from '../../../../../core';
import WarningCircleIcon from '../../../../../icons/WarningCircle';
import TextFieldFormik from '../../../../../TextFieldFormik';
import { CHOOSING_NON_AFFILIATED_USER_AS_A_LEADER, DIALOG_MODE, ORG_UNIT_TYPE } from '../../consts';

function getAllowedLeadersForTopUnit({ unitId, members, unitsDict, usersDict }) {
  const subordinates = getSubordinatesRecursive({ unitId, unitsDict });
  const membersToFilterSet = new Set(subordinates.map((subordinate) => subordinate.member_user_id));
  members.forEach((memberId) => membersToFilterSet.add(memberId));
  // return all users that are not subordinates
  return Object.values(usersDict)
    .filter((user) => !membersToFilterSet.has(user.id))
    .sort((a, b) => a.username.localeCompare(b.username));
}

function getAllowedLeaderForSubUnit({ parentUnitId, leaderUserId, unitsDict, nonAffiliatedUserIdsSet, usersDict }) {
  const parentUnit = unitsDict[parentUnitId];
  if (!parentUnit) return [];

  const parentUnitMemberIds = parentUnit.members.map((member) => member.member_user_id);
  const allowedUserIdsSet = new Set([...parentUnitMemberIds, ...nonAffiliatedUserIdsSet]);

  // add the parent unit leader as an option,
  // but make sure that this leader is not also the leader of the parent of parent unit (its limited to 2 levels)
  if (!isUserLeaderOfTwoParentUnits({ userId: parentUnit.leader_user_id, parentUnitId: parentUnit.id, unitsDict })) {
    allowedUserIdsSet.add(parentUnit.leader_user_id);
  }

  // add the current unit leader as an option (to prevent empty option in edit mode in some cases)
  if (leaderUserId) {
    allowedUserIdsSet.add(leaderUserId);
  }

  return [...allowedUserIdsSet].map((userId) => usersDict[userId]).sort((a, b) => a.username.localeCompare(b.username));
}

export const UnitLeaderCard = ({ unitsDict, usersDict, memberToUnitDict, leaderToUnitsDict, dialogMode }) => {
  const { values } = useFormikContext();
  const { id: unitId, parent_unit_id, leader_user_id, members, unit_type } = values;
  const isDeactivateMode = dialogMode === DIALOG_MODE.DEACTIVATE;

  const isTopUnit = React.useMemo(() => unit_type === ORG_UNIT_TYPE.TOP_UNIT, [unit_type]);

  const nonAffiliatedUserIdsSet = React.useMemo(
    () => new Set(Object.keys(usersDict).filter((userId) => !leaderToUnitsDict[userId] && !memberToUnitDict[userId])),
    [usersDict, leaderToUnitsDict, memberToUnitDict]
  );

  const isSelectedLeaderIsNonAffiliated = React.useMemo(() => {
    const leaderUserId = leader_user_id;
    if (!leaderUserId) return false;

    return nonAffiliatedUserIdsSet.has(leaderUserId);
  }, [leader_user_id, nonAffiliatedUserIdsSet]);

  const allowedUnitLeaders = React.useMemo(() => {
    return isTopUnit
      ? getAllowedLeadersForTopUnit({ unitId, members, unitsDict, usersDict })
      : getAllowedLeaderForSubUnit({
          parentUnitId: parent_unit_id,
          leaderUserId: leader_user_id,
          unitsDict,
          nonAffiliatedUserIdsSet,
          usersDict,
        });
  }, [usersDict, unitsDict, isTopUnit, nonAffiliatedUserIdsSet, parent_unit_id, unitId, members, leader_user_id]);

  return (
    <Card cardType={Card.CARD_TYPES.PRIMARY} title="Unit Leader">
      <div className="flex flex-col gap-20">
        {unit_type === ORG_UNIT_TYPE.SUB_UNIT && (
          <Text variant={Text.VARIANTS.SM} weight={Text.WEIGHTS.REGULAR}>
            The unit leader must be a member of the parent unit selected above or a user that is not affiliated with any
            unit.
          </Text>
        )}
        <div className="flex items-end gap-20">
          <TextFieldFormik id="leader_user_id" label="Unit Leader" fullWidth select disabled={isDeactivateMode}>
            {allowedUnitLeaders.map((user) => (
              <MenuItem key={user.id} value={user.id}>
                {user.username}
              </MenuItem>
            ))}
          </TextFieldFormik>

          {isSelectedLeaderIsNonAffiliated && !isTopUnit && (
            <TooltipIcon title={CHOOSING_NON_AFFILIATED_USER_AS_A_LEADER}>
              <WarningCircleIcon className="h-24 w-24" />
            </TooltipIcon>
          )}
        </div>
      </div>
    </Card>
  );
};

UnitLeaderCard.propTypes = {
  unitType: PropTypes.oneOf(Object.values(ORG_UNIT_TYPE)),
  unitsDict: PropTypes.shape({}),
  usersDict: PropTypes.shape({}),
  memberToUnitDict: PropTypes.shape({}),
  leaderToUnitsDict: PropTypes.shape({}),
  dialogMode: PropTypes.oneOf(Object.values(DIALOG_MODE)).isRequired,
};

UnitLeaderCard.deafultProps = {
  unitType: ORG_UNIT_TYPE.TOP_UNIT,
  unitsDict: {},
  usersDict: {},
  memberToUnitDict: {},
  leaderToUnitsDict: {},
};
