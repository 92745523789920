import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, IconButton, Typography } from '@material-ui/core';
import axios from 'axios';

import { EyeIcon } from '~/components/icons';

import { reportAxiosError } from '../../../../../../../../Utils';
import { useSysconfig } from '../../../../../../SystemConfigurationScreen';
import { getSecret } from '../../routes';

import { colorPalette, useStyles } from '../../../../../../../../assets/styles';
import styles from '../../serviceAccount.module.scss';

const SECRET_LENGTH = 70;

const Secret = ({ serviceAccountId }) => {
  const classes = useStyles();
  const { organization } = useSysconfig();
  const [isSecretLoading, setSecretLoading] = useState(false);
  const [isSecretDisplay, setSecretDisplay] = useState(false);
  const secretRef = useRef();

  const secretText = isSecretDisplay ? secretRef.current : '• '.repeat(SECRET_LENGTH);

  const onGetSecret = async () => {
    try {
      if (!secretRef.current) {
        setSecretLoading(true);

        const { data } = await axios.get(getSecret(organization, serviceAccountId));
        secretRef.current = data;
      }
      setSecretLoading(false);
      setSecretDisplay(!isSecretDisplay);

      return secretRef.current;
    } catch (error) {
      await reportAxiosError(error);
    }
    setSecretLoading(false);
  };

  return (
    <div className={styles.secretContainer}>
      {isSecretLoading ? (
        <CircularProgress size={20} className={classes.inlineEditIcon} />
      ) : (
        <>
          <Typography variant="body1">{secretText}</Typography>
          <IconButton className={classes.tableActionsIcon} onClick={onGetSecret}>
            <EyeIcon iconColor={isSecretDisplay && colorPalette.status.canceled} />
          </IconButton>
        </>
      )}
    </div>
  );
};

Secret.propTypes = {
  serviceAccountId: PropTypes.number,
};

export default Secret;
