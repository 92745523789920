import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import AddIcon from '@material-ui/icons/Add';
import axios from 'axios';
import { getIn, useFormikContext } from 'formik';
import _ from 'lodash';

import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';

import { reportAxiosError } from '../../../../Utils';
import { Heading } from '../../../core';
import useFormikChangeListener from '../../../core/Formik/FormikChangeListener';
import { useSysconfig } from '../../SystemConfigurationScreen';
import { FIELD_IDS } from '../Exposures/AddOrEditRuleDialog';

import Condition from './Condition';

import styles from './automaticConditions.module.scss';

const CONDITION_FIELD_IDS = {
  ID: 'id',
  CONDITION_KEY: 'condition_key',
  OPERATOR_1: 'operator_1',
  OPERATOR_2: 'operator_2',
  VALUE_1: 'value_1',
  VALUE_2: 'value_2',
};

const Conditions = ({ subOrganizationIds, lob, coverage, conditionsFieldId, disabled, setIsLoading }) => {
  const { organization } = useSysconfig();
  const [conditionOptions, setConditionOptions] = useState([]);
  const { setFieldValue, values } = useFormikContext();

  const conditions = getIn(values, conditionsFieldId);
  const currentConditionKeys = conditions.map((c) => c[CONDITION_FIELD_IDS.CONDITION_KEY]);

  const updateOptions = useCallback(
    (newOptions) => {
      setConditionOptions(newOptions);

      const currentValues = getIn(values, conditionsFieldId);
      const newOptionKeys = newOptions.map((o) => o.key);

      const intersection = currentValues.filter(
        (condition) =>
          newOptionKeys.includes(condition[CONDITION_FIELD_IDS.CONDITION_KEY]) ||
          !condition[CONDITION_FIELD_IDS.CONDITION_KEY]
      );

      if (!_.isEqual(currentValues, intersection)) {
        setFieldValue(conditionsFieldId, intersection);
      }
    },
    [conditionsFieldId, setFieldValue, values]
  );

  const loadConditions = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(`/api/v1/automatic_exposures/${organization.id}/condition_options`, {
        params: {
          sub_organization_ids: subOrganizationIds,
          lob,
          coverage,
        },
      });
      updateOptions(response.data);
    } catch (error) {
      await reportAxiosError(error);
    } finally {
      setIsLoading(false);
    }
  }, [setIsLoading, organization.id, subOrganizationIds, lob, coverage, updateOptions]);

  useFormikChangeListener({
    listenForKeys: [FIELD_IDS.COVERAGE_KEY],
    onChange: async () => {
      if (coverage) {
        await loadConditions();
      } else {
        updateOptions([]);
      }
    },
    runOnFirstRender: true,
  });

  const addCondition = () => {
    setFieldValue(conditionsFieldId, [
      ...conditions,
      {
        [CONDITION_FIELD_IDS.CONDITION_KEY]: '',
        [CONDITION_FIELD_IDS.OPERATOR_1]: '',
        [CONDITION_FIELD_IDS.OPERATOR_2]: '',
        [CONDITION_FIELD_IDS.VALUE_1]: '',
        [CONDITION_FIELD_IDS.VALUE_2]: '',
      },
    ]);
  };

  const removeCondition = (index) => {
    setFieldValue(conditionsFieldId, [
      ...conditions.slice(0, index),
      ...conditions.slice(index + 1, conditions.length),
    ]);
  };

  const availableConditionOptions = (index) => {
    const indexCondition = getIn(values, `${conditionsFieldId}[${index}][${CONDITION_FIELD_IDS.CONDITION_KEY}]`);
    return conditionOptions.filter(
      (option) => !currentConditionKeys.includes(option.key) || option.key === indexCondition
    );
  };

  return (
    <Grid className={`${styles.automaticConditions} ${styles.automaticRulesConfiguration}`} container spacing={2}>
      <Grid item xs={12}>
        <Heading variant={Heading.TYPES.H4} className={styles.sectionHeader}>
          Conditions
        </Heading>
        <Grid container spacing={4}>
          {(!_.isEmpty(conditionOptions) || (!_.isEmpty(conditions) && !disabled)) &&
            conditions.map((condition, index) => (
              <Grid key={`condition_${index}`} item xs={12}>
                <Condition
                  conditionFieldId={`${conditionsFieldId}[${index}]`}
                  title={`Condition #${index + 1}`}
                  conditionOptions={availableConditionOptions(index)}
                  disabled={disabled}
                  onRemoveCondition={() => removeCondition(index)}
                />
              </Grid>
            ))}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Button
          color="primary"
          onClick={addCondition}
          disabled={disabled || _.isEmpty(conditionOptions) || conditions.length === conditionOptions.length}
        >
          <AddIcon />
          Add Condition
        </Button>
      </Grid>
    </Grid>
  );
};

Conditions.propTypes = {
  subOrganizationIds: PropTypes.array,
  lob: PropTypes.string,
  coverage: PropTypes.string.isRequired,
  conditionsFieldId: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  setIsLoading: PropTypes.func.isRequired,
};

export default Conditions;
export { CONDITION_FIELD_IDS };
