import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { get } from 'lodash';

import Grid from '~/components/core/Atomic/Grid/Grid';
import SwitchFormik from '~/components/core/Formik/SwitchFormik';
import { useLobConfiguration } from '~/components/hooks/useLobConfiguration';
import cn from '~/Utils/cn';

import { LOB_ISO_CODES } from '../../../../Types';
import { isLocaleRegionIsUs } from '../../../../Utils';
import AutocompleteFormik from '../../../AutocompleteFormik';
import CardDialog from '../../../CardDialog';
import CheckboxFormik from '../../../CheckboxFormik';
import { ErrorHelperTextFormik } from '../../../core/Formik/ErrorHelperTextFormik';
import useFormikChangeListener from '../../../core/Formik/FormikChangeListener';
import useOrganization from '../../../OrganizationContext';
import { useSysconfig } from '../../../SystemConfiguration/SystemConfigurationScreen';
import TextFieldFormik from '../../../TextFieldFormik';

import { useStyles } from '../../../../assets/styles';
import wizardStyles from '../../WizardUtils/WizardUtils.module.scss';
import styles from './SubOrganizationStep.module.scss';

const SubOrganizationStepInner = ({ subOrganization }) => {
  const { claimNumberingScheme } = useOrganization();
  const { values, isSubmitting, setFieldValue } = useFormikContext();
  const classes = useStyles();
  const { lobConfigurationsDict } = useLobConfiguration();

  const isClaimsMadeSupported = get(lobConfigurationsDict, [values?.lob?.name, 'is_claims_made_supported'], false);

  useFormikChangeListener({
    listenForKeys: [`sub_orgs_with_claim_identifiers[${subOrganization.id}].is_enabled`],
    onChange: (newValues, _) => {
      const lob = get(newValues, 'lob.name');
      const isEnabled = get(newValues, `sub_orgs_with_claim_identifiers[${subOrganization.id}].is_enabled`);
      const isClaimsMadeSupportedLob = get(lobConfigurationsDict, [lob, 'is_claims_made_supported'], false);

      if (isEnabled) {
        setFieldValue(
          `sub_orgs_with_claim_identifiers[${subOrganization.id}].is_claims_made`,
          isClaimsMadeSupportedLob
        );
      }
    },
    runOnFirstRender: true,
  });

  return (
    <Grid container key={subOrganization.id} className={styles.subOrgRow} spacing={3}>
      <Grid item xs={2}>
        <SwitchFormik
          className={classes.formsSwitch}
          id={`sub_orgs_with_claim_identifiers[${subOrganization.id}].is_enabled`}
          label={subOrganization.name}
          showOnly={isSubmitting}
          withShowOnlyText
        />
      </Grid>
      {claimNumberingScheme.lob_prefix_toggle && (
        <Grid item xs={3}>
          <TextFieldFormik
            id={`sub_orgs_with_claim_identifiers[${subOrganization.id}].claim_identifier`}
            fullWidth
            className={classes.textFieldRow}
            disabled={isSubmitting || !values.sub_orgs_with_claim_identifiers[subOrganization.id].is_enabled}
            label="Line of Business specific Prefix"
          />
        </Grid>
      )}
      {isLocaleRegionIsUs() ? (
        <Grid item xs={3}>
          <AutocompleteFormik
            id={`sub_orgs_with_claim_identifiers[${subOrganization.id}].lob_iso_code`}
            label="Line of Business ISO code"
            options={Object.keys(LOB_ISO_CODES)}
            getOptionLabel={(option) => LOB_ISO_CODES[option]}
            sortAlphabetic
            disabled={isSubmitting || !values.sub_orgs_with_claim_identifiers[subOrganization.id].is_enabled}
            className={classes.textFieldRow}
            fullWidth
          />
        </Grid>
      ) : null}
      {isClaimsMadeSupported && (
        <Grid item xs={3}>
          <CheckboxFormik
            id={`sub_orgs_with_claim_identifiers[${subOrganization.id}].is_claims_made`}
            label="Claim Made Policy"
            disabled={
              isSubmitting ||
              !values.sub_orgs_with_claim_identifiers[subOrganization.id].is_enabled ||
              !isClaimsMadeSupported
            }
            fullWidth
          />
        </Grid>
      )}
    </Grid>
  );
};

SubOrganizationStepInner.propTypes = {
  subOrganization: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};

const SubOrganizationStep = () => {
  const { organization } = useSysconfig();

  return (
    <div className={wizardStyles.cardContainer}>
      <CardDialog title="Sub-Organizations">
        <div>
          <Typography variant="body1" className={cn(styles.headerNote, styles.grey)}>
            Select which sub organizations support the new line of business
          </Typography>
          <Typography variant="body1" className={cn(styles.headerNote, styles.grey)}>
            According to your claim numbering scheme please define the line of business prefix for each sub
            organization.
          </Typography>
        </div>
        <div className={styles.content}>
          <Typography variant="subtitle2">Sub Organization Name</Typography>
          {organization.sub_organizations.map((subOrganization) => (
            <SubOrganizationStepInner key={subOrganization.id} subOrganization={subOrganization} />
          ))}
        </div>
        <Grid container direction="row" alignItems="center">
          <Grid item xs={3} />
          <Grid item xs={6}>
            <ErrorHelperTextFormik
              id="sub_orgs_with_claim_identifiers"
              style={{ textAlign: 'center' }}
              withoutChildren
            />
          </Grid>
          <Grid item xs={3} />
        </Grid>
      </CardDialog>
    </div>
  );
};

export default SubOrganizationStep;
