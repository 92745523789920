import React, { useEffect } from 'react';
import { MenuItem } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { get } from 'lodash';

import { useStyles } from '~/assets/styles';
import Grid from '~/components/core/Atomic/Grid/Grid';
import { useLobConfiguration } from '~/components/hooks/useLobConfiguration';
import TextFieldFormik from '~/components/TextFieldFormik';

import { getLobDescription } from '../../../../../Utils/getLobDescription';
import useOrganization from '../../../../OrganizationContext';
import copies from '../copies.json';
import FieldConfigurationCard from '../FieldConfigurationCard/FieldConfigurationCard';
import ShowRequiredPanel from '../ShowRequiredPanel/ShowRequiredPanel';

import BaseConfigurationSection from './BaseConfigurationSection';

const ID_PREFIX = 'configuration.fnol';

const GeneralDetailsSection = () => {
  const classes = useStyles();
  const { values, setFieldValue, initialValues } = useFormikContext();
  const { lobConfigurationsDict } = useLobConfiguration();
  const { subOrganizationEnabled, subOrganizations } = useOrganization();
  const instructionsExist = get(initialValues, `${ID_PREFIX}.instructions.desc`, undefined) !== undefined;

  // Set default value for instructions - only on first render
  useEffect(() => {
    if (!instructionsExist) {
      setFieldValue(`${ID_PREFIX}.instructions.desc`, copies.default_instructions);
      setFieldValue(`${ID_PREFIX}.instructions.active`, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BaseConfigurationSection title="General Details" subtitle={<div className="p-10" />} isSection>
      <Grid item xs={12}>
        <TextFieldFormik id="display_name" label="Name" fullWidth />
      </Grid>
      {subOrganizationEnabled && (
        <Grid item xs={6}>
          <TextFieldFormik id="sub_organization_id" label="Sub Organization" select disabled fullWidth>
            <MenuItem value={values?.sub_organization_id}>
              {subOrganizations.find((so) => so.id === values?.sub_organization_id)?.name}
            </MenuItem>
          </TextFieldFormik>
        </Grid>
      )}
      <Grid item xs={subOrganizationEnabled ? 6 : 12}>
        <TextFieldFormik id="lob" label="Line of Business" select disabled fullWidth>
          <MenuItem value={values?.lob}>{getLobDescription(values?.lob, lobConfigurationsDict)}</MenuItem>
        </TextFieldFormik>
      </Grid>
      <Grid item xs={12}>
        <TextFieldFormik
          id={`${ID_PREFIX}.instructions.desc`}
          label="Instructions"
          fullWidth
          multiline
          rows={2}
          className={classes.multilineWithoutScroll}
        />
      </Grid>
      <ShowRequiredPanel
        id={`${ID_PREFIX}.fnol_policyholder_contact_id`}
        label={copies.policyholder_details_verification}
      />
      <FieldConfigurationCard idPrefix={ID_PREFIX} fieldId="submission_mode" forceRequired />
      <FieldConfigurationCard idPrefix={ID_PREFIX} fieldId="reported_date" forceRequired />
    </BaseConfigurationSection>
  );
};

export default GeneralDetailsSection;
