import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Switch, Typography } from '@material-ui/core';
import axios from 'axios';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { useStyles } from '~/assets/styles';
import AlertBanner from '~/components/core/AlertBanner';
import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';
import CancelButton from '~/components/core/Buttons/CancelButton';
import SwitchFormik from '~/components/core/Formik/SwitchFormik';
import { INTEGRATION_STATUS_DICT } from '~/Types';
import { reportAxiosError } from '~/Utils';
import cn from '~/Utils/cn';

import CardDialog from '../../../../CardDialog';
import { LoadingSwitch } from '../../../../core';
import TextFieldFormik from '../../../../TextFieldFormik';
import useDataFetcher from '../../../../useDataFetcher';
import { useSysconfig } from '../../../SystemConfigurationScreen';
import { getIsNotActiveOrInProgress } from '../../utils';
import NavigatorOrganizationTableCard from '../NavigatorOrganizationTableCard';
import useCreateSubOrgConfigIfNeeded from '../useCreateSubOrgConfigIfNeeded';

import styles from './internalAdminNavigatorWrapper.module.scss';

const InternalAdminNavigatorWrapper = () => {
  const { organization } = useSysconfig();
  const classes = useStyles();
  const [setupFormOpen, setSetupFormOpen] = useState(false);
  const [isUpdatingReportingStatus, setIsUpdatingReportingStatus] = useState(false);
  const {
    isLoading,
    isError,
    data: navigatorData,
    reloadData: reloadNavigatorData,
  } = useDataFetcher(`/api/v1/navigator/organizations/${organization.id}/setup`);
  const {
    isLoading: isLoadingNavigatorConfigurations,
    isError: isErrorNavigatorConfigurations,
    data: navigatorConfigurations,
    reloadData: reloadConfigurations,
  } = useDataFetcher(`/api/v1/navigator/organizations/${organization.id}/configurations`);

  const isNavigatorEnabled = navigatorData?.enable_navigator_setup;

  const { isLoading: isLoadingNewSubOrgsConfigs, isError: isErrorNewSubOrgsConfigs } = useCreateSubOrgConfigIfNeeded({
    organization,
    existingNavigatorConfiguration: navigatorConfigurations,
    reloadConfigurations,
    isNavigatorEnabled,
  });

  const shouldDisableStartReporting = (navigatorConfiguration) => {
    let pendingButMissingRequiredFields = false;
    if (navigatorConfiguration.navigator_status === 'pending' && navigatorData) {
      const requiredFields = ['authorization_number', 'sftp_password', 'sftp_user'];
      pendingButMissingRequiredFields = !!requiredFields.find((field) => !navigatorData[field]);
    }

    return (
      pendingButMissingRequiredFields ||
      getIsNotActiveOrInProgress(navigatorConfiguration.navigator_status) ||
      isUpdatingReportingStatus
    );
  };

  const handleSetupFormClick = () => {
    setSetupFormOpen(true);
  };

  const handleCloseSetupForm = () => {
    setSetupFormOpen(false);
  };

  const handleSubmitNavigatorSetup = async (values) => {
    try {
      await axios.put(`/api/v1/navigator/organizations/${organization.id}/setup`, values);
      await reloadConfigurations();
      await reloadNavigatorData();
      handleCloseSetupForm();
    } catch (error) {
      await reportAxiosError(error);
      throw Error('Failed while updating Navigator setup');
    }
  };

  const handleReportingToNavigator = async (navigatorConfiguration) => {
    try {
      setIsUpdatingReportingStatus(true);
      await axios.put(
        `/api/v1/navigator/organizations/${organization.id}/configuration/${navigatorConfiguration.id}/start_reporting`
      );
      await reloadConfigurations();
      setIsUpdatingReportingStatus(false);
    } catch (error) {
      await reportAxiosError(error);
    }
  };

  const additionalColumnsArray = [
    {
      id: 'is_sending_active',
      numeric: false,
      label: 'Start reporting to Navigator',
      align: 'right',
      disableSort: true,
      specialCell: (navigatorConfiguration) => {
        return (
          <Switch
            checked={navigatorConfiguration.is_reporting_to_navigator}
            className={classes.formsSwitch}
            size="small"
            onChange={() => handleReportingToNavigator(navigatorConfiguration)}
            disabled={shouldDisableStartReporting(navigatorConfiguration)}
          />
        );
      },
    },
  ];

  return (
    <LoadingSwitch
      isLoading={isLoading || isLoadingNavigatorConfigurations}
      isError={isError || isErrorNavigatorConfigurations}
    >
      <CardDialog noCardTitle containerClassName={styles.internalAdmin}>
        <EnableAndUpdateNavigatorButton
          onSetupFormClick={handleSetupFormClick}
          isNavigatorEnabled={isNavigatorEnabled}
        />
        {setupFormOpen && (
          <NavigatorSetupForm
            handleSubmit={handleSubmitNavigatorSetup}
            handleClose={handleCloseSetupForm}
            navigatorData={navigatorData}
            isReportingToNavigator={navigatorConfigurations.reduce(
              (isReportingToAny, currConfig) =>
                isReportingToAny || currConfig.navigator_status === INTEGRATION_STATUS_DICT.active,
              false
            )}
          />
        )}
        {isNavigatorEnabled &&
          (!navigatorData.authorization_number || !navigatorData.sftp_user || !navigatorData.sftp_password) && (
            <div className={styles.warningContainer}>
              <Grid item xs={4}>
                <AlertBanner
                  alertType={AlertBanner.ALERT_TYPES.ERROR}
                  note="To start reporting, make sure all setup data has been updated"
                  withIcon
                />
              </Grid>
            </div>
          )}
        {isNavigatorEnabled && (
          <LoadingSwitch isLoading={isLoadingNewSubOrgsConfigs} isError={isErrorNewSubOrgsConfigs}>
            <NavigatorOrganizationTableCard
              additionalColumnsArray={additionalColumnsArray}
              navigatorConfigurations={navigatorConfigurations}
            />
          </LoadingSwitch>
        )}
      </CardDialog>
    </LoadingSwitch>
  );
};

const EnableAndUpdateNavigatorButton = ({ onSetupFormClick, isNavigatorEnabled }) => {
  const classes = useStyles();

  if (isNavigatorEnabled) {
    return (
      <Button variant="contained" className={cn(classes.cancelButton, styles.button)} onClick={onSetupFormClick}>
        Update Setup Data
      </Button>
    );
  } else {
    return (
      <Button color="primary" variant="contained" className={styles.button} onClick={onSetupFormClick}>
        Enable Navigator Integration
      </Button>
    );
  }
};

EnableAndUpdateNavigatorButton.propTypes = {
  onSetupFormClick: PropTypes.func.isRequired,
  isNavigatorEnabled: PropTypes.bool,
};

const NavigatorSetupForm = ({ handleSubmit, handleClose, navigatorData, isReportingToNavigator }) => {
  const classes = useStyles();

  const initialValues = {
    enable_navigator_setup: true,
    sftp_user: navigatorData?.sftp_user || '',
    sftp_password: navigatorData?.sftp_password || '',
    authorization_number: navigatorData?.authorization_number || '',
  };

  const getRequiredWhenStartedReportingSchema = (baseSchema) => {
    return isReportingToNavigator ? baseSchema.required('Required') : baseSchema;
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        enable_navigator_setup: Yup.bool().required('Required'),
        sftp_user: getRequiredWhenStartedReportingSchema(Yup.string().nullable()),
        sftp_password: getRequiredWhenStartedReportingSchema(Yup.string().nullable()),
        authorization_number: getRequiredWhenStartedReportingSchema(Yup.string().nullable()),
      })}
      enableReinitialize
      onSubmit={async (values, { setSubmitting }) => {
        try {
          await handleSubmit(values);
        } catch (error) {
          setSubmitting(false);
        }
      }}
    >
      {(formikProps) => {
        const { values, isSubmitting, handleSubmit } = formikProps;

        return (
          <CardDialog
            title="Navigator Integration Setup"
            isDialog
            preventClose={isSubmitting}
            maxWidth="sm"
            fullWidth
            onClose={handleClose}
          >
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography display="block" variant="subtitle1" className={styles.subtitle}>
                  Activate Navigator
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <SwitchFormik
                  checked={values.enable_navigator_setup}
                  className={classes.formsSwitch}
                  id="enable_navigator_setup"
                  label="Enable Navigator Setup"
                  disabled
                />
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography display="block" variant="subtitle1" className={styles.subtitle}>
                  Integration technical data
                </Typography>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextFieldFormik
                    id="authorization_number"
                    label="Authorization Number"
                    disabled={isSubmitting}
                    fullWidth
                    className={classes.textField}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextFieldFormik
                    id="sftp_user"
                    label="SFTP user"
                    disabled={isSubmitting}
                    fullWidth
                    className={classes.textField}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextFieldFormik
                    id="sftp_password"
                    label="SFTP password"
                    disabled={isSubmitting}
                    fullWidth
                    className={classes.textField}
                    type="password"
                  />
                </Grid>
              </Grid>
            </Grid>
            <div className={classes.buttonsContainer}>
              <CancelButton disabled={isSubmitting} onClick={handleClose} />
              <Button variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
                Save
              </Button>
            </div>
          </CardDialog>
        );
      }}
    </Formik>
  );
};

NavigatorSetupForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  navigatorData: PropTypes.object.isRequired,
  isReportingToNavigator: PropTypes.bool.isRequired,
};

export default InternalAdminNavigatorWrapper;
