import React from 'react';
import { capitalize } from 'lodash';

import InnerCard from '~/components/core/Cards/InnerCard';
import { CopyToClipboardIcon } from '~/components/icons';

import CardDialog from '../../../../../CardDialog';
import { FsButton, FsIconButton, SortableTable, Text } from '../../../../../core';
import LoadingIndicator from '../../../../../LoadingIndicator';
import useDataFetcher from '../../../../../useDataFetcher';
import { useSysconfig } from '../../../../SystemConfigurationScreen';
import copies from '../../copies.json';

const getWebhookPath = (organization: { external_id: string }): string => {
  const port = !window.location.port ? '' : `:${window.location.port}`;
  return `https://${window.location.hostname}${port}/webhook/ccc/${organization.external_id}`;
};

const getWebhooksPaths = (organization: { external_id: string }): CccWebhookRow[] => {
  const baseUrl = getWebhookPath(organization);
  return ['documents', 'events', 'estimations'].map((webhookType) => ({
    description: `Incoming ${capitalize(webhookType)} URL`,
    value: `${baseUrl}/${webhookType}`,
    id: webhookType,
  }));
};

interface CccInfoDialogProps {
  onClose: () => void;
  config: { ccc_configuration_id: string };
}

interface CccWebhookRow {
  description: string;
  value: string;
  isLoading?: boolean;
  id: string;
}

const CccInfoDialog: React.FC<CccInfoDialogProps> = ({ onClose, config }) => {
  const { organization } = useSysconfig() as { organization: { external_id: string; id: string } };

  const { isLoading, isError, data } = useDataFetcher(
    `/api/v1/ccc_integration_configuration/${organization.id}/ccc_configuration/${config.ccc_configuration_id}/incoming_credentials`
  );

  const operationalDetails = [
    ...getWebhooksPaths(organization),
    {
      description: 'Incoming Webhook Username',
      value: isLoading ? '...' : isError ? copies.error_message : data.username,
      id: 'username',
      isLoading,
    },
    {
      description: 'Incoming Webhook Password',
      value: isLoading ? '...' : isError ? copies.error_message : data.password,
      id: 'password',
      isLoading,
    },
  ];

  const Actions = (
    <div className="flex items-center justify-end">
      <FsButton variant="contained" color="primary" onClick={onClose}>
        OK
      </FsButton>
    </div>
  );

  return (
    <CardDialog title="Operational Details" isDialog maxWidth="md" fullWidth onClose={onClose} footerActions={Actions}>
      <InnerCard className="bg-transparent">
        <Text variant={Text.VARIANTS.LG} weight={Text.WEIGHTS.SEMI_BOLD} className="mb-10">
          {copies.operational_details_title}
        </Text>
        <Text variant={Text.VARIANTS.SM} weight={Text.WEIGHTS.REGULAR}>
          {copies.default_instructions}
        </Text>
        <SortableTable
          columns={[
            {
              id: 'description',
              numeric: false,
              label: 'Description',
            },
            {
              id: 'value',
              numeric: false,
              label: 'Value',
              specialCell: (item: CccWebhookRow) =>
                !item.isLoading ? (
                  <div>
                    <span>{item.value}</span>
                    <FsIconButton
                      tooltipText="Copy to clipboard"
                      onClick={() => navigator.clipboard.writeText(item.value)}
                    >
                      <CopyToClipboardIcon className="hover:fill-teal-700" />
                    </FsIconButton>
                  </div>
                ) : (
                  <Text variant={Text.VARIANTS.SM}>
                    <LoadingIndicator />
                  </Text>
                ),
            },
          ]}
          hideHeader
          disableSortByUser
          keepRowsOrder
          rows={operationalDetails}
        />
      </InnerCard>
    </CardDialog>
  );
};

export default CccInfoDialog;
