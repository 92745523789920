import React from 'react';
import PropTypes from 'prop-types';
import AddIcon from '@material-ui/icons/Add';
import { FieldArray, useFormikContext } from 'formik';
import { get, isEmpty } from 'lodash';

import { Text } from '~/components/core';
import Button from '~/components/core/Atomic/Buttons/Button';
import { ErrorHelperTextFormik } from '~/components/core/Formik/ErrorHelperTextFormik';
import { TextFieldFormik } from '~/components/TextFieldFormik';
import cn from '~/Utils/cn';

import MoveArrows from '../../MoveArrows/MoveArrows';
import SortAzButton from '../../SortAzButton/SortAzButton';

const getReactKeyFromArgs = (...args) => {
  return args.join('_');
};

const ArrayFieldFormik = ({
  fieldId,
  innerObjectConfig,
  initialNewItemValues,
  label = '',
  disabled,
  withSortAz = true,
  withMoveArrows = true,
  addBtnLabel = 'ADD OPTION',
  columnSize = 10,
}) => {
  const { values, initialValues } = useFormikContext();

  const initialValue = get(initialValues, fieldId);
  const currentValue = get(values, fieldId);

  const getInInitialValues = (index) => {
    const currentValueId = currentValue[index]?.id;
    const initialIds = initialValue?.map((item) => item.id);
    return !isEmpty(currentValueId) && !isEmpty(initialIds) && initialIds?.includes(currentValueId);
  };

  const getFieldByType = (fieldConfig, index, arrayHelpers) => {
    const { id, type, label, disableEditAfterInitialSet, customComponent } = fieldConfig;
    const path = `${fieldId}[${index}].${id}`;
    const args = {
      id: path,
      label,
      onDelete: () => arrayHelpers.remove(index),
      isExistingValue: getInInitialValues(index),
      columnId: id,
    };

    const commonProps = {
      disableEditAfterInitialSet,
    };

    switch (type) {
      case 'text':
        return {
          Comp: TextFieldFormik,
          args: { ...args, fullWidth: true },
          ...commonProps,
        };
      case 'custom':
        return {
          Comp: customComponent,
          args: { ...args },
          ...commonProps,
        };
    }
  };

  return (
    <FieldArray
      name={fieldId}
      render={(arrayHelpers) => (
        <div className="mr-6 flex flex-col gap-20">
          <div className="flex justify-between">
            <Text>{label}</Text>
            {withSortAz && <SortAzButton fieldId={fieldId} sortKey="desc" disabled={disabled} />}
          </div>
          {Array.isArray(currentValue) &&
            currentValue.map((value, i) => (
              <div className={`grid grid-cols-${columnSize} gap-20`} key={getReactKeyFromArgs(fieldId, i)}>
                {innerObjectConfig.map((field, j) => {
                  const { Comp, args, disableEditAfterInitialSet } = getFieldByType(field, i, arrayHelpers);
                  const numberOfCompInRow = innerObjectConfig?.length;

                  return (
                    <div
                      key={getReactKeyFromArgs(fieldId, field.id, i, j)}
                      className={cn({
                        'pl-0': j === 0,
                        'col-span-3':
                          numberOfCompInRow === 3 ? args?.columnId === 'desc' || args?.columnId === 'id' : false,
                        'col-span-2':
                          numberOfCompInRow === 4 ? ['id', 'desc', 'category'].includes(args?.columnId) : false,
                        'col-span-1': args?.columnId === 'active',
                      })}
                    >
                      <Comp {...args} disabled={disabled || (disableEditAfterInitialSet && getInInitialValues(i))} />
                    </div>
                  );
                })}
                {withMoveArrows && (
                  <div className="col-span-1 box-border flex content-between items-center">
                    <MoveArrows
                      onMoveUp={() => arrayHelpers.move(i, Math.max(i - 1, 0))}
                      onMoveDown={() => arrayHelpers.move(i, Math.min(i + 1, currentValue.length - 1))}
                      disabled={disabled}
                    />
                  </div>
                )}
              </div>
            ))}
          <ErrorHelperTextFormik id={fieldId} withoutChildren />
          <div className="flex">
            <Button
              variant="text"
              color="primary"
              onClick={() => arrayHelpers.push(initialNewItemValues)}
              disabled={disabled}
            >
              <AddIcon />
              {addBtnLabel}
            </Button>
          </div>
        </div>
      )}
    />
  );
};

ArrayFieldFormik.propTypes = {
  fieldId: PropTypes.string.isRequired,
  innerObjectConfig: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      type: PropTypes.oneOf(['text', 'switch', 'checkbox', 'date', 'custom']).isRequired,
      additionalProps: PropTypes.object,
      disableEditAfterInitialSet: PropTypes.bool,
      customComponent: PropTypes.element,
    })
  ).isRequired,
  initialNewItemValues: PropTypes.object.isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  withSortAz: PropTypes.bool,
  withMoveArrows: PropTypes.bool,
  addBtnLabel: PropTypes.string,
  columnSize: PropTypes.number,
};

export default ArrayFieldFormik;
