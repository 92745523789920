import React from 'react';
import { MenuItem, Typography } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { get } from 'lodash';

import Grid from '~/components/core/Atomic/Grid/Grid';

import { CLAIM_SEARCH } from '../../../../../../../../Types';
import TextFieldFormik, { NumericTextFieldFormik } from '../../../../../../../TextFieldFormik';
import { FIELD_IDS } from '../..';
import ConfigurationTrigger from '../../TriggerSelection';

import { useStyles } from '../../../../../../../../assets/styles';
import styles from '../../claimSearchConfigurationDialog.module.scss';

const updateTriggerEntries = Object.keys(CLAIM_SEARCH.SUPPORTED_SEARCH_TRIGGERS).map((key) => ({
  id: key,
  text: CLAIM_SEARCH.SUPPORTED_SEARCH_TRIGGERS[key].desc,
}));

const searchPeriodsEntries = Object.keys(CLAIM_SEARCH.SUPPORTED_SCHEDULE_PERIOD).map((key) => ({
  id: key,
  text: CLAIM_SEARCH.SUPPORTED_SCHEDULE_PERIOD[key].desc,
}));

const timeFramesEntries = Object.keys(CLAIM_SEARCH.SCHEDULE_TIME_FRAME).map((key) => ({
  id: key,
  text: CLAIM_SEARCH.SCHEDULE_TIME_FRAME[key].desc,
}));

const UpdateTriggers = () => {
  const classes = useStyles();
  const { values, setFieldTouched, setFieldValue } = useFormikContext();

  const id = FIELD_IDS.UPDATE_REPORTS.SEARCH_TRIGGERS;

  const schedulePeriodValue = get(values, FIELD_IDS.UPDATE_REPORTS.SCHEDULE_PERIOD);

  const scheduleTimeValue = get(values, FIELD_IDS.UPDATE_REPORTS.SCHEDULE_TIME);

  React.useEffect(() => {
    // This is a hack because I couldnt get scheduleTimeValue to update the value directly to null and display an empty input
    if (scheduleTimeValue === '') {
      setFieldValue(FIELD_IDS.UPDATE_REPORTS.SCHEDULE_TIME, null);
    }
  }, [scheduleTimeValue, setFieldValue]);

  return (
    <>
      <div className={styles.updateSearchTriggers}>
        <Typography display="block" variant="subtitle2" className={styles.title}>
          By Schedule
        </Typography>
        <p className={styles.subtitle}>Initiate a new search at the following times</p>
        <Grid container className={styles.gridWrapper} spacing={2}>
          <Grid item xs={6}>
            <TextFieldFormik
              id={FIELD_IDS.UPDATE_REPORTS.SCHEDULE_PERIOD}
              label="Initiate search updates at"
              className={classes.textField}
              fullWidth
              clearable
              onClear={() => {
                setFieldValue(FIELD_IDS.UPDATE_REPORTS.SCHEDULE_PERIOD, null);
                setFieldValue(FIELD_IDS.UPDATE_REPORTS.SCHEDULE_TIME, '');
                setFieldValue(FIELD_IDS.UPDATE_REPORTS.SCHEDULE_TIME_FRAME, null);
                setFieldTouched(FIELD_IDS.UPDATE_REPORTS.SCHEDULE_PERIOD, false);
                setFieldTouched(FIELD_IDS.UPDATE_REPORTS.SCHEDULE_TIME, false);
                setFieldTouched(FIELD_IDS.UPDATE_REPORTS.SCHEDULE_TIME_FRAME, false);
              }}
              select
            >
              {searchPeriodsEntries.map(({ id, text }) => (
                <MenuItem key={id} value={id}>
                  {text}
                </MenuItem>
              ))}
            </TextFieldFormik>
          </Grid>
          <Grid item xs={3}>
            <NumericTextFieldFormik
              id={FIELD_IDS.UPDATE_REPORTS.SCHEDULE_TIME}
              label="Number"
              decimalScale={0}
              className={classes.formTextField}
              fullWidth
              disabled={!schedulePeriodValue}
            />
          </Grid>
          <Grid item xs={3}>
            <TextFieldFormik
              id={FIELD_IDS.UPDATE_REPORTS.SCHEDULE_TIME_FRAME}
              label="Time Frame"
              className={classes.textField}
              fullWidth
              select
              disabled={!schedulePeriodValue}
            >
              {timeFramesEntries.map(({ id, text }) => (
                <MenuItem key={id} value={id}>
                  {text}
                </MenuItem>
              ))}
            </TextFieldFormik>
          </Grid>
        </Grid>
      </div>
      <div className={styles.updateSearchTriggers}>
        <Typography display="block" variant="subtitle2" className={styles.title}>
          New information
        </Typography>
        <p className={styles.subtitle}>Initiate a new search after the following information is updated:</p>
        <div className={styles.triggersContainer}>
          {updateTriggerEntries.map((option) => (
            <ConfigurationTrigger key={option.id} id={`${id}.${option.id}`} text={option.text} />
          ))}
        </div>
      </div>
    </>
  );
};

export default UpdateTriggers;
