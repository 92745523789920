import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import axios from 'axios';
import { Formik } from 'formik';
import { JsonEditor } from 'jsoneditor-react';
import querystring from 'query-string';
import * as Yup from 'yup';

import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';
import SubOrganizationSelectFormik from '~/components/TPA/SubOrganizations/SubOrganizationSelectFormik';

import { CONFIGURATION_FEATURES_NAMES } from '../../../Types';
import { isFeatureEnabled, reportAxiosError } from '../../../Utils';
import { usePrevious } from '../../TextFieldFormik';
import LobRadioFormik from '../../TPA/LOB/LobRadioFormik';
import { useSysconfig } from '../SystemConfigurationScreen';

import { colorPalette, useStyles } from '../../../assets/styles';

const getBaseIncidentConfigurationsRoute = (organization) =>
  `/sysconfig/api/v1/organizations/${organization.id}/incident_configurations`;

const IncidentConfigurationInner = ({ isSubmitting, handleSubmit, values, setFieldValue, setFieldError, isValid }) => {
  const classes = useStyles();

  const { organization } = useSysconfig();
  const prevRefLob = usePrevious(values.lob);
  const prevRefSuborg = usePrevious(values.sub_organization_id);
  const [errorMessage, setErrorMessage] = useState(null);
  const [displayConfigEditor, setDisplayConfigEditor] = useState(false);

  useEffect(() => {
    async function getIncidentConfiguration() {
      if (prevRefLob === values['lob'] && prevRefSuborg === values['sub_organization_id']) {
        return;
      }

      setDisplayConfigEditor(false);
      setErrorMessage(null);

      if (prevRefSuborg !== values['sub_organization_id']) {
        setFieldValue('lob', '');
        setFieldValue('incident_configuration_json', {});
        setFieldValue('incident_configuration', {});
        return;
      }

      if (!values.lob || (organization.sub_organizations_enabled && !values.sub_organization_id)) {
        return;
      }

      try {
        const sub_organization_id = values['sub_organization_id'];
        const lob = values['lob'];
        const { data: incidentConfigSummaries } = await axios.get(
          `${getBaseIncidentConfigurationsRoute(organization)}/summary`,
          {
            params: {
              lob,
              sub_organization_ids: organization.sub_organizations_enabled ? [sub_organization_id] : undefined,
            },
            paramsSerializer: (params) => querystring.stringify(params, { arrayFormat: 'none' }),
          }
        );

        if (incidentConfigSummaries.length !== 1) {
          setFieldValue('incident_configuration_json', {});
          setFieldValue('incident_configuration', {});
          setErrorMessage('Error while fetching incident configuration');
          return;
        }

        const { data } = await axios.get(
          `${getBaseIncidentConfigurationsRoute(organization)}/${incidentConfigSummaries[0].id}`
        );
        setFieldValue('incident_configuration_json', data.configuration);
        setFieldValue('incident_configuration', data);
        setDisplayConfigEditor(true);
      } catch (error) {
        setFieldValue('incident_configuration_json', {});
        setFieldValue('incident_configuration', {});
        setErrorMessage(error.response.data.message?.replace('Notify User:', ''));
      }
    }

    getIncidentConfiguration();
  }, [values, organization, prevRefLob, prevRefSuborg, setFieldValue]);

  return (
    <Grid container direction="column" style={{ padding: '10px' }}>
      <Grid item xs={6} />
      {organization.sub_organizations_enabled && (
        <>
          <Grid item xs={6} style={{ marginBottom: 24 }}>
            <SubOrganizationSelectFormik fieldId="sub_organization_id" />
          </Grid>
          <Grid item xs={6} />
        </>
      )}
      <Grid item xs={8}>
        <div style={{ marginBottom: 24 }}>
          <Typography
            display="block"
            variant="subtitle2"
            style={{ marginBottom: 10, fontWeight: 'bold', color: '#202020' }}
          >
            Line of Business
          </Typography>
          <LobRadioFormik
            lobFieldId="lob"
            subOrganizationIds={
              organization.sub_organizations_enabled && values.sub_organization_id ? [values.sub_organization_id] : []
            }
          />
        </div>
      </Grid>
      <Grid item xs={4} />
      {displayConfigEditor && (
        <Grid item xs={12}>
          <JsonEditor
            value={values?.incident_configuration_json || {}}
            onChange={(value) => {
              setFieldValue('incident_configuration_json', value);
            }}
            mode="text"
            allowedModes={['text', 'tree']}
            onValidationError={(errors) => {
              if (errors?.length) {
                setFieldError('incident_configuration_json', errors[0].message);
              }
            }}
            htmlElementProps={{
              style: { height: 'calc(100vh - 340px)' },
            }}
          />
        </Grid>
      )}
      {errorMessage && (
        <Typography display="block" style={{ color: colorPalette.error, fontSize: '12px' }}>
          {errorMessage}
        </Typography>
      )}
      <Grid item xs={4}>
        <div className={classes.leftButtonsContainer}>
          <Button
            variant="contained"
            color="primary"
            disabled={isSubmitting || !displayConfigEditor || !isValid}
            onClick={handleSubmit}
          >
            Save
          </Button>
        </div>
      </Grid>
    </Grid>
  );
};

IncidentConfigurationInner.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setFieldError: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
};

const IncidentConfiguration = () => {
  const { organization } = useSysconfig();

  return (
    <>
      {isFeatureEnabled(organization, CONFIGURATION_FEATURES_NAMES.CONFIGURABLE_FNOL) ? (
        <IncidentConfigurationFormik />
      ) : (
        <div style={{ margin: '20px' }}>
          <Typography display="block" variant="subtitle1">
            Configurable FNOL is not enabled for this organization
          </Typography>
        </div>
      )}
    </>
  );
};

const IncidentConfigurationFormik = () => {
  const {
    organization,
    organizationOperationalDetails: { supportedClaimTypes },
  } = useSysconfig();

  return (
    <Formik
      initialValues={{
        sub_organization_id: '',
        lob: '',
        incident_configuration_json: {},
        incident_configuration: {},
      }}
      validationSchema={Yup.object().shape({
        sub_organization_id: Yup.number(),
        lob: Yup.string().required('Required').oneOf(supportedClaimTypes, 'Invalid line of business'),
        incident_configuration_json: Yup.object().required('Required'),
        incident_configuration: Yup.object().required('Required'),
      })}
      enableReinitialize
      onSubmit={async (values, formikProps) => {
        try {
          await axios.patch(`${getBaseIncidentConfigurationsRoute(organization)}/${values.incident_configuration.id}`, {
            ...values.incident_configuration,
            configuration: values.incident_configuration_json,
          });
        } catch (error) {
          await reportAxiosError(error);
          formikProps.setSubmitting(false);
        }
      }}
    >
      {(formikProps) => {
        const { isSubmitting, handleSubmit, values, setFieldValue, setFieldError, isValid } = formikProps;

        return (
          <IncidentConfigurationInner
            isSubmitting={isSubmitting}
            handleSubmit={handleSubmit}
            values={values}
            setFieldValue={setFieldValue}
            setFieldError={setFieldError}
            isValid={isValid}
          />
        );
      }}
    </Formik>
  );
};

export default IncidentConfiguration;
