import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem, Typography } from '@material-ui/core';
import { Formik, useFormikContext } from 'formik';
import { isEmpty, merge, xor } from 'lodash';
import * as Yup from 'yup';

import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';
import CancelButton from '~/components/core/Buttons/CancelButton';
import RadioButtonGroupFormik from '~/components/core/Formik/RadioButtonGroupFormik';
import SubOrgMultiSelectWithChipsFormik from '~/components/core/Formik/SubOrgMultiSelectWithChipsFormik';
import SwitchFormik from '~/components/core/Formik/SwitchFormik';
import TooltipIcon from '~/components/core/TooltipIcon';
import { InfoIcon } from '~/components/icons';

import AdjusterSelectTextFieldFormik from '../../../Adjuster/AdjusterSelectTextFieldFormik';
import { localTimeToViewTime } from '../../../DateTimeUtils';
import { MIXPANEL_EVENTS } from '../../../pocs/mixpanel';
import { CONFIGURATION_FEATURES_NAMES, DATA_EXPORT } from '../../../Types';
import { isFeatureEnabled, reportAxiosError } from '../../../Utils';
import CardDialog from '../../CardDialog';
import CheckboxFormik from '../../CheckboxFormik';
import mixpanel from '../../CmsMain/mixpanel';
import AlertBanner from '../../core/AlertBanner';
import { DatePickerTextFieldFormik, MultiSelectTextFieldFormik, TextFieldFormik } from '../../TextFieldFormik';
import { useSysconfig } from '../SystemConfigurationScreen';

import { ATTENTION_NOTE, NON_CLAIM_COMMUNICATION_ATTENTION_NOTE } from './consts.json';

import { useStyles } from '../../../assets/styles';
import styles from './ScheduledExportDialog.module.scss';

const HOURS = [
  '00:00',
  '01:00',
  '02:00',
  '03:00',
  '04:00',
  '05:00',
  '06:00',
  '07:00',
  '08:00',
  '09:00',
  '10:00',
  '11:00',
  '12:00',
  '13:00',
  '14:00',
  '15:00',
  '16:00',
  '17:00',
  '18:00',
  '19:00',
  '20:00',
  '21:00',
  '22:00',
  '23:00',
];
const DEFAULT_SECRET_TEMPLATE = '*****';
const SECRET_JSON_FIELDS = [
  'type',
  'project_id',
  'private_key_id',
  'private_key',
  'client_email',
  'client_id',
  'auth_uri',
  'token_uri',
  'auth_provider_x509_cert_url',
  'client_x509_cert_url',
];

const ScheduledExportDialog = ({ onClose, onSubmit, scheduledExports, rowIDToEdit }) => {
  const { organization } = useSysconfig();
  const scheduleExportToEdit = scheduledExports.find((row) => row.id === rowIDToEdit);

  let initialValues = {
    display_name: '',
    file_name_prefix: '',
    sub_organization_ids: [],
    target_type: 'email',
    user_id: undefined,
    export_format: DATA_EXPORT.EXPORT_FORMAT.csv.key,
    export_hour: '',
    end_date: undefined,
    is_active: true,
    include_non_claim_communications: false,
    export_days: [],
    target_configurations: {
      s3_bucket: {
        path: '',
        bucket_name: '',
        access_key_id: '',
        access_key_secret: '',
      },
      gcs_bucket: {
        path: '',
        bucket_name: '',
        secret_json: '',
      },
      sftp: {
        host: '',
        port: '',
        path: '',
        user: '',
        password: '',
      },
    },
  };

  const serverExportHourToClient = (serverExportHour) => (serverExportHour ? serverExportHour.substring(0, 5) : '');

  if (scheduleExportToEdit) {
    initialValues = merge(initialValues, scheduleExportToEdit);
    initialValues.export_hour = serverExportHourToClient(scheduleExportToEdit.export_hour);
    initialValues.is_end_date = !!scheduleExportToEdit.end_date;
    if (scheduleExportToEdit.is_all_sub_orgs) {
      initialValues.sub_organization_ids = [SubOrgMultiSelectWithChipsFormik.ALL_OPTION.id];
    }
  }

  const getDisplayNameValidation = (displayName) => {
    const displayNameExists = scheduledExports.map((item) => item.display_name).includes(displayName);
    let isValid = false;

    if (scheduleExportToEdit) {
      isValid = scheduleExportToEdit.display_name === displayName || !displayNameExists;
    } else {
      isValid = !displayNameExists;
    }
    return isValid;
  };

  const getSecretJsonValidation = (secretJsonStr) => {
    try {
      if (secretJsonStr === DEFAULT_SECRET_TEMPLATE) {
        return true;
      }

      const secretJsonKeys = Object.keys(JSON.parse(secretJsonStr));
      return isEmpty(xor(secretJsonKeys, SECRET_JSON_FIELDS));
    } catch {
      return false;
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        display_name: Yup.string()
          .required('Required')
          .test('is_display_name_unique', 'Subscription name must by unique', getDisplayNameValidation),
        sub_organization_ids: Yup.array().test('sub_orgs_ids', 'Required', (value) => {
          return organization.sub_organizations_enabled ? value?.length > 0 : true;
        }),
        target_type: Yup.string().oneOf(Object.keys(DATA_EXPORT.TARGET_TYPE)).required('Required'),
        user_id: Yup.string()
          .nullable()
          .when('target_type', { is: 'email', then: Yup.string().required('Required') }),
        target_configurations: Yup.object()
          .when('target_type', {
            is: 's3_bucket',
            then: Yup.object().shape({
              s3_bucket: Yup.object().shape({
                path: Yup.string().required('Required'),
                bucket_name: Yup.string().required('Required'),
                access_key_id: Yup.string().required('Required'),
                access_key_secret: Yup.string().required('Required'),
              }),
            }),
          })
          .when('target_type', {
            is: 'gcs_bucket',
            then: Yup.object().shape({
              gcs_bucket: Yup.object().shape({
                path: Yup.string().required('Required'),
                bucket_name: Yup.string().required('Required'),
                secret_json: Yup.string().test(
                  'is_secret_json_valid',
                  'Invalid secret json format',
                  getSecretJsonValidation
                ),
              }),
            }),
          })
          .when('target_type', {
            is: 'sftp',
            then: Yup.object().shape({
              sftp: Yup.object().shape({
                host: Yup.string().required('Required'),
                port: Yup.string().required('Required'),
                path: Yup.string().required('Required'),
                user: Yup.string().required('Required'),
                password: Yup.string().required('Required'),
              }),
            }),
          }),
        export_format: Yup.string().oneOf(Object.keys(DATA_EXPORT.EXPORT_FORMAT)).required('Required'),
        export_days: Yup.array().required('Required').min(1, 'Required'),
        export_hour: Yup.string().required('Required'),
        end_date: Yup.string()
          .nullable()
          .when('is_end_date', { is: true, then: Yup.string().required('Required') }),
        is_active: Yup.boolean().required('Required'),
      })}
      enableReinitialize
      onSubmit={async (values) => {
        try {
          const target_type = values.target_type;
          if (target_type === 'email') {
            values.target_configurations = {};
          } else {
            values.user_id = undefined;
            const chosen_configuration = values.target_configurations[target_type];
            values.target_configurations = {};
            values.target_configurations[target_type] = chosen_configuration;
          }
          if (!values.is_end_date) {
            values.end_date = undefined;
          }
          values.export_days.sort();

          if (values?.sub_organization_ids?.includes(SubOrgMultiSelectWithChipsFormik.ALL_OPTION.id)) {
            values.sub_organization_ids = [];
            values.is_all_sub_orgs = true;
          }

          await onSubmit(values);
          onClose();
          mixpanel.track(
            scheduleExportToEdit
              ? MIXPANEL_EVENTS.DATA_EXPORT_SUBSCRIPTION_RULE_UPDATED
              : MIXPANEL_EVENTS.NEW_DATA_EXPORT_SUBSCRIPTION_RULE_SUBMITTED,
            {
              subscription_name: values.display_name,
              sub_organizations: values.sub_organization_ids,
              target: values.target_type,
              format: values.export_format,
              daily_repeat_on: values.export_days,
              hour: values.export_hour,
              end_date: values.end_date,
              active_subscribe: values.is_active,
            }
          );
        } catch (error) {
          await reportAxiosError(error);
        }
      }}
    >
      <ScheduledExportDialogInner onClose={onClose} scheduleExportToEdit={scheduleExportToEdit} />
    </Formik>
  );
};

ScheduledExportDialog.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  scheduledExports: PropTypes.arrayOf(
    PropTypes.shape({
      display_name: PropTypes.string.isRequired,
    })
  ).isRequired,
  rowIDToEdit: PropTypes.number,
};

const ScheduledExportDialogInner = ({ onClose, scheduleExportToEdit }) => {
  const { organization, users } = useSysconfig();
  const filteredUsers = users.filter((user) => user.role.role_type !== 'system');
  const classes = useStyles();
  const { isSubmitting, handleSubmit, values } = useFormikContext();
  const { target_type } = values;

  const { sub_organizations_enabled } = organization;

  const isAttachNonCommunicationEnabled = isFeatureEnabled(
    organization,
    CONFIGURATION_FEATURES_NAMES.DATA_EXPORT_ATTACH_NON_CLAIM_COMMUNICATION
  );

  const renderFormatSwitch = () => {
    switch (target_type) {
      case 'email':
        return (
          <Grid item sm={6}>
            <AdjusterSelectTextFieldFormik
              id="user_id"
              key="user_id"
              label="Select System User"
              className={classes.textField}
              fullWidth
              disabled={isSubmitting}
              sortAlphabetic
              allUsers={filteredUsers}
            />
          </Grid>
        );
      case 's3_bucket':
        return (
          <Grid container className={styles.subscriptionSettings} direction="row" spacing={4}>
            <Grid item sm={6}>
              <TextFieldFormik
                id="target_configurations.s3_bucket.path"
                key="target_configurations.s3_bucket.path"
                label="Path"
                fullWidth
                className={classes.textField}
                disabled={isSubmitting}
              />
            </Grid>
            <Grid item sm={6}>
              <TextFieldFormik
                id="target_configurations.s3_bucket.bucket_name"
                key="target_configurations.s3_bucket.bucket_name"
                label="Bucket Name"
                fullWidth
                className={classes.textField}
                disabled={isSubmitting}
              />
            </Grid>
            <Grid item sm={6}>
              <TextFieldFormik
                id="target_configurations.s3_bucket.access_key_id"
                key="target_configurations.s3_bucket.access_key_id"
                label="Access key id"
                fullWidth
                type="password"
                className={classes.textField}
                disabled={isSubmitting}
              />
            </Grid>
            <Grid item sm={6}>
              <TextFieldFormik
                id="target_configurations.s3_bucket.access_key_secret"
                key="target_configurations.s3_bucket.access_key_secret"
                label="Access key secret"
                fullWidth
                type="password"
                className={classes.textField}
                disabled={isSubmitting}
              />
            </Grid>
          </Grid>
        );
      case 'gcs_bucket':
        return (
          <Grid container className={styles.subscriptionSettings} direction="row" spacing={5}>
            <Grid item sm={6}>
              <TextFieldFormik
                id="target_configurations.gcs_bucket.path"
                key="target_configurations.gcs_bucket.path"
                label="Path"
                fullWidth
                className={classes.textField}
                disabled={isSubmitting}
              />
            </Grid>
            <Grid item sm={6}>
              <TextFieldFormik
                id="target_configurations.gcs_bucket.bucket_name"
                key="target_configurations.gcs_bucket.bucket_name"
                label="Bucket Name"
                fullWidth
                className={classes.textField}
                disabled={isSubmitting}
              />
            </Grid>
            <Grid item sm={6}>
              <TextFieldFormik
                id="target_configurations.gcs_bucket.secret_json"
                key="target_configurations.gcs_bucket.secret_json"
                label="Secret json"
                fullWidth
                type="password"
                className={classes.textField}
                disabled={isSubmitting}
              />
            </Grid>
          </Grid>
        );
      case 'sftp':
        return (
          <Grid container className={styles.subscriptionSettings} direction="row" spacing={5}>
            <Grid item sm={6}>
              <TextFieldFormik
                id="target_configurations.sftp.host"
                key="target_configurations.sftp.host"
                label="Host"
                fullWidth
                className={classes.textField}
                disabled={isSubmitting}
              />
            </Grid>
            <Grid item sm={6}>
              <TextFieldFormik
                id="target_configurations.sftp.port"
                key="target_configurations.sftp.port"
                label="Port"
                fullWidth
                className={classes.textField}
                disabled={isSubmitting}
              />
            </Grid>
            <Grid item sm={6}>
              <TextFieldFormik
                id="target_configurations.sftp.path"
                key="target_configurations.sftp.path"
                label="Path"
                fullWidth
                className={classes.textField}
                disabled={isSubmitting}
              />
            </Grid>
            <Grid item sm={6}>
              <TextFieldFormik
                id="target_configurations.sftp.user"
                key="target_configurations.sftp.user"
                label="User"
                fullWidth
                className={classes.textField}
                disabled={isSubmitting}
              />
            </Grid>
            <Grid item sm={6}>
              <TextFieldFormik
                id="target_configurations.sftp.password"
                key="target_configurations.sftp.password"
                label="Password"
                fullWidth
                type="password"
                className={classes.textField}
                disabled={isSubmitting}
              />
            </Grid>
          </Grid>
        );
    }
  };

  return (
    <CardDialog
      isDialog
      title={`${scheduleExportToEdit ? 'Edit' : 'Add New'} Subscription Rule`}
      fullWidth
      maxWidth="sm"
      onClose={onClose}
      preventClose={isSubmitting}
    >
      <Grid container direction="column" spacing={4}>
        <Grid item xs={12}>
          <AlertBanner
            title="Please Note"
            note={isAttachNonCommunicationEnabled ? NON_CLAIM_COMMUNICATION_ATTENTION_NOTE : ATTENTION_NOTE}
            trackAlt="Scheduled Export Attention Note"
            alertType={AlertBanner.ALERT_TYPES.INFO}
          />
        </Grid>
        <Grid item>
          <Typography display="block" variant="subtitle1">
            Subscription Settings
          </Typography>
        </Grid>
        <Grid container item className={styles.subscriptionSettings} direction="row" spacing={4}>
          <Grid item xs={6}>
            <TextFieldFormik
              id="display_name"
              label="Subscription Name"
              fullWidth
              className={classes.textField}
              disabled={isSubmitting}
            />
          </Grid>
          <Grid item xs={6}>
            {sub_organizations_enabled && (
              <SubOrgMultiSelectWithChipsFormik
                id="sub_organization_ids"
                label="Sub Organizations"
                showOnly={isSubmitting}
                disabled={isSubmitting}
              />
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ padding: '10px' }}>
          <Typography display="block" variant="subtitle1" className={classes.h4}>
            Target
          </Typography>
          <RadioButtonGroupFormik
            id="target_type"
            direction="row"
            showOnly={isSubmitting}
            noSpacing={true}
            options={[
              {
                text: DATA_EXPORT.TARGET_TYPE.email,
                optionValue: 'email',
              },
              {
                text: DATA_EXPORT.TARGET_TYPE.s3_bucket,
                optionValue: 's3_bucket',
              },
              {
                text: DATA_EXPORT.TARGET_TYPE.sftp,
                optionValue: 'sftp',
              },
              {
                text: DATA_EXPORT.TARGET_TYPE.gcs_bucket,
                optionValue: 'gcs_bucket',
              },
            ]}
          />
        </Grid>
        <Grid item xs={12}>
          {renderFormatSwitch()}
        </Grid>
        <Grid item xs={12}>
          <Typography display="block" variant="subtitle1" className={classes.h4}>
            Format
          </Typography>
          <RadioButtonGroupFormik
            id="export_format"
            direction="row"
            showOnly={isSubmitting}
            noSpacing={true}
            options={
              Object.keys(DATA_EXPORT.EXPORT_FORMAT).map((key) => ({
                text: DATA_EXPORT.EXPORT_FORMAT[key].desc,
                optionValue: DATA_EXPORT.EXPORT_FORMAT[key].key,
              })) || []
            }
          />
        </Grid>
        <Grid item xs={12}>
          <CardDialog containerClassName={styles.greyNote} noCardTitle trackAlt="Schedule Details">
            <Typography display="block" style={{ marginBottom: '20px' }} variant="subtitle1">
              Schedule Details
            </Typography>
            <Typography display="block" variant="subtitle2">
              Daily Repeat On
            </Typography>
            <Grid item xs={6}>
              {/* Todo make this a grouped multiselect checkbox like LOB ticket - 4379 */}
              <MultiSelectTextFieldFormik
                id="export_days"
                disabled={isSubmitting}
                className={classes.textField}
                fullWidth
                renderOption={(option) => DATA_EXPORT.DAYS[option].abbr}
                options={Object.keys(DATA_EXPORT.DAYS).sort()}
                renderValue={() => ''}
                withOptionChips
                style={{ paddingBottom: '14px' }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextFieldFormik
                id="export_hour"
                label="Hour"
                className={classes.textField}
                select
                fullWidth
                disabled={isSubmitting}
              >
                {HOURS.map((hour) => (
                  <MenuItem key={hour} value={hour}>
                    {localTimeToViewTime(hour)}
                  </MenuItem>
                ))}
              </TextFieldFormik>
            </Grid>
            <Grid item xs={6}>
              <CheckboxFormik id="is_end_date" label="End Date" fullWidth disabled={isSubmitting} />
            </Grid>
            {values.is_end_date && (
              <Grid item xs={6}>
                <DatePickerTextFieldFormik
                  id="end_date"
                  label="End Date"
                  className={classes.textField}
                  disablePast
                  fullWidth
                  disabled={isSubmitting}
                  clearable
                />
              </Grid>
            )}
          </CardDialog>
        </Grid>
        <Grid item xs={12}>
          {isAttachNonCommunicationEnabled ? (
            <div className="inline-flex items-baseline">
              <SwitchFormik
                className={classes.formsSwitch}
                id="include_non_claim_communications"
                label="Include communications not attached to a claim in DE files"
                disabled={isSubmitting}
              />
              <TooltipIcon
                placement="top-start"
                arrow
                title="Communications not attached to any claim may contain sub-organization specific information."
              >
                <InfoIcon className="ml-10" size={18} />
              </TooltipIcon>
            </div>
          ) : null}
          <SwitchFormik
            className={classes.formsSwitch}
            id="is_active"
            label="Active subscribe"
            disabled={isSubmitting}
          />
        </Grid>

        <div className={classes.buttonsContainer}>
          <CancelButton disabled={isSubmitting} onClick={onClose} />
          <Button variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
            {scheduleExportToEdit ? 'UPDATE' : 'SAVE'}
          </Button>
        </div>
      </Grid>
    </CardDialog>
  );
};

ScheduledExportDialogInner.propTypes = {
  onClose: PropTypes.func.isRequired,
  scheduleExportToEdit: PropTypes.object,
};

export default ScheduledExportDialog;
