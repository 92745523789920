import React from 'react';
import { string } from 'prop-types';
import { Typography } from '@material-ui/core';

import Grid from '~/components/core/Atomic/Grid/Grid';
import cn from '~/Utils/cn';

import { TooltipIcon } from '../../../../../../../core';
import { ErrorIcon } from '../../../../../../../icons';
import RadioButtonFormik from '../../../../../../../RadioButtonFormik';

import styles from '../previewSection.module.scss';

const ANSWERS = ['Yes', 'No', 'N/A'];

const Question = ({ id, text, tooltip }) => {
  return (
    <Grid container spacing={2} className={cn(styles.questionBorder, styles.previewQuestionContainer)}>
      <Grid item xs={8}>
        <Typography display="block">
          <span className={styles.questionTitle}>
            {text}
            {tooltip && (
              <TooltipIcon title={tooltip}>
                <ErrorIcon className={styles.questionTooltipIcon} />
              </TooltipIcon>
            )}
          </span>
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <div className={styles.radioBtnContainer}>
          {ANSWERS.map((answer) => (
            <RadioButtonFormik
              key={answer}
              id={`${id}_radio_preview`}
              label={answer}
              optionValue={answer}
              size="small"
            />
          ))}
        </div>
      </Grid>
    </Grid>
  );
};

Question.propTypes = {
  id: string.isRequired,
  text: string.isRequired,
  tooltip: string,
};

export default Question;
