import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AddIcon from '@material-ui/icons/Add';
import axios from 'axios';
import { FieldArray, Formik, getIn } from 'formik';
import * as Yup from 'yup';

import { useStyles } from '~/assets/styles';
import CardDialog from '~/components/CardDialog';
import { FsButton, Heading } from '~/components/core';
import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';
import CancelButton from '~/components/core/Buttons/CancelButton';
import { ErrorHelperTextFormik } from '~/components/core/Formik/ErrorHelperTextFormik';
import { TrashIcon } from '~/components/icons';
import InlineIconButton from '~/components/InlineIconButton';
import TextFieldFormik, { ShowOnlyTextField } from '~/components/TextFieldFormik';
import { reportAxiosError } from '~/Utils';

import { useSysconfig } from '../../../../SystemConfigurationScreen';

import WebhooksInnerTable from './WebhooksInnerTable';

import styles from './EventSettings.module.scss';

const WebhookEventCategory = ({ onUpdate, category, heading, webhookEventCategory, eventsData }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { organization } = useSysconfig();
  const [isEditingCategoryCallback, setIsEditingCategoryCallback] = useState(false);
  const classes = useStyles();

  const simulateWebhookEvent = async () => {
    setIsSubmitting(true);
    try {
      await axios.post(`/api/v1/claim_events_webhook/organizations/${organization.id}/test_event`, {
        category,
      });
    } catch (e) {
      await reportAxiosError(e);
    }
    setIsSubmitting(false);
  };

  const handleUpdateCallbackUrls = async (callbackUrls) => {
    setIsSubmitting(true);
    try {
      await onUpdate({ callback_urls: callbackUrls }, category);
      setIsEditingCategoryCallback(false);
    } catch (error) {
      console.error('Failed to submit callback URLS', error);
    }
    setIsSubmitting(false);
  };

  const areCallbacksExist = webhookEventCategory?.callback_urls?.length;

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Heading variant={Heading.TYPES.H3}>{heading}</Heading>
        </Grid>
        <Grid item xs={8}>
          <ShowOnlyTextField
            classes={classes}
            label="Callback URLs"
            showOnlyValueComponent={webhookEventCategory?.callback_urls?.join(', ') || 'None'}
            onEdit={() => setIsEditingCategoryCallback(true)}
          />
        </Grid>
        <Grid item xs={4}>
          <div className={styles.testButtonWrapper}>
            <FsButton
              size="small"
              variant="outlined"
              color="primary"
              disabled={!areCallbacksExist || isSubmitting}
              onClick={simulateWebhookEvent}
            >
              SEND TEST EVENT
            </FsButton>
          </div>
        </Grid>
        <Grid item xs={12}>
          <WebhooksInnerTable
            webhookEventCategory={webhookEventCategory}
            eventsData={eventsData}
            category={category}
            showOnly={!areCallbacksExist || isSubmitting}
            onUpdate={onUpdate}
          />
        </Grid>
      </Grid>
      {isEditingCategoryCallback && (
        <EditCallbackUrls
          callbackUrls={webhookEventCategory?.callback_urls}
          onSubmit={handleUpdateCallbackUrls}
          onCancel={() => setIsEditingCategoryCallback(false)}
        />
      )}
    </>
  );
};

WebhookEventCategory.propTypes = {
  onUpdate: PropTypes.func,
  heading: PropTypes.string,
  webhookEventCategory: PropTypes.object,
  eventsData: PropTypes.array,
  category: PropTypes.string,
};

const EditCallbackUrls = ({ callbackUrls, onSubmit, onCancel }) => {
  const CALLBACK_URLS_FIELD_ID = 'callback_urls';

  const classes = useStyles();
  return (
    <Formik
      initialValues={{ [CALLBACK_URLS_FIELD_ID]: callbackUrls || [] }}
      onSubmit={(values) => onSubmit(values[CALLBACK_URLS_FIELD_ID])}
      validationSchema={Yup.object().shape({
        [CALLBACK_URLS_FIELD_ID]: Yup.array()
          .of(
            Yup.string()
              .required('callback url is required')
              .test('is-unique', 'Domain must be unique', (value, context) => {
                return context.parent.filter((item) => item === value).length < 2;
              })
          )
          .required()
          .min(1, 'At least one callback required'),
      })}
    >
      {({ isSubmitting, handleSubmit, values }) => {
        return (
          <CardDialog
            isDialog
            title="Edit Category Callbacks"
            maxWidth="sm"
            onClose={onCancel}
            fullWidth
            preventClose={isSubmitting}
          >
            <FieldArray
              name="callback_urls"
              render={({ remove, push }) => (
                <>
                  {getIn(values, CALLBACK_URLS_FIELD_ID).map((_, idx) => (
                    <div className="grid grid-cols-6 items-center gap-20" key={idx}>
                      <div className="col-span-5">
                        <TextFieldFormik
                          className={classes.textField}
                          id={`${CALLBACK_URLS_FIELD_ID}.${idx}`}
                          label="Callback URL"
                          fullWidth
                        />
                      </div>
                      {idx > 0 && (
                        <div>
                          <InlineIconButton
                            className={classes.hoverableNonFilledIcon}
                            icon={TrashIcon}
                            onClick={() => remove(idx)}
                          />
                        </div>
                      )}
                    </div>
                  ))}
                  <ErrorHelperTextFormik id={CALLBACK_URLS_FIELD_ID} withoutChildren />
                  <div className="left">
                    <Button
                      color="primary"
                      startIcon={<AddIcon />}
                      onClick={() => push('')}
                      style={{ display: 'flex' }}
                    >
                      Add callback URL
                    </Button>
                  </div>
                </>
              )}
            />
            <div className={classes.buttonsContainer}>
              <CancelButton disabled={isSubmitting} onClick={onCancel} />
              <Button variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
                Save
              </Button>
            </div>
          </CardDialog>
        );
      }}
    </Formik>
  );
};

EditCallbackUrls.propTypes = {
  callbackUrls: PropTypes.array,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
};
export default WebhookEventCategory;
