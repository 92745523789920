import React, { useState } from 'react';
import AddIcon from '@material-ui/icons/Add';
import axios from 'axios';

import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';

import { reportAxiosError } from '../../../../../../Utils';
import CardDialog from '../../../../../CardDialog';
import { LoadingSwitch } from '../../../../../core';
import useDataFetcher from '../../../../../useDataFetcher';
import { useSysconfig } from '../../../../SystemConfigurationScreen';

import AddServiceAccountDialog from './AddServiceAccountDialog';
import EditServiceAccountDialog from './EditServiceAccountDialog';
import { serviceAccounts as serviceAccountsAPI } from './routes';
import ServiceAccountsTable from './ServiceAccountsTable';

import { useStyles } from '../../../../../../assets/styles';
import styles from './serviceAccount.module.scss';

const ServiceAccounts = () => {
  const [isAddServiceAccountDialogOpen, setAddServiceAccountDialogOpen] = useState(false);
  const [isEditServiceAccountDialogOpen, setEditServiceAccountDialogOpen] = useState(false);
  const [serviceAccountEdit, setServiceAccountEdit] = useState();
  const { organization, isLoading: isOrganizationLoading, isError: isOrganizationError } = useSysconfig();
  const classes = useStyles();

  const {
    isLoading: isServiceAccountLoading,
    isError: isServiceAccountError,
    data: serviceAccounts = [],
    reloadData: reloadServiceAccounts,
  } = useDataFetcher(serviceAccountsAPI(organization));

  const onAddCloseDialog = () => {
    setAddServiceAccountDialogOpen(false);
  };

  const onAddOpenDialog = () => {
    setAddServiceAccountDialogOpen(true);
  };

  const onEditCloseDialog = () => {
    setEditServiceAccountDialogOpen(false);
  };

  const onEditOpenDialog = (serviceAccount) => {
    setServiceAccountEdit(serviceAccount);
    setEditServiceAccountDialogOpen(true);
  };

  const addNewServiceAccount = async (values) => {
    try {
      await axios.post(serviceAccountsAPI(organization), values);
      await reloadServiceAccounts();
    } catch (error) {
      await reportAxiosError(error);
    }
    onAddCloseDialog();
  };

  const editServiceAccount = async (values) => {
    try {
      await axios.put(serviceAccountsAPI(organization), values, {
        params: { service_account_id: serviceAccountEdit.id },
      });
      await reloadServiceAccounts();
    } catch (error) {
      await reportAxiosError(error);
    }
    onEditCloseDialog();
  };

  const serviceAccountNames = serviceAccounts.map(({ name }) => name);
  const isLoading = isOrganizationLoading || isServiceAccountLoading;
  const isError = isOrganizationError || isServiceAccountError;

  return (
    <CardDialog noCardTitle>
      <LoadingSwitch isLoading={isLoading} isError={isError}>
        <Grid container className={styles.serviceAccount} direction="column">
          <Grid item>
            <div className={classes.inLineButtonsContainer}>
              <Button color="primary" onClick={onAddOpenDialog}>
                <AddIcon className={classes.leftButtonIcon} />
                ADD SERVICE ACCOUNT
              </Button>
            </div>
          </Grid>
          <Grid item className={styles.tableContainer}>
            <ServiceAccountsTable
              serviceAccounts={serviceAccounts}
              onUpdate={reloadServiceAccounts}
              onActionClick={onEditOpenDialog}
            />
            {isAddServiceAccountDialogOpen && (
              <AddServiceAccountDialog
                onClose={onAddCloseDialog}
                handleFormSubmit={addNewServiceAccount}
                serviceAccountNames={serviceAccountNames}
              />
            )}
            {isEditServiceAccountDialogOpen && (
              <EditServiceAccountDialog
                serviceAccount={serviceAccountEdit}
                onClose={onEditCloseDialog}
                handleFormSubmit={editServiceAccount}
                serviceAccountNames={serviceAccountNames}
              />
            )}
          </Grid>
        </Grid>
      </LoadingSwitch>
    </CardDialog>
  );
};

export default ServiceAccounts;
