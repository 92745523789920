import React from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { get } from 'lodash';

import { useCms } from '../../../hooks/useCms';
import { useAllOptionOnMultiSelect } from '../../../TextFieldFormik';
import ArrayMultiselectFieldWithChipsFormik from '../../ArrayMultiselectFieldWithChipsFormik';

const ALL_FAKE_ID = 0;
const ALL_OPTION = {
  id: ALL_FAKE_ID,
  label: 'All (inc. future sub-orgs)',
  value: ALL_FAKE_ID,
};

const SubOrgMultiSelectWithChipsFormik = ({ id, label, showOnly = false, disabled = false }) => {
  const { userOrganization } = useCms();
  const { values } = useFormikContext();
  const { sub_organizations } = userOrganization;
  const subOrganizations =
    sub_organizations?.map((sub_org) => ({
      id: sub_org?.id,
      label: sub_org?.name,
      value: sub_org?.id,
    })) || [];

  subOrganizations.unshift(ALL_OPTION);

  useAllOptionOnMultiSelect(get(values, id, []), id, ALL_FAKE_ID);

  return (
    <ArrayMultiselectFieldWithChipsFormik
      showOnly={showOnly}
      disabled={disabled}
      id={id}
      label={label}
      options={subOrganizations}
    />
  );
};

SubOrgMultiSelectWithChipsFormik.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  showOnly: PropTypes.bool,
  disabled: PropTypes.bool,
};

SubOrgMultiSelectWithChipsFormik.ALL_OPTION = ALL_OPTION;

export default SubOrgMultiSelectWithChipsFormik;
