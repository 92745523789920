import React, { useState } from 'react';
import { MenuItem, TextField } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { get } from 'lodash';

import { Text } from '~/components/core';
import Grid from '~/components/core/Atomic/Grid/Grid';
import InnerCard from '~/components/core/Cards/InnerCard';

const ID_PREFIX = 'configuration.incident_details.types';

const filterActive = (type) => type?.active !== false;

const getAllActiveTypes = (values) => get(values, ID_PREFIX, []).filter(filterActive);

const getAllActiveSubTypes = (allTypes, typeId) =>
  allTypes?.find((type) => type?.id === typeId)?.sub_types?.filter(filterActive) || [];

const firstActiveTypeId = (values) => getAllActiveTypes(values)?.find(filterActive)?.id || '';

const firstActiveSubTypeId = (values, typeId) =>
  get(getAllActiveSubTypes(getAllActiveTypes(values), typeId), '[0].id', '');

const IncidentTypesAndSubTypesPreview = () => {
  const { values } = useFormikContext();
  const [incidentType, setIncidentType] = useState(firstActiveTypeId(values));
  const [incidentSubType, setIncidentSubType] = useState(firstActiveSubTypeId(values, incidentType));
  const allTypes = getAllActiveTypes(values);
  const subTypes = getAllActiveSubTypes(allTypes, incidentType);

  return (
    <InnerCard>
      <Grid container className="flex items-center" spacing={1}>
        <Grid item xs={12}>
          <Text weight={Text.WEIGHTS.REGULAR} variant={Text.VARIANTS.SM} colorVariant={Text.COLOR_VARIANTS.SECONDARY}>
            Incident Type and sub-type preview
          </Text>
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Incident Type"
            select
            value={incidentType || ''}
            fullWidth
            onChange={(e) => {
              const selectedType = e.target.value;
              setIncidentType(selectedType);
              setIncidentSubType(firstActiveSubTypeId(values, selectedType));
            }}
          >
            {allTypes.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.desc}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Incident Sub-Type"
            select
            value={incidentSubType || ''}
            fullWidth
            disabled={!subTypes.length}
            onChange={(e) => {
              const selectedType = e.target.value;
              setIncidentSubType(selectedType);
            }}
          >
            {subTypes.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.desc}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
    </InnerCard>
  );
};

export default IncidentTypesAndSubTypesPreview;
