import React, { useState } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import { useStyles } from '~/assets/styles';
import SwitchPanel from '~/components/core/ConfigPanel/SwitchPanel';
import TooltipIcon from '~/components/core/TooltipIcon';
import { PencilIcon } from '~/components/icons';
import InlineIconButton from '~/components/InlineIconButton';

const ConfigurableOption = ({
  isEnabled,
  onChangeToggle,
  configurationView,
  EditConfigurationDialogComponent,
  onSubmitDialog,
  configuration,
  showOnly,
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const classes = useStyles();

  const handleStatusChangeClicked = () => {
    if (!isEnabled && EditConfigurationDialogComponent && isEmpty(configuration)) {
      setIsDialogOpen(true);
      return;
    }

    onChangeToggle(!isEnabled);
  };

  return (
    <div className="mt-20">
      <SwitchPanel
        checked={!!isEnabled}
        onChange={handleStatusChangeClicked}
        showOnly={showOnly}
        label={
          <div className="flex flex-grow flex-nowrap">
            <div className="flex-grow">{configurationView}</div>
            {isEnabled && EditConfigurationDialogComponent && !isEmpty(configuration) ? (
              <div>
                <TooltipIcon title="Edit Configuration">
                  <InlineIconButton
                    icon={PencilIcon}
                    className={classes.hoverableNonFilledIcon}
                    onClick={() => setIsDialogOpen(true)}
                    disabled={showOnly}
                  />
                </TooltipIcon>
              </div>
            ) : null}
          </div>
        }
      />

      {isDialogOpen && (
        <EditConfigurationDialogComponent
          configuration={configuration}
          onSubmit={onSubmitDialog}
          onCancel={() => setIsDialogOpen(false)}
        />
      )}
    </div>
  );
};

ConfigurableOption.propTypes = {
  isEnabled: PropTypes.bool,
  onChangeToggle: PropTypes.func,
  configurationView: PropTypes.node,
  EditConfigurationDialogComponent: PropTypes.elementType,
  onSubmitDialog: PropTypes.func,
  configuration: PropTypes.object,
  showOnly: PropTypes.bool,
};
export default ConfigurableOption;
