import React from 'react';
import PropTypes from 'prop-types';
import { TextField, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import _, { isEmpty } from 'lodash';

import mixpanel from '~/components/CmsMain/mixpanel';
import Button from '~/components/core/Atomic/Buttons/Button';
import { Card, CARD_TYPES } from '~/components/core/Cards/Card';
import SwitchPanel from '~/components/core/ConfigPanel/SwitchPanel';
import EmptyState from '~/components/core/EmptyState';
import { useOrganizationUnits } from '~/components/SystemConfiguration/Users/hooks/useOrganizationUnits';
import { MembersDialog } from '~/components/SystemConfiguration/Users/OrganizationUnits/Dialogs/MembersDialog';
import {
  createUnit,
  deleteUnit,
  setUnitActivation,
  updateUnit,
} from '~/components/SystemConfiguration/Users/OrganizationUnits/unitsApi';

import cn from '../../../../Utils/cn';
import { Text } from '../../../core';
import { useSysconfig } from '../../../SystemConfiguration/SystemConfigurationScreen';

import { AddUnitDialog } from './Dialogs/AddUnitDialog';
import { DeleteUnitDialog } from './Dialogs/DeleteUnitDialog';
import { EditUnitDialog } from './Dialogs/EditUnitDialog';
import { ORG_UNIT_TYPE, ORGANIZATION_UNITS_MIXPANEL_EVENTS } from './consts';
import { OrganizationUnitsTable } from './OrganizationUnitsTable';
import { prepareDataForSave } from './Utils';

export const OrganizationUnitsTab = ({ className }) => {
  const [filteredUnits, setFilteredUnits] = React.useState([]);
  const [searchUnitText, setSearchUnitText] = React.useState('');
  const [showOnlyActiveUnits, setShowOnlyActiveUnits] = React.useState(true);
  const { organization: SysconfigOrganization, allUsers } = useSysconfig();

  const [currentUnit, setCurrentUnit] = React.useState();
  const [openAddUnitDialog, setOpenAddUnitDialog] = React.useState(false);
  const [openAddTopUnitDialog, setOpenAddTopUnitDialog] = React.useState(false);
  const [openEditUnitDialog, setOpenEditUnitDialog] = React.useState(false);
  const [isDeactivatingUnit, setIsDeactivatingUnit] = React.useState(false);
  const [openDeleteUnitDialog, setOpenDeleteUnitDialog] = React.useState(false);
  const [openMembersDialog, setOpenMembersDialog] = React.useState(false);
  const users = React.useMemo(() => allUsers.filter((user) => user.role.role_type !== 'system'), [allUsers]);
  const usersDict = React.useMemo(() => _.keyBy(users, 'id'), [users]);
  const {
    units,
    unitsDict,
    isUnitsLoading,
    isUnitsError,
    userToUnitDict,
    leaderToUnitsDict,
    reloadUnits,
    unitsToSubUnitsDict,
  } = useOrganizationUnits({ organization: SysconfigOrganization });
  const hasUnits = !isEmpty(units);

  const onActionClick = ({ action, unit }) => {
    setCurrentUnit(unit);
    if (action === 'edit') {
      setOpenEditUnitDialog(true);
    }

    if (action === 'delete') {
      setOpenDeleteUnitDialog(true);
    }

    if (action === 'show_members') {
      setOpenMembersDialog(true);
    }

    if (action === 'toggle_activation') {
      onToggleUnitActivation({ unit, isActive: !unit.is_active });
    }
  };

  const filterData = React.useCallback(() => {
    if (!units) return;

    let filtered = units
      .filter(
        (unit) =>
          (!showOnlyActiveUnits || unit.is_active) && unit.name.toLowerCase().includes(searchUnitText.toLowerCase())
      )
      .map((unit) => ({
        ...unit,
        type: unit.parent_unit_id ? ORG_UNIT_TYPE.SUB_UNIT : ORG_UNIT_TYPE.TOP_UNIT,
      }));
    setFilteredUnits(filtered);
  }, [units, showOnlyActiveUnits, searchUnitText]);

  const onAddUnit = React.useCallback(
    async ({ values }) => {
      const data = prepareDataForSave({ values });
      await createUnit({ organizationId: SysconfigOrganization.id, data });
      setOpenAddUnitDialog(false);
      setOpenAddTopUnitDialog(false);
      await reloadUnits();
    },
    [SysconfigOrganization.id, reloadUnits]
  );

  const onAddUnitClick = () => {
    setOpenAddUnitDialog(true);
    mixpanel.track(ORGANIZATION_UNITS_MIXPANEL_EVENTS.ADD_UNIT_CLICKED, {});
  };

  const onEditUnit = React.useCallback(
    async ({ values }) => {
      const unitId = values.id;
      const data = prepareDataForSave({ values });
      if (isDeactivatingUnit) {
        await setUnitActivation({
          organizationId: SysconfigOrganization.id,
          unitId,
          isActive: false,
          memberUserIds: data.member_user_ids,
        });
        setIsDeactivatingUnit(false);
      } else {
        await updateUnit({ organizationId: SysconfigOrganization.id, unitId, data });
      }
      setOpenEditUnitDialog(false);
      await reloadUnits();
    },
    [SysconfigOrganization.id, isDeactivatingUnit, reloadUnits]
  );

  const onDeleteUnit = React.useCallback(
    async ({ unitId }) => {
      await deleteUnit({ organizationId: SysconfigOrganization.id, unitId });
      setOpenDeleteUnitDialog(false);
      await reloadUnits();
    },
    [SysconfigOrganization.id, reloadUnits]
  );

  const onToggleUnitActivation = React.useCallback(
    async ({ unit, isActive }) => {
      if (isActive) {
        await setUnitActivation({ organizationId: SysconfigOrganization.id, unitId: unit.id, isActive: true });
        await reloadUnits();
      } else {
        setIsDeactivatingUnit(true);
        setOpenEditUnitDialog(true);
      }
    },
    [SysconfigOrganization.id, reloadUnits]
  );

  React.useEffect(() => {
    filterData();
  }, [units, searchUnitText, filterData]);

  return (
    <section className={cn('flex flex-col gap-20 p-20', className)}>
      {!hasUnits && !isUnitsLoading && (
        <EmptyState
          title="No organizational units have been configured yet."
          subtitle={"Begin by creating the first top-level unit for the organization's hierarchical structure."}
          buttonComponent={
            <Button
              variant="contained"
              color="primary"
              onClick={() => setOpenAddTopUnitDialog(true)}
              startIcon={<AddIcon />}
            >
              ADD TOP LEVEL UNIT
            </Button>
          }
        />
      )}

      {hasUnits && !isUnitsLoading && (
        <>
          <Typography
            display="block"
            variant="subtitle1"
            style={{ fontWeight: 'bold', fontSize: '18px', marginBottom: '10px' }}
          >
            UNITS
          </Typography>
          <div className="mb-12 flex">
            <TextField
              InputProps={{
                placeholder: 'Search Unit',
              }}
              value={searchUnitText}
              onChange={(e) => setSearchUnitText(e.target.value)}
            />
          </div>
          <Card cardType={CARD_TYPES.SECONDARY} className="p-24">
            <Text variant={Text.VARIANTS.LG} weight={Text.WEIGHTS.MEDIUM}>
              Filters
            </Text>
            <SwitchPanel
              checked={showOnlyActiveUnits}
              onChange={() => setShowOnlyActiveUnits(!showOnlyActiveUnits)}
              label="Show only active units"
              className="mt-10 border-0"
            />
          </Card>

          <div className="flex">
            <span className="flex items-center font-semibold">{`${filteredUnits?.length || '0'} Units`} </span>
            <Button color="primary" className="ml-auto" onClick={() => onAddUnitClick()}>
              <AddIcon />
              ADD UNIT
            </Button>
          </div>

          <OrganizationUnitsTable
            units={filteredUnits}
            unitsToSubUnitsDict={unitsToSubUnitsDict}
            isLoading={isUnitsLoading}
            isError={isUnitsError}
            onActionClick={onActionClick}
          />
        </>
      )}

      {(openAddUnitDialog || openAddTopUnitDialog) && (
        <AddUnitDialog
          units={units}
          unitsDict={unitsDict}
          usersDict={usersDict}
          memberToUnitDict={userToUnitDict}
          leaderToUnitsDict={leaderToUnitsDict}
          onClose={() => {
            setOpenAddUnitDialog(false);
            setOpenAddTopUnitDialog(false);
          }}
          onSave={onAddUnit}
          isTopUnitOnly={openAddTopUnitDialog}
        />
      )}
      {openEditUnitDialog && (
        <EditUnitDialog
          unit={currentUnit}
          isDeactivating={isDeactivatingUnit}
          units={units}
          unitsDict={unitsDict}
          usersDict={usersDict}
          memberToUnitDict={userToUnitDict}
          leaderToUnitsDict={leaderToUnitsDict}
          onClose={() => {
            setOpenEditUnitDialog(false);
            setIsDeactivatingUnit(false);
          }}
          onSave={onEditUnit}
        />
      )}
      {openDeleteUnitDialog && (
        <DeleteUnitDialog unit={currentUnit} onClose={() => setOpenDeleteUnitDialog(false)} onSubmit={onDeleteUnit} />
      )}
      {openMembersDialog && (
        <MembersDialog unit={currentUnit} usersDict={usersDict} onClose={() => setOpenMembersDialog(false)} />
      )}
    </section>
  );
};

OrganizationUnitsTab.propTypes = {
  className: PropTypes.string,
};

OrganizationUnitsTab.defaultProps = {
  className: null,
};
