import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { MenuItem, TextField } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { get, isEmpty } from 'lodash';

import {
  BOOL4_TYPES,
  DB_FIELDS_VALID_VALUES,
  MAX_CONTACT_FIELDS,
  MAX_LOCATION_FIELDS,
  SELECT_TYPES,
} from '../../constants';
import copies from '../copies.json';

const getConfiguredFieldsPath = (id) => `${id?.split('.')?.slice(0, -1)?.join('.')}.configured_fields`;
const filteringFuncByType = (type) => (field) => field?.type === type;
const onChangeType = (values, setFieldValue, id, selectedType, fieldsByType, setCurType) => {
  setCurType(selectedType);
  setFieldValue(`${id}.type`, selectedType);
  setFieldValue(`${id}.disable_na`, undefined);
  setFieldValue(`${id}.disable_unknown`, undefined);
  setFieldValue(`${id}.db_field`, undefined);

  if (!SELECT_TYPES.includes(selectedType)) {
    setFieldValue(`${id}.options`, undefined);
  }

  switch (selectedType) {
    case 'yes_no':
      setFieldValue(`${id}.type`, 'yes_no_na_unknown');
      setFieldValue(`${id}.disable_na`, true);
      setFieldValue(`${id}.disable_unknown`, true);
      break;
    case 'yes_no_na':
      setFieldValue(`${id}.type`, 'yes_no_na_unknown');
      setFieldValue(`${id}.disable_na`, false);
      setFieldValue(`${id}.disable_unknown`, true);
      break;
    case 'yes_no_unknown':
      setFieldValue(`${id}.type`, 'yes_no_na_unknown');
      setFieldValue(`${id}.disable_na`, true);
      setFieldValue(`${id}.disable_unknown`, false);
      break;
    case 'contact':
    case 'location':
      // check db field is already set - if not set to first available
      if (!get(values, `${id}.db_field`)) {
        const availableDbField = DB_FIELDS_VALID_VALUES[selectedType].find(
          (dbField) => !fieldsByType[selectedType].some((field) => field?.db_field === dbField)
        );
        setFieldValue(`${id}.db_field`, availableDbField);
      }
      break;
    default:
      break;
  }
};

const ConfigurationFieldType = ({ id, isConfiguredField = false }) => {
  const { values, setFieldValue, initialValues, touched, errors } = useFormikContext();
  const [curType, setCurType] = useState(get(values, `${id}.type`, ''));
  const isLimitedToCategories = !isEmpty(get(values, `${id}.incident_sub_type_categories`, []));
  const isNewField = isEmpty(get(initialValues, id, {}));
  const isNewConfiguredField = isNewField && isConfiguredField;
  const configuredFieldsPath = getConfiguredFieldsPath(id);
  const configuredFieldsArray = get(values, configuredFieldsPath, []);
  const contactFields = configuredFieldsArray.filter(filteringFuncByType('contact'));
  const locationFields = configuredFieldsArray.filter(filteringFuncByType('location'));
  const fieldsByType = { contact: contactFields, location: locationFields };
  const isContactDisabled = contactFields?.length >= MAX_CONTACT_FIELDS;
  const isLocationDisabled = locationFields?.length >= MAX_LOCATION_FIELDS;
  const isCurTypeBool4 = BOOL4_TYPES.includes(curType);
  const isDisabled = isCurTypeBool4 ? false : !isNewConfiguredField;
  const filterBooleanForNewFields = (option) => (isNewConfiguredField ? option?.id !== 'boolean' : true);
  const filterBool4Fields = (option) =>
    !isNewConfiguredField && isCurTypeBool4 ? BOOL4_TYPES.includes(option?.id) : true;
  const isOptionDisabled = (option) =>
    (option.id === 'contact' && isContactDisabled && isNewConfiguredField) ||
    (option.id === 'location' && isLocationDisabled && isNewConfiguredField) ||
    false;

  const options = copies.field_types
    .map((option) => ({
      ...option,
      disabled: isOptionDisabled(option),
    }))
    ?.filter((option) => filterBooleanForNewFields(option) && filterBool4Fields(option));

  const fieldError = get(errors, `${id}.type`);
  const fieldTouched = get(touched, `${id}.type`);

  useEffect(() => {
    const savedType = get(values, `${id}.type`, '');
    const disable_na = get(values, `${id}.disable_na`, false);
    const disable_unknown = get(values, `${id}.disable_unknown`, false);

    if (savedType === 'yes_no_na_unknown' && [disable_na, disable_unknown].includes(true)) {
      if (disable_na && !disable_unknown) {
        setCurType('yes_no_unknown');
      } else if (!disable_na && disable_unknown) {
        setCurType('yes_no_na');
      } else if (disable_na && disable_unknown) {
        setCurType('yes_no');
      }
    }
  }, [id, values]);

  return (
    <TextField
      label="Type"
      select
      value={curType || ''}
      fullWidth
      className={isLimitedToCategories ? 'mt-15' : ''}
      disabled={isDisabled}
      error={fieldError && fieldTouched}
      helperText={fieldError && fieldTouched && fieldError}
      onChange={(e) => {
        const selectedType = e.target.value;
        onChangeType(values, setFieldValue, id, selectedType, fieldsByType, setCurType);
      }}
    >
      {options.map((option) => (
        <MenuItem key={option.id} value={option.id} disabled={option?.disabled}>
          {option.desc}
        </MenuItem>
      ))}
    </TextField>
  );
};

ConfigurationFieldType.propTypes = {
  id: PropTypes.string.isRequired,
  isConfiguredField: PropTypes.bool,
};

export default ConfigurationFieldType;
