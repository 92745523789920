import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { Formik, getIn, useFormikContext } from 'formik';
import * as Yup from 'yup';

import Grid from '~/components/core/Atomic/Grid/Grid';
import DialogFooterActions from '~/components/core/DialogFooterActions';
import RadioWithButtonWrapperFormik from '~/components/core/Formik/RadioWithButtonWrapperFormik';
import SwitchFormik from '~/components/core/Formik/SwitchFormik';
import { useLobConfiguration } from '~/components/hooks/useLobConfiguration';

import { subOrgIdToNameDict } from '../../../Utils';
import { getLobDescription, getLobIcon } from '../../../Utils/lobUtils';
import CardDialog from '../../CardDialog';
import { useShouldDisableFormikField } from '../../hooks/useShouldDisableFormikField';
import { MultiSelectTextFieldFormik, TextFieldFormik, usePrevious } from '../../TextFieldFormik';
import LobRadioFormik from '../../TPA/LOB/LobRadioFormik';

import { NoAvailableLobs, useLobsSubOrgsIntersectionCheck } from './ExposureConfigurationUtils';

import { useStyles } from '../../../assets/styles';

export function SharedCoverageDialog({
  organization,
  supportedClaimTypes,
  sharedCoverage,
  onSubmit,
  onClose,
  fetchUsedSharedCoveragesKeys,
  fetchCoverages,
  errorHandler,
  wizardLob,
  overrideDisabled,
}) {
  return (
    <Formik
      initialValues={{
        shared_coverage_key: sharedCoverage?.shared_coverage_key || '',
        sub_organization_ids: sharedCoverage?.sub_organization_ids || [],
        lob: sharedCoverage?.lob || supportedClaimTypes[0],
        coverage_config_ids: sharedCoverage?.coverage_config_ids || [],
        is_limit_per_incident: sharedCoverage?.is_limit_per_incident ?? true,
      }}
      validationSchema={Yup.object().shape({
        shared_coverage_key: Yup.string()
          .max(50)
          .required('Required')
          .test('shared_coverage_key_unique', 'Must be unique', async function (value) {
            const keysUsedRet = await fetchUsedSharedCoveragesKeys({
              shared_coverage_config_ids_to_ignore: sharedCoverage ? [sharedCoverage.id] : undefined,
            });
            return !keysUsedRet.data.includes(value);
          }),
        sub_organization_ids: Yup.array().test('sub_orgs_ids', 'Required', function (value) {
          return organization.sub_organizations_enabled ? value.length > 0 : true;
        }),
        coverage_config_ids: Yup.array().required('Required').min(2, 'Required (at least two)'),
        lob: Yup.string().required('Required').oneOf(supportedClaimTypes, 'Invalid line of business'),
        is_limit_per_incident: Yup.boolean(),
      })}
      enableReinitialize
      onSubmit={async (values, { setSubmitting }) => {
        try {
          const isAllSubOrgs = values.sub_organization_ids.indexOf('all') > -1;
          await onSubmit(
            {
              ...values,
              sub_organization_ids: isAllSubOrgs ? [] : values.sub_organization_ids,
            },
            sharedCoverage?.id
          );
          onClose();
        } catch (error) {
          await errorHandler(error);
          setSubmitting(false);
        }
      }}
    >
      <SharedCoverageDialogInner
        organization={organization}
        sharedCoverage={sharedCoverage}
        onClose={onClose}
        fetchCoverages={fetchCoverages}
        errorHandler={errorHandler}
        wizardLob={wizardLob}
        overrideDisabled={overrideDisabled}
      />
    </Formik>
  );
}

export function SharedCoverageDialogInner({
  organization,
  sharedCoverage,
  onClose,
  fetchCoverages,
  errorHandler,
  wizardLob,
  overrideDisabled,
}) {
  const { lobConfigurationsDict } = useLobConfiguration();
  const [supportedCoveragesForSubOrgAndLob, setSupportedCoveragesForSubOrgAndLob] = useState({});
  const classes = useStyles();
  const { isSubmitting, handleSubmit, values, setFieldValue, errors, touched } = useFormikContext();
  const subOrgIdToName = subOrgIdToNameDict(organization);
  const prevSubOrgsRef = usePrevious(values.sub_organization_ids);
  const prevLobRef = usePrevious(values.lob);
  const isAllSubOrgs = values.sub_organization_ids.indexOf('all') > -1;

  const updateCoverages = useCallback(
    async (sub_organization_ids, lob) => {
      try {
        const data = await fetchCoverages({ subOrganizationIds: isAllSubOrgs ? [] : sub_organization_ids, lob });
        setSupportedCoveragesForSubOrgAndLob(
          Object.fromEntries(data.map((coverage) => [coverage.id ?? coverage.coverage_key, coverage.display_name]))
        );
      } catch (error) {
        await errorHandler(error);
      }
    },
    [fetchCoverages, isAllSubOrgs, errorHandler]
  );

  useEffect(() => {
    if (
      (!prevSubOrgsRef && !prevLobRef) ||
      (prevSubOrgsRef && prevSubOrgsRef !== values.sub_organization_ids) ||
      (prevLobRef && prevLobRef !== values.lob)
    ) {
      // to prevent clearing the initial values
      setSupportedCoveragesForSubOrgAndLob({});
      if (!sharedCoverage) {
        setFieldValue('coverage_config_ids', []);
      }
      updateCoverages(values.sub_organization_ids, values.lob);
    }
  }, [
    values.sub_organization_ids,
    values.lob,
    prevSubOrgsRef,
    prevLobRef,
    updateCoverages,
    setFieldValue,
    sharedCoverage,
  ]);

  const parseCoverageId = (id) => {
    const idAsInt = parseInt(id);
    return isNaN(idAsInt) ? id : idAsInt;
  };

  const { filterLobsFunction, availableLobsForSubOrgs } = useLobsSubOrgsIntersectionCheck(organization);

  const shouldDisableFieldFunction = useShouldDisableFormikField(overrideDisabled);

  return (
    <CardDialog
      isDialog
      title={`${sharedCoverage ? 'Edit' : 'Add '} Shared Coverage`}
      fullWidth
      maxWidth="md"
      onClose={onClose}
      preventClose={isSubmitting}
      footerActions={
        <DialogFooterActions disabled={isSubmitting} onClickPrimary={handleSubmit} onClickSecondary={onClose} />
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <NoAvailableLobs organization={organization} />
        </Grid>
        <Grid item xs={12}>
          <Typography display="block" variant="subtitle2" style={{ paddingTop: '8px', fontWeight: 'bold' }}>
            Details
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <div>
            <TextFieldFormik
              id="shared_coverage_key"
              label="API Key"
              helperText={
                'The selected key will be used in api calls' +
                (getIn(errors, 'coverage_key') && getIn(touched, 'coverage_key')
                  ? ' - ' + getIn(errors, 'coverage_key')
                  : '')
              }
              disabled={shouldDisableFieldFunction(sharedCoverage)}
              className={classes.formTextField}
              fullWidth
            />
          </div>
        </Grid>
        <Grid item xs={6} />
        {organization.sub_organizations_enabled && (
          <Grid item xs={6} style={{ marginBottom: 24 }}>
            <MultiSelectTextFieldFormik
              id="sub_organization_ids"
              label="Sub Organizations"
              disabled={shouldDisableFieldFunction(sharedCoverage?.is_org_level)}
              options={
                isAllSubOrgs ? ['all'] : ['all'].concat(organization.sub_organizations.map((subOrg) => subOrg.id))
              }
              renderValue={() => ''}
              renderOption={(subOrgId) =>
                subOrgId === 'all' ? 'All (including future sub-organizations)' : subOrgIdToName[subOrgId]
              }
              className={classes.formTextField}
              withOptionChips
              disabledOptions={
                shouldDisableFieldFunction(sharedCoverage?.is_org_level)
                  ? isAllSubOrgs
                    ? organization.sub_organizations.map((subOrg) => subOrg.id)
                    : sharedCoverage?.sub_organization_ids
                  : []
              }
              fullWidth
              emptyChoiceValue={
                sharedCoverage?.sub_organization_ids || sharedCoverage?.is_org_level ? undefined : 'all'
              }
            />
          </Grid>
        )}
      </Grid>
      <Grid container spacing={2}>
        <>
          <Grid item xs={12}>
            <div>
              <Typography
                display="block"
                variant="subtitle2"
                style={{ marginBottom: 10, fontWeight: 'bold', color: '#202020' }}
              >
                Select Line of Business
              </Typography>
              <div style={{ display: 'flex' }}>
                {wizardLob ? (
                  <RadioWithButtonWrapperFormik
                    id="lob"
                    text={getLobDescription(wizardLob, lobConfigurationsDict)}
                    value={wizardLob}
                    disabled
                    icon={getLobIcon({ lob: wizardLob, lobConfigurationsDict })}
                    iconLocation="start"
                  />
                ) : (
                  <>
                    {availableLobsForSubOrgs?.length === 0 ||
                    (organization.sub_organizations_enabled && values.sub_organization_ids?.length === 0) ? (
                      <LobRadioFormik
                        lobFieldId="lob"
                        subOrganizationIds={organization.sub_organizations.map((s) => s.id)}
                        disabled
                      />
                    ) : (
                      <LobRadioFormik
                        lobFieldId="lob"
                        subOrganizationIds={
                          isAllSubOrgs ? organization.sub_organizations.map((s) => s.id) : values.sub_organization_ids
                        }
                        disabled={shouldDisableFieldFunction(sharedCoverage)}
                        filterLobsFunc={filterLobsFunction}
                      />
                    )}
                  </>
                )}
              </div>
            </div>
          </Grid>
          <Grid item xs={8} />
        </>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6} style={{ marginBottom: 24 }}>
          <MultiSelectTextFieldFormik
            id="coverage_config_ids"
            label="Coverages"
            disabled={shouldDisableFieldFunction(sharedCoverage)}
            options={Object.keys(supportedCoveragesForSubOrgAndLob).map(parseCoverageId)}
            renderValue={() => ''}
            renderOption={(ccId) => supportedCoveragesForSubOrgAndLob[ccId]}
            className={classes.formTextField}
            withOptionChips
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography display="block" variant="subtitle2" style={{ paddingTop: '8px', fontWeight: 'bold' }}>
            Coverage Limits and Deductible
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <SwitchFormik
            className={classes.formsSwitch}
            id="is_limit_per_incident"
            label="Limit per claim / incident"
            withShowOnlyText
            disabled
          />
        </Grid>
      </Grid>
    </CardDialog>
  );
}

SharedCoverageDialog.propTypes = {
  organization: PropTypes.object.isRequired,
  sharedCoverage: PropTypes.object,
  supportedClaimTypes: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  fetchUsedSharedCoveragesKeys: PropTypes.func.isRequired,
  fetchCoverages: PropTypes.func.isRequired,
  errorHandler: PropTypes.func.isRequired,
  wizardLob: PropTypes.string,
  overrideDisabled: PropTypes.bool,
};

SharedCoverageDialogInner.propTypes = {
  organization: PropTypes.object.isRequired,
  sharedCoverage: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  fetchCoverages: PropTypes.func.isRequired,
  errorHandler: PropTypes.func.isRequired,
  wizardLob: PropTypes.string,
  overrideDisabled: PropTypes.bool,
};
