import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { IconButton, InputAdornment, Switch, TextField, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import Button from '~/components/core/Atomic/Buttons/Button';
import Chip from '~/components/core/Atomic/Chip/Chip';
import Grid from '~/components/core/Atomic/Grid/Grid';
import EmptyState from '~/components/core/EmptyState';
import RoundEmptyStateWrapper from '~/components/core/EmptyState/RoundEmptyStateWrapper';
import { useLobConfiguration } from '~/components/hooks/useLobConfiguration';

import {
  COVERAGE_CONFIGURATION_LIMITS,
  DAMAGE_ASSESSMENT_TYPES,
  INVOLVED_DICT_PER_CLAIM_TYPE,
  LOSS_TYPES,
} from '../../../Types';
import { isLocaleRegionIsUs, subOrgIdToNameDict } from '../../../Utils';
import { getLobDescription, getLobIcon } from '../../../Utils/lobUtils';
import CardDialog from '../../CardDialog';
import { LoadingSwitch, MultiSelectFilter, SortableTable, Text } from '../../core';
import { CheckIcon, PencilIcon, PolicyIcon, RemoveIcon, TrashIcon_Deprecated } from '../../icons';
import InlineIconButton from '../../InlineIconButton';
import { usePrevious } from '../../TextFieldFormik';

import { CoverageDialog } from './CoverageDialog';
import { SharedCoverageDialog } from './SharedCoverageDialog';

import { useStyles } from '../../../assets/styles';
import styles from './coverageConfiguration.module.scss';

const COVERAGES_SUBTITLE = 'Manage your organization coverages.';

const ExposureConfigurationTool = ({
  isLoading,
  isError,
  organization,
  supportedClaimTypes,
  coverages,
  sharedCoverages,
  coverageConfiguration,
  onUpdateCoverage,
  onCreateCoverage,
  onUpdateSharedCoverage,
  onCreateSharedCoverage,
  onToggleGeneralExpenses,
  onUpdateCoverageConfiguration,
  onDeleteCoverage,
  onDeleteSharedCoverage,
  enableRemovingExistingCoverages,
  fetchUsedCoveragesKeys,
  fetchUsedSharedCoveragesKeys,
  fetchCoverages,
  errorHandler,
  hideExposureConfigurationFilters,
  wizardLob,
  overrideDisabled,
}) => {
  const [searchText, setSearchText] = useState('');
  const [selectedLOB, setSelectedLOB] = useState([]);
  const [selectedSubOrgs, setSelectedSubOrgs] = useState([]);
  const [showCoverageDialog, setShowCoverageDialog] = useState(false);
  const [showSharedCoverageDialog, setShowSharedCoverageDialog] = useState(false);
  const [coverageToEdit, setCoverageToEdit] = useState(null);
  const [sharedCoverageToEdit, setSharedCoverageToEdit] = useState(null);
  const subOrgIdToName = subOrgIdToNameDict(organization);
  const shouldShowISOCode = isLocaleRegionIsUs();
  const prevSelectedSubOrgsRef = usePrevious(selectedSubOrgs);
  const { lobConfigurationsDict = {} } = useLobConfiguration();

  useEffect(() => {
    if (selectedSubOrgs.includes('all') && !prevSelectedSubOrgsRef?.includes('all')) {
      setSelectedSubOrgs(['all']);
    } else if (
      prevSelectedSubOrgsRef?.includes('all') &&
      selectedSubOrgs.includes('all') &&
      selectedSubOrgs.length > 1
    ) {
      setSelectedSubOrgs(selectedSubOrgs.filter((e) => e !== 'all'));
    }
  }, [selectedSubOrgs, setSelectedSubOrgs, prevSelectedSubOrgsRef]);

  const classes = useStyles();

  const isCoverageFitFilters = useCallback(
    (coverage) => {
      const doesMatchText =
        (coverage.display_name?.toLowerCase() || '').includes(searchText.toLowerCase()) ||
        (coverage.coverage_key?.toLowerCase() || '').includes(searchText?.toLowerCase());
      const doesMatchLOB = selectedLOB.length === 0 || selectedLOB.includes(coverage.lob);
      const doesMatchSubOrgs =
        selectedSubOrgs.length === 0 ||
        coverage.is_org_level ||
        selectedSubOrgs.every((subOrg) =>
          coverage.sub_organizations.some((coverageSubOrg) => coverageSubOrg.id === subOrg)
        );

      return doesMatchText && doesMatchLOB && doesMatchSubOrgs;
    },
    [searchText, selectedLOB, selectedSubOrgs]
  );

  const isSharedCoverageFitFilters = useCallback(
    (sharedCoverage) => {
      const doesMatchText =
        sharedCoverage.coverage_config_display_names
          .concat(sharedCoverage.coverage_config_keys)
          .some((x) => (x?.toLowerCase() || '').includes(searchText.toLowerCase())) ||
        `${sharedCoverage.shared_coverage_key?.toLowerCase() || ''}`.includes(searchText.toLowerCase());
      const doesMatchLOB = selectedLOB.length === 0 || selectedLOB.includes(sharedCoverage.lob);
      const doesMatchSubOrgs =
        selectedSubOrgs.length === 0 ||
        sharedCoverage.is_org_level ||
        selectedSubOrgs.every((subOrg) =>
          sharedCoverage.sub_organizations.some((coverageSubOrg) => coverageSubOrg.id === subOrg)
        );

      return doesMatchText && doesMatchLOB && doesMatchSubOrgs;
    },
    [searchText, selectedLOB, selectedSubOrgs]
  );

  const onClose = () => {
    setCoverageToEdit(null);
    setShowCoverageDialog(false);
  };

  const convertServerCoverageConfigToFormikValues = (coverageConfig) => {
    return {
      ...coverageConfig,
      sub_organization_ids: coverageConfig.is_org_level
        ? ['all']
        : coverageConfig.sub_organizations.map((sub_org) => sub_org.id),
    };
  };

  const yesOption = <CheckIcon width={18} height={18} iconColor="#909090" />;

  const actionsCell = ({ onDelete, onEdit }) => ({
    id: 'actions',
    numeric: false,
    label: 'Actions',
    specialCell: (config) => (
      <div>
        <InlineIconButton
          icon={PencilIcon}
          tooltipTitle="Edit"
          className={`${classes.textIcon} ${classes.marginedIcon} ${classes.hoverableNonFilledIcon}`}
          onClick={() => onEdit(config)}
          wrapWithSpan
        />
        {onDelete && enableRemovingExistingCoverages && (
          <InlineIconButton
            icon={TrashIcon_Deprecated}
            tooltipTitle="Delete"
            className={`${classes.textIcon} ${classes.marginedIcon} ${classes.hoverableNonFilledIcon}`}
            onClick={() => onDelete(config)}
            wrapWithSpan
          />
        )}
      </div>
    ),
  });

  const getInvolvedTypeDescription = (coverageConfig, involved_type, lobConfigurationsDict) => {
    return (
      INVOLVED_DICT_PER_CLAIM_TYPE[coverageConfig?.lob]?.[involved_type]?.desc ||
      lobConfigurationsDict[coverageConfig?.lob]?.involved_dict?.[involved_type]?.desc
    );
  };

  const columnsData = [
    {
      id: 'display_name',
      numeric: false,
      label: 'Display Name',
      specialCell: (coverageConfig) => coverageConfig.display_name,
    },
    {
      id: 'coverage_key',
      numeric: false,
      label: 'API Key',
      specialCell: (coverageConfig) => coverageConfig.coverage_key,
    },
    {
      id: 'iso_code',
      numeric: false,
      label: 'ISO Code',
      specialCell: (coverageConfig) => <TextCell value={coverageConfig.iso_code} />,
    },
    {
      id: 'damage_assessment_type',
      numeric: false,
      label: 'Damage Assessment Type',
      specialCell: (coverageConfig) => (
        <TextCell value={DAMAGE_ASSESSMENT_TYPES[coverageConfig?.damage_assessment_type]} />
      ),
    },
    {
      id: 'sub_organizations',
      numeric: false,
      label: 'Sub-Organizations',
      specialCell: (coverageConfig) => {
        return coverageConfig.is_org_level ? (
          <Chip key="all" size="small" label="All" />
        ) : (
          coverageConfig.sub_organizations?.map((subOrg) => (
            <Chip key={subOrg.id} size="small" label={subOrg.name} className={classes.chip} />
          ))
        );
      },
    },
    {
      id: 'lob',
      numeric: false,
      label: 'LOB',
      specialCell: (coverageConfig) => (
        <TextCell value={getLobDescription(coverageConfig.lob, lobConfigurationsDict)} />
      ),
    },
    {
      id: 'loss_type',
      numeric: false,
      label: 'Loss Type',
      specialCell: (coverageConfig) => <TextCell value={LOSS_TYPES[coverageConfig.loss_type]?.desc} />,
    },
    {
      id: 'involved_types',
      numeric: false,
      label: 'Involved Types',
      specialCell: (coverageConfig) =>
        coverageConfig.involved_types.map((involved_type) => (
          <Chip
            key={involved_type}
            size="small"
            label={getInvolvedTypeDescription(coverageConfig, involved_type, lobConfigurationsDict)}
            className={classes.chip}
          />
        )),
    },
    {
      id: 'coverage_group_display_name',
      label: 'Coverage Group',
    },
    {
      id: 'is_limit_per_person',
      numeric: false,
      label: 'Limit Per Person',
      specialCell: (coverageConfig) => (coverageConfig.is_limit_per_person ? yesOption : ''),
    },
    {
      id: 'is_limit_per_incident',
      numeric: false,
      label: 'Limit Per Incident',
      specialCell: (coverageConfig) => (coverageConfig.is_limit_per_incident ? yesOption : ''),
    },
    {
      id: 'is_deductible',
      numeric: false,
      label: 'Has Deductible',
      specialCell: (coverageConfig) => (coverageConfig.is_deductible ? yesOption : ''),
    },
    {
      id: 'is_limit_per_policy_per_entity',
      numeric: false,
      label: 'Limit Per Policy Per Entity',
      specialCell: (coverageConfig) => (coverageConfig.is_limit_per_policy_per_entity ? yesOption : ''),
    },
    actionsCell({
      onDelete: onDeleteCoverage,
      onEdit: (config) => {
        setCoverageToEdit(convertServerCoverageConfigToFormikValues(config));
        setShowCoverageDialog(true);
      },
    }),
  ]
    .filter((column) => column.id !== 'iso_code' || shouldShowISOCode)
    .filter((column) => column.id !== 'sub_organizations' || organization.sub_organizations_enabled);

  const sharedCoveragesColumnsData = [
    { id: 'shared_coverage_key', numeric: false, label: 'API Key' },
    {
      id: 'coverage_config_display_names',
      numeric: false,
      label: 'Coverages',
      specialCell: (sharedCoverageConfig) => sharedCoverageConfig.coverage_config_display_names.join(', '),
    },
    {
      id: 'sub_organizations',
      numeric: false,
      label: 'Sub-Organizations',
      specialCell: (sharedCoverageConfig) =>
        sharedCoverageConfig.is_org_level ? (
          <Chip key="all" size="small" label="All" />
        ) : (
          sharedCoverageConfig.sub_organizations.map((subOrg) => (
            <Chip key={subOrg.id} size="small" label={subOrg.name} className={classes.chip} />
          ))
        ),
    },
    {
      id: 'lob',
      numeric: false,
      label: 'LOB',
      specialCell: (sharedCoverageConfig) => (
        <TextCell value={getLobDescription(sharedCoverageConfig.lob, lobConfigurationsDict)} />
      ),
    },
    {
      id: 'is_limit_per_incident',
      numeric: false,
      label: 'Limit Per Incident',
      specialCell: (sharedCoverageConfig) => (sharedCoverageConfig.is_limit_per_incident ? yesOption : ''),
    },
    actionsCell({
      onDelete: onDeleteSharedCoverage,
      onEdit: (config) => {
        setSharedCoverageToEdit(convertServerCoverageConfigToFormikValues(config));
        setShowSharedCoverageDialog(true);
      },
    }),
  ].filter((column) => column.id !== 'sub_organizations' || organization.sub_organizations_enabled);

  const coveragesFiltered = coverages?.filter(isCoverageFitFilters);

  const shouldShowLobFilter = (supportedClaimTypes?.length ?? 0) > 1;

  const addCoverageButton = (text, onClick) => (
    <Button color="primary" onClick={onClick} style={{ marginRight: 10, float: 'right', marginBottom: '14px' }}>
      <AddIcon className={classes.leftButtonIcon} />
      {text}
    </Button>
  );

  const getEmptyStateComponent = (text, onClick) => (
    <EmptyState
      subtitle="No coverages added yet"
      illustration={
        <RoundEmptyStateWrapper>
          <PolicyIcon />
        </RoundEmptyStateWrapper>
      }
      buttonComponent={addCoverageButton(text, onClick)}
    />
  );

  return (
    <LoadingSwitch isLoading={isLoading || !coverages} isError={isError}>
      <CardDialog noCardTitle>
        <Text weight="regular" variant="small" className={styles.title}>
          {COVERAGES_SUBTITLE}
        </Text>
        <Grid container spacing={2} style={{ marginTop: '20px' }}>
          {coverages?.length > 0 && (
            <>
              <Grid item xs={12} style={{ marginTop: '15px', marginBottom: '20px' }}>
                <Typography
                  display="block"
                  variant="subtitle2"
                  style={{ paddingTop: '8px', fontWeight: 'bold', fontSize: '16px' }}
                >
                  Filters
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <TextField
                  className={classes.formTextField}
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                  InputProps={{
                    placeholder: 'Search Coverage',
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton title="Clear" onClick={() => setSearchText('')}>
                          <RemoveIcon className={classes.hoverableNonStrokedIcon} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={10} />
              <Grid container spacing={4}>
                {shouldShowLobFilter && (
                  <>
                    <Grid item xs={2}>
                      <MultiSelectFilter
                        label="By LOB"
                        value={selectedLOB}
                        onChange={setSelectedLOB}
                        options={supportedClaimTypes}
                        renderOption={(lob) => (
                          <>
                            <div style={{ display: 'flex' }}>
                              <span style={{ marginRight: '10px' }}>{getLobIcon({ lob, lobConfigurationsDict })}</span>
                              <Typography display="block" variant="body1">
                                {getLobDescription(lob, lobConfigurationsDict)}
                              </Typography>
                            </div>
                          </>
                        )}
                        withOptionChips
                      />
                    </Grid>
                    <Grid item xs={1} />
                  </>
                )}
                {organization.sub_organizations_enabled && (
                  <Grid item xs={2}>
                    <MultiSelectFilter
                      label="By Sub-organization"
                      value={selectedSubOrgs}
                      onChange={setSelectedSubOrgs}
                      options={['all'].concat(organization.sub_organizations.map((subOrg) => subOrg.id))}
                      renderOption={(subOrgId) =>
                        subOrgId === 'all' ? 'All sub-organizations' : subOrgIdToName[subOrgId]
                      }
                      withOptionChips
                    />
                  </Grid>
                )}
              </Grid>
            </>
          )}
        </Grid>
        {coverages?.length > 0 && addCoverageButton('ADD COVERAGE', () => setShowCoverageDialog(true))}
        <Grid item xs={12} style={{ marginTop: '15px', marginBottom: '20px' }}>
          {coverages?.length > 0 && (
            <Typography style={{ paddingTop: '8px', fontWeight: 'bold', fontSize: '16px' }}>Coverages</Typography>
          )}
        </Grid>
        <SortableTable
          columns={columnsData}
          rows={coveragesFiltered}
          stickyHeader
          maxHeight="50vh"
          emptyStateComponent={getEmptyStateComponent('ADD COVERAGE', () => setShowCoverageDialog(true))}
        />
        {coverages?.length > 0 && (
          <>
            <Grid item xs={12} style={{ marginTop: '20px' }} />
            {sharedCoverages?.length > 0 &&
              addCoverageButton('Add Shared Coverage', () => setShowSharedCoverageDialog(true))}
            <Grid item xs={12} style={{ marginTop: '15px', marginBottom: '20px' }}>
              {sharedCoverages?.length > 0 && (
                <Typography style={{ paddingTop: '8px', fontWeight: 'bold', fontSize: '16px' }}>
                  Shared Limits
                </Typography>
              )}
            </Grid>
            <SortableTable
              columns={sharedCoveragesColumnsData}
              rows={sharedCoverages?.filter(isSharedCoverageFitFilters)}
              stickyHeader
              maxHeight="50vh"
              emptyStateComponent={getEmptyStateComponent('ADD SHARED COVERAGE', () =>
                setShowSharedCoverageDialog(true)
              )}
            />
          </>
        )}
        {!hideExposureConfigurationFilters && (
          <>
            <div className={classes.cardDivRow}>
              <Switch
                checked={!coverageConfiguration['is_general_expenses_disabled']}
                onChange={onToggleGeneralExpenses}
                className={classes.formsSwitch}
                size="small"
              />
              Add General Expenses Exposure
            </div>
            <Grid container spacing={1} className={classes.cardDivRow} style={{ marginTop: '20px' }}>
              {Object.keys(COVERAGE_CONFIGURATION_LIMITS).map((limitType) => (
                <>
                  <Grid item xs={12}>
                    <Switch
                      checked={coverageConfiguration[limitType]}
                      onChange={(_, checked) => onUpdateCoverageConfiguration(limitType, checked)}
                      className={classes.formsSwitch}
                      size="small"
                    />
                    {COVERAGE_CONFIGURATION_LIMITS[limitType]?.desc}
                  </Grid>
                </>
              ))}
            </Grid>
          </>
        )}
        {showCoverageDialog && (
          <CoverageDialog
            organization={organization}
            supportedClaimTypes={supportedClaimTypes}
            coverage={coverageToEdit}
            onClose={onClose}
            onSubmit={coverageToEdit ? onUpdateCoverage : onCreateCoverage}
            fetchUsedCoveragesKeys={fetchUsedCoveragesKeys}
            errorHandler={errorHandler}
            wizardLob={wizardLob}
            overrideDisabled={overrideDisabled}
          />
        )}
        {showSharedCoverageDialog && (
          <SharedCoverageDialog
            organization={organization}
            supportedClaimTypes={supportedClaimTypes}
            sharedCoverage={sharedCoverageToEdit}
            onClose={() => {
              setSharedCoverageToEdit(null);
              setShowSharedCoverageDialog(false);
            }}
            onSubmit={sharedCoverageToEdit ? onUpdateSharedCoverage : onCreateSharedCoverage}
            fetchUsedSharedCoveragesKeys={fetchUsedSharedCoveragesKeys}
            fetchCoverages={fetchCoverages}
            errorHandler={errorHandler}
            wizardLob={wizardLob}
            overrideDisabled={overrideDisabled}
          />
        )}
      </CardDialog>
    </LoadingSwitch>
  );
};

ExposureConfigurationTool.propTypes = {
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  organization: PropTypes.object,
  supportedClaimTypes: PropTypes.arrayOf(PropTypes.string),
  coverages: PropTypes.arrayOf(PropTypes.object),
  sharedCoverages: PropTypes.arrayOf(PropTypes.object),
  coverageConfiguration: PropTypes.object,
  onUpdateCoverage: PropTypes.func.isRequired,
  onCreateCoverage: PropTypes.func.isRequired,
  onUpdateSharedCoverage: PropTypes.func.isRequired,
  onCreateSharedCoverage: PropTypes.func.isRequired,
  onToggleGeneralExpenses: PropTypes.func.isRequired,
  onUpdateCoverageConfiguration: PropTypes.func.isRequired,
  onDeleteCoverage: PropTypes.func,
  onDeleteSharedCoverage: PropTypes.func,
  enableRemovingExistingCoverages: PropTypes.bool,
  fetchUsedCoveragesKeys: PropTypes.func.isRequired,
  fetchUsedSharedCoveragesKeys: PropTypes.func.isRequired,
  fetchCoverages: PropTypes.func.isRequired,
  errorHandler: PropTypes.func.isRequired,
  hideExposureConfigurationFilters: PropTypes.bool,
  wizardLob: PropTypes.string,
  overrideDisabled: PropTypes.bool,
};

const TextCell = ({ value }) => <span style={{ textTransform: 'capitalize' }}>{value}</span>;

TextCell.propTypes = {
  value: PropTypes.string,
};

export { ExposureConfigurationTool };
