import React from 'react';
import PropTypes from 'prop-types';
import AddIcon from '@material-ui/icons/Add';
import { FieldArray, useFormikContext } from 'formik';
import { get } from 'lodash';

import Button from '~/components/core/Atomic/Buttons/Button';
import { ErrorHelperTextFormik } from '~/components/core/Formik/ErrorHelperTextFormik';

import ThreeDotsMenu from '../../../../../core/ThreeDotsMenu';
import MoveArrows from '../../MoveArrows/MoveArrows';
import SortAzButton from '../../SortAzButton/SortAzButton';
import BaseConfigurationSection from '../BaseConfigurationSection';

import IncidentTypeConfigurationCard from './IncidentTypeConfigurationCard';

const ID_PREFIX = 'configuration.incident_details.types';

const getNewField = ({ index }) => ({
  id: `incident_type_${index}`,
  desc: `Incident Type ${index}`,
  active: true,
});

const IncidentTypesSubTypesConfigurationArray = ({ disabled = false }) => {
  const { values, initialValues } = useFormikContext();

  const initialIncidentTypes = get(initialValues, ID_PREFIX);
  const incidentTypes = get(values, ID_PREFIX);

  const isDeleteAllowed = (index) => !initialIncidentTypes?.find((f) => f.id === incidentTypes?.[index]?.id);

  return (
    <BaseConfigurationSection
      title="Edit Incident Type and Subtype"
      noGridContainer
      actions={<SortAzButton fieldId={ID_PREFIX} sortKey="desc" disabled={disabled} />}
    >
      <FieldArray
        name={ID_PREFIX}
        render={(arrayHelpers) => (
          <div className="mr-6 flex flex-col gap-10">
            {Array.isArray(incidentTypes) &&
              incidentTypes.map((_, idx) => (
                <div className="row-auto flex w-full justify-between" key={idx}>
                  <IncidentTypeConfigurationCard
                    fieldId={`${ID_PREFIX}[${idx}]`}
                    actions={
                      <div className="flex items-center justify-center">
                        <MoveArrows
                          onMoveUp={() => {
                            arrayHelpers.move(idx, Math.max(idx - 1, 0));
                          }}
                          onMoveDown={() => {
                            arrayHelpers.move(idx, Math.min(idx + 1, incidentTypes?.length - 1));
                          }}
                          disabled={disabled}
                        />
                        <ThreeDotsMenu
                          stopPropagation
                          disabled={!isDeleteAllowed(idx)}
                          options={[
                            {
                              onClick: () => arrayHelpers.remove(idx),
                              node: 'Remove',
                              key: 'remove',
                              disabled: !isDeleteAllowed(idx),
                            },
                          ]}
                        />
                      </div>
                    }
                  />
                </div>
              ))}
            <ErrorHelperTextFormik id={ID_PREFIX} withoutChildren />
            <div className="flex">
              <Button
                variant="text"
                color="primary"
                onClick={() => arrayHelpers.push(getNewField({ index: incidentTypes?.length + 1 }))}
                disabled={disabled}
              >
                <AddIcon />
                ADD INCIDENT TYPE
              </Button>
            </div>
          </div>
        )}
      />
    </BaseConfigurationSection>
  );
};

IncidentTypesSubTypesConfigurationArray.propTypes = {
  fieldId: PropTypes.string.isRequired,
  section: PropTypes.string,
  disabled: PropTypes.bool,
};

export default IncidentTypesSubTypesConfigurationArray;
