import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';

import Chip from '~/components/core/Atomic/Chip/Chip';

import { FsTooltip } from '../../../components/core';
import cn from '../../../Utils/cn';

export const ChipsPanel = ({ chips, size, onDelete }) => {
  return (
    <div className="flex flex-wrap gap-10">
      {chips.map((chip) => (
        <FsTooltip title={chip.tooltip} key={chip.id} placement="top" arrow disabled={!chip.tooltip}>
          <Chip
            id={chip.id}
            label={chip.text}
            size={size}
            disabled={chip.disabled}
            onDelete={chip.allowDelete ? (e) => onDelete({ e, value: chip }) : null}
            classes={{ root: cn('bg-slate-400', { ['bg-teal-200']: chip.color === 'primary' }) }}
          />
        </FsTooltip>
      ))}
    </div>
  );
};

ChipsPanel.propTypes = {
  chips: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      text: PropTypes.string,
      tooltip: PropTypes.string,
      disabled: PropTypes.bool,
      allowDelete: PropTypes.bool,
      color: PropTypes.oneOf(['default', 'primary', 'secondary']),
    })
  ),
  size: PropTypes.oneOf(['small', 'medium']),
  onDelete: PropTypes.func,
};

ChipsPanel.defaultProps = {
  chips: [],
  size: 'small',
  onDelete: noop,
};
