import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Tooltip } from '@material-ui/core';

import WEBHOOK_CATEGORIES from '~/server_shared/generated-types/WEBHOOK_CATEGORIES';

import PlainTable from '../../../../../PlainTable';

import { useStyles } from '../../../../../../assets/styles';

const WebhooksInnerTable = ({ onUpdate, webhookEventCategory, eventsData, category, showOnly }) => {
  const classes = useStyles();
  const eventsForCategory = eventsData ? eventsData.filter((event) => event.category === category) : [];

  const handleSwitchChange = (currentEventName, checked) => {
    if (checked) {
      onUpdate({ events: [...(webhookEventCategory?.events ?? []), currentEventName] }, category);
    } else {
      const filteredEvents = webhookEventCategory?.events.filter((event) => event !== currentEventName);
      onUpdate({ events: filteredEvents }, category);
    }
  };

  const columns = [
    { id: 'desc', label: `${WEBHOOK_CATEGORIES[category]} Event Name`, width: '80%' },

    {
      id: '_',
      label: 'Enable',
      width: '100%',
      specialCell: (row) => (
        <Tooltip title={showOnly ? 'No URL Defined' : ''}>
          <span>
            <Switch
              checked={webhookEventCategory?.events?.includes(row.name)}
              onChange={(e, checked) => {
                handleSwitchChange(row.name, checked);
              }}
              className={classes.formsSwitch}
              disabled={showOnly}
            />
          </span>
        </Tooltip>
      ),
    },
  ];

  return (
    <>
      <PlainTable rows={eventsForCategory} columns={columns} order="desc" keepRowsOrder />
    </>
  );
};

WebhooksInnerTable.propTypes = {
  onUpdate: PropTypes.func,
  webhookEventCategory: PropTypes.object,
  eventsData: PropTypes.array,
  category: PropTypes.string,
  showOnly: PropTypes.bool,
};

export default WebhooksInnerTable;
