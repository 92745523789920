import React from 'react';

import CardDialog from '~/components/CardDialog';
import InnerTabs from '~/components/core/Layout/InnerTabs/InnerTabs';
import GenericTemplatesTableContainer from '~/components/GenericTemplates/GenericTemplatesConfiguration/GenericTemplatesTableContainer';
import OperationsBreadcrumbs from '~/components/SystemConfiguration/OperationsBreadcrumbs';

const GenericTemplatesConfigurationScreen: React.FC = () => {
  const tabs = [
    {
      label: 'All',
      url: 'all_templates',
      isTabHidden: false,
      component: GenericTemplatesTableContainer,
      props: {
        templateType: '',
        isCreateTemplateEnabled: false,
        shouldDisplayType: true,
      },
    },
    {
      label: 'Notes',
      url: 'notes_templates',
      isTabHidden: false,
      component: GenericTemplatesTableContainer,
      props: {
        templateType: 'note',
        isCreateTemplateEnabled: true,
        shouldDisplayType: false,
      },
    },
    {
      label: 'Email',
      url: 'email_templates',
      isTabHidden: false,
      component: GenericTemplatesTableContainer,
      props: {
        templateType: 'email',
        isCreateTemplateEnabled: true,
        shouldDisplayType: false,
      },
    },
    {
      label: 'SMS',
      url: 'sms_templates',
      isTabHidden: false,
      component: GenericTemplatesTableContainer,
      props: {
        templateType: 'sms',
        isCreateTemplateEnabled: true,
        shouldDisplayType: false,
      },
    },
  ];

  return (
    <div className="m-20">
      <OperationsBreadcrumbs currentTab="Templates" />
      <CardDialog title="Templates">
        <InnerTabs tabs={tabs} />
      </CardDialog>
    </div>
  );
};

export default GenericTemplatesConfigurationScreen;
