export const ADMIN_ROUTES = {
  IT_SECURITY: 'it',
  DEVELOPER_TOOLS: {
    MAIN: 'developer_tools',
    SERVICE_ACCOUNTS: 'service_accounts',
    EVENT_SETTINGS: 'event_settings',
    POLICY_API_CONFIG: 'policy_api_config',
    VENDOR_API_PROFILES: 'vendor_api_profiles',
  },
  FEATURES: 'features',
  ORGANIZATION: {
    MAIN: 'organization',
    ADJUSTERS: 'adjusters',
    INSURED_PROPERTIES: 'insured_properties',
    ORGANIZATION_EMAILS: 'organization_emails',
    SPECIAL_USERS: 'special_users',
    SPECIAL_CONTACTS: 'special_contacts',
    SPECIAL_USER_LISTS: 'special_user_lists',
    ORGANIZATION_COUNTRIES: 'organization_countries',
    ORGANIZATION_LINES_OF_BUSINESSES: 'organization_lobs',
    CLAIM_NUMBERING_SCHEME: 'claim_numbering',
    CATASTROPHIC_EVENTS: 'catastrophic_events',
  },
  AUTOMATION: {
    MAIN: 'automation',
    ASSIGNMENTS: 'assignments',
    EXPOSURES: 'exposures',
    NOTIFICATIONS: 'notifications',
  },
  COVERAGES: {
    MAIN: 'coverages',
    COVERAGES: 'coverages',
    COVERAGES_GROUPS: 'groups',
  },
  TRIAGE_RESERVES: 'reserves',
  CORRESPONDENCE: 'correspondence',
  INTEGRATIONS: 'integrations',
  VENDORS: 'vendors',
  COMMUNICATIONS: 'communications',
  GENERAL_EMAIL_CONFIG: 'general_email_config',
  LOGIN_CONFIGURATION: 'login_configuration',
  MOI_CONFIG: 'moi_config',
  EXPOSURE_CONFIG: 'coverage_config',
  INCIDENT_CONFIG: 'incident_config',
  PAYMENTS_CONFIGURATION: 'payments_configuration',
  PERMISSION_PROFILES: 'permission_profiles',
  TABLEAU_ACCOUNTS: 'tableau_accounts',
  CLAIM_CONFIG: {
    MAIN: 'claim_config',
    CLOSING_REASON: 'closing_reason',
  },
  FNOL_CONFIGURATION: 'fnol_configuration',
  POLICY_CONFIGURATION: 'policy_configuration',
  GENERIC_TEMPLATES_CONFIGURATION: 'generic_templates_configuration',
  CONTACT_ROLES: 'contact_roles',
};
